const UPDATE_QUOTE_PENDING = "UPDATE_QUOTE_PENDING";
const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";
const UPDATE_QUOTE_FAILED = "UPDATE_QUOTE_FAILED";
const CREATE_SCHEDULE_PENDING = "CREATE_SCHEDULE_PENDING";
const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS"; 
const CREATE_SCHEDULE_FAILED = "CREATE_SCHEDULE_FAILED"; 
const GET_VENDORS_PENDING = "GET_VENDORS_PENDING";
const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
const GET_VENDORS_FAILED = "GET_VENDORS_FAILED";
const GET_VEHICLES_BY_VENDOR_PENDING= "GET_VEHICLES_BY_VENDOR_PENDING";
const GET_VEHICLES_BY_VENDOR_SUCCESS= "GET_VEHICLES_BY_VENDOR_SUCCESS";
const GET_VEHICLES_BY_VENDOR_FAILED = "GET_VEHICLES_BY_VENDOR_FAILED ";
const EMPTY_VEHICLES_BY_VENDOR_ID = "EMPTY_VEHICLES_BY_VENDOR_ID";

export default {
    UPDATE_QUOTE_PENDING,
    UPDATE_QUOTE_SUCCESS,
    UPDATE_QUOTE_FAILED,
    CREATE_SCHEDULE_PENDING,
    CREATE_SCHEDULE_SUCCESS,
    CREATE_SCHEDULE_FAILED,
    GET_VENDORS_PENDING,
    GET_VENDORS_FAILED,
    GET_VENDORS_SUCCESS,
    GET_VEHICLES_BY_VENDOR_PENDING,
    GET_VEHICLES_BY_VENDOR_SUCCESS,
    GET_VEHICLES_BY_VENDOR_FAILED,
    EMPTY_VEHICLES_BY_VENDOR_ID,
}