import { LOGIN, TICKETS } from "../constants";
import { formatAMPM } from '../utils/helper';

const LOGIN_STATE = {
  isPending: false,
  userId: null,
  authenticated: false,
  loginErr: false,
  loginTime: false,
	userToken: null,
};

export const loginReducer = (state = LOGIN_STATE, action) => {
	switch (action.type) {
		case LOGIN.LOGIN_PENDING:
			return Object.assign({}, state, { isPending: true });

		// case LOGIN.LOGIN_SUCCESS:
		// 	return {
		// 		...state,
		// 		userId: action.payload.userId,
		// 		userToken: action.payload.userToken,
		// 		authenticated: true,
		// 		loginErr: false,
		// 		loginTime: formatAMPM(new Date())
		// 	};

		case LOGIN.SET_LOCALSTORAGE_AUTH_TRUE:
			return {
				...state,
				userId: action.payload.userId,
				userToken: action.payload.userToken,
				authenticated: true,
				loginErr: false,
				loginTime: formatAMPM(new Date())
			};

		case LOGIN.LOGIN_FAILED:
			localStorage.clear();
			localStorage.setItem("authenticated", false);
			return {
				...state,
				authenticated: false,
				loginErr: action.payload || false,
			};

		case TICKETS.CREATE_TICKET_FAILED:
			return Object.assign({}, state, {
				responseStatus: action.payload.meta,
				isPending: false
			});

		// case LOGIN.SET_LOCALSTORAGE_AUTH_TRUE:
		// 	return Object.assign({}, state, {
		// 		authenticated: true
		// 	});
			
		case LOGIN.LOGOUT:
			localStorage.clear();
			localStorage.setItem("authenticated", false)
			return Object.assign({}, state, {
				authenticated: false
			});

		default:
			return state;
	}
};

const CREDENTIAL_STATE = {
  userName: "",
  password: ""
};

export const userCredentialReducer = (state = CREDENTIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN.SET_USER_NAME:
      return Object.assign({}, state, {
        userName: action.payload
      });
    case LOGIN.SET_USER_PASSWORD:
      return Object.assign({}, state, {
        password: action.payload
      });
    default:
      return state;
  }
};
