import mixpanel from 'mixpanel-browser';
mixpanel.init('420c0d920780e169cc86c05db04f46e7', { debug: false, ignore_dnt: true });

// let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    // console.log(id)
    // if (env_check) mixpanel.identify(id);
    mixpanel.identify(id);
  },
  alias: (id) => {
    // console.log(id)
    // if (env_check) mixpanel.alias(id);
    mixpanel.alias(id);
  },
  track: (name, props, username) => {
    if(username){
      props = {
        ...props,
        username: localStorage.getItem('username')
      }
    }
    // console.log(name,props)
    // if (env_check) mixpanel.track(name, props);
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
    // console.log(props)
      // if (env_check) mixpanel.people.set(props);
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;