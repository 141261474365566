import { takeEvery } from "redux-saga/effects";
import { UPDATE_TICKET } from '../constants';
// import { validationFields } from '../constants/validationConst';
import { updateAllTicketDetails } from '../actions/ticketAction';
import { updateTicketAction } from '../actions/quoteAndBookAction';
import store from '../store';
// import { patientDetailsValid } from '../actions/validTicketAction';
import { ignoreCaseStringEquals } from '../utils/helper';
import { Mixpanel } from './../utils/mixpanelUtil';

function deepEqual(diff, object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (areObjects === false && val1 !== val2 && !Array.isArray(val1) && !Array.isArray(val2)) {
      diff = { ...diff, [key]: val2 };
    }
    if (areObjects) {
      diff = deepEqual(diff, val1, val2)
    };
  }
  return diff;
}

function isObject(object) {
  return object != null && typeof object === 'object' && !Array.isArray(object);
}

const handleTrack = (ticketData) => {
  let { patientInfo } = store.getState();
  let fetchedTicketData = { ...patientInfo.fetchedTicketData };
  let ticketStage = ticketData.ticketStage;
  let diff = {};
  diff = deepEqual(diff, fetchedTicketData, ticketData);
  let ticketUpdatedAt = ignoreCaseStringEquals(ticketStage, 'patientinfo') ? 'Patient Info Updated'
    : ((ignoreCaseStringEquals(ticketStage, 'confirmed') ||
      ignoreCaseStringEquals(ticketStage, 'hold') ||
      ignoreCaseStringEquals(ticketStage, 'cancelled') ||
      ignoreCaseStringEquals(ticketStage, 'quoteandbook')) ?
      'Quote & Book Updated'
      : (ignoreCaseStringEquals(ticketStage, 'closed') ? 'Ticket Closed' : 'Ticket Updated')
    )
  Mixpanel.track(ticketUpdatedAt, diff, true)
}

function* updateTicketDetails({ payload }) {
  console.log('updateObjpayload', payload);
  let { patientInfo } = store.getState();
  let updatedTicket = { ...patientInfo.ticketData };
  if (payload.detailsType && payload.detailsType !== "ticketDetails") {
    updatedTicket = {
      ...updatedTicket,
      [payload.detailsType]: {
        ...updatedTicket[payload.detailsType],
        ...payload.data
      }
    }
  } else if (payload.detailsType && payload.detailsType === "ticketDetails") {
    updatedTicket = {
      ...updatedTicket,
      ...payload.data,
    }
  }
  store.dispatch(updateAllTicketDetails(updatedTicket));
  if (payload.callback && typeof payload.callback === "function" && updatedTicket) {
    let update = { ...updatedTicket, ownerId: localStorage.getItem('username') };
    handleTrack(update);
    store.dispatch(updateTicketAction(update, payload.callback));
  }
}

export default function* updateTicketSaga() {
  yield takeEvery(UPDATE_TICKET.UPDATE_TICKET_DETAILS, updateTicketDetails)
}