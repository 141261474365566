import React from 'react'
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import "./style.scss";

function StepProgress(props) {
	const { listItems = [], activeStep } = props;
  let activeStepPercent = 0;
  let stepNum = 0;
  listItems.forEach((l) => {
    if(Array.isArray(l.values) && l.values.includes(activeStep)){
      activeStepPercent = l.stepPercent;
      stepNum = l.step;
    }
  });
  let currentStep = false
	return (
    <div className={`${props.class} ${activeStepPercent > 33 ? " green" : " orange"}`}>
      <ProgressBar percent={activeStepPercent} >
        { 
          listItems.map((l) => {
            let stepVal = stepNum + 1;
            return <Step key={l.step} >
              {({ accomplished, index }) => {
                let stepcondition = ((!accomplished && !currentStep && stepNum) || (!index && !stepNum && !currentStep))
                if(stepcondition){currentStep = true};
                let className = accomplished && stepNum ? "accomplished" :  (stepcondition ? "current-step" : "");
                return ( <div className="step">
                  <div className={`indexedStep ${className}`}>
                    {index + 1}
                  </div>
                  <span key={l.step}  className={`${l.step < stepVal ? "done" : (l.step === stepVal ? "current" : "incomplete")} ${l.className}`}>{l.label}</span>
                </div>
              )}}
            </Step>
          })
        }
      </ProgressBar>
      {/* <div className="step-labels">
        {listItems.map((l) => {
          let stepVal = stepNum + 1;
          return <span key={l.step}  className={l.step < stepVal ? "done" : (l.step === stepVal ? "current" : "incomplete")}>{l.label}</span>
        })} */}
      {/* </div> */}
    </div>
	)
}

function StepProgressOnChange(props) {
	const { listItems = [], activeStep, stepClicked } = props;
  let activeStepPercent = 0;
  let stepNum = 0;
  listItems.forEach((l) => {
    if(Array.isArray(l.values) && l.values.includes(activeStep)){
      activeStepPercent = l.stepPercent;
      stepNum = l.step;
    }
  });
  let currentStep = false
	return (
    <div className={`${props.class} ${activeStepPercent > 33 ? " green" : " green"}`}>
      <ProgressBar percent={activeStepPercent} >
        { 
          listItems.map((l, i) => {
            let stepVal = stepNum + 1;
            return <Step key={i} >
              {({ accomplished, index }) => {
                let stepcondition = ((!accomplished && !currentStep && stepNum) || (!index && !stepNum && !currentStep))
                if(stepcondition){currentStep = true};
                let className = accomplished && stepNum ? "accomplished" :  (stepcondition ? "current-step" : "");
                return ( <div className="step" onClick={() => stepClicked(l.step)}>
                  <div className={`indexedStep ${className}`}>
                    {index + 1}
                  </div>
                  {l.createdAt && <span className={`${l.step < stepVal ? "done" : (l.step === stepVal ? "current" : "incomplete")} ${l.createdAtClass}`}>
                    {l.createdAt}
                  </span>}
                  <span className={`${l.step < stepVal ? "done" : (l.step === stepVal ? "current" : "incomplete")} ${l.className} ${l.newClass}`}>
                    {l.label}
                  </span>
                </div>
              )}}
            </Step>
          })
        }
      </ProgressBar>
    </div>
	)
}

export default StepProgress;
export {
  StepProgressOnChange
}
