import styled from 'styled-components';
import { CALL_STATES } from '../../constants';

const getTextColor = (props) => {
  switch(props.callState){
    case CALL_STATES.DIAL_STATE: 
      return '#1DBF67'
    case CALL_STATES.DISCONNECTED_STATE:
      return '#F4AAA2';
    case CALL_STATES.HOLD_STATE:
      return '#F0A600'
    case CALL_STATES.ANSWERED_STATE: 
      return '#4A9CE2';
    default: 
      return '#4A9CE2'
  } 
}

export const CallEventButtons = styled.button`
  background: transparent;
  border: none;
  width: 2vw;
  height: 5vh;
  padding: 2px;
  flex: 0 1 auto;
`;


export const BottomCallBtn = styled.button`
  background: transparent;
  border: none;
  width: 30%;
  height: 5vh;
  display: flex;
  font-size: 1vw;
  align-items: center;
  font-weight: 600;
  color: #9A9A9A;
  justify-content: space-between;
  margin-left: 6px;
  margin-top: 4px;
  svg {
    width: 2vw;
    height: 3vh;
  }
`;

export const CallStateSpan = styled.span`
  font-size: 0.8vw;
  font-weight: 480;
  color: ${getTextColor};
  margin-right: 0.5rem;
`;

export const AmbulanceNameSpan = styled.span`
  font-size: 0.8vw;
  font-weight: 500;
  color: #343434;
  flex: 1 1;
  margin: 0 .5rem;
`;

export const CallBtnDiv = styled.div``;

export const CallBottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyCallText = styled.p`
  color: #939393;
  text-align: center;
  font-size: 13px;
  margin: 0;
`