import React from "react";
import styled from "styled-components";

import {
  StyledCardContainer,
  HeadingWrapper,
  StyledHeading,
  SpaceBetweenWrapper,
  StyledData,
  FooterTextDScore,
  LocationButtonWrapper,
  LocationButton,
  LocationButtonActive
} from "./index";

import score from "../../../images/score.svg";
import gold from "../../../images/gold.svg";
import silver from "../../../images/silver.svg";
import bronze from "../../../images/bronze.svg";

const TripsStyledData = styled(StyledData)`
  font-size: 0.9vw;
`;

const StyledImage = styled.img`
  height: 1vw;
  width: 1vw;
`;

const DScoreLeaderboardCard = ({ children }) => {
  return (
    <StyledCardContainer>
      <HeadingWrapper>
        <StyledHeading> D.Score Leaderboard </StyledHeading>
        <img src={score} alt="score" />
      </HeadingWrapper>
      {/* <LocationButtonWrapper>
        <LocationButtonActive>Mumbai</LocationButtonActive>
        <LocationButton>India</LocationButton>
      </LocationButtonWrapper> */}
      <LocationButtonWrapper>
        <LocationButtonActive></LocationButtonActive>
        <LocationButton></LocationButton>
      </LocationButtonWrapper>
      <div>
        <div>
          <SpaceBetweenWrapper>
            <TripsStyledData>
              {" "}
              <StyledImage src={gold} />
              {/* &nbsp; 1. Sanket{" "} */}
            </TripsStyledData>
            {/* <TripsStyledData>8.9 DS</TripsStyledData> */}
            <TripsStyledData></TripsStyledData>
          </SpaceBetweenWrapper>
        </div>
        <div>
          <SpaceBetweenWrapper>
            <TripsStyledData>
              {" "}
              <StyledImage src={silver} />
              {/* &nbsp; 2. Vivek{" "} */}
            </TripsStyledData>
            {/* <TripsStyledData>7.9 DS</TripsStyledData> */}
            <TripsStyledData></TripsStyledData>
          </SpaceBetweenWrapper>
        </div>
        <div>
          <SpaceBetweenWrapper>
            <TripsStyledData style={{ fontSize: "1.1vw", color: "#945BF8" }}>
              {" "}
              <StyledImage src={bronze} />
              {/* &nbsp; 3. Aakash{" "} */}
            </TripsStyledData>
            <TripsStyledData style={{ color: "#945BF8" }}>
              {/* 7.8 DS */}
            </TripsStyledData>
          </SpaceBetweenWrapper>
        </div>
      </div>
      <FooterTextDScore />
    </StyledCardContainer>
  );
};

export default DScoreLeaderboardCard;
