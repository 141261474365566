import { Matrix } from "openrouteservice-js";
import { openRouteKeys } from '../api/config';

const randomlySelectArrValue = (arr) => {
  const random = Math.floor(Math.random() * arr.length);
  return { key: random, value: arr[random] };
}

let { value } = randomlySelectArrValue(openRouteKeys)

let openRouteKey = value;

export const estimatedTripTime = (source, target, cb) => {
  let google = window.google;
  const directionsService = new google.maps.DirectionsService();
  directionsService.route(
    {
      origin: source,
      destination: target,
      travelMode: "DRIVING"
    },
    (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        cb(null, {
          duration: result.routes[0].legs[0].duration,
          distance: result.routes[0].legs[0].distance
        })
        console.log('gooogog', 'duration', result.routes[0].legs[0].duration,
          'distance', result.routes[0].legs[0].distance);
      } else {
        cb('error');
      }
    }
  );
}

export const calculateArrival = async (source, target) => {
  let tar = {
    lat: parseFloat(target.lat),
    lng: parseFloat(target.lng)
  }
  try {
    let google = window.google;
    const directionsService = new google.maps.DirectionsService();
    let req = {
      origin: source,
      destination: tar,
      travelMode: "DRIVING"
    };
    return await directionsService.route(req);
  } catch (err) {
    return null;
  }
}

export const calculateArrivalForCustom = async (source, target) => {
  let tar = {
    lat: parseFloat(target.lat),
    lng: parseFloat(target.lng)
  }
  try {
    let google = window.google;
    const directionsService = new google.maps.DirectionsService();
    let req = {
      origin: source,
      destination: tar,
      travelMode: "DRIVING"
    };
    let res = await directionsService.route(req);
    return res
  } catch (err) {
    return null;
  }
}

export const calculateEtaDist = async (locations, sources, destinations) => {
  try {
    let MatrixApi = new Matrix({ api_key: openRouteKey });
    let response = await MatrixApi.calculate({
      locations: locations,
      profile: "driving-car",
      sources: sources,
      destinations: destinations,
      format: 'json',
      metrics: ['distance', 'duration']
    })
    return response;
  } catch (err) {
    //let str = "An error occurred: " + err;
    return null;
  }
}