import { CALL } from '../constants';
import { takeLatest } from 'redux-saga/effects';
import store from '../store';
import { getCallDetailsAction } from '../actions/getCallDetailsAction';

// function* handleCallEvents({ payload }) {
//   if(payload && Object.keys(payload).length){
//     console.log('callEvents',payload)
//     yield put({ type: CALL.HANDLE_CALL_EVENTS, payload })
//   }
// }

function* handleIncomingCall({ payload }){
  // console.log(payload,'incoming');
  let callData = {
    mobile: `${payload.caller}`,
    ticketStage: "NEWTICKET",
    leadDetails: {},
    patientDetails: {},
    quoteDetails: {},
    hospitalSelectDetails: {},
    patientOnboardDetails: {},
    billReviewDetails: {},
    ownerId: `${localStorage.getItem('username')}`.trim()
  }
  store.dispatch(getCallDetailsAction(callData));
}

export default function* watchCallEvents() {
  // yield takeEvery(CALL.UPDATE_CALL_EVENTS, handleCallEvents)
  yield takeLatest(CALL.INCOMING_CALL_EVENT, handleIncomingCall)
}