import { REQUEST_CONFIG } from "../constants";

const INITIAL_STATE = {
	breakList: [],
	selectedBreak: "",
	dispositionTypes: [],
	subDispositionTypes: [],
	leadsource: [],
	ambulancetype:[],
	ambulanceaddons: [],
	callerCities: [],
	ticketStages: [],
	newDispositions: []
};

const ticketConfigReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_CONFIG.CONFIG_DATA: {
			let callerCities = action.payload.callCity;
			let ticketStages = action.payload.ticketstage;
			// let dispositionTypes = action.payload.dispositiontype;
			let newDispositions = action.payload.dispositionNewType;
			return {
				...state,
				...action.payload,
				callerCities,
				ticketStages,
				// dispositionTypes,
				newDispositions,
			}
		}
		default:
			return state;
	}
};

export default ticketConfigReducer;