import React, {useState} from 'react'
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import './index.scss';
import { FeedBackContainer, ButtonGroupDiv } from './styles';
import CustomButton from './../../components/CustomButton/index';
import { useDispatch, useSelector } from 'react-redux';
import { updateTicketDetails } from '../../actions/ticketAction';
import { redirectToDashboard } from '../../actions/redirectAction';
import { createNotification, ignoreCaseStringEquals } from '../../utils/helper';

const ratings = [
  {
    label: '1',
    value: 'one',
    className: 'r-1-btn'
  },
  {
    label: '2',
    value: 'two',
    className: 'r-1-btn'
  },
  {
    label: '3',
    value: 'three',
    className: 'r-1-btn'
  },
  {
    label: '4',
    value: 'four',
    className: 'r-1-btn'
  },
  {
    label: '5',
    value: 'five',
    className: 'r-1-btn'
  },
  {
    label: '6',
    value: 'six',
    className: 'r-1-btn'
  },
  {
    label: '7',
    value: 'seven',
    className: 'r-2-btn'
  },
  {
    label: '8',
    value: 'eight',
    className: 'r-2-btn'
  },
  {
    label: '9',
    value: 'nine',
    className: 'r-3-btn'
  },
  {
    label: '10',
    value: 'ten',
    className: 'r-3-btn'
  },
]

function FeedbackPage({ toDisable }) {
  const [status, setStatus] = useState(false);
  const [updating, setUpdating] = useState(false);
  const ticket = useSelector(state => state.patientInfo?.ticketData || {});
  const dispatch = useDispatch();

  const setOnChange = (value,q) => {
    if(ticket.ticketStage !== 'FEEDBACK' && !toDisable){
      let obj = {[q]: value};
      // console.log(obj,q,value)
      dispatch(updateTicketDetails('feedbackDetails',obj));
    }
  }

  const getValue = (q) => {
    let feedbackDetails = ticket.feedbackDetails || {};
    let value = feedbackDetails[q] || '';
    // console.log(value)
    return value
  }

  const submit = () => {
    setUpdating(true);
    let updateTicket = {ticketStage: 'FEEDBACK'};
    if(status === false && (ticket.feedbackDetails === null || ticket.feedbackDetails.rating === null || ticket.feedbackDetails.serviceLiked === null || ticket.feedbackDetails.optionalComment === null)){
      setUpdating(false);
      createNotification('error','Please Fill all the details');
    } else {
      updateTicket = {...updateTicket, feedbackDetails: {...ticket.feedbackDetails , feedbackNotProvided: status}};
      dispatch(updateTicketDetails('ticketDetails',updateTicket,(res) => {
        setUpdating(false);
        if(res){
          createNotification('success','Feedback Saved');
          dispatch(redirectToDashboard());
        }
      }));
    }
  }

  const radioHandler = (e) => {
    setStatus(e.target.checked);
    if(e.target.checked === true){
      setOnChange(null, 'serviceLiked')
      setOnChange(null, 'rating');
      setOnChange(null, 'optionalComment');
    }
  };

  return (
    <><FeedBackContainer>
      <Form className={`f-form`}>
        <Form.Group>
          <Form.Check
            disabled={ticket.ticketStage === 'FEEDBACK' || toDisable}
            className='f-radio'
            label={'Customer Refused to give feedback'}
            value={'Yes'}
            checked={status || (ticket.feedbackDetails && ticket.feedbackDetails.feedbackNotProvided)}
            onChange={(e) => radioHandler(e)}
          />
          <Form.Label className='f-l'>Was our customer service staff helpful?</Form.Label>
          <Form.Check
            disabled={ticket.ticketStage === 'FEEDBACK' || status === true || toDisable}
            className='f-radio'
            type='radio'
            name='group1' id='inline-radio-2'
            checked={ignoreCaseStringEquals('No', getValue('serviceLiked'))}
            label={'No'}
            value={'No'}
            onChange={(e) => setOnChange(e.target.value, 'serviceLiked')}
          />
          <Form.Check
            disabled={ticket.ticketStage === 'FEEDBACK' || status === true || toDisable}
            className='f-radio' type='radio' name='group1' id='inline-radio-1'
            checked={ignoreCaseStringEquals('Yes', getValue('serviceLiked'))}
            label={'Yes'} value={'Yes'}
            onChange={(e) => setOnChange(e.target.value, 'serviceLiked')}
          />
          <Form.Label className='f-l'>Rate your overall experience with us ?</Form.Label>
          <ButtonGroup
            disabled={ticket.ticketStage === 'FEEDBACK' || toDisable}
            size="sm" className="me-2" aria-label="First group"
          >
            {ratings.map((r) => {
              return <Button
                disabled={ticket.ticketStage === 'FEEDBACK' || status === true || toDisable}
                className={`r-btn ${r.className} ${ignoreCaseStringEquals(r.value, getValue('rating')) ? 'active' :''}`}
                value={r.value}
                key={r.label}
                onClick={() => setOnChange(r.value, 'rating')}
              >
                <span>{r.label}</span>
              </Button>
            })}
          </ButtonGroup>
          <Form.Label className='f-l'>What did you like the most about us ?</Form.Label>
          <Form.Control className='f-ta' disabled={ticket.ticketStage === 'FEEDBACK' || status === true || toDisable} value={getValue('optionalComment') || ''} as="textarea" rows={3} onChange={(e) => setOnChange(e.target.value, 'optionalComment')}/>
        </Form.Group>
      </Form>
    </FeedBackContainer>
    <ButtonGroupDiv>
      <CustomButton green feedback style={{margin: '0',marginRight:'1rem'}} disabled={true}>
        Ask Testimonial
      </CustomButton>
      <CustomButton cyan feedback style={{margin: '0',marginRight:'1rem'}} onClick={() => submit()} disabled={ticket.ticketStage === 'FEEDBACK' || toDisable}>
          {updating ? "Wait.." : "Submit & Save"}
      </CustomButton>
    </ButtonGroupDiv></>
  )
}

export default FeedbackPage
