import React from "react";
import search from "../../../images/search.svg";
import styled from "styled-components";

const StyledContainer = styled.div`
  box-shadow: 0px 4px 16px #00000029;
  background-color: #ffffff;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1em;
  height: 7vh;
`;

const StyledSearchBar = styled.input`
  width: 100%;
  border: none;
  border-radius: 18px;
  padding: 0 1em;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #9f9f9f;
    font-size: 1vw;
  }
`;

const StyledSearchIcon = styled.img`
  padding: 0.6em 0;
`;

const SearchBar = ({ handleSearch , ...props }) => {
  return (
    <StyledContainer>
      <StyledSearchBar {...props} />
      <StyledSearchIcon src={search} alt="search" id="searchbutton" onClick={handleSearch} />
    </StyledContainer>
  );
};

export default SearchBar;
