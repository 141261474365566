import styled from "styled-components";

const getInputColor = props => {
  if (props.black) {
    return "#343434";
  }
  if (props.green) {
    return "#1DBF67";
  }
  if (props.cyan) {
    return "#16999E";
  }
  if (props.pink) {
    return "#FDE9E7";
  } else {
    return "#F0F0F0";
  }
};

const Input = styled.input`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: ${getInputColor};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "48px")};
  padding: 0px 10px 0px 10px;
  font-size: 1vw;
`;

const SummarySpan = styled.span`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: ${getInputColor};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "48px")};
  padding: 0px 10px 0px 10px;
  font-size: 1vw;
`;

const AudioWrapper = styled.div`
  outline: none;
  border: none;
  border-radius: 25px; 
  background-color: rgb(253, 233, 231);
  width: ${props => (props.width ? props.width : "100%")};
  height: auto;
  padding: 0px 5px 0px 2px;
  display: flex;
  align-items: center;
  svg{
    width: 25px;
  }
`

const TextArea = styled.textarea`
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: ${getInputColor};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => (props.height ? props.height : "48px")};
  padding: 0px 10px 0px 10px;
`;

export default Input;

export {
  AudioWrapper,
  TextArea,
  SummarySpan,
}