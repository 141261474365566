import styled from 'styled-components';
import { CALL_STATES } from '../../constants';

const getTextColor = (props) => {
  switch (props.callState) {
    case CALL_STATES.DIAL_STATE:
      return '#1DBF67'
    case CALL_STATES.DISCONNECTED_STATE:
      return '#F4AAA2';
    case CALL_STATES.HOLD_STATE:
      return '#F0A600'
    case CALL_STATES.ANSWERED_STATE:
      return '#4A9CE2';
    default:
      return '#4A9CE2'
  }
}

export const QuoteBookWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, auto);
  column-gap: 0.4rem;
  row-gap: 0.2rem;
  height: ${props => props.height ? '35vh' : 'auto'};
  overflow-y: scroll;
  max-height: 35vh;
  margin-top: ${props => props.marginTop ? '0.5rem' : '0'};
`;

export const EmptyContainerWrapper = styled.div`
  height: 38vh;
`;

export const QuoteBookMainWrapper = styled.div`
  padding: ${props => props.isLogin ? '1rem 8rem' : '1rem'};
`

export const SelectedAmbulanceDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(8, auto);
  column-gap: 0.4rem;
  row-gap: 0.2rem;
  margin-bottom: 0.5rem;
`;

export const QuoteHeading = styled.h3`
  font-weight: 500;
  font-size: 20px;
  color: #16999e;
`
export const SelectedAmbulanceHead = styled.h3`
  margin-top: 0.5rem;
  font-weight: 500;
  font-size: 20px;
  color: #16999e;
`

export const QuoteHeads = styled.p`
  font-weight: 500;
  font-size: 1vw;
  color: #000;
  margin-bottom: 0.2rem;
`
export const PlaceCallText = styled.p`
  font-weight: 500;
  font-size: 1vw;
  color: #000;
  margin-bottom: 0.2rem;
  cursor: pointer;
  padding: 20px;
  :hover{
    background: #16999E;
    color: #fff;
    border-radius: 15px;
  }
`

export const SearchInputDiv = styled.div`
  font-weight: 500;
  font-size: 1vw;
  color: #000;
  margin-bottom: 0.2rem;
`

export const VehicleData = styled.p`
  font-weight: 400;
  font-size: 1vw;
  color: #000;
  background: #f0f0f0;
  padding: 0.5em;
  margin-bottom: 0.2rem;
  overflow-wrap: break-word;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
`

export const VehicleInputs = styled.input`
  font-weight: 400;
  font-size: 1vw;
  color: #000;
  background: #f0f0f0;
  border-radius: 5px;
  border: none;
  padding: 0 0 0 0.5rem;
  height: 7vh;
  width: 5vw;
  :focus-visible, :focus {
    outline: none;
    border-bottom: 2px solid #bdbdbd;
  }

`

export const VehicleOwnerInput = styled.input`
  font-weight: 400;
  font-size: 1vw;
  color: #000;
  background: #f0f0f0;
  padding: 0.5em;
  margin-bottom: 0.2rem;
  border-radius: 5px;
  cursor: pointer;
`

export const CheckboxWrapper = styled.div`
  font-weight: 500;
  font-size: 1vw;
  color: #000;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  span svg {
    height: 3vh;
    width: 2vw;
    cursor: pointer;
  }
  span {
    border: none;
    background: transparent;
  }
  .form-check input {
    cursor: pointer;
  }
`

export const SelectedAmbulanceHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const DisplayCallState = styled.p`
  text-align: center;
  color: ${getTextColor};
  font-size: 1.4vw;
  font-weight: 500;
`