import styled from "styled-components";

const getGridColumnSize = props => {
  if (props.chat) {
    return "0.3fr 1.4fr 0.4fr";
  } else {
    return "0.3fr 1.4fr 0.3fr";
  }
};

const GridWrapperSimple = styled.div`
  display: grid;
  grid-template-columns: ${getGridColumnSize};
  grid-template-rows: auto 1fr;
  padding: 30px;
`;

export default GridWrapperSimple;
