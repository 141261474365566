import { VEHICLE } from '../constants';
import { select, takeEvery, put } from 'redux-saga/effects';

function* paginateAndCalculateVehiclesEtaDist_HP({ payload, type }) {

    console.log('ambupage', payload, type);
    const getVehiclesData = (state) => state.nearbyAmbulancesHP;
    const getOldVehiclesData = (state) => state.patientInfo;
    let allVehiclesHP = [...payload.data] || [];

    if (type === VEHICLE.GET_VEHICLE_SUCCESS_HP) {
        const oldTicketData = yield select(getOldVehiclesData);
        const { fetchedTicketData = {} } = oldTicketData;
        const { quoteDetails = {} } = fetchedTicketData;
        const { nearbyAmbulanceDetails = [] } = quoteDetails;
        if (nearbyAmbulanceDetails && nearbyAmbulanceDetails.length > 0) {
            yield allVehiclesHP.forEach((veh, i) => {
                nearbyAmbulanceDetails.forEach((nearbyVeh, j) => {
                    if (
                        veh && nearbyVeh &&
                        nearbyVeh.vehicleObject &&
                        nearbyVeh.modified &&
                        veh.vehicleObject && nearbyVeh.vehicleObject.id &&
                        veh.vehicleObject.id && nearbyVeh.vehicleObject.id === veh.vehicleObject.id
                    ) {
                        let newVeh = {
                            ...nearbyVeh,
                            ...veh
                        }
                        allVehiclesHP.splice(i, 1, { ...newVeh })
                    }
                })
            })
        }
    }
    const handleLoadedVeh = {
        type: VEHICLE.LOAD_MODIFIED_RATE_VEHICLES_HP,
        payload: { data: [...allVehiclesHP] }
    }
    yield put(handleLoadedVeh)
    let nearbyAmbulancesDataHP = yield select(getVehiclesData);
    let { page, vehicleCount, isGoogle } = nearbyAmbulancesDataHP;
    let displayedAmbulances = allVehiclesHP.splice(page, vehicleCount);
    let newPage = page + vehicleCount;
    let setType = isGoogle ? VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE_HP : VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE_HP;
    let actionConst = {
        type: setType,
        payload: { ambulanceList: [...displayedAmbulances], page: newPage }
    }
    yield put(actionConst)
}


export default function* handleVehiclesLoadHP() {
    yield takeEvery([VEHICLE.GET_VEHICLE_SUCCESS_HP, VEHICLE.LOAD_MORE_VEHICLES_HP], paginateAndCalculateVehiclesEtaDist_HP);
}