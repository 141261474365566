import React, { useState, useEffect } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
  TabsWrapper,
  TabName,
  HeadWrapper,
  MainWrapper,
} from "../../components/NavTabs";
import { ticketTabs } from "../../constants/propsConst";
import LogoHeading from "../../components/LogoHeading/index";
import WhiteCenterDiv from '../../components/WhiteCenterDiv';
// import PatientInfoPage from '../PatientInfoPage';
// import QuoteAndBookPage from '../QuoteAndBookPage';
import PatientOnboardPage from '../PatientOnboardPage';
import RenderRedirect from "../../utils/RenderRedirect";
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToDashboard } from '../../actions/redirectAction';
import { TabsMainWrapper, LogoBtn, MainContainer } from './../../components/NavTabs/index';
import PatientInfo from '../PatientInfoPage/index';
import QuotePage from '../QuoteAndBookPage/index1';
import scriptLoader from 'react-async-script-loader';
import { mapKey, mapUrl } from '../../api/config';
import CallTab from './callTab';
import FeedbackPage from '../FeedbackPage';
import AmbJourney from '../AmbJourney';
import EmptyContainer from '../../components/EmptyContainer';

const randomlySelectArrValue = (arr) => {
  const random = Math.floor(Math.random() * arr.length);
  return { key: random, value: arr[random] };
}

let { value } = randomlySelectArrValue(mapKey)

let googleUrl = mapUrl(value);

function PatientCall(props) {
  const ticket = useSelector(state => state.patientInfo?.ticketData || {});
  const responseStatus = useSelector(state => state.redirect?.responseStatus || false);
  const [activeTab, setActiveTab] = useState("patientInfo");
  const [disabled, setDisabled] = useState(false);
  const [pageAddress, setPageAddress] = useState(false);
  const disableOnTicketStage = ['CONFIRMED', 'CANCELLED', 'CLOSED', 'FEEDBACK'];
  const dispatch = useDispatch();

  useEffect(() => {
    let activeTab = "patientInfo"
    if(ticket?.ticketStage && disableOnTicketStage.includes(ticket.ticketStage)){
      if(ticket.ticketStage === 'CLOSED'){
        activeTab = "summary"
      }else if(ticket.ticketStage === 'FEEDBACK'){
        activeTab = 'feedback'
      }else if(ticket.ticketStage === 'CONFIRMED'){
        activeTab='journeyTracking'
      }else{
        activeTab='quote'
      }
      setDisabled(ticket.ticketStage)
    }
    setActiveTab(activeTab);
    return () => {
      props.history.goForward();
      setActiveTab(activeTab);
    }
  }, []);

  if(props.isScriptLoaded && props.isScriptLoadSucceed){
    return <MainWrapper>
      <RenderRedirect
        responseStatus={responseStatus}
        pageAddress="/"
      />
      <HeadWrapper>
        <LogoBtn onClick={() => dispatch(redirectToDashboard())} ><LogoHeading /></LogoBtn>
      </HeadWrapper>
      <MainContainer>
        <TabsMainWrapper>
          <Tab.Container activeKey={activeTab} onSelect={(e) => setActiveTab(e)} transition={false}>
            <TabsWrapper>
              {ticketTabs.map((t) => {
                let tabColor = activeTab === t.key ? "#16999e": "#9F9F9F";
                // let toDisable = t.enabledStages.includes(ticket?.ticketStage) ? false : true;
                // let cur = toDisable ? "not-allowed" : "pointer"; 
                // return <Nav.Link key={t.key} eventKey={t.key} style={{ cursor: cur }} className="ticketlink" disabled={toDisable}>
                return <Nav.Link key={t.key} eventKey={t.key} style={{ cursor: "pointer"}} className="ticketlink">
                  <TabName style={{ backgroundColor: tabColor }}>{t.label}</TabName>
                </Nav.Link>
              })}
            </TabsWrapper>
            <Tab.Content className='p-content'>
              {ticketTabs.map((t) => {
                let toDisable = t.enabledStages.includes(ticket?.ticketStage?.toUpperCase()) ? false : true;
                switch(t.key) {
                  case 'patientInfo':
                    return <Tab.Pane key={t.key} eventKey="patientInfo">
                    <WhiteCenterDiv>
                      <PatientInfo setActiveTab={setActiveTab} toDisable={toDisable} />
                    </WhiteCenterDiv>
                  </Tab.Pane>
                  case 'quote': 
                    return  <Tab.Pane key={t.key} eventKey="quote">
                    <WhiteCenterDiv>
                      {/* <QuoteAndBookPage activeTab={activeTab} setActiveTab={setActiveTab} setPageAddress={setPageAddress} disabled={disabled}/> */}
                      <QuotePage activeTab={activeTab} setActiveTab={setActiveTab} setPageAddress={setPageAddress} disabled={disabled} toDisable={toDisable}/>
                    </WhiteCenterDiv>
                  </Tab.Pane>
                  case 'journeyTracking': 
                    return toDisable ? 
                      <Tab.Pane key={t.key} eventKey="journeyTracking">
                        <WhiteCenterDiv minHeight='80vh'>
                          <EmptyContainer text="No Journey To Be Tracked" />
                        </WhiteCenterDiv>
                    </Tab.Pane>
                    : <Tab.Pane key={t.key} eventKey="journeyTracking">
                      <WhiteCenterDiv>
                        <AmbJourney activeTab={activeTab} setActiveTab={setActiveTab} setPageAddress={setPageAddress} disabled={disabled} toDisable={toDisable}/>
                      </WhiteCenterDiv>
                    </Tab.Pane>
                  case 'summary': 
                    return toDisable ? 
                    <Tab.Pane key={t.key} eventKey="summary">
                      <WhiteCenterDiv minHeight='80vh'>
                        <EmptyContainer text="No Summary Found" />
                      </WhiteCenterDiv>
                  </Tab.Pane>
                  : <Tab.Pane key={t.key} eventKey="summary">
                    <WhiteCenterDiv>
                      <PatientOnboardPage activeTab={activeTab} setActiveTab={setActiveTab} toDisable={toDisable}/>
                    </WhiteCenterDiv>
                  </Tab.Pane>
                  case 'feedback':
                    return <Tab.Pane key={t.key} eventKey="feedback">
                    <WhiteCenterDiv>
                      <FeedbackPage activeTab={activeTab} setActiveTab={setActiveTab} toDisable={toDisable}/>
                    </WhiteCenterDiv>
                  </Tab.Pane>
                  default: 
                    return <Tab.Pane key={t.key} eventKey="patientInfo">
                    <WhiteCenterDiv>
                      <PatientInfo setActiveTab={setActiveTab} toDisable={toDisable} />
                    </WhiteCenterDiv>
                  </Tab.Pane>
                }
              })}
            </Tab.Content>
          </Tab.Container>
        </TabsMainWrapper>
        <CallTab />
      </MainContainer>
    </MainWrapper> 
  }else{
    return(
      <div>loading...</div>
    )
  }
}

export default scriptLoader([googleUrl])(withRouter(PatientCall))