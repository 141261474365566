import { VEHICLE, REDIRECT, UPDATE_QUOTE } from "../constants";
import { metersToKM, secToTime } from '../utils/helper';

const VEHICLE_LIST_STATE = {
  isPending: false,
  ambulanceList: [],
  vendorsList: [],
  vehiclesByVendor: [],
  vehiclesEta: [],
  customVehicleEta: {},
  reqBody: {}
};

export const vehicleListReducer = (state = VEHICLE_LIST_STATE, action) => {
  switch (action.type) {
    case VEHICLE.GET_VEHICLE_PENDING:
      return {
        ...state,  
        isPending: true,
        vehiclesEta: [],
        customVehicleEta: {},
      };
    case VEHICLE.EMPTY_VEHICLES:
      return VEHICLE_LIST_STATE;
    // case VEHICLE.GET_VEHICLE_SUCCESS:
    //   return {
    //     ...state, 
    //     isPending: false,
    //     ambulanceList: action.payload.data,
    //   };
    case VEHICLE.LOAD_MODIFIED_RATE_VEHICLES:
      return {
        ...state, 
        isPending: false,
        ambulanceList: action.payload.data,
      };
    case VEHICLE.ADD_VEHICLES_FROM_TICKET_DETAILS:
      return {
        ...state, 
        isPending: false,
        ambulanceList: action.payload.data,
      };
    case VEHICLE.UPDATE_MODIFIED_RATE_DISPLAY_LIST:
      return {
        ...state, 
        ambulanceList: action.payload.ambulanceList,
      };
    case VEHICLE.GET_VEHICLE_FAILED:
      return {
        ...state, 
        isPending: false,
        ambulanceList: action.payload.meta,
      };
    case VEHICLE.UPDATE_VEHICLE_LIST:
      return {
        ...state, 
        ambulanceList: action.payload,
      };
    case VEHICLE.CALCULATE_VEHICLES_ETA: {
      return {
        ...state,
        vehiclesEta: [...state.vehiclesEta, ...action.payload]
      }
    }
    case VEHICLE.CALCULATED_CUSTOM_VEHICLE_ETA: {
      return {
        ...state,
        customVehicleEta: action.payload
      }
    }
    case UPDATE_QUOTE.GET_VENDORS_SUCCESS:{
      return {
        ...state,
        vendorsList: action.payload.data
      }
    }
    case VEHICLE.HANDLE_OPEN_ROUTE_RESPONSE: {
      let calculatedEtaAndDist = [];
      let { vehiclesId = [], vehicleLocations = [], response = {} } = action.payload;
      let duratonArray = response.durations[0];
      let distanceArray = response.distances[0];
      vehiclesId.forEach((id,i) => {
        let timeText = secToTime(duratonArray[i]);
        let distText = metersToKM(distanceArray[i]);
        let value = {
          id,
          duration: {
            text: timeText,
            value: response.durations[i]
          },
          distance: {
            text: distText,
            value: response.distances[i]
          },
          location: vehicleLocations[i]
        }
        calculatedEtaAndDist.push(value)
      })
      return {
        ...state,
        vehiclesEta: [...state.vehiclesEta, ...calculatedEtaAndDist]
      }
    }
    case UPDATE_QUOTE.GET_VEHICLES_BY_VENDOR_SUCCESS: {
      return {
        ...state,
        vehiclesByVendor: action.payload.data
      }
    }
    case VEHICLE.SET_VEHICLE_POST_REQUEST: {
      return {
        ...state,
        reqBody: action.payload
      }
    }
    case UPDATE_QUOTE.EMPTY_VEHICLES_BY_VENDOR_ID: {
      return{
        ...state,
        vehiclesByVendor: [],
      }
    }
    case REDIRECT.REDIRECT_TO_DASHBOARD: {
      return VEHICLE_LIST_STATE;
    }
    default:
      return state;
  }
};
