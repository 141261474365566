import { VEHICLE } from "../constants";
import { getVehicles, getCustomQuoteVehicle ,reportsByFilter} from "../api";
// import { temp } from '../constants/temp';


export const getIdleVehicleNearSourceAction = (location) => async (dispatch) => {
  let loading = {data : []};
  dispatch({ type: VEHICLE.GET_VEHICLE_PENDING, payload: loading });
  try{
    let vehicles = await getVehicles({...location,vehicleType:"nonhelpnow"});
    let data =  vehicles.data; 
    // let vehicles = { status: 200 };
    // let data = { data: temp };
    if(vehicles.status === 200){
      dispatch({ type: VEHICLE.GET_VEHICLE_SUCCESS, payload: data });
    }else{
      let error = vehicles.error || "Error Fetching Vehicles";
      dispatch({ type: VEHICLE.GET_VEHICLE_FAILED, payload: error });
    }
  }catch(error){
    dispatch({ type: VEHICLE.GET_VEHICLE_FAILED, payload: error });
  }
};

export const getIdleVehicleNearSourceActionHP = (location) => async (dispatch) => {
  let loading = {data : []};
  dispatch({ type: VEHICLE.GET_VEHICLE_PENDING_HP, payload: loading });
  try{
    let vehicles = await getVehicles({...location,vehicleType:"helpnow"});
    let data =  vehicles.data; 
    // let vehicles = { status: 200 };
    // let data = { data: temp };
    if(vehicles.status === 200){
      dispatch({ type: VEHICLE.GET_VEHICLE_SUCCESS_HP, payload: data });
    }else{
      let error = vehicles.error || "Error Fetching Vehicles";
      dispatch({ type: VEHICLE.GET_VEHICLE_FAILED_HP, payload: error });
    }
  }catch(error){
    dispatch({ type: VEHICLE.GET_VEHICLE_FAILED_HP, payload: error });
  }
};

// export const paymentSourceMode = async () =>  {
//     let loading = {data : []};
//     let paymentDetails = await reportsByFilter(data);
//     let data =  paymentDetails.data; 
//     // let vehicles = { status: 200 };
//     // let data = { data: temp };
  
// };

export const getCustomQuote = async (postReq) =>  {
  try{
    let vehicles = await getCustomQuoteVehicle(postReq);
    let data =  vehicles.data; 
    if(vehicles.status === 200){
      return data;
    }else{
      //let error = vehicles.error || "Error Fetching Vehicles";
      return false;
    }
  }catch(error){
    return false;
  }
};

export const vehicleBodyRequest = (reqBody) => ({
  type: VEHICLE.SET_VEHICLE_POST_REQUEST,
  payload: reqBody
})

export const setSelectedVehicle = (updatedList,selectedAmbulance) => ({
  type: VEHICLE.UPDATE_VEHICLE_LIST,
  payload: { updatedList, selectedAmbulance },
});

export const getCustomVehicleEta = (vehicle) => ({
  type: VEHICLE.CALCULATE_CUSTOM_VEHICLE_ETA,
  payload: vehicle,
});
