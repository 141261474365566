import React, { useEffect, useState } from "react";
import LeftContent from "./leftContent";
import MiddleContent from "./middleContent";
import RightContent from "./rightContent";
import { TabContentContainer } from "./styles";
import { useDispatch, useSelector } from "react-redux";
// import { updatePatientInfo } from '../../actions/patientInfoAction';
// import { setLeadDetails } from '../../actions/quoteAndBookAction';
import {
  getTicketConfig,
  updateTicketDetails,
} from "../../actions/ticketAction";
import "./patientinfo.css";
import { getTieupHospitalList, getalldriverlist } from "../../api";
import { createNotification } from "../../utils/helper";
// import { getLatLngByAddress } from '../../utils/helper';
import { getTicketByIdAction } from "../../actions/patientInfoAction";
import { useParams } from "react-router-dom";
import axios from "axios";
import { GetAllDrivers } from "../../api/config";

// const unitofextrachargesmapper = [
//   {
//     id: "O2",
//     unit: "hours",
//   },
//   {
//     id: "WHEELCHAIR",
//     unit: "number",
//   },
//   {
//     id: "AC",
//     unit: "hours",
//   },
//   {
//     id: "EXTRAHELPER",
//     unit: "number",
//   },
//   {
//     id: "WAITINGTIME",
//     unit: "minutes",
//   }
// ]

function PatientInfo({ setActiveTab, toDisable }) {
  const ticketConfig = useSelector((state) => state.ticketConfig || {});
  const ticketData = useSelector((state) => state.patientInfo.ticketData || {});
  console.log("tcdata", ticketConfig);
  let patientDetails = ticketData.patientDetails || {};
  let leadDetails = ticketData.leadDetails || {};
  const [tieupHospitalList, setTieupHospitalList] = useState([]);
  const [driverlist, setDriverlist] = useState([]);
  const [displayTieupHospitalList, setDisplayTieupHospitalList] =
    useState(false);
  const [showHelperNumber, setShowHelperNumber] = useState(
    ticketData?.extraHelper ? true : false
  );
  const [o2Number, setO2Number] = useState(
    ticketData?.extraChargesMapperList?.O2?.numberOfAddons > 0 ? true : false
  );
  const [payByHospital, setPayByHospital] = useState(
    ticketData?.payByHospital ? ticketData?.payByHospital : false
  );
  // console.log(toDisable)
  const dispatch = useDispatch();
  const { ticketId } = useParams();
  console.log("ticketconfig", ticketConfig);

  // const getunit = (id) => {
  //   let unit = unitofextrachargesmapper?.filter((item) => item?.id === id)
  //   console.log('unit', unit);
  //   return unit[0].unit
  // }

  const fetchTieupHospitalList = async (firstCall) => {
    try {
      if (!tieupHospitalList || !tieupHospitalList.length) {
        let hospitalList = await getTieupHospitalList();
        if (
          hospitalList &&
          hospitalList.data &&
          hospitalList.data.data &&
          Array.isArray(hospitalList.data.data) &&
          hospitalList.data.data.length
        ) {
          let listData = [...hospitalList.data.data].map(
            (el) => el.hospitalName
          );
          setTieupHospitalList(listData);
          if (
            firstCall &&
            leadDetails &&
            leadDetails.leadSource &&
            leadDetails.leadSource === "HOSPITALSTAFF"
          )
            setDisplayTieupHospitalList(true);
        }
      }
    } catch (e) {
      console.log("e", e);
      createNotification(
        "error",
        "Error Fetching Tieup Hospital List",
        "Please Try Again!"
      );
    }
  };

  const displayTieupHospitals = async (show) => {
    if (tieupHospitalList && tieupHospitalList.length && show) {
      setDisplayTieupHospitalList(true);
    } else if (!show) {
      setDisplayTieupHospitalList(false);
    } else if (!tieupHospitalList || !tieupHospitalList.length) {
      fetchTieupHospitalList(false);
    }
  };

  const Getdriverlist = async () => {
    await getalldriverlist()
      .then((res) => res.data)
      .then((data) => {
        // console.log("driverdata", data.data);
        setDriverlist(data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };


  useEffect(() => {
    fetchTieupHospitalList(true);
    dispatch(getTicketByIdAction(ticketId));
    dispatch(getTicketConfig());
    Getdriverlist();
  }, []);

  const setAmbulanceAddOns = (id) => {
    let patientAmbulanceAddOns = [];
    if (patientDetails && patientDetails.patientAmbulanceAddOns) {
      patientAmbulanceAddOns = patientDetails.patientAmbulanceAddOns;
    }
    let index = patientAmbulanceAddOns.findIndex((addOn) => addOn === id);
    if (index !== -1) {
      patientAmbulanceAddOns.splice(index, 1);
    } else {
      patientAmbulanceAddOns.push(id);
    }
    return patientAmbulanceAddOns;
  };

  const triptimezone = ["DAY_TRIP", "NIGHT_TRIP"];
  const transferType = ["Patient", "OT_Machine", "OPD_to_IPD"];
  // const callReferredBy = ["option1", "option2", "option3"];
  // const callReferredBy =
  //   driverlist?.map((obj) => ({ name: obj.name, id: obj.id })) || [];
  const callReferredBy = [
    { name: 'NA', id: 'na' },
    ...(driverlist?.map((obj) => ({ name: obj.name, id: obj.id })) || [])
  ]

  const getPropertyOptions = (id) => {
    switch (id) {
      case "callerCity":
        return ticketConfig?.callerCities;
      case "patientAmbulanceType":
        return ticketConfig?.ambulancetype;
      case "patientAmbulanceAddOns":
        return ticketConfig?.ambulanceaddons;
      case "leadSource":
        let leaddeatils = ticketConfig?.leadsource.filter(
          (e) => e?.toUpperCase() !== "WHATSAPP" && e?.toUpperCase() !== "JD"
        );
        return leaddeatils;
      case "tripTimeZone":
        return ticketConfig?.triptimezone;
      case "transferType":
        return ticketConfig?.transferType;
      case "callReferredBy":
        return callReferredBy;
      case "driverName":
        return callReferredBy;
      default:
        return [];
    }
  };

  const getPropertyValues = (id) => {
    if (id === "numberofExtraHelper") {
      let value =
        ticketData?.extraChargesMapperList?.EXTRAHELPER?.numberOfAddons;
      return value;
    }
    if (id === "O2Details") {
      let value = ticketData?.extraChargesMapperList?.O2?.numberOfAddons;
      return value;
    } else if (id === "O2") {
      let value =
        ticketData?.extraChargesMapperList?.O2?.numberOfAddons > 0
          ? true
          : false;
      return value;
    }
    // else if (id === "extraHelper") {
    //   if (ticketData?.extraChargesMapperList?.EXTRAHELPER?.numberOfAddons === 0) {
    //     return false
    //   } else if (ticketData?.extraChargesMapperList?.EXTRAHELPER?.numberOfAddons > 0) {
    //     return true
    //   }
    // }
    else if (id && patientDetails && patientDetails.hasOwnProperty(id)) {
      return patientDetails[id] || "";
    } else if (id && leadDetails && leadDetails.hasOwnProperty(id)) {
      return leadDetails[id] || "";
    } else if (id && ticketData.hasOwnProperty(id)) {
      return ticketData[id] || "";
    } else if (id && ticketData?.extraChargesMapperList?.hasOwnProperty(id)) {
      let value = ticketData?.extraChargesMapperList[id]?.numberOfAddons;
      if (value == 0) {
        return false;
      } else if (value == 1) {
        return true;
      }
    } else {
      return "";
    }
  };

  const setPropertyValues = (value, id) => {
    console.log("EEE", value, id);
    let updateObj = {};
    let detailsType = "ticketDetails";
    if (id === "patientAmbulanceAddOns") {
      value = setAmbulanceAddOns(value);
    }

    // if (id === "extraHelper") {
    //   console.log(value, 'fffvalue');
    //   // if (!value) {
    //   detailsType = 'extraChargesMapperList'
    //   updateObj = {
    //     EXTRAHELPER: {
    //       addonsType: 'EXTRAHELPER',
    //       numberOfAddons: 0
    //     }
    //   }
    //   // }
    // }
    console.log("dop", id, value);

    updateObj = { [id]: value };
    if (id === "numberofExtraHelper") {
      detailsType = "extraChargesMapperList";
      console.log("EEE", value);
      updateObj = {
        EXTRAHELPER: {
          addonsType: "EXTRAHELPER",
          numberOfAddons: value,
        },
      };
    } else if (id === "O2Details") {
      detailsType = "extraChargesMapperList";
      console.log("EEE", value);
      updateObj = {
        O2: {
          addonsType: "O2",
          numberOfAddons: value,
        },
      };
    } else if (id === "O2") {
      detailsType = "extraChargesMapperList";
      console.log("EEE", value);
      updateObj = {
        [id]: {
          addonsType: id,
          numberOfAddons: value,
        },
      };
    } else if (id && patientDetails && patientDetails.hasOwnProperty(id)) {
      detailsType = "patientDetails";
    } else if (id && leadDetails && leadDetails.hasOwnProperty(id)) {
      detailsType = "leadDetails";
    } else if (id && ticketData.hasOwnProperty(id)) {
      detailsType = "ticketDetails";
    } else if (id === "AC" || id === "WHEELCHAIR") {
      detailsType = "extraChargesMapperList";
      console.log("dopvalue", value);

      updateObj = {
        [id]: {
          addonsType: id,
          numberOfAddons: value,
        },
      };
      console.log("updateObj", updateObj);
    }
    dispatch(updateTicketDetails(detailsType, updateObj));
  };

  return (
    <TabContentContainer
      style={{ cursor: toDisable ? "not-allowed" : "mouse" }}
    >
      <LeftContent
        getPropertyOptions={(id) => getPropertyOptions(id)}
        getPropertyValues={(id) => getPropertyValues(id)}
        setPropertyValues={(value, id) => setPropertyValues(value, id)}
        toDisable={toDisable}
      />
      <MiddleContent
        getPropertyOptions={(id) => getPropertyOptions(id)}
        getPropertyValues={(id) => getPropertyValues(id)}
        setPropertyValues={(value, id) => setPropertyValues(value, id)}
        displayTieupHospitals={(show) => displayTieupHospitals(show)}
        toDisable={toDisable}
        setShowHelperNumber={setShowHelperNumber}
        showHelperNumber={showHelperNumber}
        setPayByHospital={setPayByHospital}
        setO2Number={setO2Number}
        o2Number={o2Number}
      />
      <RightContent
        setActiveTab={setActiveTab}
        getPropertyValues={(id) => getPropertyValues(id)}
        setPropertyValues={(value, id) => setPropertyValues(value, id)}
        toDisable={toDisable}
        displayTieupHospitalList={displayTieupHospitalList}
        tieupHospitalList={tieupHospitalList}
        showHelperNumber={showHelperNumber}
        setPayByHospital={setPayByHospital}
        payByHospital={payByHospital}
      />
    </TabContentContainer>
  );
}

export default PatientInfo;
