import React from "react";

const CalendarSvg = () => (
  <svg viewBox="0 0 21.03 24">
    <defs></defs>
    <path fill="#16999e" d="M-.015,0V6h21V0Zm0,9V23.73a.269.269,0,0,0,.27.27h20.43a.269.269,0,0,0,.27-.27V9h-21Zm3,3h3v3h-3Zm6,0h3v3h-3Zm6,0h3v3h-3Zm-12,6h3v3h-3Zm6,0h3v3h-3Z" transform="translate(0.045)"/>
  </svg>
);

const PlayIcon = () => (
  <svg viewBox="0 0 512.000000 512.000000">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill="red" stroke="none">
      <path d="M2407 4350 c-508 -45 -974 -303 -1281 -710 -436 -578 -481 -1368
      -114 -1990 224 -380 612 -686 1024 -809 553 -165 1148 -59 1604 285 953 719
      952 2150 -1 2869 -350 264 -798 393 -1232 355z m417 -1349 c569 -329 650 -378
      664 -408 14 -30 14 -36 0 -66 -14 -30 -95 -80 -669 -410 -709 -410 -699 -405
      -750 -351 l-24 26 0 768 0 768 24 26 c25 27 53 35 87 26 11 -3 312 -173 668
      -379z"/>
    </g>
  </svg>
);

const PauseIcon = () => (
  <svg viewBox="0 0 512.000000 512.000000">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill="red" stroke="none">
      <path d="M2420 5109 c-705 -34 -1391 -383 -1842 -939 -305 -377 -505 -856
      -558 -1335 -14 -129 -14 -421 0 -550 53 -479 253 -958 558 -1335 419 -516
      1046 -858 1707 -930 129 -14 421 -14 550 0 661 72 1288 414 1707 930 305 377
      505 856 558 1335 14 129 14 421 0 550 -53 479 -253 958 -558 1335 -465 573
      -1163 918 -1907 942 -44 2 -141 0 -215 -3z m-326 -1230 c14 -7 30 -25 36 -42
      7 -20 10 -402 10 -1179 0 -971 -2 -1154 -14 -1183 -23 -53 -49 -57 -389 -53
      l-299 3 -24 28 -24 28 0 1169 c0 743 4 1178 10 1195 5 14 20 30 32 35 35 13
      632 13 662 -1z m1613 -12 c17 -17 18 -81 21 -1202 l2 -1184 -24 -28 -24 -28
      -299 -3 c-340 -4 -366 0 -389 53 -12 29 -14 212 -14 1183 0 779 3 1159 11
      1179 5 17 20 35 32 41 15 7 129 10 344 9 294 -2 324 -4 340 -20z"/>
    </g>
  </svg>
)

const CallHoldIcon = () => (
  <svg viewBox="0 0 22 22">
    <defs></defs>
    <g transform="translate(-1543 -499)">
      <g transform="translate(207 111)">
        <circle class="a" fill='#4a9ce2' cx="11" cy="11" r="11" transform="translate(1336 388)"/>
      </g>
      <g transform="translate(1541 498.25)">
        <path class="b" fill='#fff' d="M11.637,16.75H9.3A.276.276,0,0,1,9,16.5V7a.276.276,0,0,1,.3-.25h2.34a.276.276,0,0,1,.3.25v9.5A.276.276,0,0,1,11.637,16.75Z" transform="translate(0 0)"/>
        <path class="b" fill='#fff' d="M24.012,16.75h-2.34a.276.276,0,0,1-.3-.25V7a.276.276,0,0,1,.3-.25h2.34a.276.276,0,0,1,.3.25v9.5A.276.276,0,0,1,24.012,16.75Z" transform="translate(-7.309 0)"/>
      </g>
    </g>
  </svg>
)

const CallResumeIcon = () => (
  <svg viewBox="0 0 22 22">
    <g transform="translate(-936 -766)">
      <circle class="a" fill='#f0c883' cx="11" cy="11" r="11" transform="translate(936 766)"/><path class="b" fill='#fff' d="M3.667,0,7.333,5.867H0Z" transform="translate(950.667 773.333) rotate(90)"/>
    </g>
  </svg>
)

const CallGreenIcon = () => (
  <svg viewBox="0 0 30 30"><defs><style></style></defs><g transform="translate(-516 -308)"><circle class="a" fill='#1dbf67' cx="15" cy="15" r="15" transform="translate(516 308)"/><path class="b" fill='#fff' d="M3.3,5.7a1.031,1.031,0,0,1,.263-.56l2.1-2.1q.245-.21.368.07l1.7,3.185a.458.458,0,0,1-.088.543l-.77.77a.871.871,0,0,0-.245.543A2.672,2.672,0,0,0,7.165,9.5a11.509,11.509,0,0,0,1.068,1.4l.543.56c.164.164.373.365.631.6A10.567,10.567,0,0,0,10.674,13a2.839,2.839,0,0,0,1.391.586.762.762,0,0,0,.56-.228l.911-.91a.385.385,0,0,1,.525-.07l3.064,1.8a.256.256,0,0,1,.14.184.224.224,0,0,1-.07.2l-2.1,2.1a1.029,1.029,0,0,1-.56.262A4.234,4.234,0,0,1,12.6,16.7a8.753,8.753,0,0,1-1.995-.954q-.919-.595-1.707-1.207t-1.26-1.05l-.455-.438q-.175-.175-.464-.481T5.712,11.359A17.578,17.578,0,0,1,4.469,9.6a10.261,10.261,0,0,1-.91-1.952A4.152,4.152,0,0,1,3.3,5.7Z" transform="translate(520.733 313.053)"/></g></svg>
)

const MuteIcon = ({ fillColor = '#000' }) => (
  <svg viewBox="0 0 22 22">
    <g transform="translate(-1507 -334)"><circle class="a" fill={fillColor} cx="11" cy="11" r="11" transform="translate(1507 334)"/><g transform="translate(1508.5 335.5)"><path class="b" fill='#fff' d="M23.8,9.5a4.066,4.066,0,0,1-.354,1.663l.83.83A5.19,5.19,0,0,0,24.9,9.5a5.116,5.116,0,0,0-3.88-5V5.671A3.973,3.973,0,0,1,23.8,9.5Z" transform="translate(-10.404)"/><path class="b" fill='#fff' d="M15.741,5.657,14.413,7.024l1.329,1.329Z" transform="translate(-6.241 -0.729)"/><path class="b" fill='#fff' d="M13.793,13.309,5.206,4.722l-.625.625L6.938,7.7l-.216.272H4.5v3.333H6.722l2.778,2.9V10.265l2.472,2.466a3.855,3.855,0,0,1-1.352.738V14.64a4.9,4.9,0,0,0,2.144-1.115l1.032,1.034.625-.626-.625-.626Z" transform="translate(0 -0.14)"/><path class="b" fill='#fff' d="M22.409,14.066a2.593,2.593,0,0,0-1.385-2.314V13.31l1.323,1.323A2.62,2.62,0,0,0,22.409,14.066Z" transform="translate(-10.404 -4.566)"/></g></g>
  </svg>
)

const CallHangupRedIcon = () => (
  <svg viewBox="0 0 30 30">
    <g transform="translate(-1336 -388)"><circle class="a" fill='#e25244' cx="15" cy="15" r="15" transform="translate(1336 388)"/><path class="b" fill='#fff' d="M3.3,5.7a1.031,1.031,0,0,1,.263-.56l2.1-2.1q.245-.21.368.07l1.7,3.185a.458.458,0,0,1-.088.543l-.77.77a.871.871,0,0,0-.245.543A2.672,2.672,0,0,0,7.165,9.5a11.509,11.509,0,0,0,1.068,1.4l.543.56c.164.164.373.365.631.6A10.567,10.567,0,0,0,10.674,13a2.839,2.839,0,0,0,1.391.586.762.762,0,0,0,.56-.228l.911-.91a.385.385,0,0,1,.525-.07l3.064,1.8a.256.256,0,0,1,.14.184.224.224,0,0,1-.07.2l-2.1,2.1a1.029,1.029,0,0,1-.56.262A4.234,4.234,0,0,1,12.6,16.7a8.753,8.753,0,0,1-1.995-.954q-.919-.595-1.707-1.207t-1.26-1.05l-.455-.438q-.175-.175-.464-.481T5.712,11.359A17.578,17.578,0,0,1,4.469,9.6a10.261,10.261,0,0,1-.91-1.952A4.152,4.152,0,0,1,3.3,5.7Z" transform="translate(1365.3 404.789) rotate(135)"/></g>
  </svg>
)

const NegativeCircleIcon = () => (
  <svg viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#F4AAA2" stroke="none">
      <path d="M2395 5109 c-318 -18 -657 -107 -949 -249 -778 -379 -1306 -1113 -1421 -1975 -22 -161 -22 -489 0 -650 130 -979 798 -1790 1728 -2099 274 -91 502 -127 807 -127 305 0 533 36 807 127 930 309 1598 1120 1728 2099 22 161 22 489 0 650 -76 569 -326 1078 -729 1481 -523 523 -1220 786 -1971 743z m1525 -2549 l0 -160 -1365 0 -1365 0 0 160 0 160 1365 0 1365 0 0 -160z"/>
    </g>
  </svg>
)

const MergeIcon = ({ fillColor = '#000' }) => (
  <svg viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
    <path d="M1875 4360 l-760 -760 355 -355 355 -355 157 157 158 158 0 -485 0 -485 -771 -765 -771 -765 354 -353 355 -353 915 908 916 908 1 688 c0 378 4 687 8 687 4 0 74 -66 155 -147 l148 -148 353 353 352 352 -760 760 -760 760 -760 -760z m1315 -215 l545 -545 -143 -142 -142 -143 -305 305 -305 305 0 -991 0 -991 -766 -758 -765 -759 -141 141 -140 140 706 703 706 703 0 911 0 911 -310 -310 -310 -310 -140 140 -140 140 547 547 c302 302 550 548 553 548 3 0 250 -245 550 -545z"/>
    <path d="M3192 1284 c-194 -197 -351 -358 -349 -359 2 -1 815 -766 940 -884 l37 -35 350 349 c193 193 350 353 350 357 0 3 -197 192 -437 419 -241 227 -461 434 -489 461 l-51 49 -351 -357z m777 -458 c64 -60 117 -114 119 -120 1 -6 -60 -73 -135 -148 l-138 -137 -272 257 -273 257 142 142 142 142 150 -142 c82 -78 202 -191 265 -251z"/>
    </g>
  </svg>
)

export const RefreshIcon = () => (
  <svg viewBox="0 0 17 18" fill="none">
    <path d="M9.87408 16.2001C6.37334 16.9273 2.8726 15.0502 1.55348 11.7185C0.301981 8.55602 1.38432 4.97073 4.1241 3.02587L3.85349 4.81849C3.81963 5.08911 4.00567 5.35973 4.27628 5.39346H4.34388C4.59751 5.39346 4.80053 5.20742 4.85126 4.95378L5.25718 2.24784C5.27404 2.11253 5.24031 1.97722 5.15572 1.87578C5.07114 1.77433 4.95282 1.68974 4.81752 1.67287L2.11157 1.26696C1.84096 1.2331 1.57033 1.41914 1.5366 1.68976C1.50274 1.96037 1.68878 2.23099 1.9594 2.26473L3.16019 2.43389C0.302072 4.70004 -0.780252 8.58978 0.589572 12.0567C1.87484 15.2868 4.96972 17.3332 8.33517 17.3332C8.91014 17.3332 9.48511 17.2824 10.0602 17.1471C10.3308 17.0964 10.5168 16.8258 10.4491 16.5552C10.4152 16.3185 10.1445 16.1325 9.87405 16.2001L9.87408 16.2001ZM15.0153 15.6252L13.713 15.4391C16.4697 13.156 17.5013 9.33399 16.1483 5.93472C14.6432 2.14648 10.6689 0.0155982 6.67775 0.844275C6.40714 0.895002 6.22111 1.16563 6.28881 1.43625C6.33954 1.70686 6.61017 1.8929 6.88078 1.82519C10.3815 1.09804 13.8823 2.97513 15.2014 6.30681C16.419 9.36784 15.4381 12.8178 12.8845 14.7965L13.1381 13.1053C13.172 12.8347 12.9859 12.5641 12.7153 12.5304C12.4447 12.4965 12.1741 12.6826 12.1404 12.9532L11.7344 15.6591C11.7176 15.7944 11.7513 15.9297 11.8359 16.0312C11.9205 16.1326 12.0388 16.2172 12.1741 16.2341L14.8801 16.64H14.9476C15.2013 16.64 15.4043 16.454 15.455 16.2003C15.4889 15.9295 15.3029 15.6758 15.0154 15.6251L15.0153 15.6252Z" fill="white"/>
  </svg>
)

export const EditIcon = () => (
  <svg fill="#000000" viewBox="0 0 128 128">    
    <path d="M 79.335938 15.667969 C 78.064453 15.622266 76.775 15.762109 75.5 16.099609 C 72.1 16.999609 69.299609 19.199219 67.599609 22.199219 L 64 28.699219 C 63.2 30.099219 63.699609 32.000781 65.099609 32.800781 L 82.400391 42.800781 C 82.900391 43.100781 83.400391 43.199219 83.900391 43.199219 C 84.200391 43.199219 84.399219 43.199609 84.699219 43.099609 C 85.499219 42.899609 86.1 42.399219 86.5 41.699219 L 90.199219 35.199219 C 91.899219 32.199219 92.4 28.700781 91.5 25.300781 C 90.6 21.900781 88.400391 19.100391 85.400391 17.400391 C 83.525391 16.337891 81.455078 15.744141 79.335938 15.667969 z M 60.097656 38.126953 C 59.128906 38.201172 58.199219 38.724609 57.699219 39.599609 L 27.5 92 C 24.1 97.8 22.200781 104.30039 21.800781 110.90039 L 21 123.80078 C 20.9 124.90078 21.5 125.99961 22.5 126.59961 C 23 126.89961 23.5 127 24 127 C 24.6 127 25.199219 126.8 25.699219 126.5 L 36.5 119.40039 C 42 115.70039 46.7 110.8 50 105 L 80.300781 52.599609 C 81.100781 51.199609 80.599219 49.3 79.199219 48.5 C 77.799219 47.7 75.899609 48.199609 75.099609 49.599609 L 44.800781 102 C 41.900781 106.9 37.899609 111.20039 33.099609 114.40039 L 27.300781 118.19922 L 27.699219 111.30078 C 27.999219 105.60078 29.699609 100 32.599609 95 L 62.900391 42.599609 C 63.700391 41.199609 63.200781 39.3 61.800781 38.5 C 61.275781 38.2 60.678906 38.082422 60.097656 38.126953 z M 49 121 C 47.3 121 46 122.3 46 124 C 46 125.7 47.3 127 49 127 L 89 127 C 90.7 127 92 125.7 92 124 C 92 122.3 90.7 121 89 121 L 49 121 z M 104 121 A 3 3 0 0 0 101 124 A 3 3 0 0 0 104 127 A 3 3 0 0 0 107 124 A 3 3 0 0 0 104 121 z"/>
  </svg>
)

export const TickIcon = () => (
  <svg viewBox="0 0 48 48">
    <path fill="#43A047" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"/>
  </svg>
)

export const InfoIcon = () => (
  <svg fill="#000000" viewBox="0 0 32 32"><path d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z M 16 5 C 22.085938 5 27 9.914063 27 16 C 27 22.085938 22.085938 27 16 27 C 9.914063 27 5 22.085938 5 16 C 5 9.914063 9.914063 5 16 5 Z M 15 10 L 15 12 L 17 12 L 17 10 Z M 15 14 L 15 22 L 17 22 L 17 14 Z"/></svg>
)

export {
  CalendarSvg,
  PlayIcon,
  PauseIcon,
  CallHoldIcon,
  CallResumeIcon,
  CallGreenIcon,
  MuteIcon,
  CallHangupRedIcon,
  NegativeCircleIcon,
  MergeIcon,
}