import React from 'react'
import { ellipsisForLongText, formatAMPM, formatDateMonthYear } from '../../utils/helper';
import {
  TicketCallBtns,
  CardText,
  TicketDetails,
  UpdateText,
  CyanButton,
  AddressText,
  AllTicketHead,
} from './style';


function SingleTicketDetails({ ticket, scheduleTicket = false, getTicketById }) {
  let updatedAt = new Date(ticket.updatedAt)
  let createdAt = new Date(ticket.createdAt)
  let scheduleDateTime = ticket && ticket.schedule && ticket.schedule.scheduledDateTime ? ticket.schedule.scheduledDateTime : null;
  let scheduledAt = new Date(scheduleDateTime);
  let updatedDate = `${formatDateMonthYear(updatedAt)},${formatAMPM(updatedAt)}`;
  let createdDate = `${formatDateMonthYear(createdAt)},${formatAMPM(createdAt)}`;
  let scheduledDate = `${formatDateMonthYear(scheduledAt)},${formatAMPM(scheduledAt)}`;
  let pickupLocation = ticket && ticket.leadDetails && ticket.leadDetails.pickupLocation ? ellipsisForLongText(ticket.leadDetails.pickupLocation) : "NA";
  let dropLocation = ticket && ticket.leadDetails && ticket.leadDetails.dropLocation ? ellipsisForLongText(ticket.leadDetails.dropLocation) : "NA";
  let fromAndTo = `${pickupLocation} to ${dropLocation}`
  let Invoicelink = ticket?.invoiceDetails?.invoiceLink[0]
  let closedDate;
  if (ticket?.closedAt != null) {
    let closedAt = new Date(ticket.closedAt)
    closedDate = `${formatDateMonthYear(closedAt)},${formatAMPM(closedAt)}`;
  } else {
    closedDate = ''
  }
  return (
    <>
      <AllTicketHead onClick={() => { getTicketById(ticket.ticketId) }} style={{ "cursor": "pointer" }} >
        {/* <PurpleButton>Feedback 1/10</PurpleButton> */}
        {scheduleTicket ? <CardText>Scheduled At {scheduledDate}<br /> Created By: {ticket.ownerName}</CardText> : <CardText>Created at {createdDate} <br /> {ticket?.ticketStage === 'CLOSED' ? `Closed at ${closedDate}` : ''} </CardText>}
        <AddressText>{fromAndTo}</AddressText>
        <CardText purple>{ticket.ticketStage} &nbsp;</CardText>
        <TicketDetails>
          <CardText>{ticket.ticketId}</CardText>
          <UpdateText>
            {ticket?.leadDetails?.leadSource && <CardText dirtygreen>{ticket?.leadDetails?.leadSource} Lead &nbsp;</CardText>}
            <AddressText>{updatedDate}</AddressText>
          </UpdateText>
        </TicketDetails>
      </AllTicketHead>
      <TicketCallBtns>
        <div>
          {ticket?.leadDetails?.leadType && <CardText>Request Type: {ticket?.leadDetails?.leadType}</CardText>}
        </div>
        <UpdateText>
          <CyanButton boxy>
            Call Patient
          </CyanButton>
          <CyanButton boxy>
            Call Vendor
          </CyanButton>
          <CyanButton boxy>
            Call Hospital
          </CyanButton>
          {Invoicelink && <CyanButton boxy onClick={() => { window.open(Invoicelink, "_blank") }}>
            Invoice
          </CyanButton>}
        </UpdateText>
      </TicketCallBtns>
    </>
  )
}

export default SingleTicketDetails
