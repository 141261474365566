import styled, { css } from "styled-components";

const getRoundButtonHeight = props => {
  if (props.small) return "2vh";
  return "3vh";
};

const round = css`
  font-size: 20px;
  border-radius: 30px;
  padding: 15px 20px;
  height: ${getRoundButtonHeight};
`;

const infoRound = css`
  font-size: 1.2vw;
  font-weight: 600;
  border-radius: 30px;
  padding: ${props => props.padding || '.5rem'};
  height: ${props => props.height || '7vh'};
  width: ${props => props.width || 'auto'};
  outline: none;
  margin-bottom: ${props => props.marginBottom || '0'};
  margin-right: ${props => props.marginRight || '0.5rem'};
  margin-left: ${props => props.marginLeft || '0'};
  margin-top: ${props => props.marginTop || '0'};
  text-align: center;
  &:focus{
    outline: none;
  }
`;

const vendorRound = css`
  font-size: 1vw;
  font-weight: 600;
  border-radius: 30px;
  padding: ${props => props.padding || '.5rem'};
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || 'auto'};
  outline: none;
  margin-bottom: ${props => props.marginBottom || '0'};
  margin-right: ${props => props.marginRight || '0'};
  margin-left: ${props => props.marginLeft || '0'};
  margin-top: ${props => props.marginTop || '0'};
  &:focus{
    outline: none;
  }
`;

const loginButton = css`
  ${infoRound}
  justify-content: ${props => props.textCenter ? 'space-around' : 'flex-start'};
  padding: ${props => props.textCenter ? '.5rem 1.5rem .5rem 1.5rem' : '.5rem 3rem .5rem .5rem'};
  margin: 2rem auto 1rem auto;
`;

const sendInvoiceBtn = css`
  ${infoRound}
  padding: .5rem;
  margin: 2rem auto 0 auto;
`;

const feedbackBtn = css`
  ${infoRound}
  padding: 1.5rem;
  margin: 2rem auto 0 auto;
`;

// const onhold = css`
//   font-size: 1.2vw;
//   border-radius: 30px;
//   height: 7vh;
//   margin-right: 1vw;
//   width: 17%;
//   &:focus{
//     outline: none;
//   }
// `

const boxy = css`
  font-size: 12px;
  border-radius: 6px;
  padding: 5px 15px;
  height: 28px;
`;

const getButtonStyle = props => {
  if (props.boxy) return boxy;
  if (props.infoRound) return infoRound;
  if (props.vendorRound) return vendorRound;
  if(props.loginButton) return loginButton;
  if(props.sendInvoice) return sendInvoiceBtn;
  if(props.feedback) return feedbackBtn;
  return round;
};

const getButtonColor = props => {
  if (props.orange) {
    return "#FEA729";
  }
  if (props.green) {
    return "#1DBF67";
  }
  if (props.cyan) {
    return "#16999E";
  }
  if (props.purple) {
    return "#E0D0FC";
  }
  if (props.blue) {
    return "#346CCC";
  } else {
    return "#9F9F9F";
  }
};

const getCursorStyle = props => {
  if (props.disabled) return "not-allowed";
  return "pointer";
};

export const commonFlexCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CustomButton = styled.button`
  cursor: ${getCursorStyle};
  background-color: ${getButtonColor};
  border: none;
  color: white;
  font-weight: 600;
  letter-spacing: 0px;
  flex-direction: row;
  ${commonFlexCSS}
  ${getButtonStyle}
`;

export default CustomButton;
