import { VEHICLE } from '../constants';
import { select, takeEvery, put } from 'redux-saga/effects';


function* paginateAndCalculateVehiclesEtaDist ({ payload, type }){
  const getVehiclesData = (state) => state.nearbyAmbulances;
  const getOldVehiclesData = (state) => state.patientInfo;
  let allVehicles = [...payload.data] || [];
  if(type === VEHICLE.GET_VEHICLE_SUCCESS){
    const oldTicketData = yield select(getOldVehiclesData);
    const { fetchedTicketData = {} } = oldTicketData;
    const { quoteDetails = {} } = fetchedTicketData;
    const { nearbyAmbulanceDetails = [] } = quoteDetails;
    if(nearbyAmbulanceDetails && nearbyAmbulanceDetails.length > 0){
      yield allVehicles.forEach((veh, i) => {
        nearbyAmbulanceDetails.forEach((nearbyVeh, j) => {
          if(
            veh && nearbyVeh && 
            nearbyVeh.vehicleObject && 
            nearbyVeh.modified &&
            veh.vehicleObject && nearbyVeh.vehicleObject.id && 
            veh.vehicleObject.id && nearbyVeh.vehicleObject.id === veh.vehicleObject.id
          ){
            let newVeh = {
              ...nearbyVeh,
              ...veh
            }
            allVehicles.splice(i, 1, {...newVeh})
          }
        })
      })
    }
  }
  const handleLoadedVeh = {
    type: VEHICLE.LOAD_MODIFIED_RATE_VEHICLES,
    payload: { data: [...allVehicles] }
  }
  yield put(handleLoadedVeh)
  let nearbyAmbulancesData = yield select(getVehiclesData);
  let { page, vehicleCount, isGoogle }  = nearbyAmbulancesData;
  let displayedAmbulances = allVehicles.splice(page, vehicleCount);
  let newPage = page + vehicleCount;
  let setType = isGoogle ? VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE : VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE;
  let actionConst = { 
    type: setType, 
    payload: { ambulanceList: [...displayedAmbulances], page: newPage } 
  }
  yield put(actionConst)
}


export default function* handleVehiclesLoad() {
  yield takeEvery([VEHICLE.GET_VEHICLE_SUCCESS, VEHICLE.LOAD_MORE_VEHICLES], paginateAndCalculateVehiclesEtaDist);
}