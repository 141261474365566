import { REQUEST_TICKET } from "../constants";

const INITIAL_STATE = {
	ticketHistory: {}, 
  error: false,
  pending: false,
};

const ticketHistoryReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REQUEST_TICKET.REQUEST_TICKET_HISTORY_FAILED: {
      return {
        ...state,
        ticketHistory:{},
        pending: false,
        error: action.payload
      }
    }
		case REQUEST_TICKET.REQUEST_TICKET_HISTORY_PENDING: {
      return {
        ...state,
        ticketHistory:{},
        pending: true,
        error: false
      }
    }
		case REQUEST_TICKET.REQUEST_TICKET_HISTORY_SUCCESS: {
      let ticketHistory = {...action.payload}
			return {
				...state,
        pending:false,
        error:false,
        ticketHistory
			}
		}
		default:
			return state;
	}
};

export default ticketHistoryReducer;