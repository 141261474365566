import styled from "styled-components";
import { commonFlexCSS } from '../../components/CustomButton';

export const TabContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.8vw;
`;

export const ContentWrapper = styled.div``;

export const ContentLabel = styled.label``;

export const OptSpan = styled.span`
  font-weight: 400;
  color: #9F9F9F;
`;

export const AutoCompleteWrapper = styled.div`
  position: relative;
`;

export const LocationInput = styled.input``;

export const AutoCompleteDropContainer = styled.div`
  position: absolute;
  z-index: 2;
  font-size: 1vw;
  border: 1px solid #ccc;
  top: 5.5vh;
  height: 25vh;
  overflow-y: scroll;
`;

export const SuggestionsLoading = styled.div``;

export const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

export const PropsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2,auto);
  margin-top: 2rem;
`;

export const PropInternalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SuggestionWrapper = styled.div`
  background-color: #f0f0f0;
  padding: .5em;
  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }
`;

export const SuggestionSpan = styled.span`
  margin-bottom: .3em;
`;

export const CustomLabel = styled.label`
  height: fit-content;
  width: fit-content;
`;

export const ButtonSvg = styled.img`
  margin-right: 1vw;
`;

export const UpdateInfoButton = styled.button`
  font-size: 1.2vw;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white
`;

export const RadioButtonWrapper = styled.div`
  display: flex; 
  justify-content: space-between;
`
export const TextBoxWrapper = styled.div`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  flex-direction: column;
  ${commonFlexCSS}
`