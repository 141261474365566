// reducer.js

import { SET_POPUP_Hidden, SET_POPUP_SHOWN } from "../actions/popupActions.js";



const initialState = {
    popupShown: false,
};

const popupreducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POPUP_SHOWN:
            // Set the flag in localStorage
            localStorage.setItem('popupShown', true);
            return {
                ...state,
                popupShown: true,
            };
        case SET_POPUP_Hidden:
            localStorage.setItem('popupShown', false)
            return {
                ...state,
                popupShown: false
            };
        default:
            return state;
    }
};

export default popupreducer;
