import React from "react";
// import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from "react-bootstrap";
// import DatePicker from 'react-datepicker';
// import { addMonths } from 'date-fns';
import "../index.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { CalendarSvg } from './icons';
import { NotificationManager } from "react-notifications";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import not from "../images/notification_sound.wav";
import micicon from "../assets/images/muteicon.png";
import {
  // AutoCompleteWrapper,
  // OptSpan,
  // LocationInput,
  // AutoCompleteDropContainer,
  // SuggestionsLoading,
  // SuggestionWrapper,
  // SuggestionSpan,
  PropsWrapper,
  CustomLabel,
  PropInternalWrapper,
  OptSpan,
} from "../containers/PatientInfoPage/styles";
import { InfoErrorMessage } from "../components/NavTabs";
import LocationComponent from "../containers/PatientInfoPage/LocationSearchInput";

function isDateBeforeToday(currentdate, endDate) {
  return (
    new Date(currentdate.toDateString()) <
      new Date(new Date().toDateString()) ||
    new Date(currentdate.toDateString()) > new Date(endDate.toDateString())
  );
}

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function formatDateMonthYear(date) {
  let dd = date.getDate();
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let yyyy = date.getFullYear();
  let dt = dd + " " + monthNames[date.getMonth()] + " " + yyyy;
  return dt;
}

function formatDateSmallMonthYear(date) {
  let dd = date.getDate();
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let yyyy = date.getFullYear();
  let dt = dd + " " + monthNames[date.getMonth()] + " " + yyyy;
  return dt;
}

function metersToKM(m) {
  let meter = parseFloat(m) ? parseFloat(m) : false;
  let km = false;
  if (meter) {
    let temp = meter / 1000;
    km = temp.toFixed(2) + " km ";
  }
  return km;
}

function secToTime(duration) {
  if (duration && parseFloat(duration)) {
    let hours = Math.floor(duration / 3600);
    duration %= 3600;
    let minutes = Math.floor(duration / 60);
    return (hours ? hours + " hr " : "") + (minutes ? minutes + " min " : "");
  }
  return false;
}

const Input = (props) => {
  let {
    add = false,
    className = "",
    label = "",
    svg = false,
    onChange,
    value = "",
    id = "",
    placeholder = "",
    childClass = "",
    childLabel = "",
    getPropertyValues,
    disabled = false,
    invalid = false,
    errorMessage = "",
  } = props;

  let checkedVal = id === "patientMobile" ? getPropertyValues("mobile") : false;
  let checked = value === checkedVal;

  return (
    <Form className={className}>
      <Form.Group>
        <Form.Label>
          {label}
          {id === "patientEmail" ? <OptSpan> (optional)</OptSpan> : ""}
        </Form.Label>
        <div className={svg ? "s-c" : ""}>
          {svg && <span className="rs-t">{svg}</span>}
          <Form.Control
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            as="input"
            disabled={disabled}
          />
          {add && add === "checkbox" && (
            <div className={childClass}>
              <InputGroup.Checkbox
                checked={checked}
                value="sameAsDialer"
                onChange={onChange}
              />
              <label>{childLabel}</label>
            </div>
          )}
        </div>
        {invalid && errorMessage && (
          <InfoErrorMessage>{errorMessage}</InfoErrorMessage>
        )}
      </Form.Group>
    </Form>
  );
};

export const TextArea = (props) => {
  let {
    className = "",
    label = "",
    onChange,
    placeholder = "",
    id = "",
    value = "",
    invalid = false,
    errorMessage = "",
    disabled = false,
  } = props;

  return (
    <Form className={className}>
      <Form.Group>
        {label && label !== "" && (
          <Form.Label>
            {label}
            {id === "patientExpectations" || id === "callReferredByRemark" ? (
              <OptSpan> (optional)</OptSpan>
            ) : (
              ""
            )}
          </Form.Label>
        )}
        <Form.Control
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          as="textarea"
          rows={3}
          disabled={disabled}
        />
        {invalid && errorMessage && (
          <InfoErrorMessage>{errorMessage}</InfoErrorMessage>
        )}
      </Form.Group>
    </Form>
  );
};

export const Select = (props) => {
  let {
    className = "",
    label = "",
    onChange,
    value = "",
    placeholder = "",
    options = [],
    invalid = false,
    errorMessage = "",
    disabled = false,
  } = props;
  return (
    <Form className={className}>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          custom
        >
          <option value={""} disabled>
            {placeholder}
          </option>
          {options && Array.isArray(options) && options.length
            ? options.map((opt) => {
                return (label === "Call Referred By" || label === "Driver Name") ? (
                  <option key={opt.id} value={opt.name}>
                    {opt.name}
                  </option>
                ) : (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                );
              })
            : null}
        </Form.Control>
        {invalid && errorMessage && (
          <InfoErrorMessage>{errorMessage}</InfoErrorMessage>
        )}
      </Form.Group>
    </Form>
  );
};

export const CheckBox = (props) => {
  let {
    className = "",
    label = "",
    onChange,
    value = "",
    checked = false,
    disabled = false,
  } = props;

  return (
    <>
      <Form className={`c-${className}`}>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <InputGroup.Checkbox
            checked={checked}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </Form.Group>
      </Form>
    </>
  );
};

const InputFactory = (props) => {
  let {
    type = null,
    className = "",
    label = "",
    onChange,
    id = "",
    getPropertyOptions = null,
    getPropertyValues = null,
    value = "",
    options = [],
    propOpt = [],
    propVal = null,
    blockproperty,
  } = props;

  options =
    type === "select"
      ? options?.length && Array.isArray(options)
        ? options
        : id && getPropertyOptions
        ? getPropertyOptions(id)
        : []
      : [];
  propOpt =
    type === "repeat-checkbox" || type === "repeat-text"
      ? propOpt?.length && Array.isArray(propOpt)
        ? propOpt
        : id && getPropertyOptions
        ? getPropertyOptions(id)
        : []
      : [];
  value = value ? value : id && getPropertyValues ? getPropertyValues(id) : "";
  let checkVal =
    type === "checkbox"
      ? id && getPropertyValues
        ? getPropertyValues(id)
        : false
      : false;
  // console.log(id, checkVal);

  switch (type) {
    case "text":
      return <Input value={value} {...props} />;
    case "textarea":
      return <TextArea value={value} {...props} />;
    case "select":
      return <Select value={value} options={options} {...props} />;
    case "location-text":
      return <LocationComponent value={value} {...props} />;
    // case 'repeat-checkbox':
    //   return <PropsWrapper className={className}>
    //     <CustomLabel>{label}</CustomLabel>
    //     <PropInternalWrapper>
    //       {propOpt && Array.isArray(propOpt) && propOpt.length ? propOpt.map((prop,i) => {
    //         console.log(prop);
    //         let val = typeof value === 'boolean' ? value : (value && Array.isArray(value) && value.includes(prop.val) ? true : false);
    //         return <Form key={i} className={`c-${className}`}>
    //           <Form.Group>
    //             <Form.Label>{prop}</Form.Label>
    //             <InputGroup.Checkbox checked={val} value={prop} onChange={onChange}/>
    //           </Form.Group>
    //         </Form>;
    //       }) : null}
    //     </PropInternalWrapper>
    //   </PropsWrapper>;
    case "repeat-checkbox":
      return (
        <PropsWrapper className={className}>
          <CustomLabel>
            {label}
            {id === "patientAmbulanceAddOns" ? (
              <OptSpan> (optional)</OptSpan>
            ) : (
              ""
            )}
          </CustomLabel>
          <PropInternalWrapper style={{ display: `${blockproperty}` }}>
            {propOpt && Array.isArray(propOpt) && propOpt.length
              ? propOpt.map((prop, i) => {
                  prop.disabled = props.disabled ? props.disabled : false;
                  return (
                    <InputFactory
                      key={i}
                      getPropertyValues={getPropertyValues}
                      onChange={onChange}
                      {...prop}
                    />
                  );
                })
              : null}
          </PropInternalWrapper>
        </PropsWrapper>
      );
    case "emptyElem":
      return <div></div>;
    case "checkbox":
      let val =
        typeof checkVal === "boolean"
          ? checkVal
          : checkVal && Array.isArray(checkVal) && checkVal.includes(propVal)
          ? true
          : false;
      return (
        <CheckBox
          value={propVal}
          checked={val}
          onChange={onChange}
          {...props}
        />
      );
    case "repeat-text":
      return (
        <PropInternalWrapper>
          {propOpt && Array.isArray(propOpt) && propOpt.length
            ? propOpt.map((prop, i) => {
                return (
                  <InputFactory
                    key={i}
                    getPropertyValues={getPropertyValues}
                    onChange={onChange}
                    {...prop}
                  />
                );
              })
            : null}
        </PropInternalWrapper>
      );
    default:
      return null;
  }
};

const getLatLngByAddress = async (address) => {
  try {
    let results = await geocodeByAddress(address);
    let latLng = await getLatLng(results[0]);
    return { longitude: latLng.lng, latitude: latLng.lat };
  } catch (err) {
    return false;
  }
};

const ellipsisForLongText = (input, num) => {
  let n = num ? num : 12;
  return input.length > 10 ? `${input.substring(0, n)}...` : input;
};

const localStorageSetItem = (itemObj) => {
  if (itemObj && Object.keys(itemObj)) {
    Object.keys(itemObj).forEach((key) => {
      localStorage.setItem(key, itemObj[key]);
    });
  }
};

const localStorageGetItem = (key) => {
  if (key && typeof key === "string") {
    return localStorage.getItem(key) ? localStorage.getItem(key) : null;
  } else if (key && typeof key === "object") {
    let obj = { ...key };
    Object.keys(key).forEach((k) => {
      obj[k] = localStorage.getItem(k) ? localStorage.getItem(k) : null;
    });
    return obj;
  }
};

const keyValue = (key, value, obj) => {
  let newObj = Object.assign({}, obj, { [key]: value });
  return newObj;
};

var audio = null;

const pauseNotificationSound = () => {
  if (audio) {
    audio.pause();
  }
};

const createNotification = (type, message, title = "", timeOut, leadtype) => {
  console.log("timeout", timeOut, leadtype);
  if (leadtype) {
    notficationsound();
  }
  switch (type) {
    case "info":
      // return NotificationManager.info(message,title,timeOut);
      return NotificationManager.info(
        <div>
          <h4>
            {timeOut ? (
              <img
                src={micicon}
                color="white"
                width={"30px"}
                height={"30px"}
                alt="mute icon"
                onClick={() => pauseNotificationSound()}
              />
            ) : null}
          </h4>
          {message}
          {/* <button onClick={() => pauseNotificationSound()}>Pause</button> */}
        </div>,
        title,
        timeOut
      );
    case "success":
      return NotificationManager.success(message, title);
    case "warning":
      return NotificationManager.warning(message, title);
    case "error":
      return NotificationManager.error(message, title);
    default:
      return NotificationManager.info("", "");
  }
};

const checkIfStringIsNumber = (str, limit) => {
  if (str) {
    let num = parseInt(str);
    let ans = /^[0-9]+$/.test(str);
    let newstr = str.trim();
    if (limit && newstr.length !== limit) {
      ans = false;
    }
    return ans;
  }
  return false;
};

const checkIfNumberIsNumber = (str, limit) => {
  if (str) {
    let ans = /^[0-9]+$/.test(str);
    return ans;
  }
  return false;
};

export const checkIfEmailValid = (str) => {
  let validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str);
  return validEmail;
};

export const appendScript = (scriptToAppend, id) => {
  const script = document.createElement("script");
  script.id = id;
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

export const removeScript = (elem) => {
  document.body.removeChild(elem);
};

export const randomlySelectArrValue = (arr) => {
  const random = Math.floor(Math.random() * arr.length);
  return { key: random, value: arr[random] };
};

export const isValueEqual = (value1, value2) => {
  let isEqual =
    (value1 &&
      value2 &&
      typeof value1 === "string" &&
      typeof value2 === "string" &&
      value1.toLowerCase() &&
      value2.toLowerCase() &&
      value1.toLowerCase() === value2.toLowerCase()) ||
    false;
  return isEqual;
};

export const isValueNotEqual = (value1, value2) => {
  let isNotEqual =
    (value1 && value2 && value1.toLowerCase() !== value2.toLowerCase()) ||
    false;
  return isNotEqual;
};

// export function findKeyValue(obj, target, key) {
//   console.log(o)
//   const fnd = obj => {
//     for (const [k, v] of Object.entries(obj)) {
//       if (k === key) return { [k]: v };
//       if (typeof v === 'object' && !Array.isArray(v)) {
//         const f = fnd(v);
//         if (f) return f;
//       }
//     }
//   }
//   return fnd(obj);
// }

export const ignoreCaseStringEquals = (str1, str2) => {
  return (
    str1 &&
    str2 &&
    typeof str1 === "string" &&
    typeof str2 === "string" &&
    str1.toLowerCase() &&
    str2.toLowerCase() &&
    str1.toLowerCase() === str2.toLowerCase()
  );
};

export const removeSpaceIgnoreCaseStringEquals = (str1, str2) => {
  return (
    str1 &&
    str2 &&
    typeof str1 === "string" &&
    typeof str2 === "string" &&
    str1.toLowerCase() &&
    str2.toLowerCase() &&
    str1.replace(/ /g, "").toLowerCase() ===
      str2.replace(/ /g, "").toLowerCase()
  );
};

export const numberSuffix = (num) => {
  let i = parseInt(num);
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

const notficationsound = () => {
  audio = new Audio(not);
  audio.play();
};

const convertTimeToMinutes = (timeString) => {
  // Split the time string into hours and minutes
  let timeArray = timeString.split(" ");

  // Get the hours and minutes as integers
  let hours = parseInt(timeArray[0]);
  let minutes = parseInt(timeArray[2]);

  // Calculate the total time in minutes
  let totalMinutes = hours * 60 + minutes;

  return totalMinutes;
};

export {
  InputFactory,
  formatAMPM,
  formatDateMonthYear,
  ellipsisForLongText,
  localStorageSetItem,
  keyValue,
  createNotification,
  checkIfStringIsNumber,
  isDateBeforeToday,
  metersToKM,
  secToTime,
  getLatLngByAddress,
  formatDateSmallMonthYear,
  localStorageGetItem,
  notficationsound,
  checkIfNumberIsNumber,
  convertTimeToMinutes,
  pauseNotificationSound,
};
