import { createTicket } from "../api";
import { TICKETS } from "../constants";

export const createTicketAction = (data, callback) => async (dispatch) => {
  dispatch({ type: TICKETS.CREATE_TICKET_PENDING });
  if (callback && typeof callback === "function") callback("loading")
  try{
    let createTicketReq = await createTicket(data);
    if(createTicketReq.status === 200){
      if (callback && typeof callback === "function") callback(true)
      dispatch({ type: TICKETS.CREATE_TICKET_SUCCESS, payload: createTicketReq.data }) 
    }else {
      if (callback && typeof callback === "function") callback(false)
      let error = createTicketReq.error || "Error Creating New Ticket"  
      dispatch({ type: TICKETS.CREATE_TICKET_FAILED, payload: error })
    }
  }catch(error){
    if (callback && typeof callback === "function") callback(false)
    dispatch({ type: TICKETS.CREATE_TICKET_FAILED, payload: error })
  }
};
