import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TicketsWrapper } from './style';
import EmptyContainer from './../../../../EmptyContainer';
import LiveTicketDetails from '../../../../TicketDetailsDisplay/liveTicketDetails';
import { SingleTicketWrapper } from '../../../../TicketDetailsDisplay/style';
import { getTicketByIdAction } from '../../../../../actions/patientInfoAction';
import { Mixpanel } from './../../../../../utils/mixpanelUtil';
import { Container } from 'react-bootstrap';
import ButtonSvg from '../../../../ButtonSvg';
import loadingIcon from '../../../../../images/loading.gif';

function LiveTickets() {
  const tickets = useSelector(state => state.tickets || {});
  const liveTickets = tickets.liveTicketsData && Array.isArray(tickets.liveTicketsData) ? tickets.liveTicketsData : [];
  const { liveTicketDataLoading = false, liveTicketDataErr = false } = tickets;

  const dispatch = useDispatch();
  const getTicketById = (ticketId) => {
    Mixpanel.track('Live Ticket Clicked', { ticketId }, true);
    dispatch(getTicketByIdAction(ticketId))
  }

  if (liveTicketDataLoading) return <Container className='loading-container'>
    <ButtonSvg src={loadingIcon} />
    Loading
  </Container>
  if (liveTicketDataErr) return <EmptyContainer text={"Something Went Wrong!"} />
  if (!liveTickets.length) return <EmptyContainer text={"No Tickets Found"} />
  return (
    <TicketsWrapper>
      {liveTickets.map((ticket) => {
        return <SingleTicketWrapper
          key={ticket.id}
          boxShadow='orange'
          style={{ 'maxHeight': '45vh', 'minHeight': '10vh', 'backgroundColor': (ticket?.ticketStage).toUpperCase() === 'NEWTICKET' ? '#16999e3d' : '#FFF' }}
          // onClick={() => getTicketById(ticket.ticketId)}
          cursor='auto'
        >
          <LiveTicketDetails ticket={ticket} getTicketById={getTicketById} />
        </SingleTicketWrapper>
      })}
    </TicketsWrapper>
  )
}

export default LiveTickets
