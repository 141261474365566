const UPDATE_CALL_EVENTS = 'UPDATE_CALL_EVENTS';
const HANDLE_CALL_EVENTS = 'HANDLE_CALL_EVENTS';
const WS_CONNECTED = 'WS_CONNECTED';
const DIAL_CALL_EVENT = 'DIAL_CALL_EVENT';
const HANGUP_CALL_EVENT = 'HANGUP_CALL_EVENT';
const RINGING_CALL_EVENT = 'RINGING_CALL_EVENT';
const INCOMING_CALL_EVENT = 'INCOMING_CALL_EVENT';
const LOGIN_CALL_EVENT = 'LOGIN_CALL_EVENT';
const ANSWERED_CALL_EVENT = 'ANSWERED_CALL_EVENT';
const HOLD_CALL_EVENT = 'HOLD_CALL_EVENT';
const LOGOUT_CALL_EVENT = 'LOGOUT_CALL_EVENT';
const CONFERENCE_CALL_EVENT = 'CONFERENCE_CALL_EVENT';
const MUTE_CALL_EVENT = 'MUTE_CALL_EVENT';
const UNMUTE_CALL_EVENT = 'UNMUTE_CALL_EVENT';
const REMOVE_CALLS_FROM_CALL_LIST = 'REMOVE_CALLS_FROM_CALL_LIST';

export default {
  UPDATE_CALL_EVENTS,
  WS_CONNECTED,
  HANDLE_CALL_EVENTS,
  DIAL_CALL_EVENT,
  HANGUP_CALL_EVENT,
  RINGING_CALL_EVENT,
  INCOMING_CALL_EVENT,
  LOGIN_CALL_EVENT,
  ANSWERED_CALL_EVENT,
  HOLD_CALL_EVENT,
  LOGOUT_CALL_EVENT,
  CONFERENCE_CALL_EVENT,
  MUTE_CALL_EVENT,
  UNMUTE_CALL_EVENT,
  REMOVE_CALLS_FROM_CALL_LIST,
}