import React from "react";
import {
  StyledCardContainer,
  HeadingWrapper,
  StyledHeading,
  StyledDescription,
  SpaceBetweenWrapper,
  CenterWrapper,
  StyledData,
  StyledFooterText
} from "./index";
import bonus from "../../../images/bonus.svg";

const DailyBonusCard = ({ children }) => {
  return (
    <StyledCardContainer>
      <HeadingWrapper>
        <StyledHeading> Daily Bonus </StyledHeading>
        <img src={bonus} alt="bonus" />
      </HeadingWrapper>
      <StyledDescription>
        {/* HelpNow Fleet &nbsp; <b>2 of 4 Trips Left</b>{" "} */}
      </StyledDescription>
      <div>
        <SpaceBetweenWrapper>
          <StyledData>
            {/* Helpline Calls */}
          </StyledData>
          <StyledData>
            {/* ₹ 200 Won! */}
          </StyledData>
        </SpaceBetweenWrapper>
      </div>
      <div>
        <SpaceBetweenWrapper>
          <StyledData>
            {/* Outbound Calls */}
          </StyledData>
          <StyledData>
            {/* ₹ 200 Won! */}
          </StyledData>
        </SpaceBetweenWrapper>
      </div>
      <CenterWrapper>
        <StyledFooterText>
          {/* Close more calls & WIN Rewards! */}
        </StyledFooterText>
      </CenterWrapper>
    </StyledCardContainer>
  );
};

export default DailyBonusCard;
