import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    //   console.log('serachresults',
    //     React.Children.toArray(children).filter(
    //       (child, index, array) => {
    //         const childText = child.props.children.toLowerCase();
    //         const startsWithCondition = !value || childText.startsWith(value);
    //         const includesCondition = !value || childText.includes(value);

    //         // Check if the current child meets either condition and is not already in the filtered array
    //         return (startsWithCondition || includesCondition) && array.indexOf(child) === index;
    // })
    //   )
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child, index, array) => {
              const childText = child.props.children.toLowerCase();
              const startsWithCondition = !value || childText.startsWith(value);
              const includesCondition = !value || childText.includes(value);

              // Check if the current child meets either condition and is not already in the filtered array
              return (startsWithCondition || includesCondition) && array.indexOf(child) === index;
            })}
        </ul>
      </div>
    );
  },
);

export default CustomMenu;