import React from 'react'
import { CountsContainer } from "./style";

function TotalCount() {
    return (
        <CountsContainer></CountsContainer>
    )
}

export default TotalCount;
