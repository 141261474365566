// const getVehiclesNearSourceUrl = 'http://dispatcherServiceALB-135434094.ap-south-1.elb.amazonaws.com/api/v1/vehicle/getAllIdleSourceVehicles';
const dispatcherBaseUrl = process.env.REACT_APP_DISPATCHER_URL;
const baseUrl = process.env.REACT_APP_LEAD_URL;
const loginUrl =
  process.env.REACT_APP_AUTH_URL +
  "auth/_/username/login?serviceType=lead_service";
const verifyTokenUrl = process.env.REACT_APP_AUTH_URL + "auth/token/verify";
const generateTokenUrl =
  process.env.REACT_APP_AUTH_URL + "auth/_/username/token";
const driverUrl = process.env.REACT_APP_DRIVER_SERVICE_URL;
const ticketBaseUrl = baseUrl + "ticket/";
const tieupHospitalListUrl = baseUrl + "tieupHospital/getAll";
const leadConfig = baseUrl + "config/leadConfig";
const breakUrl = baseUrl + "breakDetails/";
const callScheduleBaseUrl =
  "http://call-service-ALB-1323443076.ap-south-1.elb.amazonaws.com/api/v1/schedule/";
const getTicketBaseUrl = baseUrl + "ticket/allTickets/";
const tripLeaderboardUrl = baseUrl + "leaderboard/boardList";
const mapApi = "https://maps.googleapis.com/maps/api/js?libraries=places&key=";
const WS_BASE = "ws://localhost:8800";
const pusherApiKey = "45987be4c79e14071e28";
const pusherClusterName = "ap2";
// Hdp-admin
const baseApiUrl = process.env.REACT_PUBLIC_API_URL;
const baseReportsUrl = "/api/v1/";
//startDate='+yesterday+'&endDate='+today
// const reportsByFilterUrl = baseReportsUrl + 'reports/filters?';
const leadSystemConfig = baseUrl + "SystemConfig/get";
const getAllVendorsUrl =
  dispatcherBaseUrl + "vendor/getAllVendorDetails?active=true";
const getVehiclesNearSourceUrl =
  dispatcherBaseUrl + "vehicle/idleVehiclesWithRate";
const getCustomQuoteVehicleUrl =
  dispatcherBaseUrl + "vehicle/CustomQuotationWithRate";
const getVehiclesByVendorIdUrl = (id, city) =>
  dispatcherBaseUrl + `vehicle/vendor/${id}?city=${city}`;
const createTicketUrl = ticketBaseUrl + "create";
// const requestTicketUrl = ticketId => ticketBaseUrl + ticketId;
const requestTicketUrl = (ticketId) => ticketBaseUrl + `internalId/${ticketId}`;
// const getAllTicketsUrl = ticketBaseUrl + 0/20;
const getAllTicketsUrl = (id, skip, limit) =>
  getTicketBaseUrl + `${id}?skip=${skip}&limit=${50}`;
const updateHospitalUrl = (id) => ticketBaseUrl + `update/${id}`;
const updateTicketUrl = (id) =>
  ticketBaseUrl + `updateTicketByInternalID/${id}`;
// const getLiveTicketsUrl = ownerId =>  ticketBaseUrl + `liveTickets/${ownerId}`;
const getLiveTicketsUrl = (ownerId) =>
  ticketBaseUrl + `getTicketByFilters?ownerId=${ownerId}&ticketStage=LIVE`;
// const getLiveTicketsUrl = ownerId => 'http://leadServiceALB-1253102499.ap-south-1.elb.amazonaws.com/api/v1/ticket/getTicketByFilters?ownerId=neil_test&ticketStage=LIVE';
const createBreakUrl = breakUrl + "create";
const updateBreakUrl = (id) => breakUrl + `update/${id}`;
const updateTripUrl = (id) => driverUrl + `trip/${id}`;
const GetAllDrivers = driverUrl + `driver/getAllDrivers`;
const callScheduleUrl = callScheduleBaseUrl + "create";
const getTicketByMobile = (mb) => ticketBaseUrl + `mobile/${mb}`;
const getLatestTicketByMobile = (mb, ticketId) =>
  ticketBaseUrl + `latestTicket/mobile/${mb}/${ticketId}`;
const getTicketHistoryUrl = (ticketId) =>
  baseUrl +
  `ticketActionHistory/ticketActionHistoryByTicketID/${ticketId}?source=HelpNow`;
const ticketsBySearchUrl = (searchTerm, skipValue) =>
  ticketBaseUrl +
  `getTicketByTextSearch/${searchTerm}?skip=${skipValue}&limit=5`;
const getLeadTicketsUrl = (skip, limit) =>
  ticketBaseUrl + `getUnOwnedLeadTickets?skip=${skip}&limit=${limit}`;
const getScheduleTicketsUrl = (ownerId) =>
  ticketBaseUrl + `getTicketByFilters?ownerId=${ownerId}&ticketStage=SCHEDULED`;
const updateTicketRateurl = (id) => baseUrl + `ticket/updateTicketRate/${id}`;
const getnotesurl = (id) => baseUrl + `notes/getNotes/${id}`;
const mapKey = ["AIzaSyDCrcK3oVxbhPptF-jW4gKS0wmmfmwKHFM"];
// const mapKey = ['AIzaSyCHZHVRQJ5VS9o0D3Z2BljYFR1BrWmFIE8', 'AIzaSyDCrcK3oVxbhPptF-jW4gKS0wmmfmwKHFM'];
// const mapKey = ['AIzaSyCd3zLpJd5zkTj7nppt3PFJauvo1331gYI', 'AIzaSyDf0Y6n7mzelKb60LZg5MUvA1agLw8ngsA', 'AIzaSyCHZHVRQJ5VS9o0D3Z2BljYFR1BrWmFIE8', 'AIzaSyDCrcK3oVxbhPptF-jW4gKS0wmmfmwKHFM'];
const mapUrl = (key) => mapApi + key;
const openRouteKeys = [
  "5b3ce3597851110001cf6248127fceeb78484a2c955d6c674856b91b",
  "5b3ce3597851110001cf62484adcaa0086f64cd499b71853d1156383",
];
export {
  getVehiclesNearSourceUrl,
  loginUrl,
  createTicketUrl,
  requestTicketUrl,
  updateHospitalUrl,
  getAllTicketsUrl,
  leadConfig,
  createBreakUrl,
  updateBreakUrl,
  updateTicketUrl,
  callScheduleUrl,
  getLiveTicketsUrl,
  getTicketByMobile,
  ticketsBySearchUrl,
  getVehiclesByVendorIdUrl,
  getAllVendorsUrl,
  getLeadTicketsUrl,
  mapUrl,
  mapKey,
  WS_BASE,
  getScheduleTicketsUrl,
  openRouteKeys,
  pusherApiKey,
  pusherClusterName,
  tripLeaderboardUrl,
  getCustomQuoteVehicleUrl,
  getTicketHistoryUrl,
  updateTripUrl,
  getLatestTicketByMobile,
  leadSystemConfig,
  verifyTokenUrl,
  generateTokenUrl,
  tieupHospitalListUrl,
  updateTicketRateurl,
  getnotesurl,
  GetAllDrivers,
};
