const LOGIN_PENDING = "LOGIN_PENDING";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const SET_LOCALSTORAGE_AUTH_TRUE = "SET_LOCALSTORAGE_AUTH_TRUE";
const LOGOUT = "LOGOUT";
const SET_USER_NAME = "SET_USER_NAME";
const SET_USER_PASSWORD = "SET_USER_PASSWORD";
const LOGIN_FAILED = "LOGIN_FAILED";

export default {
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    SET_LOCALSTORAGE_AUTH_TRUE,
    LOGOUT,
    SET_USER_NAME,
    SET_USER_PASSWORD,
    LOGIN_FAILED,
}