import React from 'react'
import { useSelector } from 'react-redux';
import { InputFactory } from '../../utils/helper';
import { ContentWrapper } from './styles';
import { Form, InputGroup } from 'react-bootstrap';

const patientInfoDetails = [
  {
    id: 'mobile',
    label: 'Caller Mobile No',
    type: 'text',
    className: 'in-t',
    disabled: true,
  },
  {
    id: 'patientEmail',
    label: 'Caller / Patient Email',
    type: 'text',
    className: 'in-t',
    errorMessage: 'Enter Valid Email.',
    disabled: false,
  },
  {
    id: 'patientMobile',
    label: 'Patient Mobile No',
    type: 'text',
    className: 'in-t r-f',
    add: 'checkbox',
    childLabel: 'Same As Dialer',
    childClass: 'a-it s-d',
    errorMessage: 'Enter 10 digits Mobile No.',
    disabled: false,
  },
  {

    id: 'dropLocation',
    label: 'Drop Location',
    type: 'location-text',
    className: 'in-t',
    errorMessage: 'Enter Valid Locations',

    disabled: false,
  },
  {
    id: 'leadSource',
    label: 'Lead Source',
    type: 'select',
    className: 'in-t',
    placeholder: 'Select Lead Source',
    errorMessage: 'Select Lead Source',
    disabled: false,
  },
  {
    id: 'callReferredByRemark',
    label: 'Remark (Call Referred By)',
    type: 'textarea',
    className: 'in-t',
    disabled: false
  },
  {
    id: 'patientAmbulanceAddOns',
    label: 'Ambulance Add ons',
    type: 'repeat-checkbox',
    className: 'a-it',
    blockproperty: 'inline-block',
    propOpt: [
      //   {
      //   id: 'patientAmbulanceAddOns',
      //   label: 'AC',
      //   propVal: 'AC',
      //   type: 'checkbox',
      //   className: 'a-it',
      //   disabled: false,
      // },{
      //   id: 'patientAmbulanceAddOns',
      //   label: 'O2',
      //   propVal: 'O2',
      //   type: 'checkbox',
      //   className: 'a-it',
      //   disabled: false,
      // },{
      //   id: 'patientAmbulanceAddOns',
      //   label: 'NONAC',
      //   propVal: 'NONAC',
      //   type: 'checkbox',
      //   className: 'a-it',
      //   disabled: false,
      // },
      {
        id: 'emergencyCase',
        label: 'EMERGENCYCASE',
        propVal: 'EMERGENCYCASE',
        type: 'checkbox',
        className: 'a-it',
        disabled: false,
      }, {
        id: 'extraHelper',
        label: 'EXTRAHELPER',
        propVal: 'EXTRAHELPER',
        type: 'checkbox',
        className: 'a-it',
        disabled: false,
      },
      {
        id: 'AC',
        label: 'AC',
        propVal: 'AC',
        type: 'checkbox',
        className: 'a-it',
        disabled: false,
      },
      {
        id: 'WHEELCHAIR',
        label: 'WHEELCHAIR',
        propVal: 'WHEELCHAIR',
        type: 'checkbox',
        className: 'a-it',
        disabled: false,
      },
      {
        id: 'O2',
        label: 'O2',
        propVal: 'O2',
        type: 'checkbox',
        className: 'a-it',
        disabled: false,
      }
    ]
  }
]

const helperDetails = [{
  id: 'numberofExtraHelper',
  label: 'Number of Extra helper',
  type: 'text',
  className: 'in-t',
  errorMessage: 'Enter Valid Number.',
  disabled: false,
},
]

const O2Details = [{
  id: 'O2Details',
  label: 'O2',
  type: 'text',
  className: 'in-t',
  errorMessage: 'Enter Valid Number.',
  disabled: false,
},
]

function MiddleContent({ getPropertyOptions, getPropertyValues, setPropertyValues, toDisable, displayTieupHospitals, showHelperNumber, setShowHelperNumber, setPayByHospital, setO2Number, o2Number }) {
  const ticketData = useSelector(state => state.patientInfo.ticketData);
  const ticketData2 = useSelector(state => state.patientInfo.fetchedTicketData || {});
  //const isValid = useSelector(state => state.validation.isValid || false);
  const invalidDetailsKeys = useSelector(state => state.validation.invalidDetailsKeys || []);
  const handleChange = (e, id) => {
    let value = e.target?.value;
    // console.log('onchange', value, id);
    if (value === 'sameAsDialer') {
      let checked = e.target?.checked;
      if (checked) {
        value = ticketData?.mobile;
      } else {
        value = ''
      }
    }
    if (id === 'patientAmbulanceAddOns' && value === 'EMERGENCYCASE') {
      id = 'emergencyCase';
      let checked = e.target?.checked;
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    if (id === 'patientAmbulanceAddOns' && value === 'EXTRAHELPER') {
      id = 'extraHelper';
      let checked = e.target?.checked;
      if (checked) {
        value = true;
        setShowHelperNumber(true);
      } else {
        value = false;
        setShowHelperNumber(false);
        setPropertyValues('0', "numberofExtraHelper")
      }
    }
    if (id === 'patientAmbulanceAddOns' && value === "O2") {
      id = value
      let checked = e.target?.checked;
      if (checked) {
        value = 1;
        setO2Number(true)
        // setShowHelperNumber(true);
      } else {
        value = 0;
        setO2Number(false)
        setPropertyValues(0, "O2Details")
        // setShowHelperNumber(false);
      }
    }
    if (id === 'patientAmbulanceAddOns' && (value === 'AC' || value === 'WHEELCHAIR')) {
      id = value;
      let checked = e.target?.checked;
      if (checked) {
        value = 1;
        // setShowHelperNumber(true);
      } else {
        value = 0;
        // setShowHelperNumber(false);
      }
    }
    if (id === 'leadSource' && value === 'HOSPITALSTAFF') {
      displayTieupHospitals(true);
    } else if (id === 'leadSource' && value !== 'HOSPITALSTAFF') {
      displayTieupHospitals(false);
      setPropertyValues(null, 'tieupHospitalName')
      setPayByHospital(false)
    }
    setPropertyValues(value, id)
  }

  return (
    <ContentWrapper>
      {patientInfoDetails.map((p, i) => {
        p.disabled = p.id === 'mobile' ? true : toDisable;
        // if(p.id === 'leadSource'){
        // if(ticketData2?.leadDetails?.leadSource?.toUpperCase() ==='WHATSAPP' || ticketData2?.leadDetails?.leadSource?.toUpperCase() ==='JD'){
        //   p.disabled = true;
        // }
        // else{
        //   ticketData?.ticketStage?.toUpperCase() === "PATIENTINFO" ? p.disabled = true : p.disabled = false;
        // }
        // }
        if (p.id === 'leadSource' && (ticketData2?.leadDetails?.leadSource?.toUpperCase() === 'WHATSAPP' || ticketData2?.leadDetails?.leadSource?.toUpperCase() === 'JD')) {
          p.type = 'text';
          p.disabled = true;
          return <InputFactory
            invalid={invalidDetailsKeys.includes(p.id)}
            key={i}
            onChange={(e) => handleChange(e, p.id)}
            getPropertyOptions={(id) => getPropertyOptions(id)}
            getPropertyValues={(id) => getPropertyValues(id)}
            {...p}
          />
        } else if (p.id === 'leadSource') {
          p.type = 'select';
          p.disabled = false;
          return <InputFactory
            invalid={invalidDetailsKeys.includes(p.id)}
            key={i}
            onChange={(e) => handleChange(e, p.id)}
            getPropertyOptions={(id) => getPropertyOptions(id)}
            getPropertyValues={(id) => getPropertyValues(id)}
            {...p}
          />
        }
        else {
          return <InputFactory
            invalid={invalidDetailsKeys.includes(p.id)}
            key={i}
            onChange={(e) => handleChange(e, p.id)}
            getPropertyOptions={(id) => getPropertyOptions(id)}
            getPropertyValues={(id) => getPropertyValues(id)}
            {...p}
          />
        }
      })}
      {ticketData.extraHelper || showHelperNumber ? <>{helperDetails.map((p, i) => {
        return <InputFactory
          invalid={invalidDetailsKeys.includes(p.id)}
          key={i}
          onChange={(e) => handleChange(e, p.id)}
          getPropertyValues={(id) => getPropertyValues(id)}
          {...p}
        />
      })}</> : null}
      {o2Number || ticketData?.extraChargesMapperList?.O2?.numberOfAddons > 0 ? <>{O2Details.map((p, i) => {
        return <InputFactory
          invalid={invalidDetailsKeys.includes(p.id)}
          key={i}
          onChange={(e) => handleChange(e, p.id)}
          getPropertyValues={(id) => getPropertyValues(id)}
          {...p}
        />
      })}</> : null}

      {/* <Form className={'a-it addon'}>
        <Form.Group>
          <Form.Label>AC</Form.Label>
          <InputGroup.Checkbox //checked={freeTrip} value={'FREE'} 
            onChange={(e) => handleChange(e)} disabled={toDisable} />
        </Form.Group>
        <Form.Group>
          <Form.Label>EXTRAHELPER</Form.Label>
          <InputGroup.Checkbox //value={checked} onChange={handleChangeRoundtrip}
            disabled={toDisable} />
        </Form.Group>
        <Form.Group>
          <Form.Label>WHEELCHAIR</Form.Label>
          <InputGroup.Checkbox //value={checked} onChange={handleChangeRoundtrip}
            disabled={toDisable} />
        </Form.Group>
        <Form.Group>
          <Form.Label>O2</Form.Label>
          <InputGroup.Checkbox //value={checked} onChange={handleChangeRoundtrip}
            disabled={toDisable} />
        </Form.Group>
      </Form> */}
    </ContentWrapper>
  )
}

export default MiddleContent;
