import React from "react";
import styled from "styled-components";

const StyledPopup = styled.span`
  background-color: #16999e;
  border-radius: 18px 18px 0px 0px;
  width: 20%;
  height: 7vh;
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: ${props => props.fontWeight || 600};
  font-size: ${props => props.fontSize || "1.2vw"};
`;

const PopupHeading = ({ children, ...props }) => {
  return <StyledPopup {...props}>{children}</StyledPopup>;
};

export default PopupHeading;
