const GET_VEHICLE_PENDING = "GET_VEHICLE_PENDING";
const GET_VEHICLE_SUCCESS = "GET_VEHICLE_SUCCESS";
const GET_VEHICLE_FAILED = "GET_VEHICLE_FAILED";
const UPDATE_VEHICLE_LIST = "UPDATE_VEHICLE_LIST";
const CALCULATE_VEHICLES_ETA = 'CALCULATE_VEHICLES_ETA';
const ERROR_CALCULATING_ETA = 'ERROR_CALCULATING_ETA';
const CALCULATE_CUSTOM_VEHICLE_ETA = 'CALCULATE_CUSTOM_VEHICLE_ETA';
const CALCULATED_CUSTOM_VEHICLE_ETA = 'CALCULATED_CUSTOM_VEHICLE_ETA';
const CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE = 'CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE';
const CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE = 'CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE';
const CALCULATING_ETA_DIST = 'CALCULATING_ETA_DIST';
const HANDLE_OPEN_ROUTE_RESPONSE = 'HANDLE_OPEN_ROUTE_RESPONSE';
const LOAD_MORE_VEHICLES = 'LOAD_MORE_VEHICLES';
const SET_VEHICLE_POST_REQUEST = 'SET_VEHICLE_POST_REQUEST';
const GET_CUSTOM_QUOTE_PENDING = 'GET_CUSTOM_QUOTE_PENDING';
const GET_CUSTOM_QUOTE_SUCCESS = 'GET_CUSTOM_QUOTE_SUCCESS';
const GET_CUSTOM_QUOTE_FAILED = 'GET_CUSTOM_QUOTE_FAILED';
const EMPTY_VEHICLES = 'EMPTY_VEHICLES';
const ADD_VEHICLES_FROM_TICKET_DETAILS = 'ADD_VEHICLES_FROM_TICKET_DETAILS';
const LOAD_MODIFIED_RATE_VEHICLES = 'LOAD_MODIFIED_RATE_VEHICLES';
const UPDATE_MODIFIED_RATE_DISPLAY_LIST = 'UPDATE_MODIFIED_RATE_DISPLAY_LIST';

const GET_VEHICLE_PENDING_HP = "GET_VEHICLE_PENDINGHP_HP";
const GET_VEHICLE_SUCCESS_HP = "GET_VEHICLE_SUCCESS_HP";
const GET_VEHICLE_FAILED_HP = "GET_VEHICLE_FAILED_HP";
const UPDATE_VEHICLE_LIST_HP = "UPDATE_VEHICLE_LIST_HP";
const CALCULATE_VEHICLES_ETA_HP = 'CALCULATE_VEHICLES_ETA_HP';
const ERROR_CALCULATING_ETA_HP = 'ERROR_CALCULATING_ETA_HP';
const CALCULATE_CUSTOM_VEHICLE_ETA_HP = 'CALCULATE_CUSTOM_VEHICLE_ETA_HP';
const CALCULATED_CUSTOM_VEHICLE_ETA_HP = 'CALCULATED_CUSTOM_VEHICLE_ETA_HP';
const CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE_HP = 'CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE_HP';
const CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE_HP = 'CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE_HP';
const CALCULATING_ETA_DIST_HP = 'CALCULATING_ETA_DIST_HP';
const HANDLE_OPEN_ROUTE_RESPONSE_HP = 'HANDLE_OPEN_ROUTE_RESPONSE_HP';
const LOAD_MORE_VEHICLES_HP = 'LOAD_MORE_VEHICLES_HP';
const SET_VEHICLE_POST_REQUEST_HP = 'SET_VEHICLE_POST_REQUEST_HP';
const GET_CUSTOM_QUOTE_PENDING_HP = 'GET_CUSTOM_QUOTE_PENDING_HP';
const GET_CUSTOM_QUOTE_SUCCESS_HP = 'GET_CUSTOM_QUOTE_SUCCESS_HP';
const GET_CUSTOM_QUOTE_FAILED_HP = 'GET_CUSTOM_QUOTE_FAILED_HP';
const EMPTY_VEHICLES_HP = 'EMPTY_VEHICLES_HP';
const ADD_VEHICLES_FROM_TICKET_DETAILS_HP = 'ADD_VEHICLES_FROM_TICKET_DETAILS_HP';
const LOAD_MODIFIED_RATE_VEHICLES_HP = 'LOAD_MODIFIED_RATE_VEHICLES_HP';
const UPDATE_MODIFIED_RATE_DISPLAY_LIST_HP = 'UPDATE_MODIFIED_RATE_DISPLAY_LIST_HP';

export default {
    GET_VEHICLE_PENDING,
    GET_VEHICLE_SUCCESS,
    GET_VEHICLE_FAILED,
    UPDATE_VEHICLE_LIST,
    CALCULATE_VEHICLES_ETA,
    ERROR_CALCULATING_ETA,
    CALCULATE_CUSTOM_VEHICLE_ETA,
    CALCULATED_CUSTOM_VEHICLE_ETA,
    CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE,
    CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE,
    CALCULATING_ETA_DIST,
    HANDLE_OPEN_ROUTE_RESPONSE,
    LOAD_MORE_VEHICLES,
    SET_VEHICLE_POST_REQUEST,
    GET_CUSTOM_QUOTE_PENDING,
    GET_CUSTOM_QUOTE_SUCCESS,
    GET_CUSTOM_QUOTE_FAILED,
    EMPTY_VEHICLES,
    ADD_VEHICLES_FROM_TICKET_DETAILS,
    LOAD_MODIFIED_RATE_VEHICLES,
    UPDATE_MODIFIED_RATE_DISPLAY_LIST,

    GET_VEHICLE_PENDING_HP,
    GET_VEHICLE_SUCCESS_HP,
    GET_VEHICLE_FAILED_HP,
    UPDATE_VEHICLE_LIST_HP,
    CALCULATE_VEHICLES_ETA_HP,
    ERROR_CALCULATING_ETA_HP,
    CALCULATE_CUSTOM_VEHICLE_ETA_HP,
    CALCULATED_CUSTOM_VEHICLE_ETA_HP,
    CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE_HP,
    CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE_HP,
    CALCULATING_ETA_DIST_HP,
    HANDLE_OPEN_ROUTE_RESPONSE_HP,
    LOAD_MORE_VEHICLES_HP,
    SET_VEHICLE_POST_REQUEST_HP,
    GET_CUSTOM_QUOTE_PENDING_HP,
    GET_CUSTOM_QUOTE_SUCCESS_HP,
    GET_CUSTOM_QUOTE_FAILED_HP,
    EMPTY_VEHICLES_HP,
    ADD_VEHICLES_FROM_TICKET_DETAILS_HP,
    LOAD_MODIFIED_RATE_VEHICLES_HP,
    UPDATE_MODIFIED_RATE_DISPLAY_LIST_HP
}