import React from "react";
import "./styles.scss";

const RippleEffect = () => {
  return (
    <div className="request-loader">
      <span>
        20  seconds
      </span>
    </div>
  );
};

export default RippleEffect;
