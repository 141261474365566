import React, { useEffect, useRef } from 'react'
import SearchComponent from './searchComponent';
import { MiddleSectionWrapper } from './style';
import TicketsTab from './ticketsTab';
import TotalCount from './totalCount';
import { useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';

function MiddleSection({ notesdata }) {
  const search = useSelector(state => state.tickets?.searched || false);
  const marqueeRef = useRef(null);

  const stopMarquee = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const startMarquee = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };
  return (
    <MiddleSectionWrapper>
      <marquee ref={marqueeRef} onMouseEnter={stopMarquee} onMouseLeave={startMarquee} scrollamount='10' style={{display:'flex'}}>
        {notesdata.length > 0 && notesdata.map((item) => {
          return (
            <div style={{display:'inline-block'}}>
              <span style={{ marginLeft: '5px', marginRight: '5px' }}>&#8226;</span>
              <span style={{ display: 'inline-block' }}>{item.message}</span>
            </div>
          )
        })}
      </marquee>
      <SearchComponent />
      {!search ?
        <>
          <TicketsTab />
          <TotalCount />
        </>
        : null}
    </MiddleSectionWrapper>
  )
}

export default MiddleSection;
