import styled from 'styled-components';

export const PointsWrapper = styled.div`
    height: 82vh;
    width: auto;
    display: grid;
    grid-template-rows: repeat(3, auto);
    row-gap: 3vh;
`;

