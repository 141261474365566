import React from "react";
import styled from "styled-components";

import {
  StyledCardContainer,
  HeadingWrapper,
  StyledHeading,
  SpaceBetweenWrapper,
  StyledData,
  FooterTextTrips,
  /*LocationButtonWrapper,
  LocationButton,
  LocationButtonActive,*/
  FlexWrapper,
  SeeMoreText
} from "./index";

import trips from "../../../images/trips.svg";
import gold from "../../../images/gold.svg";
import silver from "../../../images/silver.svg";
import bronze from "../../../images/bronze.svg";
import { numberSuffix } from '../../../utils/helper';

const TripsStyledData = styled(StyledData)`
  font-size: 0.9vw;
`;

const StyledImage = styled.img`
  height: 1vw;
  width: 1vw;
`;

const TripsLeaderboardCard = ({ tripsCount = [], setSeeMore = () => {}, seeMore = false }) => {
  const username = localStorage.getItem('username');
  let currentOfficerIndex = tripsCount.findIndex(obj => obj.officerId === username);
  let currentOfficerRank =  currentOfficerIndex && tripsCount[currentOfficerIndex] && tripsCount[currentOfficerIndex].rank && parseInt(tripsCount[currentOfficerIndex].rank) ? parseInt(tripsCount[currentOfficerIndex].rank) : 0;
  let officerRank = numberSuffix(currentOfficerRank)
  return (
    <StyledCardContainer>
      <HeadingWrapper>
        <StyledHeading> Trips Leaderboard </StyledHeading>
        <img src={trips} alt="leaderboard logo" />
      </HeadingWrapper>
      <FlexWrapper style={{ height: seeMore ? '50vh': 'auto', width: '100%' }}>
        <SpaceBetweenWrapper>
          <TripsStyledData>Name</TripsStyledData>
          <TripsStyledData>Tickets</TripsStyledData>
        </SpaceBetweenWrapper>
        {tripsCount.length ? tripsCount.map((trip, i) => {
          let { officerName = '', rank = '1', totalClosedTicket='0' } = trip;
          return !seeMore && i >= 3 ? null 
          :<SpaceBetweenWrapper key={i}>
            <TripsStyledData>
              {rank === '1' ? <span><StyledImage src={gold} /> {officerName}</span>
              : (rank === '2' ? <span> <StyledImage src={silver} /> {officerName} </span>
              : (rank === '3' ? <span><StyledImage src={bronze} /> {officerName}</span> 
              :  rank + ". " + officerName ))}
          </TripsStyledData>
          <TripsStyledData>{totalClosedTicket}</TripsStyledData></SpaceBetweenWrapper>
        }) : 'No Trips Found'}
      </FlexWrapper>
      <FooterTextTrips rank={officerRank} />
      <SeeMoreText onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'See Less' : 'See More'}</SeeMoreText>
    </StyledCardContainer>
  );
};

export default TripsLeaderboardCard;
