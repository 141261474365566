import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from "./sagas";


const saga = createSagaMiddleware();

const middlewares = [thunkMiddleware,saga];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

saga.run(rootSaga)

export default store;
