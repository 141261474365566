import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { getTicketByIdAction, getPage } from '../../actions/patientInfoAction';
import { getTicketsBySearch } from '../../actions/ticketAction';
// import LiveTicketCard from '../DashboardComponents/LiveTicketCard';
import { TicketsWrapper } from '../DashboardElements/DashboardMiddle/MiddleSection/TicketTabs/style';
import EmptyContainer from '../EmptyContainer';
import LiveTicketDetails from '../TicketDetailsDisplay/liveTicketDetails';
import { SingleTicketWrapper } from '../TicketDetailsDisplay/style';
import { Center, Bottom, White, ClearSpan, PageSpan } from './style';
import { Mixpanel } from './../../utils/mixpanelUtil';
import { Container } from 'react-bootstrap';
import loadingIcon from '../../images/loading.gif';
import ButtonSvg from '../ButtonSvg';

function SearchedResults(props) {
  let {
    searchedTickets = [],
    // getTicketByIdAction, 
    clearSearch,
    searchSkip,
    limit,
    getPage,
    searchTerm = '',
    getTicketsBySearch,
    totalCount,
    searchTicketDataLoading = false,
    searchTicketDataErr = false
  } = props;

  let activePage = searchSkip / limit || 0;

  const dispatch = useDispatch();

  const getSearchedTickets = (id) => {
    let searchSkip = props.searchSkip;
    let skipValue = id === "next" ? searchSkip + props.limit : (id === "prev" ? searchSkip - props.limit : searchSkip);
    if (id === 'next') {
      Mixpanel.track('Ticket Search Next Page', { searchTerm }, true)
    } else if (id === 'prev') {
      Mixpanel.track('Ticket Search Previous Page', { searchTerm }, true)
    }
    getPage(id);
    getTicketsBySearch(searchTerm, skipValue);
  }

  const getTicketById = (ticketId) => {
    Mixpanel.track('Searched Ticket Clicked', { ticketId }, true);
    dispatch(getTicketByIdAction(ticketId))
    clearSearch()
  }

  // if (searchTicketDataLoading) return <White>
  //   <Center>
  //     <Container className='loading-container'>
  //       <ButtonSvg src={loadingIcon} />
  //       Loading
  //     </Container></Center> </White>
  // if (searchTicketDataErr) return <EmptyContainer text={"Something Went Wrong!"} />

  return (
    <White>
      <Center>
        <TicketsWrapper>
          {searchedTickets?.length ? searchedTickets.map((ticket, index) => {
            return <SingleTicketWrapper key={index} style={{ 'width': '95%', 'minHeight': '15vh', cursor: 'auto' }}
            // onClick={() => getTicketById(ticket.ticketId)}
            >
              <LiveTicketDetails isSearch={true} ticket={ticket} getTicketById={getTicketById} />
            </SingleTicketWrapper>
          })
            : searchTicketDataLoading ? <Container className='loading-container'>
              <ButtonSvg src={loadingIcon} />
              Loading
            </Container>
              : searchTicketDataErr ? <EmptyContainer text={"Something Went Wrong!"} />
                : <EmptyContainer text={"No Tickets Found"} />}
        </TicketsWrapper>
      </Center>
      <Bottom>
        <ClearSpan>{searchSkip + searchedTickets?.length || 0}/{totalCount}</ClearSpan>
        <div style={{ paddingLeft: '20px' }}>
          {activePage ? <PageSpan onClick={() => getSearchedTickets('prev')} >prev</PageSpan> : null}
          <PageSpan>{activePage + 1}</PageSpan>
          {(!(searchedTickets?.length === totalCount) && !(searchSkip + searchedTickets?.length === totalCount)) ?
            <PageSpan onClick={() => getSearchedTickets('next')}>next</PageSpan>
            : null}
        </div>
        <ClearSpan onClick={() => clearSearch()}>Clear All</ClearSpan>
      </Bottom>
    </White>
  )
}

const mapStateToProps = (state) => ({
  searchedTickets: state.tickets.searchedTicketsData,
  searchSkip: state.tickets.searchSkip,
  limit: state.tickets.limit,
  totalCount: state.tickets.totalCount,
  searchTicketDataLoading: state.tickets.searchTicketDataLoading,
  searchTicketDataErr: state.tickets.searchTicketDataErr,
})

const mapDispatchToProps = {
  getPage,
  // getTicketByIdAction,
  getTicketsBySearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchedResults)
