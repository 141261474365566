import { REQUEST_TICKET } from "../constants";

const INITIAL_STATE = {
  allTicketsData: [],
  allTicketscount: 0,
  closedTicketCount: 0,
  newTicketCount: 0,
  liveTicketsData: [],
  liveTicketscount: 0,
  scheduledTicketscount: 0,
  searchedTicketsData: [],
  scheduledTicketsData: [],
  liveTicketDataLoading: false,
  allTicketDataLoading: false,
  searchTicketDataLoading: false,
  scheduleTicketDataLoading: false,
  liveTicketDataErr: false,
  allTicketDataErr: false,
  searchTicketDataErr: false,
  scheduleTicketDataErr: false,
  searchSkip: 0,
  searched: false,
  limit: 5,
  totalCount: 0,
};

export const ticketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_TICKET.REQUEST_LIVE_TICKET_PENDING:
      return {
        ...state,
        liveTicketDataLoading: true
      }
    case REQUEST_TICKET.REQUEST_SCHEDULED_TICKETS_PENDING:
      return {
        ...state,
        scheduleTicketDataLoading: true
      }
    case REQUEST_TICKET.REQUEST_ALL_TICKET_PENDING:
      return {
        ...state,
        allTicketDataLoading: true
      }
    case REQUEST_TICKET.TICKETS_BY_SEARCH:
      return {
        ...state,
        searchTicketDataLoading: true
      }
    case REQUEST_TICKET.REQUEST_ALL_TICKETS_FAILED:
      return {
        ...state,
        allTicketsData: [],
        allTicketDataLoading: false,
        allTicketDataErr: true,
      }
    case REQUEST_TICKET.TICKETS_BY_SEARCH_FAILED:
      return {
        ...state,
        searchTicketDataLoading: false,
        searchTicketDataErr: true,
        searchedTicketsData: [],
        totalCount: 0,
        searched: true,
      }
    case REQUEST_TICKET.REQUEST_LIVE_TICKETS_FAILED:
      return {
        ...state,
        liveTicketDataLoading: false,
        liveTicketDataErr: true,
        liveTicketsData: []
      }
    case REQUEST_TICKET.REQUEST_SCHEDULED_TICKETS_FAILED:
      return {
        ...state,
        scheduleTicketDataLoading: false,
        scheduleTicketDataErr: true,
        scheduledTicketsData: []
      }
    case REQUEST_TICKET.REQUEST_ALL_TICKETS_SUCCESS:
      return {
        ...state,
        allTicketDataLoading: false,
        allTicketDataErr: false,
        allTicketsData: action.payload.ticketList,
        allTicketscount: action.payload.count,
        closedTicketCount: action.payload.closedTicketCount,
        newTicketCount: action.payload.newTicketCount,
      }
    case REQUEST_TICKET.REQUEST_LIVE_TICKETS_SUCCESS:
      return {
        ...state,
        liveTicketDataLoading: false,
        liveTicketDataErr: false,
        liveTicketsData: action.payload.data.ticketList,
        liveTicketscount: action.payload.data.count,
      }
    case REQUEST_TICKET.REQUEST_SHEDULED_TICKETS_SUCCESS:
      return {
        ...state,
        scheduleTicketDataLoading: false,
        scheduleTicketDataErr: false,
        scheduledTicketsData: action.payload.data.ticketList,
        scheduledTicketscount: action.payload.data.count,

      }
    // case REQUEST_TICKET.REQUEST_LEAD_TICKETS_SUCCESS:
    //   return {
    //     ...state,
    //     leadTicketsData: action.payload
    //   }
    case REQUEST_TICKET.TICKETS_BY_SEARCH_SUCCESS:
      return {
        ...state,
        searchTicketDataLoading: false,
        searchTicketDataErr: false,
        searchedTicketsData: action.payload.data?.ticketList || [],
        totalCount: action.payload.data?.count || 0,
        searched: true
      }
    case REQUEST_TICKET.SEARCH_BY_PAGE:
      let searchSkip = state.searchSkip;
      let skipValue = action.payload === "next" ? searchSkip + state.limit
        : (action.payload === "prev" ? searchSkip - state.limit : searchSkip)
      return {
        ...state,
        searchSkip: skipValue,
      }
    case REQUEST_TICKET.EMPTY_SEARCH:
      return {
        ...state,
        searchedTicketsData: [],
        searchSkip: 0,
        searched: false,
      }
    default:
      return state;
  }
};
