import React from "react";

import {
  AccordionGridWrapper,
  AccordionCardsContainer,
  AccordionCard,
  AccordionCardHeaderWrapper,
  CustomAccordionButton,
  AccordionCardBody,
  CustomRowLabel,
} from "./AccordionStyles";

import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Input from '../../../components/Input/Input';

function AccordionComponent (props){
  let { ticket = {} } = props;
  let { leadDetails = {}, patientDetails = {}, /*quoteDetails = {},*/ emergencyCase=false } = ticket;
  let { pickupLocation = null , dropLocation = null } = leadDetails || {};
  let { 
    patientExpectations = null,
    patientAmbulanceType = null
    /*patientAmbulanceAddOns = []*/
    } = patientDetails || {};
  let isEmergencyCase = emergencyCase ? 'Yes' : 'No';

  return (
    <AccordionGridWrapper>
      {/* <AccordionTitle>Summary</AccordionTitle> */}
      <AccordionCardsContainer>
        <Accordion defaultActiveKey={1}>
          <AccordionCard>
            <AccordionCardHeaderWrapper>
              <Accordion.Toggle
                eventKey={1}
                as={Button}
                variant="link"
                style={{ padding: "0px", textDecoration: "none" }}
              >
                <CustomAccordionButton title="Info Taken In" />
              </Accordion.Toggle>
              {/* <TimeText>32 sec</TimeText> */}
            </AccordionCardHeaderWrapper>
            <Accordion.Collapse eventKey={1}>
              <AccordionCardBody>
                <CustomRowLabel>Type of call</CustomRowLabel>
                <Input height='5vh' value={"Type of call"} disabled/>

                <CustomRowLabel>Pick-up</CustomRowLabel>
                <Input height='5vh' value={pickupLocation || ""} disabled/>
                  
                <CustomRowLabel>Drop Loc</CustomRowLabel>
                <Input height='5vh' value={dropLocation || ""} disabled/>

                <CustomRowLabel>Ambulance Type</CustomRowLabel>
                <Input height='5vh' value={patientAmbulanceType || " "} disabled/>

                <CustomRowLabel>Special Needs</CustomRowLabel>
                <Input height='5vh' value={patientExpectations || " "} disabled/>

                <CustomRowLabel>Emergency Case</CustomRowLabel>
                <Input height='5vh' value={isEmergencyCase} disabled/>

                {/* <CustomRowLabel>Call Recording</CustomRowLabel>
                <AudioWrapper>
                  <AudioComponent />
                </AudioWrapper> */}
                {/* <Input style={{height:'auto'}} value={isEmergencyCase} disabled/> */}
              </AccordionCardBody>
            </Accordion.Collapse>
          </AccordionCard>

          {/* <AccordionCard>
            <AccordionCardHeaderWrapper>
              <Accordion.Toggle
                eventKey={2}
                as={Button}
                variant="link"
                style={{ padding: "0px", textDecoration: "none" }}
              >
                <CustomAccordionButton title="Quote & Book" />
              </Accordion.Toggle>
              //  <TimeText>32 sec</TimeText> 
            </AccordionCardHeaderWrapper>
            <Accordion.Collapse eventKey={2}>
              <AccordionCardBody>
                <CustomRowLabel>Amb Number (Vendor)</CustomRowLabel>
                <Input height='auto' value={patientAmbulanceType || " "} disabled/>

                <CustomRowLabel>Arrival Time</CustomRowLabel>
                <Input height='auto' value={patientAmbulanceType || " "} disabled/>

                <CustomRowLabel>Patient Cost</CustomRowLabel>
                <Input height='auto' value={patientAmbulanceType || " "} disabled/>

                <CustomRowLabel>HelpNow Share</CustomRowLabel>
                <Input height='auto' value={patientAmbulanceType || " "} disabled/>

                 <CustomRowLabel>Vendor Rating</CustomRowLabel> 
                 <CustomRow></CustomRow> 
              </AccordionCardBody>
            </Accordion.Collapse>
          </AccordionCard> */}
        </Accordion>
      </AccordionCardsContainer>
    </AccordionGridWrapper>
  );
}

const mapStateToProps = (state) => ({
  ticket: state.patientInfo.ticketData,
});

export default connect(mapStateToProps, null)(AccordionComponent);
