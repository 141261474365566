import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// const ProtectedRoute = ({ component: Component, authenticated, ...rest }) => (
//   <Route
//     {...rest}
//     component={props =>
//       authenticated ? <Component {...props} /> : <Redirect to="/login" />
//     }
//   />
// );

const ProtectedRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    component={props => <Component {...props} /> 
    }
  />
);

const mapStateToProps = state => ({
  authenticated: state.login.authenticated
});

export default connect(mapStateToProps, null)(ProtectedRoute);
