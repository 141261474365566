import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import SearchBar from '../../../DashboardComponents/SearchBar'
import SearchedResults from '../../../SearchedResults';
import { getTicketsBySearch, emptySearch } from "../../../../actions/ticketAction";
import { Mixpanel } from './../../../../utils/mixpanelUtil';

function SearchComponent(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);

  const handleSearch = (e) => {
    let { searchSkip, getTicketsBySearch } = props;
    Mixpanel.track('Ticket Searched', { searchTerm } , true)
    setSearch(true)
    getTicketsBySearch(searchTerm,searchSkip);
  }

  const clearSearch = () => {
    Mixpanel.track('Ticket Search Cleared', { searchTerm }, true)
    setSearch(false)
    setSearchTerm('')
    props.emptySearch();
  }

  useEffect(()=>{
    const listener = event =>{
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        var SearchButton = document.getElementById("searchbutton");
        SearchButton.click(); // this will trigger the click event
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  },[])

  return (
    <>
      <SearchBar
        handleSearch={(e) => handleSearch(e)}
        onChange={(e) => setSearchTerm(e.target.value)} 
        value={searchTerm}
        placeholder="Search for Patient Mobile, Patient Email, Ticket ID and Trip ID" 
      />
      {search ? <SearchedResults
        searchTerm={searchTerm} 
        clearSearch={(e) => clearSearch(e)} />
      : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  searchSkip: state.tickets.searchSkip,
})


const mapDispatchToProps = {
  getTicketsBySearch,
  emptySearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent)
