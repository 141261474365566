import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import LogoHeading from '../../LogoHeading';
import { 
  Topbar, 
  LogTime, 
  LogText,
  TakeABreak,
  Profile, 
} from './style';
import { localStorageSetItem, formatDateMonthYear } from '../../../utils/helper';
import { logoutAction, loginFromBreak } from "../../../actions/loginAction";
import { getTicketConfig, postBreakRequest, updateBreakRequest } from "../../../actions/ticketAction";
import Dropdown from "react-bootstrap/Dropdown";
import './index.scss';
import PopupModal from "../../PopupModal";
import Input from '../../Input/Input';
import ButtonSvg from "../../ButtonSvg";
import loginIcon from "../../../images/loginIcon.svg";
// import { WebSocketContext } from '../../../utils/websocket';
import { Mixpanel } from '../../../utils/mixpanelUtil';
// import BreakComponent from './breakComponent';
import { useHistory } from 'react-router-dom';

function DashboardTopbar(props) {
  //const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loginPwd, setLoginPwd] = useState("");
  const [breakId, setBreakId] = useState("");
  const [logging, setLogging] = useState(false);
  let todisplayusername = localStorage.getItem("username");
  const history = useHistory();
  // const ws = useContext(WebSocketContext);
  
  const handleBreakRequest = (value) => {
    let username = localStorage.getItem("username");
    Mixpanel.track('User On Break', { username, breakType: value }, false);
    props.postBreakRequest(value, username, (data) => {
      if(data.id){
        handleBreakData(data)
        setShowModal(true)
      }
    });
  }
  
  useEffect(() => {
    // console.log("useEffect lead Config called", localStorage.getItem('userToken'))
    props.getTicketConfig();
  }, []);
  
  useEffect(() => {
    let breakType = localStorage.getItem("breakType");
    let breakId = localStorage.getItem("breakId");
    if(breakType && breakId){
      setBreakId(breakId);
      setShowModal(true);
    }
  }, [breakId])
  
  
  const handleLogout = () => {
    Mixpanel.track('User Logged Out', {}, true);
    props.logoutAction();
    history.push('/login');
    // ws.logout();
  };

  const handleBreakData = (data) => {
    let localStorageItem = {
      "breakId": data.id,
      "breakType": data.breakType
    }
    localStorageSetItem(localStorageItem)
    setBreakId(data.id);    
  }

  const closeModal = (data) => {
    if(data){
      localStorage.removeItem("breakType")
      localStorage.removeItem("breakId")
      setShowModal(false)
    }
  }

  const handleFormSubmit = () => {
    let username = localStorage.getItem("username");
    let userCredential = {
      userName: username,
      password: loginPwd
    }
    Mixpanel.track('Logged In From Break', userCredential, false);
    setLogging(true);
    props.loginFromBreak(userCredential,(val) => {
      setLogging(false);
      if(val){
        props.updateBreakRequest(breakId, closeModal)
      }else{
        setShowModal("error")
      }
    });
  }

  const checkPassword = (e) => {
    setLoginPwd(e.target.value)
  }


  return (
    <Topbar>
      <LogoHeading />
      <LogTime>
        <LogText>{formatDateMonthYear(new Date())}</LogText>
        <LogText>Logged @ {props.loginTime} </LogText>
      </LogTime>
      <TakeABreak>
        <Dropdown onSelect={(e) => handleBreakRequest(e)}>
          <Dropdown.Toggle id="dropdown-basic" className="dd-top" >
            Take a break
          </Dropdown.Toggle>
          <Dropdown.Menu className="dd-menu">
            {
              props.breakList.length ? props.breakList.map((breakName) => {
                return <Dropdown.Item className="dd-item"  key={breakName} eventKey={breakName}>{breakName}</Dropdown.Item>
              }) : null
            }
          </Dropdown.Menu>
        </Dropdown>
      </TakeABreak>
      <Profile>
        <Dropdown onSelect={(e) => handleLogout(e)}>
          <Dropdown.Toggle id="dropdown-basic" className="profile-top" >
            Hi {todisplayusername}
          </Dropdown.Toggle>
          <Dropdown.Menu className="profile-menu">
            <Dropdown.Item className="profile-item" eventKey={"logout"}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Profile>
      { showModal && <PopupModal showModal={showModal} setShowModal={setShowModal} >
        <p>Enter Password to Resume</p>
        <Input width="60%" height="6vh" onChange={(e) => checkPassword(e)} />
        <button className="loginButton" disabled={logging} onClick={() => handleFormSubmit()}>
          <ButtonSvg src={loginIcon} />
          <span>{logging ? "Wait..." : "Login"}</span>
        </button>
        {showModal === "error" ? <p>Please Check Your Password and Try Again</p> : null}
      </PopupModal> }
    </Topbar> 
  )
}

const mapStateToProps = ({ 
  ticketConfig: { breakList, selectedBreak }, 
  login : { loginTime }  
}) => ({
  breakList,
  selectedBreak,
  loginTime,
})

const mapDispatchToProps = {
  logoutAction,
  postBreakRequest,
  getTicketConfig,
  loginFromBreak,
  updateBreakRequest,
};

export default connect(mapStateToProps,mapDispatchToProps)(DashboardTopbar);
