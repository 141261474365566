import React from 'react'
import { Modal } from 'react-bootstrap'
import WhiteContainer from '../../components/WhiteContainer';
import CustomButton from '../../components/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupHidden } from '../../actions/popupActions';
import notibell from '../../assets/images/notification-bell.svg'
const Notemessagepopup = ({ notesdata }) => {
    const dispatch = useDispatch();

    // let popupShown = localStorage.getItem('popupShown') || false
    const popupShown = useSelector(state => state.popup.popupShown);
    let contentClassName = "m-content"
    let headerClassName = "m-header"
    let bodyClassName = "m-body"
    let footerClassName = "m-footer"

    const handlecancel = () => {
        dispatch(setPopupHidden())
        // localStorage.setItem('popupShown', 'false')
    }




    return (
        <>
            <Modal
                show={popupShown}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={'static'}
                className={contentClassName || " "}
            >
                <Modal.Header className={headerClassName || " "}>
                    <Modal.Title>Notification <img src={notibell} alt='notifictionicon' style={{ width: '25px' }} /></Modal.Title>
                    {/* <button>x</button> */}
                </Modal.Header>
                <WhiteContainer style={{ borderRadius: "18px", width: "auto", maxHeight: '50vh',overflowY:'auto'  }}>
                    <Modal.Body className={bodyClassName || " "}>
                        <ul>
                            {notesdata?.map((point, index) => (
                                <li style={{wordBreak:'break-all'}} key={index}>{point.message}</li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer className={footerClassName || " "}>
                        <CustomButton width='20%' infoRound onClick={handlecancel}>Cancel</CustomButton>
                    </Modal.Footer>
                </WhiteContainer>
            </Modal ></>
    )
}

export default Notemessagepopup