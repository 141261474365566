import React, { useState, useEffect } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  AutoCompleteWrapper,
  OptSpan,
  AutoCompleteDropContainer,
  SuggestionsLoading,
  SuggestionWrapper,
  SuggestionSpan,
} from './styles';
import { Form } from 'react-bootstrap';
import { InfoErrorMessage } from '../../components/NavTabs';

const LocationComponent = ({ className, label, value, onChange, invalid, errorMessage, disabled = false }) => {
  const [location, setLocation] = useState('');

  useEffect(() => {
    setLocation(value)
  }, [value])

  const handleLocationChange = (value) => {
    setLocation(value)
    onChange({'target': {value}});
  }
  
    return <Form className={className}>
      <Form.Group>
        <Form.Label>{label}<OptSpan> (optional)</OptSpan></Form.Label>
        <PlacesAutocomplete
          value={location}
          onChange={(value) => handleLocationChange(value)}
          onSelect={(value) => handleLocationChange(value)}
          shouldFetchSuggestions={location.length > 7}
        > 
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            return <AutoCompleteWrapper>

              <input {...getInputProps({ placeholder: '', className: 'form-control', disabled: disabled })}/>

              {(suggestions?.length || loading) ? <AutoCompleteDropContainer>
                {loading && <SuggestionsLoading>Loading...</SuggestionsLoading>}
                {suggestions.map((suggestion,i) => {
                  const classN = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  const style = suggestion.active

                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }

                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <SuggestionWrapper 
                      {...getSuggestionItemProps(suggestion, { className: classN, style })}
                      key={suggestion.placeId}
                    >
                      <SuggestionSpan>{suggestion.description}</SuggestionSpan>
                    </SuggestionWrapper>
                  );
                })}
              </AutoCompleteDropContainer> : null}
            </AutoCompleteWrapper>
          }}
        </PlacesAutocomplete>
        {invalid  && errorMessage && <InfoErrorMessage>{errorMessage}</InfoErrorMessage>}
      </Form.Group>
    </Form>
}

export default LocationComponent;
