import { HOSPITAL, REQUEST_TICKET, TRIP, UPDATE_QUOTE, UPDATE_TICKET, VEHICLE } from "../constants";
import { 
  requestTickets, 
  /*updateQuote, */
  updateTicket, 
  scheduleCall, 
  getAllVendors, 
  getVehiclesByVendor, 
  updateTrip } from "../api";

export const getTicketByIdAction = (ticketId) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_PENDING });
    let tickets = await requestTickets(ticketId);
    if(tickets && tickets.status && tickets.status === 200){
      dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_SUCCESS, payload: tickets.data })
    }else{
      let error = tickets.error || "Error Fetching Tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_FAILED, payload: error })
    }
  }catch(err){
    dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_FAILED, payload: err })
  }
};

export const setInputDataAction = (selectedAmbulance) => ({
  type: HOSPITAL.SET_SELECTED_AMBULANCE,
  payload: selectedAmbulance,
});

export const setLeadDetails = (leadtype,data) => ({
  type: UPDATE_TICKET.UPDATE_TICKET_LEAD_DETAILS,
  payload: { leadtype , data},
});

export const updateTicketAction = (data,callback) => async(dispatch) => {
  try{
    dispatch({ type: UPDATE_QUOTE.UPDATE_QUOTE_PENDING });
    // let updateQuotesData = await updateQuote(data.id, data)
    let updateQuotesData = await updateTicket(data.ticketId, data)
    if(updateQuotesData.status && updateQuotesData.status === 200){
      dispatch({ type: UPDATE_QUOTE.UPDATE_QUOTE_SUCCESS, payload: updateQuotesData.data  })
      callback(true,"Ticket has been successfully updated");
    }else{
      let error = updateQuotesData.error || "Error Updating Quotes";
      dispatch({ type: UPDATE_QUOTE.UPDATE_QUOTE_FAILED, payload: error })
      callback(false,"There Is Some Error Updating Your Ticket")
    }
  }catch(err){
    dispatch({ type: UPDATE_QUOTE.UPDATE_QUOTE_FAILED, payload: err })
    callback(false,"There Is Some Error Updating Your Ticket")
  }
};

export const updateTripAction = (tripId,data,callback) => async(dispatch) => {
  try{
    dispatch({ type: TRIP.UPDATE_TRIP_PENDING });
    let updateQuotesData = await updateTrip(tripId, data)
    if(updateQuotesData.status && updateQuotesData.status === 200){
      dispatch({ type: TRIP.UPDATE_TRIP_SUCCESS, payload: updateQuotesData.data  })
      callback(true,"Ticket has been successfully updated");
    }else{
      let error = updateQuotesData.error || "Error Updating Quotes";
      dispatch({ type: TRIP.UPDATE_TRIP_FAILED, payload: error })
      callback(false,"There Is Some Error Updating Your Ticket")
    }
  }catch(err){
    dispatch({ type: TRIP.UPDATE_TRIP_FAILED, payload: err })
    callback(false,"There Is Some Error Updating Your Ticket")
  }
};

export const handleDispositionData = (dispositionTypes,subdispositionTypes) => ({
  type: REQUEST_TICKET.TICKET_DISPOSITION_TYPES,
  dispositionTypes,
  subdispositionTypes,
});

export const createSchedule = (data) => async(dispatch) => {
  try{
    dispatch({ type: UPDATE_QUOTE.CREATE_SCHEDULE_PENDING });
    let scheduleData = await scheduleCall(data);
    if(scheduleData.status && scheduleData.status === 200){
      dispatch({ type: UPDATE_QUOTE.CREATE_SCHEDULE_SUCCESS })
    }else{
      dispatch({ type: UPDATE_QUOTE.CREATE_SCHEDULE_FAILED })
    }
  }catch(err){
    dispatch({ type: UPDATE_QUOTE.CREATE_SCHEDULE_FAILED , payload: err });
  }
}

export const getVendors = () => async(dispatch) => {
  try{
    dispatch({ type: UPDATE_QUOTE.GET_VENDORS_PENDING })
    let vendorsData = await getAllVendors();
    if(vendorsData && vendorsData.status === 200){
      dispatch({ type: UPDATE_QUOTE.GET_VENDORS_SUCCESS, payload: vendorsData.data });
    }else{
      dispatch({ type: UPDATE_QUOTE.GET_VENDORS_FAILED, payload: vendorsData.data });
    }
  }catch(err){
    dispatch({ type: UPDATE_QUOTE.GET_VENDORS_FAILED, payload: err });
  }
}

export const getVehiclesByVendorId = (vendorId, city) => async(dispatch) => {
  try{
    dispatch({ type: UPDATE_QUOTE.GET_VEHICLES_BY_VENDOR_PENDING})
    let vehicleByVendorIdData = await getVehiclesByVendor(vendorId, city);
    if(vehicleByVendorIdData && vehicleByVendorIdData.status === 200){
      dispatch({ type: UPDATE_QUOTE.GET_VEHICLES_BY_VENDOR_SUCCESS, payload: vehicleByVendorIdData.data });
    }else{
      dispatch({ type: UPDATE_QUOTE.GET_VEHICLES_BY_VENDOR_FAILED, payload: vehicleByVendorIdData.data });
    }
  }catch(err){
    dispatch({ type: UPDATE_QUOTE.GET_VEHICLES_BY_VENDOR_FAILED, payload: err });
  }
}

export const emptyVendorVehicles = () => ({
  type: UPDATE_QUOTE.EMPTY_VEHICLES_BY_VENDOR_ID,
})

export const loadMoreVehicles = (data) => ({
  type: VEHICLE.LOAD_MORE_VEHICLES,
  payload: { data }
})

export const setVehicleEmpty = () => ({
  type: VEHICLE.EMPTY_VEHICLES
});

export const setVehiclePending = () => dispatch => {
  let payload = { data: [] };
  dispatch({type: VEHICLE.GET_VEHICLE_PENDING, payload : payload })
}