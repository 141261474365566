import { REDIRECT, REQUEST_TICKET, TICKETS } from '../constants'

const INITIAL_STATE = {
  responseStatus: false,
}

const redirectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDIRECT.REDIRECT_TO_DASHBOARD:
      return { 
        ...state, 
        responseStatus: {
          statusCode: 200,
        },
      }
    case REDIRECT.TO_REDIRECT: 
    case TICKETS.CREATE_TICKET_SUCCESS: 
    case REQUEST_TICKET.REQUEST_TICKET_SUCCESS: 
      return {
        ...state, 
        responseStatus: {
          statusCode: false,
        },
      }
    default:
      return state
  }
}

export default redirectReducer;
