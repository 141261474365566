const defaultValues = {
  confirmDispositionVal: "ConfirmedBooking",
  confirmsubDispositionVal: "HelpNow",
  cancelDispositionVal: "InvalidCall",
  cancelsubDispositionVal: "FakeCall",
  onholdDispositionVal: "Hold",
  onholdsubDispositionVal: "BookingForNextDay",
}

// const dispositionProps = {
//     "onhold": {
//     heading: "Call Hold",
//     props: [
//       {
//         type : "select",
//         label : "Disposition Type",
//         id: "dispositionType",
//         defaultValue : "",
//         options: [],
//         className: "dispositionModal" 
//       },
//       {
//         type: "select",
//         label: "Sub-Disposition Type",
//         id: "subdispositionType",
//         defaultValue: "",
//         options: [],
//         className: "dispositionModal" 
//       },
//       {
//         type: "callback",
//         className: "dispositionModal flex-container",
//         children: [{
//           type : "calendar",
//           label : "Rescheule Call",
//           id: "scheduledDate",
//           defaultValue : "",
//           className: "dispositionModal flex-elem calendar", 
//           svg: true,
//         },{
//           type : "time-select",
//           label : "Time",
//           id: "scheduledTime",
//           defaultValue : "",
//           className: "dispositionModal flex-elem time", 
//           options: [],
//         }]
//       },
//       {
//         type: "textarea",
//         label: "Case Remarks",
//         id: "caseRemarks",
//         defaultValue: "",
//         className: "dispositionModal" 
//       },
//     ]
//   },
//   "cancel": {
//     heading: "Call Dispose",
//     props: [
//       {
//         type: "select",
//         label: "Disposition Type",
//         id: "dispositionType",
//         defaultValue: "",
//         options: [],      
//         className: "dispositionModal" 
//       },
//       {
//         type: "select",
//         label: "Sub-Disposition Type",
//         id: "subdispositionType",
//         defaultValue: "",
//         options: [],
//         className: "dispositionModal" 
//       },
//       {
//         type: "textarea",
//         label: "Case Remarks",
//         id: "caseRemarks",
//         defaultValue: " ",
//         className: "dispositionModal" 
//       },
//     ]
//   },
//   "confirm": {
//     heading: "Confirmed Booking",
//     props:  [
//       {
//         type: "select",
//         label: "Disposition Type",
//         id: "dispositionType",
//         defaultValue: "",
//         className: "dispositionModal",
//         options: [],      
//       },
//       {
//         type: "select",
//         label: "Sub-Disposition Type",
//         id: "subdispositionType",
//         className: "dispositionModal", 
//         defaultValue: "",
//         options: [],      
//       },
//       {
//         type: "case-repeat",
//         className: "dispositionModal flex-container",
//         children: [{
//           type : "text",
//           label : "Ambulance",
//           id: "vehicleNumber",
//           defaultValue : "",
//           className: "flex-elem amb", 
//         },
//         {
//           type : "text",
//           label : "ETA",
//           defaultValue : "10 min",        
//           id: "eta",
//           className: "dispositionModal flex-elem eta", 
//         }]
//       },
//       {
//         type : "text",
//         label : "Final Amount Collected",
//         defaultValue : "",
//         id: "amountCollected",
//         svg: true,
//         className: "dispositionModal", 
//       },
//       {
//         type: "case-repeat",
//         className: "dispositionModal flex-container",
//         children: [{
//           type : "text",
//           label : "Base Price",
//           id: "basePrice",
//           defaultValue : "",
//           svg: true,
//           className: "flex-elem", 
//         },
//         {
//           type : "text",
//           label : "HN Commission",
//           defaultValue : "",        
//           id: "helpnowShare",
//           svg: true,
//           className: "dispositionModal flex-elem", 
//         }]
//       },
//       {
//         type: "textarea",
//         label : "Case Remarks",
//         defaultValue : " ",
//         className: "dispositionModal", 
//         id: "caseRemarks",
//       },
//     ]
//   }
// };

const ticketProgressStep = [
  {
    step: 1,
    label: "Info Taken",
    values : ["PATIENTINFO"],
    className: 'info',
    stepPercent: 32,
  },
  {
    step: 2,
    label: "Quote & Book",
    values: [
      'QUOTE', 
      'BOOK', 
      'QUOTEANDBOOK', 
      'CONFIRMED', 
      'CANCELLED', 
      'HOLD', 
      'SCHEDULED'
    ],
    className: 'quote',
    stepPercent: 65,
  },
  {
    step: 3,
    label: "Closed Ticket",
    values: ['CLOSED'],
    className: 'closed',
    stepPercent: 98,
  },
  {
    step: 4,
    label: "Feedback",
    values: ['FEEDBACK'],
    className: 'feedback',
    stepPercent: 100,
  }
]

const ticketTabs = [
  {
    label : "Patient Info",
    key : "patientInfo",
    enabledStages: [
      'NEWTICKET', 
      'PATIENTINFO', 
      'HOLD', 
      'QUOTEANDBOOK',
      'SCHEDULED'
    ]
  },
  {
    label : "Quote & Book",
    key: "quote",
    enabledStages: [
      'NEWTICKET', 
      'PATIENTINFO', 
      'CONFIRMED', 
      'CANCELLED', 
      'HOLD', 
      'QUOTEANDBOOK',
      'SCHEDULED',
    ],
  },
  {
    label : "Journey Tracking",
    key: "journeyTracking",
    enabledStages: [
      'CONFIRMED'
    ],
  },
  {
    label : "Summary",
    key: "summary",
    enabledStages: [
      // 'CONFIRMED', 
      'CLOSED'
    ],
  },
  {
    label : "Feedback",
    key: "feedback",
    enabledStages: [
      'CLOSED',
      'FEEDBACK'
    ]
  },
];

const accordionTitleList = [
  "Info Taken In",
  "Quote & Book",
  // "Summary",
];

const accordionInputList = [
  [
    "Type of call",
    "Pick-up",
    "Drop Loc",
    "Ambulance Type",
    "Special Needs",
    "Emergency Case",
  ],
  [
    "Amb Number (Vendor)",
    "Arrival Time",
    "Trip Distance",
    "Patient Cost",
    "HelpNow Share",
    "Vendor Rating",
  ],
  // [
  //   "Bed Confirmation",
  //   "Patient Metric",
  //   "Hospital Booked",
  //   "P2H",
  //   "Doctor Name",
  //   "Doctor No.",
  //   "Speciality",
  // ],
];

export {
  defaultValues,
  // dispositionProps,
  ticketProgressStep,
  ticketTabs,
  accordionTitleList,
  accordionInputList,
}