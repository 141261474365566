const VALID_PATIENT_DETAILS = "VALID_PATIENT_DETAILS";
const VALID_QUOTE_DETAILS = "VALID_QUOTE_DETAILS";
const validationFields = {
  patientInfo: {
    patientDetails: [
      "callerName",
      "callerCity",
      "patientName",
      "patientMobile",
      "patientAmbulanceType",
      "transferType",
      "tripTimeZone",
    ],
    leadDetails: ["leadSource"],
  },
  // "quoteAndBook": {
  //   "leadDetails": ["disposition", "subDisposition"],
  //   "quoteDetails": {
  //     "confirmedTrip": ["selectedAmbulanceDetails","nearbyAmbulanceDetails","estimatedTripTime","distanceFromSourceToDestination","helpnowShare","basePrice"],
  //     "isOnHold": [],
  //     "cancelledTrip": [],
  //   }
  // }
  dispositioninfo: {
    patientDetails: [
      "callerName",
      "callerCity",
      // "patientName","patientMobile",
      "patientAmbulanceType",
    ],
    leadDetails: ["leadSource"],
  },
};

const INVALID_DETAILS_KEYS = "INVALID_DETAILS_KEYS";

export default {
  VALID_PATIENT_DETAILS,
  VALID_QUOTE_DETAILS,
  INVALID_DETAILS_KEYS,
};

export { validationFields };
