import React, { memo } from 'react';
import styled from 'styled-components';
import src from "../../images/undraw_blank_canvas.svg";

const Container = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    padding-top: 1em;
`

const BlankImage = styled.img`
    width: 25%;
    min-height: 10vh;
    max-height: 25vh;
`
const Text = styled.h5`
    font-size: 1.2vw;
    margin-top: 2vh;
`

function EmptyContainer({ text }) {
    return (
       <Container>
           <BlankImage src={src} />
           <Text>{text}</Text>
       </Container>
    )
}

export default memo(EmptyContainer);
