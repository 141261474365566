import { all, put, select, takeEvery, call } from 'redux-saga/effects';
import { VEHICLE } from '../constants';
// import { call } from 'redux-saga/effects';
import { calculateArrival, calculateEtaDist } from '../utils/tripHelper';

function* nearbyVehiclesEtaWithOpenRouteHP ({ payload }) {
    console.log('payload',payload);
  try{
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: true });
    const getTicketData = (state) => state.patientInfo.ticketData;
    const ticketData = yield select(getTicketData)
    const leadDetails = ticketData?.leadDetails || {};
    let { pickupLatitude = null, pickupLongitude = null } = leadDetails;
    let vehicles = payload.ambulanceList;
    let vehiclesId = []; 
    let vehicleLocations = [];
    let locations = [];
    let pickupLocation = [pickupLongitude, pickupLatitude];
    locations.push(pickupLocation);
    let destinationsIndexes = [];
    if(vehicles && Array.isArray(vehicles) && vehicles.length){
      vehicles.map((v,i) => {
        if(v.location && Array.isArray(v.location) && v.location.length === 2 && v.vehicleObject && v.vehicleObject.id){
          let obj = { lat : v.location[1], lng: v.location[0] };
          let vehicleId = v.vehicleObject.id;
          let index = i + 1;
          destinationsIndexes.push(index)
          locations.push(v.location);
          vehiclesId.push(vehicleId);
          vehicleLocations.push(obj);
        }
      })
    }
    let sourceIndex = [0];
    let response = yield call(calculateEtaDist,locations,sourceIndex,destinationsIndexes);
    if(
      response && response.distances && 
      response.durations && Array.isArray(response.distances) && Array.isArray(response.durations)
    ){
      yield put({ type: VEHICLE.HANDLE_OPEN_ROUTE_RESPONSE_HP, payload: { response, vehiclesId, vehicleLocations }})
    }
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: false });
  }catch(err){
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: false });
  }
}

function* nearbyVehiclesEtaWithGoogleHP ({ payload }) {
  try {
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: true });
    const getTicketData = (state) => state.patientInfo.ticketData;
    const ticketData = yield select(getTicketData)
    const leadDetails = ticketData?.leadDetails || {};
    let { pickupLatitude = null, pickupLongitude = null } = leadDetails;
    let vehicles = payload.ambulanceList;
    let vehiclesId = []; 
    let vehicleLocations = [];
    let calculatedEtaAndDist = [];
    let pickupLocation = {
      lat: pickupLatitude,
      lng: pickupLongitude
    }
    if(vehicles && Array.isArray(vehicles) && vehicles.length){
      vehicles.map((v) => {
        if(v.location && Array.isArray(v.location) && v.location.length === 2 && v.vehicleObject && v.vehicleObject.id){
          let obj = { lat : v.location[1], lng: v.location[0] };
          let vehicleId = v.vehicleObject.id;
          vehicleLocations.push(obj);
          vehiclesId.push(vehicleId)
        }
      })
    }
    if(vehicleLocations && Array.isArray(vehicleLocations) && vehicleLocations.length && pickupLocation){
      const response = yield all(
        vehicleLocations.map((v) => {
          return call(calculateArrival, v, pickupLocation)
        })
      )
      if(response && Array.isArray(response) && response.length){
        yield response.map((res, i) => {
          if(res && res.status === 'OK'){
            let value = {
              id: vehiclesId[i],
              duration: res.routes[0].legs[0].duration,
              distance: res.routes[0].legs[0].distance,
              location: vehicleLocations[i]
            }
            calculatedEtaAndDist.push(value)
          }else{
            calculatedEtaAndDist.push(false)
          }
        })
      }
    }
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: false });
    yield put({ type: VEHICLE.CALCULATE_VEHICLES_ETA_HP, payload: calculatedEtaAndDist  })
  }catch(err){
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: false });
    yield put({ type: VEHICLE.ERROR_CALCULATING_ETA_HP, payload: err })
  }
}

function* customVehicleEtaHP({ payload }){
  try{
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: true });
    const getTicketData = (state) => state.patientInfo.ticketData;
    const ticketData = yield select(getTicketData)
    const leadDetails = ticketData?.leadDetails || {};
    let { pickupLatitude = null, pickupLongitude = null } = leadDetails;
    let vehicleObj = payload?.vehicleObject || {};
    let vehicleLocationArr = payload?.location || [];
    // let vehicleLocation = {
    //   lat: parseFloat(vehicleObj?.vehicleLocationLatitude) || null,
    //   lng: parseFloat(vehicleObj?.vehicleLocationLongitude) || null
    // }
    let vehicleLocation = {
      lat: vehicleLocationArr[1] || null,
      lng: vehicleLocationArr[0] || null
    }
    let pickupLocation = {
      lat: parseFloat(pickupLatitude),
      lng: parseFloat(pickupLongitude)
    }
    let calculatedEtaAndDist = {};
    let response = yield call(calculateArrival, vehicleLocation, pickupLocation);
    if(response.status === 'OK'){
      calculatedEtaAndDist = {
        id: vehicleObj.id,
        duration: response.routes[0].legs[0].duration,
        distance: response.routes[0].legs[0].distance,
        location: vehicleLocation
      }
    }else{
      calculatedEtaAndDist = false;
    }
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: false });
    yield put({ type: VEHICLE.CALCULATED_CUSTOM_VEHICLE_ETA_HP, payload: calculatedEtaAndDist  })
  }catch(err){
    yield put({ type: VEHICLE.CALCULATING_ETA_DIST_HP, payload: false });
    yield put({ type: VEHICLE.ERROR_CALCULATING_ETA_HP, payload: err })
  }
}

export default function* vehiclesEtaHP() {
  yield takeEvery(VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE_HP, nearbyVehiclesEtaWithGoogleHP);
  yield takeEvery(VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE_HP, nearbyVehiclesEtaWithOpenRouteHP);
  yield takeEvery(VEHICLE.CALCULATE_CUSTOM_VEHICLE_ETA_HP, customVehicleEtaHP);
}