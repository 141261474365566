import React, { useEffect, useState } from 'react'
import DailyBonusCard from '../../../DashboardComponents/BonusAndLeaderCard/DailyBonusCad';
import DScoreLeaderboardCard from './../../../DashboardComponents/BonusAndLeaderCard/DScoreLeaderboardCard';
import TripsLeaderboardCard from './../../../DashboardComponents/BonusAndLeaderCard/TripsLeaderboardCard';
import Pusher from 'pusher-js';
import { PointsWrapper } from './style';
import { pusherApiKey, pusherClusterName } from './../../../../api/config';
import { getLeaderBoardDetails, setTripsCount } from '../../../../actions/ticketAction';
import { useDispatch, useSelector } from 'react-redux';

function PointsSection() {
  const tripsCount = useSelector(state => state.leadTickets.tripsCount || [])
  const [seeMore, setSeeMore] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    const pusher = new Pusher(pusherApiKey, {
      cluster: pusherClusterName,
      encrypted: true
    });
    const channel = pusher.subscribe('LeaderBoard');
    channel.bind('LeaderBoardRefreshed', data => {
      if(data && data.leaderBoard && Array.isArray(data.leaderBoard)){
        dispatch(setTripsCount(data.leaderBoard))
      }
    });
    dispatch(getLeaderBoardDetails());
    return () => {
      pusher.unsubscribe('LeaderBoard');
    } 
  }, [])
  return (
    <>
    { seeMore ? <TripsLeaderboardCard tripsCount={tripsCount} seeMore={seeMore} setSeeMore={setSeeMore} />
    : <PointsWrapper>
      <DailyBonusCard />
      <TripsLeaderboardCard tripsCount={tripsCount} seeMore={seeMore} setSeeMore={setSeeMore} />
      <DScoreLeaderboardCard/>
    </PointsWrapper>}
    </>
  )
}

export default PointsSection
