import { REQUEST_TICKET } from '../constants';

const initialState = {
  leadTicketsData: [],
  leadSkip: 0,
  leadLimit: 15,
  leadTicketsLoading: false,
  leadTicketsErr: false,
  tripsCount: [],
}

const leadTicketsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_TICKET.REQUEST_LEAD_TICKET_PENDING: {
      return {
        ...state,
        leadTicketsLoading: true,
        leadTicketsErr: false
      }
    }
    case REQUEST_TICKET.REQUEST_LEAD_TICKETS_SUCCESS:
      return {
        ...state,
        leadTicketsData: payload,
        leadTicketsLoading: false,
        leadTicketsErr: false,
        leadSkip: 0,
      }
    case REQUEST_TICKET.REQUEST_LEAD_TICKETS_FAILED:
      return {
        ...state,
        leadTicketsLoading: false,
        leadTicketsErr: true,
      }
    case REQUEST_TICKET.REQUEST_MORE_LEAD_TICKETS_FETCHED: 
      return {
        ...state,
        leadTicketsData: [...state.leadTicketsData, ...payload.data],
        leadSkip: payload.skip,
        leadTicketsLoading: false,
        leadTicketsErr: false,
      }
    case REQUEST_TICKET.LEADERBOARD_DATA: {
      return {
        ...state,
        tripsCount: [...payload]
      }
    }
    default:
      return state
  }
}

export default leadTicketsReducer;
