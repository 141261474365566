import { LOGIN } from "../constants";
import { loginUser } from "../api";

export const loginAction = (userCredential,callback) => async(dispatch) => {
  try{
    dispatch({ type: LOGIN.LOGIN_PENDING });
    let logUser = await loginUser(userCredential);
    const { userID, token, refreshToken } = logUser?.data?.data || {};
    let data = {
      "userId": userID,
      "username": userCredential.userName,
      // 'callUsername': '1120',
      // 'callPassword': '2uCqZT',
      // 'callServer': 'pbx.lintelindia.com',
      // 'callToken': '6dc0b9187bf011ebb5e654bf644715b16ea509ba7bf011ebac1e54bf644715b16ef7480c7bf011eb9d9a54bf644715b1',
      'userToken': token,
      'refreshToken': refreshToken
    }
    if(logUser && logUser.status === 200){
      dispatch({ type: LOGIN.LOGIN_SUCCESS, payload: data });
      callback(data)  
    }
  }catch(err){
    if(err){
      dispatch({ type: LOGIN.LOGIN_FAILED, payload: true });
      callback(false);
    }
  }
}

export const loginFromBreak = (userCredential,callback) => async() => {
  try{
    let logUser = await loginUser(userCredential);
    if(logUser && logUser.status === 200){
      callback(true)  
    }else {
      callback(false)
    }
  }catch(err){
    callback(false)
  }
}

export const setInputDataAction = (inputType, inputText) => ({
  type: inputType,
  payload: inputText
});

export const localStorageLoginAction = () => ({
  type: LOGIN.SET_LOCALSTORAGE_AUTH_TRUE
});

export const logoutAction = () => ({
  type: LOGIN.LOGOUT
});

export const loginSuccess = (data) => ({
  type: LOGIN.LOGIN_SUCCESS,
  payload: data
})

export const loginFailed = () => ({
  type: LOGIN.LOGIN_FAILED,
})
