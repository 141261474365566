import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const RenderRedirect = ({ ...props }) => {
  if (props.responseStatus) {
    if (props.responseStatus.statusCode === 200) {
      // console.log("to",props.pageAddress);
      return <Redirect to={`${props.pageAddress}`} />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export default connect(null, null)(RenderRedirect);
