import axios from "axios";
import dayjs from 'dayjs';
import {
    createTicketUrl,
    getAllTicketsUrl,
    getVehiclesNearSourceUrl,
    loginUrl,
    requestTicketUrl,
    updateHospitalUrl,
    leadConfig,
    createBreakUrl,
    updateBreakUrl,
    callScheduleUrl,
    getLiveTicketsUrl,
    getTicketByMobile,
    updateTicketUrl,
    ticketsBySearchUrl,
    getAllVendorsUrl,
    getVehiclesByVendorIdUrl,
    getLeadTicketsUrl,
    getScheduleTicketsUrl,
    tripLeaderboardUrl,
    getCustomQuoteVehicleUrl,
    getTicketHistoryUrl,
    updateTripUrl,
    getLatestTicketByMobile,
    leadSystemConfig,
    verifyTokenUrl,
    generateTokenUrl,
    tieupHospitalListUrl,
    updateTicketRateurl,
    getnotesurl,
    GetAllDrivers,
} from './config';
import { localStorageGetItem } from '../utils/helper';
// export const  reportsByFilter = (data) => axios.post( `${reportsByFilterUrl}collectedBy=${data.collectedBy}&paymentMode=${data.paymentMode}` ,{ headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const createTicket = (data) => axios.post(createTicketUrl, data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getTicketDataByMobile = (mb) => axios.get(getTicketByMobile(mb), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getLeadSystemConfig = () => axios.get(leadSystemConfig, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const requestTickets = (ticketId) => axios.get(requestTicketUrl(ticketId), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getAllTicketsBySearch = (searchTerm,skip) => axios.get(ticketsBySearchUrl(searchTerm,skip), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const updateQuote = (id,data) => axios.post(updateHospitalUrl(id), data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const updateTicket = (id,data) => axios.post(updateTicketUrl(id), data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const updateTrip = (id,data) => axios.post(updateTripUrl(id), data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const loginUser = (data) => axios.post(loginUrl, data);
export const getAllTicketsReq = (id,skip,limit) => axios.get(getAllTicketsUrl(id,skip,limit), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getLiveTicketsReq = (ownerId) => axios.get(getLiveTicketsUrl(ownerId), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getLeadTicketsReq = (skip,limit) => axios.get(getLeadTicketsUrl(skip,limit), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getVehicles = (location) => axios.post(getVehiclesNearSourceUrl, location, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getCustomQuoteVehicle = (postReq) => axios.post(getCustomQuoteVehicleUrl, postReq, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const ticketConfig = () => axios.get(leadConfig, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getAllVendors = () => axios.get(getAllVendorsUrl, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getVehiclesByVendor = (vendorId, city) => axios.get(getVehiclesByVendorIdUrl(vendorId, city), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const createBreak = (data) => axios.post(createBreakUrl, data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const updateBreak = (id,data) => axios.post(updateBreakUrl(id), data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const scheduleCall = (data) => axios.post(callScheduleUrl, data, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getScheduleTicketsReq = (ownerId) => axios.get(getScheduleTicketsUrl(ownerId), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getLeaderBoardData = () => axios.get(tripLeaderboardUrl, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const getOldTicket = (mb, ticketId) => axios.get(getLatestTicketByMobile(mb, ticketId), { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const verifyJWTToken = (userToken) => axios.get(verifyTokenUrl, { headers: { authorization:  `Bearer ${userToken}` }});
export const generateToken = (data) => axios.post(generateTokenUrl, data);
export const getTicketHistory = (ticketId) => axios.get(getTicketHistoryUrl(ticketId), { headers: { authorization: '384e28fe-ee2b-4b24-8a3a-d38f30257968'} });
export const getTieupHospitalList = () => axios.get(tieupHospitalListUrl, { headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }});
export const updateTicketRate =(id,data) => axios.post(updateTicketRateurl(id),data,{ headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` }})
export const getnotes = (id) =>axios.get(getnotesurl(id),{ headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}` },params:{accessType:'agent'}})
export const getalldriverlist = () => axios.get(GetAllDrivers,{ headers: { authorization:  `Bearer ${localStorageGetItem('userToken')}`}})