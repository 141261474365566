import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addMonths } from 'date-fns';
import { CalendarSvg } from '../../utils/icons';
import {
  formatAMPM,
  formatDateMonthYear,
  InputFactory,
  isDateBeforeToday
  /*, metersToKM, 
  secToTime*/
} from "../../utils/helper";
import { ConfirmContainerWrapper } from './styles';

const confirmArray = [
  {
    id: 'vehicleNumber',
    label: 'Ambulance Vehicle Number',
    type: 'text',
    className: 'in-t',
    disabled: true,
    detailsFrom: 'selectedAmbulance',
  },
  {
    id: 'distanceTime',
    type: 'repeat-text',
    propOpt: [{
      id: 'estimatedTripTime',
      label: 'ETA',
      type: 'text',
      className: 'in-t c-in-t',
      disabled: true,
      detailsFrom: 'quoteDetails',
    },
    {
      id: 'distanceFromSourceToDestination',
      label: 'Distance',
      type: 'text',
      className: 'in-t c-in-t',
      disabled: true,
      detailsFrom: 'quoteDetails',
    }]
  },
  {
    id: 'vehicleOwnerName',
    label: 'Vendor Name',
    type: 'text',
    className: 'in-t',
    disabled: true,
    detailsFrom: 'selectedAmbulance'
  },
  {
    id: 'vehicleOwnerContact',
    label: 'Ambulance Contact No.',
    type: 'text',
    className: 'in-t',
    disabled: true,
    detailsFrom: 'selectedAmbulance'
  },
  {
    id: 'patientAmount',
    label: 'Final Amount Collected',
    type: 'text',
    className: 'in-t',
    svg: '₹',
    detailsFrom: 'quoteDetails',
    disabled: true,
  },
  // {
  //   id: '',
  //   label: '',
  //   type: 'emptyElem',
  //   className: '',
  // },
  {
    id: 'basePrice',
    label: 'Base Price',
    type: 'text',
    className: 'in-t',
    svg: '₹',
    detailsFrom: 'quoteDetails',
    disabled: true,
  },
  {
    id: 'helpnowShare',
    label: 'HN Commission',
    type: 'text',
    className: 'in-t',
    svg: '₹',
    detailsFrom: 'quoteDetails',
    disabled: true,
  }, {
    id: 'extracharges',
    label: 'Extra Charges',
    type: 'text',
    className: 'in-t',
    svg: '₹',
    detailsFrom: 'extracharges',
    disabled: true,
  },
];

const ConfirmContainer = ({ freeTrip, roundTrip, quoteDetails, handleChange, etaObj }) => {
  let selectedAmbulance = quoteDetails && quoteDetails?.selectedAmbulanceDetails && quoteDetails?.selectedAmbulanceDetails?.vehicleObject ? quoteDetails?.selectedAmbulanceDetails?.vehicleObject : {};
  // console.log('quoteDetails?.selectedAmbulanceDetails', quoteDetails?.selectedAmbulanceDetails?.extraChargesMapperMap);
  //let eta = (sec) => secToTime(sec);
  //let dist = (m) => metersToKM(m);
  let extramapper = quoteDetails?.selectedAmbulanceDetails?.extraChargesMapperMap || {}
  let extracharges = Object?.values(extramapper).reduce(
    (total, addon) => total + addon.amountForAddons,
    0
  );
  let tripDetailsId = ['patientAmount', 'basePrice', 'helpnowShare'];
  const getPropertyValues = (id) => {
    let prop = confirmArray.filter((p) => p.id === id);
    let detailsFrom = prop && prop[0] && prop[0].detailsFrom ? prop[0].detailsFrom : '';
    if (id === 'estimatedTripTime' || id === 'distanceFromSourceToDestination') detailsFrom = 'etaObj';

    switch (detailsFrom) {
      case 'quoteDetails':
        let value = quoteDetails[id];
        return value || '';
      case 'selectedAmbulance':
        return selectedAmbulance[id] || '';
      case 'etaObj':
        let val;
        if (id === 'estimatedTripTime') val = etaObj && etaObj.duration ? etaObj.duration.text : 'NA';
        if (id === 'distanceFromSourceToDestination') val = etaObj && etaObj.distance ? etaObj.distance.text : 'NA';
        return val;
      case "extracharges":
        return extracharges;
      default:
        return '';
    }
  }

  const setPropertyValues = (e, id) => {
    handleChange(e, 'quoteDetails', id);
  }

  return (
    <ConfirmContainerWrapper>
      {confirmArray.map((prop) => {
        prop.disabled = (freeTrip === 'FREE' || roundTrip === 'ROUND' && tripDetailsId.includes(prop.id)) ? true
          : (freeTrip !== 'FREE' || roundTrip !== 'ROUND' && tripDetailsId.includes(prop.id) ? true
            : prop.disabled);
        return <InputFactory
          key={prop.id}
          // value={value} 
          getPropertyValues={(id) => getPropertyValues(id)}
          onChange={(e) => setPropertyValues(e, prop.id)}
          {...prop}
        />
      })}
    </ConfirmContainerWrapper>
  )
}

const HoldContainer = ({ schedule, callback, handleSchedule }) => {
  let toSchedule = schedule ? new Date(schedule) : new Date();
  const [scheduledDateTime, setScheduleDateTime] = useState(toSchedule);

  const handleDateChange = (changedDate) => {
    let dateTime = formatDateMonthYear(changedDate) + " " + formatAMPM(scheduledDateTime);
    let scheduleDate = new Date(dateTime);
    setScheduleDateTime(scheduleDate);
    handleSchedule(scheduleDate)
  }

  const handleTimeChange = (date) => {
    let dateTime = formatDateMonthYear(scheduledDateTime) + " " + formatAMPM(date);
    let scheduleDate = new Date(dateTime);
    setScheduleDateTime(scheduleDate);
    handleSchedule(scheduleDate);
  }

  return (
    <>
      {callback ? <div className="dispositionModal flex-container">
        <Form className={"dispositionModal flex-elem calendar"}>
          <Form.Group className="selectFormTextInput">
            <Form.Label>Reschedule Call</Form.Label>
            <div className="ip-div">
              <Form.Control value={formatDateMonthYear(scheduledDateTime)} as="input" disabled />
              <DatePicker
                selected={new Date(scheduledDateTime)}
                minDate={new Date()}
                maxDate={addMonths(new Date(), 3)}
                showDisabledMonthNavigation={true}
                onChange={changedDate => handleDateChange(changedDate)}
                dateFormat="d MMMM, yyyy"
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                customInput={<span className="calendar-svg"><CalendarSvg /></span>}
                dayClassName={(currentDate) => isDateBeforeToday(currentDate, addMonths(new Date(), 3)) ? "invalid-date" : "valid-date"}
              />
            </div>
          </Form.Group>
        </Form>
        <Form className={"dispositionModal flex-elem time"}>
          <Form.Group className="selectFormTextInput">
            <Form.Label>Time</Form.Label>
            <div className="ip-div">
              <DatePicker
                selected={scheduledDateTime}
                onChange={date => handleTimeChange(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                customInput={
                  <div className="form-control displayed-time">
                    {formatAMPM(scheduledDateTime)}
                    <span className="down-arrow">▼</span>
                  </div>
                }
              />
            </div>
          </Form.Group>
        </Form>
      </div> : null}
    </>
  )
}

export {
  ConfirmContainer,
  HoldContainer,
} 
