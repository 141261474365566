// actions.js
export const SET_POPUP_SHOWN = 'SET_POPUP_SHOWN';
export const SET_POPUP_Hidden = 'SET_POPUP_Hidden';

export const setPopupShown = () => ({
  type: SET_POPUP_SHOWN,
});
export const setPopupHidden = () => ({
  type: SET_POPUP_Hidden,
});
