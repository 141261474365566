import watcherSaga from "./watcherSaga";
import updateTicketSaga from './updateTicketSaga';
import { all } from "redux-saga/effects";
import watchCallEvents from './callEventsSaga';
import watchLoginSuccess from './loginSagas';
import vehiclesEta from './vehiclesEtaSaga';
import vehiclesEtaHP from './vehiclesEtaSagaHP';
import handleVehiclesLoad from './handleVehiclesLoadSaga';
import handleVehiclesLoadHP from './handleVehiclesLoadHP';

export default function* rootSaga() {
  yield all([
    watcherSaga(),
    vehiclesEta(),
    vehiclesEtaHP(),
    updateTicketSaga(),
    handleVehiclesLoad(),
    handleVehiclesLoadHP(),
    watchCallEvents(),
    watchLoginSuccess(),
  ])
}