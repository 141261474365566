import { PATIENT_INFO, REQUEST_TICKET, UPDATE_TICKET, VEHICLE } from "../constants";
import { 
  getOldTicket,
  requestTickets, 
  /*updateQuote, */
  updateTicket } from "../api";
import swal from 'sweetalert';
// import { temp } from '../constants/temp';

export const getTicketByIdAction = (ticketId) => async (dispatch) => {
  dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_PENDING });
  try{
    let tickets = await requestTickets(ticketId);
    if(tickets && tickets.status && tickets.status === 200){
      dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_SUCCESS, payload: tickets.data });
      let ambulanceList = tickets?.data?.data?.quoteDetails?.nearbyAmbulanceDetails || [];
      dispatch({ type: VEHICLE.ADD_VEHICLES_FROM_TICKET_DETAILS, payload: {ambulanceList: [...ambulanceList] } });
    }else{
      let error = tickets.error || "Error Fetching Tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_FAILED, payload: error })
    }
  }catch(error){
    dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_FAILED, payload: error })
  }
};

export const getLeadTicketByIdAction = (ticketId,callback) => async (dispatch) => {
  try{
    let tickets = await requestTickets(ticketId);
    if(tickets && tickets.status && tickets.status === 200){
      callback(tickets.data,false);
    }else{
      let error = tickets.error || "Error Fetching Tickets";
      callback(false,error)
    }
  }catch(error){
    callback(false,error)
  }
};

export const getLatestTicketByMobile = (mobile, ticketId, callback) => async (dispatch) => {
  try{
    let tickets = await getOldTicket(mobile, ticketId);
    if(tickets && tickets.status && tickets.status === 200){
      callback(tickets.data,false);
    }else{
      let error = tickets.error || "Error Fetching Tickets";
      callback(false,error)
    }
  }catch(error){
    // console.log(error);
    callback(false,error)
  }
};

export const setInputDataAction = (inputType, inputText) => ({
  type: inputType,
  payload: inputText,
});

export const updatePatientInfo = (id, details) => ({
  type: PATIENT_INFO.UPDATE_PATIENT_INFO,
  payload: { id, details},
});

export const updateDisplayAmbulanceList = (data) => ({
  type: VEHICLE.UPDATE_MODIFIED_RATE_DISPLAY_LIST,
  payload: { ambulanceList: data },
});
export const updateDisplayAmbulanceList_HP = (data) => ({
  type: VEHICLE.UPDATE_MODIFIED_RATE_DISPLAY_LIST_HP,
  payload: { ambulanceList: data },
});

export const getPage = (id) => ({
  type: REQUEST_TICKET.SEARCH_BY_PAGE,
  payload: id,
});

export const updateTicketAction = (data,ticketStage) => async (dispatch) => {
  dispatch({ type: UPDATE_TICKET.UPDATE_TICKET_PENDING  });
  try{
    let ticketStatus = ticketStage ? ticketStage : "NEWTICKET"
    data = {...data, ticketStage: ticketStatus }
    // let ticketId = data.id;
    // let updateQuotesData = await updateQuote(ticketId,data)
    let ticketId = data.ticketId
    let updateQuotesData = await updateTicket(ticketId,data)
    if(updateQuotesData.status && updateQuotesData.status === 200){
      dispatch({ type: UPDATE_TICKET.UPDATE_TICKET_SUCCESS , payload: updateQuotesData.data  })
      swal("Your Ticket Has Been Updated");
    }else{
      let error = updateQuotesData.error || "Error Updating Tickets";
      dispatch({ type: UPDATE_TICKET.UPDATE_TICKET_FAILED , payload: error })
    }
  }catch(error){
    dispatch({ type: UPDATE_TICKET.UPDATE_TICKET_FAILED , payload: error })
  }
};
