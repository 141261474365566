import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import EmptyContainer from '../../../../EmptyContainer';
import { TicketsWrapper } from './style';
import SingleTicketDetails from '../../../../TicketDetailsDisplay/singleTicketDetails';
import { SingleTicketWrapper } from '../../../../TicketDetailsDisplay/style';
import { getTicketByIdAction } from '../../../../../actions/patientInfoAction';
import { Mixpanel } from './../../../../../utils/mixpanelUtil';
import ButtonSvg from '../../../../ButtonSvg';
import loadingIcon from '../../../../../images/loading.gif';
import { Container} from 'react-bootstrap';
import { getAllTicketsData } from '../../../../../actions/ticketAction';
let i = 50;
function AllTickets() {
  const scrollRefff = useRef(null);
  const tickets = useSelector(state => state.tickets || {});
  const { allTicketDataLoading , allTicketDataErr = false } = tickets;
  const allTickets = tickets.allTicketsData && Array.isArray(tickets.allTicketsData) ? tickets.allTicketsData : [];
  const dispatch = useDispatch();
  const getTicketById = (ticketId) => {
    Mixpanel.track('Ticket Clicked From All Tickets', { ticketId }, true);
    dispatch(getTicketByIdAction(ticketId))
  }
  if (allTicketDataLoading) return <Container className='loading-container'>
    <ButtonSvg src={loadingIcon} />
    Loading
  </Container>
  if (allTicketDataErr) return <EmptyContainer text={"Something Went Wrong!"} />
  if (!allTickets.length) return <EmptyContainer text={"No Tickets Found"} />
  const handleScroll = (e) => {
    let lt = 50
    let skip = i ;
    let el = scrollRefff?.current;
    let username = localStorage.getItem('username');
    if(!allTicketDataLoading && el.scrollHeight - el.scrollTop < 500){
      dispatch(getAllTicketsData(username,skip,lt))
      i+=50
    }
  }
  return (
    <>
      <TicketsWrapper ref={scrollRefff} onScroll={(e) => handleScroll(e)}
      >
        { allTickets?.map((ticket) => {
        return <SingleTicketWrapper cursor='auto' key={ticket.id} //onClick={() => getTicketById(ticket.ticketId)}
        >
          <SingleTicketDetails ticket={ticket} getTicketById={getTicketById} />
        </SingleTicketWrapper>
      })}
      </TicketsWrapper>
    </>
  )
}
export default AllTickets;