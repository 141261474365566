import React, { useEffect } from 'react'
import { DashboardWrapper } from "./styles";
import DashboardMain from './DashboardMain';
import ChatSection from './ChatSection';
import RenderRedirect from './../../utils/RenderRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { Mixpanel } from './../../utils/mixpanelUtil';
import Pusher from 'pusher-js';
import { pusherApiKey, pusherClusterName } from '../../api/config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setPopupShown } from '../../actions/popupActions.js';
import Notemessagepopup from '../DispositionModal/Notemessagepopup';
import { getnotes } from '../../api';
import { useState } from 'react';



function DashboardPage() {
  const createTicket = useSelector(state => state.createTicket || {});
  const callEvents = useSelector(state => state.callEvents || {});
  const { incomingCall = false, incomingCallStatusCode = false } = callEvents.incomingCall || false;
  let ticketId = createTicket?.ticketId || false;
  let responseStatus = createTicket?.responseStatus || false;
  let officerid = localStorage.getItem('username')
  const [notesdata, setNotesdata] = useState([])
  const history = useHistory();
  const dispatch = useDispatch();
  // const popupShown = useSelector(state => state.popup.popupShown);
  let popupShown = localStorage.getItem('popupShown') || false

  useEffect(() => {
    Mixpanel.track('Dashboard Page Loaded', {}, true)
  }, [])

  const eventname = `Convox_Call_${officerid}`


  useEffect(() => {
    const pusher = new Pusher(pusherApiKey, {
      cluster: pusherClusterName,
    });
    const channel = pusher.subscribe('ConvoxCallNotification')
    channel.bind(eventname, data => {
      history.push(`/call/${data?.ticketId}`);
    })
    return () => {
      pusher.unsubscribe('ConvoxCallNotification')
    }
  }, [])

  // useEffect(() => {
  //   let id = 'map-api';
  //   let elem = document.getElementById(id);
  //   if(!elem){
  //     // removeScript(elem);
  //     let { key, value } = randomlySelectArrValue(mapKey);
  //     console.log('generatedKey',key)
  //     let googleUrl = mapUrl(value);
  //     appendScript(googleUrl, id);
  //   }
  // }, [])



  const GetNotes = async () => {
    await getnotes(officerid).then((result) => result.data).then((data) => setNotesdata(data.data)).catch((err) => console.log('error', err))
  }


  useEffect(() => {
    if (popupShown === false) {
      dispatch(setPopupShown());
      // localStorage.setItem('popupShown', 'true');
    }
  }, []);




  useEffect(() => {
    GetNotes()
  }, [])



  return (
    <>
      {
        popupShown && notesdata.length > 0 && <Notemessagepopup notesdata={notesdata} />
      }
      <DashboardWrapper>
        {incomingCall && <RenderRedirect
          responseStatus={incomingCallStatusCode}
          pageAddress='/newticket'
        />}
        {ticketId && <RenderRedirect
          responseStatus={responseStatus}
          pageAddress={`/call/${ticketId}`}
        />}
        <DashboardMain notesdata={notesdata} />
        <ChatSection />
      </DashboardWrapper>
    </>
  )
}

export default DashboardPage;
