import React from "react";
import AccordionComponent from "./Accordion/index";
import { OuterWrapper } from "./patientOnboard-Styles";
// import {
//   BlackAndPurpleHeadingWrapper,
//   PurpleBoldText,
//   BlackBoldText,
// } from "../../components/BlackAndPurpleHeading/index";
// import OnboardRightSection from './onboardRightSection';
// import BillRightSection from './billRightSection';
import CustomButton from '../../components/CustomButton';
// import { QuoteBookMainWrapper } from '../QuoteAndBookPage/styles';
// import { updateTicketAction } from './../../actions/patientInfoAction';
import { useDispatch, useSelector } from 'react-redux';
import { updateTicketDetails } from '../../actions/ticketAction';
import { createNotification } from '../../utils/helper';
// import { redirectToDashboard } from './../../actions/redirectAction';

function Summary({ setActiveTab }) {
  const ticket = useSelector(state => state.patientInfo?.ticketData || {});
  const dispatch = useDispatch();

  const closeTicket = () => {
    let updateTicket = { ticketStage: 'CLOSED' };
    dispatch(updateTicketDetails('ticketDetails',updateTicket, (res) => {
      if(res){
        createNotification('success', 'Ticket Closed');
        setActiveTab('feedback');
      }
    }));    
  }

  return (
    <OuterWrapper>
      {/* <BlackAndPurpleHeadingWrapper>
        <BlackBoldText>Called Logged at </BlackBoldText>
        <PurpleBoldText> 1:04pm</PurpleBoldText>
      </BlackAndPurpleHeadingWrapper> */}

        <AccordionComponent />
      {/* <CloseBtnWrapper> */}
        <CustomButton cyan sendInvoice onClick={() => closeTicket()} disabled={ticket.ticketStage === 'CLOSED'}>
          Close & Send Invoice
        </CustomButton>
      {/* </CloseBtnWrapper> */}
      {/* { activeTab === "patientOnboard" ? <OnboardRightSection /> : (activeTab === "bill" ? <BillRightSection /> :null)} */}
    </OuterWrapper>
  );
}

export default Summary;