import styled from 'styled-components';

export const TabsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 1em;
  align-items: center;
`

const getMaxHeight = (props) => {
  switch(props.height){
    case 'patients':
      return '13vh';
    case 'ambulances':
      return '20vh';
    case 'hospitals':
      return '6vh';
    default:
      return '20vh';
  }
}

export const TabsMainWrapper = styled.div`
  width: ${props => props.isLogin ? '45%' : '60%'};
  height: ${props => props.isLogin ? '75vh' : '90vh'};
  margin: ${props => props.isLogin ? 'auto' : 'auto 0 auto auto'};
  z-index: ${props => props.isLogin ? 'auto' : '1'};
`
export const MainContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

export const CallsDiv = styled.div`
 height: ${getMaxHeight};
 overflow-y: scroll;
  .no-c {
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
 }
`

export const CallContainer = styled.div`
  width: 23%;
  max-height: 70vh;
  margin: 0 5vw 10vh 0;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 16px #00000029;
  padding-bottom: .5rem;
`

export const ErrorMessage = styled.p`
  font-size: 1vw;
  color: #f00;
  font-weight: 500;
  text-align: center;
  margin: 0;
`
export const InfoErrorMessage = styled.p`
  font-size: .8vw;
  color: #f00;
  font-weight: 500;
  text-align: right;
  margin-bottom: ${props => props.marginBottom || '0'};
  width: 100%;
`

export const HeadWrapper = styled.div`
  
`
export const CallAddBtn = styled.button`
  background-color:transparent;
  border: none; 
  outline: none;
  color: #346CCC
`

export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, auto);
`

export const LogoBtn = styled.button`
  background-color:transparent;
  border: none; 
  outline: none;
  margin: 10px 0 0 20px
`

export const TabName = styled.span`
  background-color: ${props => props.backgroundColor || "#9F9F9F"};
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 1vw;
  height: 6vh;
  width: 8vw;
`;