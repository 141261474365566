import { REQUEST_TICKET, REDIRECT } from "../constants";

const INITIAL_STATE = {
  requestTicketData: null
};

const getCallDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_TICKET.SET_REQUEST_TICKET:
      return Object.assign({}, state, {
        requestTicketData: action.payload
      });
    case REDIRECT.REDIRECT_TO_DASHBOARD: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default getCallDetailsReducer;
