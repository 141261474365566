import React from 'react'
import {
  TicketHead,
  CardText,
  TicketDetails,
  AddressText,
  CyanButton
} from './style';
import { ellipsisForLongText, formatAMPM, formatDateMonthYear, formatDateSmallMonthYear } from '../../utils/helper';
import StepProgress from '../StepProgress';
import { ticketProgressStep } from '../../constants/propsConst';
import CustomButton from '../CustomButton';

function LiveTicketDetails(props) {
  let {
    ticket,
    isSearch = false,
    getTicketById
  } = props;
  let Invoicelink = ticket?.invoiceDetails?.invoiceLink[0]
  let createdAt = new Date(ticket.createdAt)
  let createdDate = `${formatAMPM(createdAt)}, ${isSearch ? formatDateSmallMonthYear(createdAt) : formatDateMonthYear(createdAt)}`;
  let pickupLocation = ticket && ticket.leadDetails && ticket.leadDetails.pickupLocation ? ellipsisForLongText(ticket.leadDetails.pickupLocation) : "NA";
  let dropLocation = ticket && ticket.leadDetails && ticket.leadDetails.dropLocation ? ellipsisForLongText(ticket.leadDetails.dropLocation) : "NA";
  let ticketOwner = ticket && (ticket.ownerName || ticket.ownerId) ? (ticket.ownerName || ticket.ownerId) : "NA";
  let fromAndTo = `${pickupLocation} to ${dropLocation}`;
  return (
    <>
      <div onClick={() => { getTicketById(ticket.ticketId) }} style={{ cursor: 'pointer' }} >
        <TicketHead  >
          {/* <GreenButton>FU 3pm today</GreenButton> */}
          <CardText>Created at {createdDate}{isSearch ? `, ${ticketOwner}` : ''}</CardText>
          <AddressText>{fromAndTo}</AddressText>
          <TicketDetails>
            <CardText>{ticket.ticketId}</CardText>
          </TicketDetails>
        </TicketHead>
        <StepProgress listItems={ticketProgressStep} activeStep={ticket.ticketStage} class={"ticketProgressBar"} />
      </div>
      {isSearch &&
        <div style={{ justifyContent: 'center', display: 'flex', textAlign: 'center' }}>
          {Invoicelink &&
            <CyanButton boxy onClick={() => { window.open(Invoicelink, "_blank") }}>
              Invoice
            </CyanButton>}
        </div>}
    </>
  )
}

export default LiveTicketDetails
