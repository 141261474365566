import { VALID } from '../constants';

export const patientDetailsValid = (isValid,fromTab) => ({
    type: VALID.VALID_PATIENT_DETAILS,
    payload: { isValid, fromTab }
});

export const setInvalidPatientDetails = (arr) => ({
    type: VALID.INVALID_DETAILS_KEYS,
    payload: arr 
});
