import styled, { css } from 'styled-components'
import CustomButton from '../CustomButton'


const getBoxShadow = props => {
  if(props.boxShadow === 'orange'){
    return '0 0 1em #F7AA28'
  }
  return '0 0 1em #00000029'
}

const getcursor = props =>{
  if(props.cursor === 'auto'){
    return 'auto'
  }else{
    return 'pointer'
  }
}

const getTextColor = props => {
  if(props.purple){
    return '#945bf8';
  }else if(props.dirtygreen){
    return '#5C8081'
  }
  return '#000'
}

export const SingleTicketWrapper = styled.div`
  background-color: #fff;
  padding: 0.4em;
  margin-bottom: 0.5em;
  box-shadow: ${getBoxShadow};
  cursor: ${getcursor};
  display: grid;
  grid-template-rows: repeat(2,auto);
`

export const CardText = styled.p`
  font-size: 0.8vw;
  margin: 0;
  padding: 0;
  color: ${getTextColor};
`

export const AddressText = styled.p`
  font-size: 0.8vw;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-align: center;
  color: ${getTextColor};
`

export const TicketHead = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

export const AllTicketHead = styled.div`
  display: grid;
  grid-template-columns: .5fr .8fr .2fr 1fr;
`

export const TicketCallBtns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0.5em 0;
`

export const UpdateText = styled.div`
  display: flex;
`

export const TicketDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledButton = css`
  width: fit-content;
  height: 4vh;
  padding: 1em;
  font-size: 0.7vw;
`;

export const OrangeButton = styled(CustomButton)`
  background-color: #ffffff;
  color: #fc7567;
  border: 1px solid #fc7567;
  ${StyledButton}
`;

export const GreenButton = styled(CustomButton)`
  background-color: #E9F4ED;
  color: #5C8081;
  border: 1px solid #1DBF67;
  ${StyledButton}
`;

export const PurpleButton = styled(CustomButton)`
  background-color: #e0d0fc;
  color: #945bf8;
  border: 1px solid #ab7dfc;
  margin-right: 1em;
  ${StyledButton}
`;

export const CyanButton = styled(CustomButton)`
  background-color: #16999E;
  ${StyledButton}
  margin-right: 0.5em;
`;

