/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TicketsWrapper } from './style';
import EmptyContainer from './../../../../EmptyContainer';
import SingleTicketDetails from '../../../../TicketDetailsDisplay/singleTicketDetails';
import { SingleTicketWrapper } from '../../../../TicketDetailsDisplay/style';
import { getTicketByIdAction } from '../../../../../actions/patientInfoAction';
import { Mixpanel } from './../../../../../utils/mixpanelUtil';
import { Container } from 'react-bootstrap';
import ButtonSvg from '../../../../ButtonSvg';
import loadingIcon from '../../../../../images/loading.gif';
import { createNotification, notficationsound } from '../../../../../utils/helper';

function ScheduleTickets() {
  const tickets = useSelector(state => state.tickets || {});
  const scheduledTickets = tickets.scheduledTicketsData && Array.isArray(tickets.scheduledTicketsData) ? tickets.scheduledTicketsData : [];
  const { scheduleTicketDataLoading = false, scheduleTicketDataErr = false } = tickets;
  const dispatch = useDispatch();
  const [timestamp, setTimestamp] = useState(null);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const currentDate = new Date();
  //     const currentTimestamp = currentDate.getTime();
  //     setTimestamp(currentTimestamp);
  //   }, 1000); // Update the timestamp every second

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);


  // const getTimedifference = () => {

  //   let ownerTickets = scheduledTickets?.length > 0 && scheduledTickets?.filter((item) => item.ownerId === username)
  //   ownerTickets?.length > 0 && ownerTickets?.map((item) => {

  //     let scheduletime = item.schedule.scheduledDateTime
  //     let currenttime = timestamp

  //     const timeDifferenceInMilliseconds = scheduletime - currenttime
  //     const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);
  //     console.log('timeDifferenceInSeconds', timeDifferenceInSeconds);
  //     // const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  //     if (timeDifferenceInSeconds === 600) {
  //       console.log('alertttttttttttt');
  //       notficationsound();
  //       createNotification('info', "You have a Schedule Ticket Pending in Ten Minutes of Time","Schedule Ticket",30000)
  //     }
  //     return timeDifferenceInMilliseconds
  //   })
  // }

  // useEffect(() => {

  //   getTimedifference()

  // }, [timestamp])

  const username = localStorage.getItem('username');
  const getTicketById = (ticketId) => {
    Mixpanel.track('Scheduled Ticket Clicked', { ticketId }, true);
    dispatch(getTicketByIdAction(ticketId))
  }


  if (scheduleTicketDataLoading) return <Container className='loading-container'>
    <ButtonSvg src={loadingIcon} />
    Loading
  </Container>
  if (scheduleTicketDataErr) return <EmptyContainer text={"Something Went Wrong!"} />
  if (!scheduledTickets.length) return <EmptyContainer text={"No Tickets Found"} />
  return (
    <TicketsWrapper>
      {scheduledTickets.map((ticket) => {
        return <SingleTicketWrapper key={ticket.id} cursor='auto' // onClick={() => getTicketById(ticket.ticketId)}
          style={{ 'backgroundColor': ticket?.ownerId === username ? '#16999e3d' : '#FFF' }}
        >
          <SingleTicketDetails ticket={ticket} scheduleTicket={true} getTicketById={getTicketById} />
        </SingleTicketWrapper>
      })}
    </TicketsWrapper>
  )
}

export default ScheduleTickets
