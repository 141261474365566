import styled from "styled-components";
import React from "react";

import accordionicon from "../../../images/accordionicon.svg";

import Accordion from "react-bootstrap/Accordion";

export const AccordionGridWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

export const AccordionTitle = styled.span`
  font-size: 22px;
  color: #16999e;
`;

export const AccordionCardsContainer = styled.div`
  background-color: #e9f4ed;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 11px;
  grid-row: 2/3;
  border-radius: 8px;
`;

export const AccordionCard = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 11px;
  padding: 14px 16px;
`;

// Custom Header and all its content styling

export const AccordionCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TimeText = styled.span`
  color: #40d980;
  font-size: 15px;
`;

const StyledButton = styled(Accordion)`
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  column-gap: 6px;
  align-items: center;
  text-decoration: none;
`;

const StyledText = styled.span`
  color: #065053;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
`;

export const CustomAccordionButton = ({ children, ...props }) => {
  return (
    <StyledButton {...props}>
      <StyledText>{props.title}</StyledText>
      <img src={accordionicon} alt="accordionicon" />
    </StyledButton>
  );
};

// End of Header Styling

export const AccordionCardBody = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(7, 1fr);
  row-gap: 5px;
  margin-top: 17px;
`;

export const CustomRow = styled.span`
  height: 29px;
  border-radius: 2px;
`;

export const CustomRowInputLabel = styled(StyledText)`
  font-weight: 400;
  font-size: 1vw;
`;

export const CustomRowLabel = styled.span`
  font-weight: 400;
  font-size: 1vw;
`;
