import React from 'react';
import { DashboardMainWrapper } from './styles';
import DashboardTopbar from '../../components/DashboardElements/DashboardTopbar';
import DashboardMiddle from './../../components/DashboardElements/DashboardMiddle';

function DashboardMain({notesdata}) {
  return (
    <DashboardMainWrapper>
      <DashboardTopbar />
      <DashboardMiddle notesdata={notesdata} />
    </DashboardMainWrapper>
  )
}

export default DashboardMain
