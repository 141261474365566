const REQ_TICKET_CONFIG_BREAKLIST = "REQ_TICKET_CONFIG_BREAKLIST";
const REQ_TICKET_CONFIG_DISPOSITION = "REQ_TICKET_CONFIG_DISPOSITION";
const LEADSOURCE_CONFIG = "LEADSOURCE_CONFIG";
const AMBULANCE_TYPES = "AMBULANCE_TYPES";
const CONFIG_DATA = "CONFIG_DATA";
const CONFIG_DATA_FAILED = "CONFIG_DATA_FAILED";
const LEADER_BOARD_UPDATED = 'LEADER_BOARD_UPDATED';
const LEADER_BOARD_FAILED = 'LEADER_BOARD_FAILED';

export default {
    REQ_TICKET_CONFIG_BREAKLIST,
    REQ_TICKET_CONFIG_DISPOSITION,
    LEADSOURCE_CONFIG,
    AMBULANCE_TYPES,
    CONFIG_DATA,
    CONFIG_DATA_FAILED,
    LEADER_BOARD_UPDATED,
    LEADER_BOARD_FAILED,
}