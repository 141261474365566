/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import PatientCall from '../containers/PatientCall';
import DashboardPage from "../containers/DashboardPage";
// import DashboardPage1 from "../containers/DashboardPage/index1";
import NewTicketPage from "../containers/NewTicketPage";
import { useSelector } from 'react-redux';
import {  formatAMPM, notficationsound, pauseNotificationSound } from '../utils/helper';
import { NotificationManager } from 'react-notifications';
import micicon from '../assets/images/muteicon.png'
import { useHistory } from 'react-router-dom';

function PrivateRoutes() {
  const tickets = useSelector(state => state.tickets || {});
  const scheduledTickets = tickets.scheduledTicketsData && Array.isArray(tickets.scheduledTicketsData) ? tickets.scheduledTicketsData : [];
  const [timestamp, setTimestamp] = useState(null);
  const history = useHistory();

  const navigate = (ticketId) => {
    pauseNotificationSound()
    history.push(`/call/${ticketId}`)
  }

  const createNotificationduplicate = (type, message, title = '', timeOut, id) => {
    console.log('timeout', timeOut);
    switch (type) {
      case 'info':
        // return NotificationManager.info(message,title,timeOut);
        return NotificationManager.info(
          <div>
            <h4>
              {timeOut ? (<img src={micicon} color='white' width={'30px'} height={'30px'} alt='mute icon' onClick={() => { pauseNotificationSound() }} />) : null}
            </h4>
            <p onClick={() => { navigate(id) }}>
              {message}
            </p>
            {/* <button onClick={() => pauseNotificationSound()}>Pause</button> */}
          </div>,
          title,
          timeOut
        );
      case 'success':
        return NotificationManager.success(message, title);
      case 'warning':
        return NotificationManager.warning(message, title);
      case 'error':
        return NotificationManager.error(message, title);
      default:
        return NotificationManager.info('', '');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime();
      setTimestamp(currentTimestamp);
    }, 1000); // Update the timestamp every second

    return () => {
      clearInterval(interval);
    };
  }, []);



  const getTimedifference = () => {

    // let ownerTickets = scheduledTickets?.length > 0 && scheduledTickets?.filter((item) => item.ownerId === username)
    scheduledTickets?.length > 0 && scheduledTickets?.map((item) => {
      // console.log('item?.schedule?.scheduledDateTime', item?.schedule?.scheduledDateTime);
      let scheduletime = item?.schedule?.scheduledDateTime || null
      let currenttime = timestamp
      let ownername = item.ownerName
      let scheduledAt = new Date(scheduletime);
      let schtime = `${formatAMPM(scheduledAt)}`
      let id = item.ticketId
      const timeDifferenceInMilliseconds = scheduletime - currenttime
      const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);
      // console.log('timeDifferenceInSeconds', timeDifferenceInSeconds, schtime);
      // const timeDifferenceInMinutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
      if (timeDifferenceInSeconds === 300) {
        // console.log('alertttttttttttt', ownername, id);
        notficationsound();
        createNotificationduplicate('info', `${ownername} has a scheduled ticket with TicketID ${id} which is pending at ${schtime}.`, "Schedule Ticket", 30000, id)
      }
      return timeDifferenceInMilliseconds
    })
  }

  useEffect(() => {

    getTimedifference()

  }, [timestamp])

  return (
    <Switch>
      <Route path="/" exact component={DashboardPage} />
      {/* <Route path="/home" exact component={DashboardPage1} /> */}
      <Route path="/patientCall" exact component={PatientCall} />
      {/* <Route path="/" exact component={PatientCall} /> */}
      <Route path="/call/:ticketId" exact component={PatientCall} />
      <Route path="/newticket" exact component={NewTicketPage} />
    </Switch>
  )
}

export default PrivateRoutes;
