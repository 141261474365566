import styled from 'styled-components';

const getBoxShadow = props => {
    if(props.orange){
        return '0 0 1em #F7AA28'
    }
    return '0 0 1em #00000029'
}

export const TicketsWrapper = styled.div`
    height: 95%;
    margin: auto;
    width: 95%;
    padding: 1em;
    overflow-y: scroll;
`

export const SingleTicket = styled.div`
    background-color: #fff;
    box-shadow: ${getBoxShadow}
`