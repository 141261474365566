import React, { 
  /*useContext*/
  useState } from 'react';
import { Row, Image } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { CallContainer, CallsDiv } from '../../components/NavTabs';
import user from '../../images/user.svg';
import './callContainer.scss';
import SingleCallRow from './singleCallRow';
import { NegativeCircleIcon } from '../../utils/icons';
import { CallBottomDiv, BottomCallBtn, EmptyCallText } from './styles';
//import { WebSocketContext } from '../../utils/websocket';
import { removeFromCallList } from './../../actions/getCallDetailsAction';

function CallTab() {
  //const ws = useContext(WebSocketContext);
  const userCredential = useSelector(state => state.userCredential);
  const callEvents = useSelector(state => state.callEvents || {});
  let hangupCallIds = callEvents.hangupCallIds || [];
  //let isMute = callEvents.isMute || false;
  //let onConference = callEvents.onConference || false;
  const [selectedNum, setSelectedNum] = useState([]);
  const dispatch = useDispatch();

  // console.log(hangupCallIds)
  let username = userCredential?.userName || 'You';

  const handleSelect = (e, mobNum) => {
    let newSelectedNum = [...selectedNum];
    let isExist = newSelectedNum.findIndex(num => num === mobNum);
    if(e.target && e.target.checked && isExist === -1){
      newSelectedNum.push(mobNum)
    }else if(isExist !== -1){
      newSelectedNum.splice(isExist,1);
    }
    setSelectedNum(newSelectedNum);
  }

  /*const muteCall= (mute) => {
    if(mute){
      ws.mute()
    }else{
      ws.unmute()
    }
  }

  const conferenceCall = () => {
    ws.conference();
  }*/
  
  return (
    <CallContainer>
      <Row className='cc-u'>
        <Image src={user} roundedCircle className='cc-ui'/>
        {username}
        {/* {isMute ? <CallBtnDiv><CallEventButtons onClick={() => muteCall(false)}><MuteIcon fillColor='#52c3ed' /></CallEventButtons></CallBtnDiv> 
        : <CallBtnDiv><CallEventButtons onClick={() => muteCall(true)} fillColor='#000'><MuteIcon/></CallEventButtons></CallBtnDiv>} */}
      </Row>
      <Row className='cc-n'>
        Ambulances
        {/* <CallAddBtn>Add</CallAddBtn> */}
      </Row>
      <CallsDiv height={'ambulances'}>
        {hangupCallIds && Array.isArray(hangupCallIds) && hangupCallIds.length ?  
          hangupCallIds.map((obj) => {
            if(obj.from === 'ambulance'){
              return <SingleCallRow 
                key={obj.number} 
                handleSelect={handleSelect} 
                selectedNum={selectedNum} 
                {...obj} 
              /> 
            }
            else {
              return null;
            }
          })
        : <Row className='no-c'>
            <EmptyCallText>No Callers Added</EmptyCallText>
        </Row>}
      </CallsDiv>
      <Row className='cc-n'>
        Patients
        {/* <CallAddBtn>Add</CallAddBtn> */}
      </Row>
      <CallsDiv height={'patients'}>
        {hangupCallIds && Array.isArray(hangupCallIds) && hangupCallIds.length ?  
          hangupCallIds.map((obj) => {
            if(obj.from === 'patients'){
              return <SingleCallRow 
                key={obj.number} 
                selectedNum={selectedNum} 
                handleSelect={handleSelect}  
                {...obj}
              />
            }
            else {
              return null;
            }
          })
        : <Row className='no-c'>
          <EmptyCallText>No Callers Added</EmptyCallText>
        </Row>}
      </CallsDiv>
      <Row className='cc-n'>
        Hospital
        {/* <CallAddBtn>Add</CallAddBtn> */}
      </Row>  
      <CallsDiv height={'hospitals'}>  
        {hangupCallIds && Array.isArray(hangupCallIds) && hangupCallIds.length ?  
          hangupCallIds.map((obj) => {
            if(obj.from === 'hospitals'){
              return <SingleCallRow 
                key={obj.number} 
                selectedNum={selectedNum} 
                handleSelect={handleSelect} 
                {...obj}
              />
            }
            else {
              return null;
            }
          })
        : <Row className='no-c'>
          <EmptyCallText>No Callers Added</EmptyCallText>
        </Row>}  
      </CallsDiv>
      <CallBottomDiv>
        <BottomCallBtn onClick={() => dispatch(removeFromCallList(selectedNum))}>
          <NegativeCircleIcon /> 
          Remove
        </BottomCallBtn>
        <BottomCallBtn>
        </BottomCallBtn>
        {/* <BottomCallBtn onClick={() => conferenceCall()}>
          {onConference ? 'Conference is on': 'Merge Call'}
          <MergeIcon fillColor={onConference ? '#346CCC': '#000'} />
        </BottomCallBtn> */}
      </CallBottomDiv>
    </CallContainer>
  )
}

export default CallTab;
