import { TICKETS, REDIRECT, REQUEST_TICKET } from "../constants";

const TICKET_STATE = {
  isPending: false,
  responseStatus: null,
  responseData: null,
  ticketId: null
};

export const createTicketReducer = (state = TICKET_STATE, action) => {
  switch (action.type) {
    case TICKETS.CREATE_TICKET_PENDING:
      return {
        ...state,
        isPending: true 
      };
    case TICKETS.CREATE_TICKET_SUCCESS:
      return { 
        ...state,
        responseStatus: action.payload.meta,
        responseData: action.payload.data,
        ticketId: action.payload.data.ticketId,
        isPending: false
      };
    case REQUEST_TICKET.REQUEST_TICKET_SUCCESS:
      return {
        ...state,
        responseStatus: action.payload.meta,
        responseData: action.payload.data,
        ticketId: action.payload.data.ticketId,
        isPending: false
      };
    case TICKETS.CREATE_TICKET_FAILED:
      return {
        ...state,
        responseStatus: action.payload.meta,
        isPending: false
      };
    case REDIRECT.REDIRECT_TO_DASHBOARD: 
      return TICKET_STATE;
    default:
      return state;
  }
};
