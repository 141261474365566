import React, { useEffect, useRef, useState } from "react";
import { StepProgressOnChange } from "../../components/StepProgress";
import { OuterWrapper } from "../PatientOnboardPage/patientOnboard-Styles";
import {
  JourneyDetails,
  JourneyDetailsPara,
  JourneyDetailsWrapper,
  JourneyDetailsHead,
  JourneyDetailsRow,
  JourneyDetailsData,
  ProgressBarWrapper,
  ProgressBarTop,
  ButtonSvg,
  ButtonSvgWrapper,
  JourneyText,
  ConfirmBtn,
  AutoRefreshPara,
  AutoRefreshWrapper,
  TimerPara,
  ExtraChargeBox,
  ExtraChargeHeading,
  EditButton,
  ButtonEditSvg,
  FlexBox,
} from "./styles";
import ambulance from "../../images/ambulance.svg";
import edit from "../../images/Edit.svg";
import {
  createNotification,
  ellipsisForLongText,
  formatAMPM,
  removeSpaceIgnoreCaseStringEquals,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTicketActionHistory,
  updateTicketDetails,
} from "../../actions/ticketAction";
import { RefreshIcon } from "./../../utils/icons";
import { TakeABreak } from "../../components/DashboardElements/DashboardTopbar/style";
import { Dropdown } from "react-bootstrap";
import { updateTripAction } from "../../actions/quoteAndBookAction";
import { getLeadSystemConfig, updateTicketRate } from "../../api";
import { updateTrip } from "../../api/index";
import Button from "react-bootstrap/Button";
import EditExtraChargePopup from "../DispositionModal/EditExtraChargePopup";
import { updateTicketAction } from "../../actions/patientInfoAction";
const journeyProgressStep = [
  {
    step: 1,
    label: "Called Patient",
    values: ["calledpatient"],
    className: "info",
    newClass: "",
    stepPercent: 12,
    createdAt: null,
    nextStepQ: "Confirm Started Trip?",
    nextStepVal: "STARTEDTRIP",
    nextStepHNObj: {
      tripStatus: "started",
      sourceReached: true,
    },
  },
  {
    step: 2,
    label: "Started Trip",
    values: ["startedtrip"],
    className: "quote tripstart",
    newClass: "",
    stepPercent: 48,
    createdAt: null,
    nextStepQ: "Confirm Pickup Location?",
    nextStepVal: "REACHEDPICKUP",
    nextStepHNObj: {
      tripStatus: "started",
      sourceReached: true,
    },
  },
  {
    step: 3,
    label: "Reached Pickup Location",
    values: ["reachedpickuplocation"],
    className: "closed reachedpickup",
    newClass: "",
    stepPercent: 70,
    createdAt: null,
    nextStepQ: "Confirm Drop Location?",
    nextStepVal: "REACHEDDROP",
    nextStepHNObj: {
      tripStatus: "destinationReached",
      destinationReached: true,
    },
  },
  {
    step: 4,
    label: "Reached Drop Location",
    values: ["reacheddroplocation"],
    className: "closed reacheddrop",
    newClass: "",
    stepPercent: 98,
    className: "closed reacheddrop ",
    newClass: "",
    stepPercent: 90,

    createdAt: null,
    nextStepQ: "Confirm Payment Collected?",
    nextStepVal: "PAYMENTCOLLECTED",
    nextStepHNObj: {
      tripStatus: "completed",
      paymentType: "",
      paymentMode: "",
      collectedBy: "",
    },
  },
  {
    step: 5,
    label: "Payment Collected",
    values: ["paymentcollected"],
    className: "feedback payment",
    newClass: "",
    stepPercent: 100,
    createdAt: null,
  },
];

const detailsData = [
  {
    label: "Patient Name",
    id: "patientName",
  },
  {
    label: "Mobile No",
    id: "patientMobile",
  },
  {
    label: "Pickup Address",
    id: "pickupLocation",
  },
  {
    label: "Drop Location",
    id: "dropLocation",
  },
  {
    label: "Ambulance Type",
    id: "ambulanceType",
  },
];

const tripDetails = [
  {
    label: "Trip Id",
    id: "tripUniqueId",
  },
  {
    label: "Ambulance Number",
    id: "vehicleNumber",
  },
  // {
  //   label: "Total Cost",
  //   id: "totalAmount",
  // },
  {
    label: "",
    id: "roundTrip",
  },
];

const extracharges = [
  {
    id: "AC",
    label: "AC",
  },
  {
    id: "WHEELCHAIR",
    label: "Wheel chair",
  },
  {
    id: "EXTRAHELPER",
    label: "Extra Helper",
  },
  {
    id: "O2",
    label: "O2",
    unit: "hours",
  },
  {
    id: "WAITINGTIME",
    label: "Waiting Time",
    unit: "mins",
  },
];

// const unitofextrachargesmapper = [
//   {
//     id: "O2",
//     unit: "hours",
//   },
//   {
//     id: "WHEELCHAIR",
//     unit: "number",
//   },
//   {
//     id: "AC",
//     unit: "hours",
//   },
//   {
//     id: "EXTRAHELPER",
//     unit: "number",
//   },
//   {
//     id: "WAITINGTIME",
//     unit: "minutes",
//   }
// ]

const typeOfPayment = [
  "cash",
  "online",
  "Free",
  "PayByHospital",
  "PayByCorporate",
];

function AmbJourney({ setActiveTab }) {
  const [show, setShow] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Ref = useRef(null);
  const toDisable = false;
  const [timer, setTimer] = useState("00:00");
  const [question, setQuestion] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [paymentType, setPaymentType] = useState("cash");
  const dispatch = useDispatch();
  const [select, setSelect] = useState("");
  const [collect, setCollect] = useState("");
  const [trip, setTrip] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [drop, setDrop] = useState(false);
  const [showextrapopup, setExtrapopup] = useState(false);
  const [errors, setErrors] = useState({});
  const ticketData = useSelector(
    (state) => state.patientInfo?.ticketData || {}
  );
  const paymentoptions = useSelector(
    (state) => state.ticketConfig?.paymentMode || []
  );
  let selectedambulancedata =
    ticketData?.quoteDetails?.selectedAmbulanceDetails;
  let ismodified = ticketData?.quoteDetails?.selectedAmbulanceDetails?.modified;
  let modifiedratecard =
    ticketData?.quoteDetails?.selectedAmbulanceDetails?.modifiedRateCard;
  let baseFare =
    ismodified && modifiedratecard && modifiedratecard?.baseFare
      ? modifiedratecard?.baseFare
      : ticketData?.quoteDetails?.selectedAmbulanceDetails?.baseFare;
  let helpnowShare =
    ismodified && modifiedratecard && modifiedratecard?.helpnowShare
      ? modifiedratecard?.helpnowShare
      : ticketData?.quoteDetails?.selectedAmbulanceDetails?.helpnowShare;
  let extramapper =
    ticketData?.quoteDetails?.selectedAmbulanceDetails?.extraChargesMapperMap;
  let extracharagesmapper =
    extramapper && extramapper?.hasOwnProperty("WAITINGTIME")
      ? { ...extramapper }
      : extramapper
      ? {
          ...extramapper,
          WAITINGTIME: {
            addonsType: "WAITINGTIME",
            amountForAddons: 0,
            numberOfAddons: 0,
            unit: "minutes",
          },
        }
      : {};
  const [addonsData, setAddonsData] = useState(extracharagesmapper);

  // const getunit = (id) => {
  //   let unit = unitofextrachargesmapper?.filter((item) => item?.id === id)
  //   return unit[0].unit
  // }
  const ticketHistory = useSelector(
    (state) => state.ticketHistory?.ticketHistory || {}
  );
  const { ticket = {}, history = [] } = ticketHistory;
  let pickupLocation = ticket?.pickupLocation
    ? ellipsisForLongText(ticket.pickupLocation, 15)
    : "";
  let dropLocation = ticket?.dropLocation
    ? ellipsisForLongText(ticket.dropLocation, 15)
    : "";
  const quoteDetails = ticketData?.quoteDetails || {};
  let { tripTrackingLink = "" } = ticketData;
  // console.log(ticket, "ticket 2.0", ticketData.roundTrip);
  const vehicleType =
    quoteDetails.selectedAmbulanceDetails &&
    quoteDetails.selectedAmbulanceDetails.vehicleObject &&
    quoteDetails.selectedAmbulanceDetails.vehicleObject.vehicleType
      ? quoteDetails.selectedAmbulanceDetails.vehicleObject.vehicleType
      : null;
  const actions =
    history &&
    Array.isArray(history) &&
    history.length &&
    history[0] &&
    history[0].actionTaken &&
    Array.isArray(history[0].actionTaken) &&
    history[0].actionTaken.length
      ? history[0].actionTaken
      : [];
  let activeStep = "calledpatient";
  const activeProgress = [];

  journeyProgressStep.forEach((progress) => {
    let newProgress = { ...progress };
    let isProgressed = actions.findIndex((obj) =>
      removeSpaceIgnoreCaseStringEquals(obj.actionTaken, progress.label)
    );
    if (isProgressed !== -1) {
      activeStep = progress.values[0];
      newProgress.createdAt = formatAMPM(
        new Date(actions[isProgressed].createdAt)
      );
      newProgress.newClass = "label-t";
    }
    activeProgress.push(newProgress);
  });
  const clearTimer = (e) => {
    setTimer("00:59");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const paymentOption = () => setShowResults(true);
  const roundtripOption = () => setDrop(true);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        // (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
  };

  useEffect(() => {
    onClickReset();
    const intervalId = setInterval(onClickReset, 59000);
    return () => {
      setTimer("00:59");
      clearInterval(intervalId);
    };
  }, [updating, ticketData.ticketStage]);

  const onClickReset = () => {
    if (!updating && ticketData.ticketId) {
      dispatch(fetchTicketActionHistory(ticketData.ticketId));
    }
    clearTimer(getDeadTime());
  };

  const handleStepChange = (step) => {
    const activeStepIndex = journeyProgressStep.findIndex(
      (obj) => obj.values[0] === activeStep
    );
    setDrop(true);
    setShowResults(true);
    if (activeStepIndex !== -1) {
      let nextStepQ = journeyProgressStep[activeStepIndex].nextStepQ;
      setQuestion(nextStepQ);
      console.log(nextStepQ);
      console.log(activeStep, "activestep");
      console.log(activeProgress, "activeProgress");
    }
    setShowPopup(!showPopup);
  };

  const isTripCreationAdded = async () => {
    try {
      const { data } = await getLeadSystemConfig();
      const isTripCreation =
        data && data.data && data.data.tripCreation
          ? data.data.tripCreation
          : false;
      return isTripCreation;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  const confirmJourneyStatus = async (status) => {
    try {
      const isTripCreation = await isTripCreationAdded();
      const activeStepIndex = journeyProgressStep.findIndex(
        (obj) => obj.values[0] === activeStep
      );
      if (activeStepIndex !== -1 && vehicleType) {
        let value = journeyProgressStep[activeStepIndex].nextStepVal;
        if (status && vehicleType === "helpnow" && isTripCreation) {
          let hnObj = journeyProgressStep[activeStepIndex].nextStepHNObj;
          if (value === "PAYMENTCOLLECTED") {
            hnObj.paymentType = paymentType;
            hnObj.paymentMode = paymentType;
            hnObj.collectedBy = "driver";
          }
          setUpdating(true);
          dispatch(
            updateTripAction(ticket.tripId, hnObj, (res, msg) => {
              if (res) {
                createNotification("success", "Trip Updated!");
                if (value === "PAYMENTCOLLECTED") {
                  dispatch(
                    updateTicketDetails("ticketDetails", {
                      ticketStage: "CLOSED",
                    })
                  );
                  setActiveTab("summary");
                }
              } else {
                createNotification("error", "Unable To Update Trip!");
              }
              setUpdating(false);
            })
          );
        } else if (
          (status && vehicleType === "nonhelpnow") ||
          !isTripCreation
        ) {
          let obj = {
            ticketStage: value === "PAYMENTCOLLECTED" ? "CLOSED" : "CONFIRMED",
            journeyStatus: value,
            paymentMode: select || null,
            collectedBy: collect || null,
          };
          setUpdating(true);
          dispatch(
            updateTicketDetails("ticketDetails", obj, (res, msg) => {
              if (res) {
                createNotification("success", "Trip Updated!");
              } else {
                createNotification("error", "Unable To Update Trip!");
              }
              setUpdating(false);
            })
          );
        }
      }
      setShowPopup(false);
    } catch (err) {
      createNotification("error", "Something Went Wrong!");
    }
  };
  // const confirmJourneyStatus = async (status) => {
  //   // setShowResults(true);
  //   console.log(select,collect);
  //   // paymentOption();
  //   try {
  //     const isTripCreation = await isTripCreationAdded();
  //     const activeStepIndex = journeyProgressStep.findIndex(
  //       (obj) => obj.values[0] === activeStep
  //     );
  //     if (activeStepIndex !== -1 && vehicleType) {
  //       let value = journeyProgressStep[activeStepIndex].nextStepVal;
  //       // setShowResults(true);
  //       if (status && vehicleType === "helpnow " && isTripCreation) {
  //     if (activeStepIndex !== -1 && vehicleType) {
  //       let value = journeyProgressStep[activeStepIndex].nextStepVal;
  //       if (status && vehicleType === "helpnow" && isTripCreation) {

  //         let hnObj = journeyProgressStep[activeStepIndex].nextStepHNObj;
  //         if (value === "PAYMENTCOLLECTED") {
  //           hnObj.paymentType = paymentType;
  //         }
  //         setUpdating(true);
  //         dispatch(
  //           updateTripAction(ticket.tripId, hnObj, (res, msg) => {
  //             if (res) {
  //               createNotification("success", "Trip Updated!");
  //               if (value === "PAYMENTCOLLECTED") {
  //                 dispatch(
  //                   updateTicketDetails("ticketDetails", {
  //                     ticketStage: "CLOSED",
  //                   })
  //                 );
  //                 setActiveTab("summary");
  //               }
  //             } else {
  //               createNotification("error", "Unable To Update Trip!");
  //             }
  //             setUpdating(false);
  //           })
  //         );
  //       } else if (
  //         (status && vehicleType === "nonhelpnow") ||
  //         !isTripCreation
  //       ) {
  //         let obj = {
  //           ticketStage: value === "PAYMENTCOLLECTED" ? "CLOSED" : "CONFIRMED",
  //           journeyStatus: value,
  //           paymentMode:select,
  //           collectedBy:collect
  //         };
  //         setUpdating(true);
  //         dispatch(
  //           updateTicketDetails("ticketDetails", obj, (res, msg) => {
  //             if (res) {
  //               createNotification("success", "Trip Updated!");
  //             } else {
  //               createNotification("error", "Unable To Update Trip!");
  //             }
  //             setUpdating(false);
  //           })
  //         );
  //       }
  //     }
  //     setShowPopup(false);
  //   } catch (err) {
  //     createNotification("error", "Something Went Wrong!");
  //   }

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event);
  };

  const handleInputChange = (key, field, value) => {
    // setAddonsData(prevData => ({
    //   ...prevData,
    //   [key]: {
    //     ...prevData[key],
    //     [field]: value,
    //     addonsType: key,
    //   }
    // }));

    setAddonsData((prevData) => {
      const updatedData = {
        ...prevData,
        [key]: {
          ...prevData[key],
          [field]: value,
          addonsType: key,
        },
      };

      if (field === "numberOfAddons" && (value === 0 || value === "")) {
        updatedData[key].amountForAddons = 0;
      }

      return updatedData;
    });

    console.log("value", value, key, field, addonsData);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${key}_${field}`]: isNaN(value)
        ? "Please enter a valid number"
        : parseFloat(value) < 0
        ? "Please enter a valid number"
        : null,
    }));
  };

  // console.log('err', Object.values(errors).filter(error => error === null));
  const handleupdateextracharges = async (totalextracharges) => {
    // let quoteupdate = ticketData.quoteDetails.selectedAmbulanceDetails?.extraChargesMapperMap
    // let selected = { ...quoteupdate.selectedAmbulanceDetails }
    // let extraupdate = { ...selected, extraChargesMapperMap: addonsData }

    // Object.keys(addonsData).forEach(addonType => {
    //   const addon = addonsData[addonType];
    //   console.log('adddddd', addonType);

    //   if (addon.numberOfAddons && addon.amountForAddons) {
    //     console.log('present both', addon);
    //   } else if (addon.numberOfAddons) {
    //     console.log('present not number', addon);
    //     setErrors(prevErrors => ({
    //       ...prevErrors,
    //       [`${addonType}_amountForAddons`]: "Required"
    //     }));
    //     return
    //   } else if (addon.amountForAddons) {
    //     console.log('present not amount', addon);
    //     setErrors(prevErrors => ({
    //       ...prevErrors,
    //       [`${addonType}_numberOfAddons`]: "Required"
    //     }));
    //     return
    //   }
    // fields.forEach(field => {
    // const fieldKey = `${addonType}_${field}`;
    //   const otherField = field === "numberOfAddons" ? "amountForAddons" : "numberOfAddons";
    //   const otherFieldKey = `${addonType}_${otherField}`;

    //   // Check if one field is missing and the other is present
    //   if (!finalObject[addonType]?.[field] && finalObject[addonType]?.[otherField]) {
    //     updatedErrors[fieldKey] = `${field} is missing`;
    //     updatedErrors[otherFieldKey] = null; // Clear the error for the other field
    //   }
    // });
    // });

    // console.log('err', errors);

    // if (Object.values(errors).some(error => error === null)) {

    let ticketid = ticketData?.ticketId;

    let rateupdateobject = {
      baseFare: baseFare,
      helpnowShare: helpnowShare,
      extraChargesMapperMap: addonsData,
      totalAmount: totalextracharges + +baseFare + +helpnowShare,
    };

    console.log("rateupdateobject", rateupdateobject);
    try {
      let upadateratecard = await updateTicketRate(ticketid, rateupdateobject);

      if (upadateratecard.data.data) {
        dispatch(
          updateTicketDetails("ticketDetails", upadateratecard.data.data)
        );
        dispatch(fetchTicketActionHistory(ticketData.ticketId));
        createNotification("success", "Your ticket has been updated");
      } else {
        createNotification("error", "Something went wrong !!");
      }
    } catch (error) {
      console.log("error", error);
      createNotification(
        "error",
        error?.response?.data?.message || "Something went wrong !!"
      );
    }
    setExtrapopup(false);
    // }
    // let ticketupdationdata = {
    //   extraChargesMapperList: addonsData,
    //   quoteDetails: { ...ticketData.quoteDetails, selectedAmbulanceDetails: { ...ticketData.quoteDetails.selectedAmbulanceDetails, extraChargesMapperMap: addonsData } }
    // }
    // console.log('ticketupdationdata', ticketupdationdata);
    // let ticketstage = ticketData?.ticketStage
  };

  return (
    <>
      {showextrapopup && (
        <EditExtraChargePopup
          show={showextrapopup}
          setShow={setExtrapopup}
          contentClassName="m-content"
          headerClassName="m-header"
          bodyClassName="m-body"
          footerClassName="m-footer"
          extraChargesMapperMap={extracharagesmapper}
          setAddonsData={setAddonsData}
          addonsData={addonsData}
          extracharges={extracharges}
          handleInputChange={handleInputChange}
          handleupdateextracharges={handleupdateextracharges}
          errors={errors}
        />
      )}
      <OuterWrapper width="90%">
        <JourneyDetailsWrapper>
          <JourneyDetails>
            <tbody>
              {detailsData.map(({ label, id }) => {
                let displayVal = ticket[id] ? ticket[id] : "";
                displayVal =
                  displayVal &&
                  displayVal !== "" &&
                  id !== "pickupLocation" &&
                  id !== "dropLocation"
                    ? displayVal
                    : ellipsisForLongText(displayVal, 25);
                return (
                  <JourneyDetailsRow key={id}>
                    <JourneyDetailsHead style={{ width: "34%" }}>
                      {label}
                    </JourneyDetailsHead>
                    <JourneyDetailsData>{displayVal}</JourneyDetailsData>
                  </JourneyDetailsRow>
                );
              })}
            </tbody>
          </JourneyDetails>
          <JourneyDetails style={{ width: "40%" }}>
            <tbody>
              {tripDetails.map(({ label, id }) => {
                return (
                  <JourneyDetailsRow key={id}>
                    {id === "roundTrip" && ticketData.roundTrip ? (
                      <JourneyDetailsHead>Round Trip</JourneyDetailsHead>
                    ) : (
                      <JourneyDetailsHead>{label}</JourneyDetailsHead>
                    )}
                    <JourneyDetailsData>{ticket[id]}</JourneyDetailsData>
                  </JourneyDetailsRow>
                );
              })}
              <JourneyDetailsRow>
                <JourneyDetailsHead>Base Fare</JourneyDetailsHead>
                <JourneyDetailsData>{baseFare}</JourneyDetailsData>
              </JourneyDetailsRow>
            </tbody>

            <tbody>
              <ExtraChargeBox>
                <ExtraChargeHeading>Extra Charges</ExtraChargeHeading>
                <EditButton
                  onClick={() => {
                    setExtrapopup(true);
                  }}
                >
                  <ButtonEditSvg src={edit} />
                </EditButton>
              </ExtraChargeBox>
              {extracharges?.map(({ id, label, unit }) => {
                return (
                  <>
                    {extracharagesmapper?.hasOwnProperty(id) && (
                      <JourneyDetailsRow>
                        <JourneyDetailsHead>{label}</JourneyDetailsHead>
                        <JourneyDetailsData>
                          <FlexBox>
                            <span>
                              {extracharagesmapper[id]?.numberOfAddons} {unit}
                            </span>
                            <span style={{ color: "#16999E" }}>
                              ₹ {extracharagesmapper[id]?.amountForAddons}
                            </span>
                          </FlexBox>
                        </JourneyDetailsData>
                      </JourneyDetailsRow>
                    )}
                  </>
                );
              })}
              {/* {
                Object?.keys(extracharagesmapper)?.map((item) => {
                  console.log('item', item);
                  let addon = extracharagesmapper[item]
                  return (
                    <>
                      <JourneyDetailsRow>
                        <JourneyDetailsHead>
                          {addon?.addonsType}
                        </JourneyDetailsHead>
                        <JourneyDetailsData>
                          <FlexBox>
                            <span>{addon?.numberOfAddons}</span>
                            <span style={{ color: '#16999E' }}>₹ {addon?.amountForAddons}</span>
                          </FlexBox>
                        </JourneyDetailsData>
                      </JourneyDetailsRow>
                    </>
                  )
                })
              } */}
              {/* <JourneyDetailsRow>
                <JourneyDetailsHead>
                  Extra Helper
                </JourneyDetailsHead>
                <JourneyDetailsData>
                  <FlexBox>
                    <span>20</span>
                    <span style={{ color: '#16999E' }}>₹ 500</span>
                  </FlexBox>
                </JourneyDetailsData>
              </JourneyDetailsRow>
              <JourneyDetailsRow>
                <JourneyDetailsHead>
                  Waiting Timing
                </JourneyDetailsHead>
                <JourneyDetailsData>
                  <FlexBox>
                    <span>45 mins</span>
                    <span style={{ color: '#16999E' }}>₹ 20</span>
                  </FlexBox>
                </JourneyDetailsData>
              </JourneyDetailsRow>
              <JourneyDetailsRow>
                <JourneyDetailsHead>
                  O<sup>2</sup>
                </JourneyDetailsHead>
                <JourneyDetailsData>
                  <FlexBox>
                    <span>2</span>
                    <span style={{ color: '#16999E' }}>₹ 2000</span>
                  </FlexBox>
                </JourneyDetailsData>
              </JourneyDetailsRow> */}
            </tbody>
            <tbody style={{ borderTop: "solid 1px #D5D5D5" }}>
              <JourneyDetailsRow>
                <JourneyDetailsHead>
                  <div style={{ color: "#16999E", fontWeight: "700" }}>
                    Total Cost
                  </div>
                </JourneyDetailsHead>
                <JourneyDetailsData>
                  <div
                    style={{
                      color: "#16999E",
                      textAlign: "end",
                      fontWeight: "700",
                    }}
                  >
                    ₹ {ticket?.totalAmount}
                  </div>
                </JourneyDetailsData>
              </JourneyDetailsRow>
            </tbody>
          </JourneyDetails>
        </JourneyDetailsWrapper>
        <ProgressBarWrapper>
          <ProgressBarTop
            justify={"space-between"}
            style={{ marginBottom: "5.5vh" }}
          >
            <ProgressBarTop style={{ flex: "1" }}>
              <ButtonSvgWrapper>
                <ButtonSvg src={ambulance} />
              </ButtonSvgWrapper>
              <JourneyText>
                {pickupLocation} to {dropLocation}
              </JourneyText>
              <AutoRefreshWrapper>
                <AutoRefreshPara onClick={() => onClickReset()}>
                  <RefreshIcon />
                </AutoRefreshPara>
                <TimerPara>Auto Refresh in {timer}</TimerPara>
              </AutoRefreshWrapper>
            </ProgressBarTop>
            <div>
              <JourneyDetailsPara>{ticket.ticketId}</JourneyDetailsPara>
              <JourneyDetailsPara big>
                {tripTrackingLink && tripTrackingLink !== "" ? (
                  <a href={tripTrackingLink} target="_blank">
                    Track Ambulance
                  </a>
                ) : (
                  "Tracking Disabled"
                )}
              </JourneyDetailsPara>
            </div>
          </ProgressBarTop>
          <StepProgressOnChange
            listItems={activeProgress}
            activeStep={activeStep}
            class={"ticketProgressBar"}
            className={"ticketProgressBar"}
            stepClicked={handleStepChange}
          />
        </ProgressBarWrapper>
        {showPopup && question !== "" ? (
          <ProgressBarWrapper width={"79%"}>
            <ProgressBarTop justify={"space-between"}>
              {question}
              {vehicleType === "helpnow" &&
                activeStep === "reacheddroplocation" && (
                  <TakeABreak>
                    <Dropdown onSelect={(e) => handlePaymentTypeChange(e)}>
                      <Dropdown.Toggle id="dropdown-basic" className="dd-top">
                        {paymentType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dd-menu">
                        {paymentoptions.length
                          ? paymentoptions.map((type) => {
                              return (
                                <Dropdown.Item
                                  className="dd-item"
                                  key={type}
                                  eventKey={type}
                                >
                                  {type}
                                </Dropdown.Item>
                              );
                            })
                          : null}
                      </Dropdown.Menu>
                    </Dropdown>
                  </TakeABreak>
                )}
              {showResults && activeStep === "reacheddroplocation" && (
                <div className="d-flex justify-content-around mx-2">
                  {vehicleType !== "helpnow" && (
                    <select
                      className="form-control"
                      style={{ fontSize: "13px" }}
                      value={select}
                      onChange={(e) => {
                        setSelect(e.target.value);
                      }}
                      defaultValue={select}
                    >
                      {paymentoptions.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                      {/* <option select value="Payment">
                      Payment
                    </option>
                    <option select value="Online">
                      Online
                    </option>
                    <option select value="Cash">
                      Cash
                    </option> */}
                    </select>
                  )}
                  <select
                    className=" mx-2 form-control"
                    style={{ fontSize: "13px" }}
                    value={collect}
                    onChange={(e) => {
                      setCollect(e.target.value);
                    }}
                    defaultValue={collect}
                  >
                    <option select value="Collected">
                      Collected
                    </option>
                    <option select value="Customer">
                      Customer
                    </option>
                    <option select value="Vendor">
                      Vendor
                    </option>
                    <option select value="Helpnow">
                      Helpnow
                    </option>
                  </select>
                </div>
              )}
              <ProgressBarTop>
                <ConfirmBtn teal onClick={() => confirmJourneyStatus(true)}>
                  Yes
                </ConfirmBtn>
                <ConfirmBtn
                  margin={"7px"}
                  onClick={() => confirmJourneyStatus(false)}
                >
                  No
                </ConfirmBtn>
              </ProgressBarTop>
            </ProgressBarTop>
          </ProgressBarWrapper>
        ) : null}
      </OuterWrapper>
    </>
  );
}

export default AmbJourney;
