import React, { useEffect, useRef, useState } from 'react'
import { Form, Overlay, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { SelectedAmbulanceHeadWrapper, SelectedAmbulanceHead, SelectedAmbulanceDetails, QuoteHeads, VehicleData, VehicleInputs, CheckboxWrapper } from './styles'
import { getVehiclesByVendorId } from './../../actions/quoteAndBookAction';
import { getCustomVehicleEta, setSelectedVehicle } from '../../actions/vehicleListAction';
import { Dropdown } from 'react-bootstrap';
import CustomMenu from './customMenu';
import { updateTicketDetails } from '../../actions/ticketAction';
import { EditIcon, InfoIcon, TickIcon } from '../../utils/icons';
import { createNotification } from '../../utils/helper';


let inState = { customAmbOwnerId: '', customAmbOwnerName: '' };

function SelectedAmbulance({ handleSelect, disable, getCustomQuoteWithRate }) {
  // console.log(disable)
  const [customAmb, setCustomAmb] = useState(inState);
  const ticket = useSelector(state => state.patientInfo?.ticketData || {});
  const vendorVehicles = useSelector(state => state.vehicleList?.vehiclesByVendor || []);
  const vendorsList = useSelector(state => state.vehicleList?.vendorsList || []);
  const customVehicleEta = useSelector(state => state.vehicleList?.customVehicleEta || []);
  let vehiclesEta
  const vehiclesEtanonhp = useSelector(state => state.vehicleList?.vehiclesEta || []);
  const vehiclesEtaHP = useSelector(state => state.vehicleListHP?.vehiclesEta || []);
  const [edit, setEdit] = useState(false);
  const [mRateCard, setMRateCard] = useState({});
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const target1Ref = useRef(null);
  const target2Ref = useRef(null);

  let quoteDetails = ticket?.quoteDetails || {};
  let vehicleList = quoteDetails?.nearbyAmbulanceDetails || [];
  let selectedAmbulanceDetails = quoteDetails?.selectedAmbulanceDetails || false;
  if (selectedAmbulanceDetails?.vehicleObject?.vehicleType === 'helpnow') {
    vehiclesEta = vehiclesEtaHP
  } else {
    vehiclesEta = vehiclesEtanonhp
  }
  let customQuote = quoteDetails?.customQuote || false;
  let vehicleObj = selectedAmbulanceDetails?.vehicleObject || null;
  let time = vehicleObj?.id && customVehicleEta && customVehicleEta.duration && customVehicleEta.duration.text ? customVehicleEta.duration.text : 'NA'
  let distance = vehicleObj?.id && customVehicleEta && customVehicleEta.distance && customVehicleEta.distance.text ? customVehicleEta.distance.text : 'NA'
  let vehicleNumber = vehicleObj && vehicleObj.vehicleNumber ? vehicleObj.vehicleNumber : 'NA';

  let etaIndex = vehicleObj && vehicleObj.id ? vehiclesEta.findIndex((v) => v.id === vehicleObj.id) : null;
  let selectedAmbTime = etaIndex !== null && vehiclesEta[etaIndex] && vehiclesEta[etaIndex].duration && vehiclesEta[etaIndex].duration.text ? vehiclesEta[etaIndex].duration.text : 'NA'
  let selectedAmbDist = etaIndex !== null && vehiclesEta[etaIndex] && vehiclesEta[etaIndex].distance && vehiclesEta[etaIndex].distance.text ? vehiclesEta[etaIndex].distance.text : 'NA'
  let extramapper = selectedAmbulanceDetails?.extraChargesMapperMap || {}
  let extracharges = Object?.values(extramapper).reduce(
    (total, addon) => total + addon.amountForAddons,
    0
  ) || 0;
  let baseFare = selectedAmbulanceDetails?.modified && selectedAmbulanceDetails?.modifiedRateCard && selectedAmbulanceDetails?.modifiedRateCard?.baseFare ? selectedAmbulanceDetails.modifiedRateCard.baseFare : selectedAmbulanceDetails?.baseFare;
  let helpnowShare = selectedAmbulanceDetails?.modified && selectedAmbulanceDetails?.modifiedRateCard && selectedAmbulanceDetails?.modifiedRateCard?.helpnowShare ? selectedAmbulanceDetails.modifiedRateCard.helpnowShare : selectedAmbulanceDetails?.helpnowShare;
  let totalAmountCollected = selectedAmbulanceDetails?.modified && selectedAmbulanceDetails?.modifiedRateCard && selectedAmbulanceDetails?.modifiedRateCard?.totalAmountCollected ? selectedAmbulanceDetails.modifiedRateCard.totalAmountCollected : selectedAmbulanceDetails?.totalAmountCollected;

  const dispatch = useDispatch();
  let vehicletype = selectedAmbulanceDetails?.vehicleObject?.vehicleType
  let displayProps = []
  if (vehicletype === 'nonhelpnow') {
    displayProps = ['Vendor Name/Number', 'Amb No.', 'ETA(mins)', 'Dist(kms)', 'Base Fare', 'HN Share', 'Total Cost', ' '];
  } else {
    displayProps = ['Vendor Name/Number', 'Amb No.', 'ETA(mins)', 'Dist(kms)', 'Base Fare',
      'Extra Chg',
      //'HN Share', 
      'Total Cost', ' '];
  }

  const setCustomQuoteData = async (selectedAmbKey, value, vendorName) => {
    if (selectedAmbKey && selectedAmbKey === "customAmbOwnerId") {
      setCustomAmb({ customAmbOwnerId: value, customAmbOwnerName: vendorName });
      let city = ticket?.patientDetails?.callerCity;
      dispatch(getVehiclesByVendorId(value, city));
    } else if (selectedAmbKey && selectedAmbKey === "vehicleNumber") {
      let newSelectedAmbulanceDetails = vendorVehicles.filter((vehicle) => vehicle.id === value);
      let obj = await getCustomQuoteWithRate(newSelectedAmbulanceDetails[0]);
      dispatch(getCustomVehicleEta(obj))
      dispatch(setSelectedVehicle(vehicleList, obj));
    }
  }

  const editRate = (vehicleId) => {
    if (disable) return;
    let newSelectedAmbulanceDetails = { ...selectedAmbulanceDetails }
    if (edit && edit === vehicleId) {
      newSelectedAmbulanceDetails = {
        ...newSelectedAmbulanceDetails,
        modified: true,
        modifiedRateCard: {
          ...(selectedAmbulanceDetails?.modifiedRateCard || {}),
          ...mRateCard
        },
      }
      const newQuote = {
        ...quoteDetails,
        basePrice: mRateCard.baseFare,
        patientAmount: mRateCard.totalAmountCollected,
        helpnowShare: mRateCard.helpnowShare,
        selectedAmbulanceDetails: { ...newSelectedAmbulanceDetails },
      }
      dispatch(updateTicketDetails('quoteDetails', newQuote, () => {
        createNotification('success', 'Updated Rates Saved!')
        setEdit(false);
      }));
    } else {
      const modifiedBaseFare = selectedAmbulanceDetails?.modifiedRateCard?.baseFare || selectedAmbulanceDetails?.baseFare || '0';
      const modifiedHNShare = selectedAmbulanceDetails?.modifiedRateCard?.helpnowShare || selectedAmbulanceDetails?.helpnowShare || '0'
      const modifiedTCost = selectedAmbulanceDetails?.modifiedRateCard?.totalAmountCollected || selectedAmbulanceDetails?.totalAmountCollected || '0'
      const modifiedRateCard = {
        baseFare: modifiedBaseFare,
        helpnowShare: modifiedHNShare,
        totalAmountCollected: modifiedTCost,
      }
      setMRateCard(modifiedRateCard);
      setEdit(vehicleId);
    }
  }

  const handleModifiedRate = (type, value) => {
    let newMCard = {
      ...mRateCard,
      [type]: value
    };
    newMCard.totalAmountCollected = parseFloat(newMCard.baseFare) + parseFloat(newMCard.helpnowShare) + parseFloat(extracharges)
    setMRateCard(newMCard);
  }

  useEffect(() => {
    if (vehicletype === 'helpnow') {
      console.log('mratecard2', mRateCard, vehicletype)
      setMRateCard({ ...mRateCard, helpnowShare: 0, totalAmountCollected: mRateCard.baseFare })
    }
  }, [vehicletype])

  return (
    <>
      <SelectedAmbulanceHeadWrapper>
        <SelectedAmbulanceHead>Selected Ambulance</SelectedAmbulanceHead>
        <Form className="c-q-container">
          <Form.Label>Add New Custom Quote</Form.Label>
          <Form.Check type="switch" id="custom-switch" value={customQuote}
            label=" " onChange={(e) => {
              handleSelect(null, e.target.checked)
              setCustomAmb(inState)
            }
            } checked={customQuote}
            disabled={disable}
          />
        </Form>
      </SelectedAmbulanceHeadWrapper>
      {customQuote || (vehicleObj && Object.keys(vehicleObj).length) ?
        <SelectedAmbulanceDetails>
          {displayProps.map((p, i) => {
            if ((p === ' ' && customQuote) || (p !== ' ') || !customQuote) {
              return (<QuoteHeads key={i}>{p}</QuoteHeads>)
            }
          }
          )}
          {!customQuote ?
            <>
              {/* <VehicleData>{vehicleObj?.vehicleOwnerName || "NA"}/ {vehicleObj?.vehicleOwnerContact || "NA"}</VehicleData> */}
              <VehicleData>{vehicleObj?.vehicleOwnerName || 'NA'}</VehicleData>
              <VehicleData
                ref={target1Ref}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={vehicleObj?.driverName}
              >{vehicleNumber}</VehicleData>
              <VehicleData>{selectedAmbTime}</VehicleData>
              <VehicleData>{selectedAmbDist}</VehicleData>
              <VehicleData>{baseFare || "0"}</VehicleData>
              {vehicletype === 'nonhelpnow' ? <VehicleData>{helpnowShare || "0"}</VehicleData> : <VehicleData>{extracharges || "0"}</VehicleData>}
              <VehicleData>{totalAmountCollected || "0"}</VehicleData>
            </>
            : (customQuote ?
              <>
                <Dropdown className='c-d' disabled={disable}>
                  <Dropdown.Toggle className="qb-top">
                    {customAmb.customAmbOwnerName || vehicleObj?.vehicleOwnerName || "Select Vendor"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu} className="qb-menu" disabled={disable}>
                    {vendorsList && Array.isArray(vendorsList) && vendorsList.length ? vendorsList.map((vendors, i) => {
                      return <Dropdown.Item
                        className="qb-item"
                        key={vendors.id}
                        eventKey={vendors.vendorInternalId}
                        active={customAmb?.customAmbOwnerId === vendors.vendorInternalId}
                        onSelect={(e) => setCustomQuoteData("customAmbOwnerId", e, vendors.name)}
                        disabled={disable}
                      >
                        {vendors.name}
                      </Dropdown.Item>
                    }) : null}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='c-d' onSelect={(e) => setCustomQuoteData("vehicleNumber", e)} disabled={disable}>
                  <Dropdown.Toggle id="dropdown-basic" className="qb-top"
                  >
                    {vehicleObj?.vehicleNumber || "Select Vehicle"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="qb-menu vl-list" disabled={disable}>
                    {
                      vendorVehicles && Array.isArray(vendorVehicles) && vendorVehicles.length ? vendorVehicles.map((vehicle) => {
                        
                        return <Dropdown.Item
                          className="qb-item"
                          key={vehicle.id}
                          eventKey={vehicle.id}
                          disabled={disable}
                          ref={target2Ref}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={vehicle?.driverName}
                        >
                          {vehicle.vehicleNumber}<span style={{ fontWeight: 'bold' }}> ({vehicle?.driverStatus || '--'})</span>
                        </Dropdown.Item>
                      }) : null
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <VehicleData>{time}</VehicleData>
                <VehicleData>{distance}</VehicleData>
                {!edit ? <>
                  <VehicleData>{baseFare || "0"}</VehicleData>
                  {vehicletype === 'nonhelpnow' ? <VehicleData>{helpnowShare || "0"}</VehicleData> : <VehicleData>{extracharges || '0'}</VehicleData>}
                  <VehicleData>{totalAmountCollected || "0"}</VehicleData>
                </>
                  : <>
                    <VehicleInputs value={mRateCard.baseFare || '0'} onChange={(e) => handleModifiedRate('baseFare', e.target.value)} />
                    {vehicletype === 'nonhelpnow' ? <VehicleInputs value={mRateCard.helpnowShare || '0'} onChange={(e) => handleModifiedRate('helpnowShare', e.target.value)} /> : <VehicleData>{extracharges || '0'}</VehicleData>}
                    <VehicleInputs value={mRateCard.totalAmountCollected || '0'} onChange={(e) => handleModifiedRate('totalAmountCollected', e.target.value)} />
                  </>}
                <CheckboxWrapper>
                  <span className='ed-t' disabled={disable} onClick={() => editRate(vehicleObj?.id)}>
                    {!edit ? <EditIcon />
                      : edit && edit === vehicleObj?.id ? <TickIcon />
                        : null}
                  </span>
                  {selectedAmbulanceDetails?.modified && <span
                    className='ed-t'
                    ref={target}
                    onClick={() => setShow(show === vehicleObj?.id ? false : vehicleObj?.id)}
                  >
                    <InfoIcon />
                  </span>}
                  <Overlay target={target.current} show={show === vehicleObj?.id} placement="right">
                    {(props) => (
                      <Tooltip id="overlay-example" {...props}>
                        {/* modifiedAt: {secToTime(ambulance.modifiedRateCard.modifiedAt)} */}
                        B Fare: {selectedAmbulanceDetails?.baseFare} <br />
                        {vehicletype === 'nonhelpnow' ? `HN Share: ${selectedAmbulanceDetails?.helpnowShare}` : null} < br />
                        T Cost: {selectedAmbulanceDetails?.totalAmountCollected}
                      </Tooltip>
                    )}
                  </Overlay>
                </CheckboxWrapper>
              </>
              : null)}
        </SelectedAmbulanceDetails> : <p style={{ fontSize: '20px' }}>No Selected Ambulance</p>}
    </>
  )
}

export default SelectedAmbulance
