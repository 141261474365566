import styled from 'styled-components';

export const FeedBackContainer = styled.div`
  width: 45%;
  margin: 0.5rem 0 0 3rem;
  padding: 0.5rem 0px;
  min-height: 40vh;
`;

export const ButtonGroupDiv = styled.div`
  display: flex;
  align-items: center;
  height: 10vh;
  justify-content: flex-end;
`