import React, { memo } from "react";
import styled from 'styled-components';
// import helpnowLogo from "../../images/helpnowLogo.svg";

// const LogoHeading = ({ children, ...props }) => {
//   return <img src={helpnowLogo} alt="getHelpNow" />;
// };

const LogoHead = styled.p`
  color: #000;
  font-family: Poppins,sans-serif;
  display: inline-block;
  font-weight: 700;
  font-size: 2vw;
  padding: 0.1em;
  margin: 0;
`

const LogoHeading = () => {
  return <span><LogoHead>HelpNow</LogoHead></span>;
};

export default memo(LogoHeading);
