import React, { useEffect, useState } from 'react'
import { QuoteBookMainWrapper, QuoteHeading } from './styles'
import { useDispatch, useSelector } from 'react-redux';
import NearbyAmbulances from './nearbyAmbulance';
import SelectedAmbulance from './selectAmbulance';
import ButtonSections from './buttonSection';
import { estimatedTripTime } from '../../utils/tripHelper';
import { createNotification, getLatLngByAddress, isValueEqual } from '../../utils/helper';
import { getIdleVehicleNearSourceAction, vehicleBodyRequest, getCustomQuote, getIdleVehicleNearSourceActionHP } from '../../actions/vehicleListAction';
import DispositionModal from '../DispositionModal';
import { getVendors, emptyVendorVehicles, setVehiclePending, setVehicleEmpty } from './../../actions/quoteAndBookAction';
import { updateTicketDetails } from '../../actions/ticketAction';
import { setSelectedVehicle } from '../../actions/vehicleListAction';
import "./Styles.scss";
import { redirectToDashboard } from './../../actions/redirectAction';
import { Nav, Tab, TabContainer } from 'react-bootstrap';
import { NavContainer } from '../../components/DashboardElements/DashboardMiddle/MiddleSection/style';
import NearbyAmbulancesHP from './NearbyAmbulancesHP';
import SelectedAmbulanceHP from './SelectedAmbulanceHp';

const initialState = { pickupLocation: '', dropLocation: '', patientAmbulanceType: '' };

function QuotePage({ disabled, setActiveTab, activeTab, toDisable = false }) {
  const [activeTab1, setActiveTab1] = useState('helpnow');
  const [location, setLocation] = useState(initialState);
  const [openDispositionModal, setOpenDispositionModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(false);
  const [updating, setUpdating] = useState(false);
  const ticket = useSelector(state => state.patientInfo?.ticketData || {});
  const vendorsListHP = useSelector(state => state.vehicleListHP?.vendorsList || {});
  const vehiclesEtaHP = useSelector(state => state.vehicleListHP.vehiclesEta || []);
  const vehicleReqBodyHP = useSelector(state => state.vehicleListHP?.reqBody || {});
  const customVehicleEtaHP = useSelector(state => state.vehicleListHP.customVehicleEta || {});
  let vehicleListHP = useSelector(state => state.nearbyAmbulancesHP?.displayedAmbulanceList || []);
  const vendorsList = useSelector(state => state.vehicleList?.vendorsList || {});
  const vehiclesEta = useSelector(state => state.vehicleList.vehiclesEta || []);
  const vehicleReqBody = useSelector(state => state.vehicleList?.reqBody || {});
  const customVehicleEta = useSelector(state => state.vehicleList.customVehicleEta || {});
  let vehicleList = useSelector(state => state.nearbyAmbulances?.displayedAmbulanceList || []);
  let isValid = useSelector(state => state.validation.isValid || false);
  let patientDetails = ticket?.patientDetails || {};
  let leadDetails = ticket?.leadDetails || {};
  let quoteDetails = ticket?.quoteDetails || {};
  // let vehicleList = quoteDetails?.nearbyAmbulanceDetails || [];
  // let vehicleList = quoteDetails?.nearbyAmbulanceDetails || [];
  let selectedAmbulance = quoteDetails?.selectedAmbulanceDetails || {};
  let customQuote = quoteDetails?.customQuote || false;
  let { pickupLongitude = null, pickupLatitude = null, pickupLocation = null, dropLocation = null, dropLongitude = null, dropLatitude = null } = leadDetails;
  let { patientAmbulanceType = null } = patientDetails || {};
  let disableStages = ['CONFIRMED', 'CANCELLED', 'CLOSED']
  let { ticketId = '' } = ticket;
  let disable = disableStages.includes(ticket.ticketStage) || toDisable;
  let index = selectedAmbulance && selectedAmbulance.vehicleObject && selectedAmbulance.vehicleObject.id ? vehiclesEta.findIndex(v => v.id === selectedAmbulance.vehicleObject.id) : null;
  let etaObj = customQuote ? customVehicleEta : (index !== null ? vehiclesEta[index] : false);
  let indexHP = selectedAmbulance && selectedAmbulance.vehicleObject && selectedAmbulance.vehicleObject.id ? vehiclesEtaHP.findIndex(v => v.id === selectedAmbulance.vehicleObject.id) : null;
  let etaObjHP = customQuote ? customVehicleEtaHP : (indexHP !== null ? vehiclesEtaHP[indexHP] : false);

  const dispatch = useDispatch();
  const setData = () => {
    let updatedTicketData = { ...ticket };
    let updateLeadDetails = {};
    if (currentTab) {
      let quoteType = currentTab === "confirm" ? "confirmedTrip" : (currentTab === "cancelled" ? "cancelledTrip" : "isOnHold")
      let ticketStage = ticket.ticketStage;
      if (currentTab === "confirm") {
        setUpdating("confirm")
        ticketStage = ticketStage !== 'CONFIRMED' ? 'QUOTEANDBOOK' : 'CONFIRMED';
        let timeVal, distValue = null;
        let location = []
        if (etaObj) {
          timeVal = etaObj.duration && etaObj.duration.value !== null ? etaObj.duration.value : null;
          distValue = etaObj.distance && etaObj.distance.value !== null ? etaObj.distance.value : null;
          let lat = etaObj.location && etaObj.location.lat ? etaObj.location.lat : null;
          let lng = etaObj.location && etaObj.location.lng ? etaObj.location.lng : null;

          location = [lat, lng]
          updateLeadDetails = {
            distanceFromCurrentToSourceLocation: distValue,
            estimatedSourceReachTime: timeVal,
            currentLocation: location
          }
        }
        if (etaObjHP) {
          timeVal = etaObjHP.duration && etaObjHP.duration.value !== null ? etaObjHP.duration.value : null;
          distValue = etaObjHP.distance && etaObjHP.distance.value !== null ? etaObjHP.distance.value : null;
          let lat = etaObjHP.location && etaObjHP.location.lat ? etaObjHP.location.lat : null;
          let lng = etaObjHP.location && etaObjHP.location.lng ? etaObjHP.location.lng : null;
          location = [lat, lng]
          updateLeadDetails = {
            distanceFromCurrentToSourceLocation: distValue,
            estimatedSourceReachTime: timeVal,
            currentLocation: location
          }
        }
        if (
          !updateLeadDetails &&
          !updateLeadDetails.distanceFromCurrentToSourceLocation &&
          !updateLeadDetails.estimatedSourceReachTime &&
          !updateLeadDetails.currentLocation.length &&
          ticketStage !== 'CONFIRMED'
        ) {
          return createNotification('error', 'Something Went Wrong!, Please try again')
        }
      } else if (currentTab === "cancelled") {
        setUpdating("cancel")
        ticketStage = "CANCELLED";
        dispatch(redirectToDashboard());
      } else if (currentTab === "hold") {
        setUpdating("hold")
        if (ticket && ticket.leadDetails && ticket.leadDetails.subDisposition === 'ScheduleTicket') {
          ticketStage = 'SCHEDULED'
        } else {
          ticketStage = "HOLD";
        }
      }
      let quoteData = {
        ...quoteDetails,
        [quoteType]: true,
      }
      updatedTicketData = {
        ...updatedTicketData,
        ticketStage,
        quoteDetails: {
          ...quoteData,
          ...updateLeadDetails
        },
      }
    }
    dispatch(updateTicketDetails("ticketDetails", updatedTicketData, (res, msg) => {
      setUpdating(false)
      if (res) {
        createNotification('success', 'Ticket Details Updated !')
        if (currentTab === 'confirm') {
          setActiveTab("journeyTracking")
        }
      }
    }))
  }

  const openDispositionPopUp = (modal, submit) => {
    setOpenDispositionModal(modal);
    if (modal) {
      setCurrentTab(modal);
    }
    if (submit) {
      setData();
    }
  }

  const handleSelect = (index, setCustom) => {
    if (disable) return;
    let tempList = [...vehicleList];
    let currentVehicle = null;
    for (let i = 0; i < tempList.length; i++) {
      if ((i !== index || i === index) && tempList[i].vehicleObject.vehicleSelected) {
        tempList[i].vehicleObject.vehicleSelected = false;
      } else if (i === index && !tempList[i].vehicleObject.vehicleSelected) {
        tempList[i].vehicleObject.vehicleSelected = true
        currentVehicle = tempList[i]
      } else if (setCustom && tempList[i].vehicleObject.vehicleSelected) {
        tempList[i].vehicleObject.vehicleSelected = false;
      }
    }
    if (setCustom) {
      currentVehicle = {
        vehicleObject: {},
        basePrice: null,
        helpnowShare: null,
        patientAmount: null
      }
      if (!(vendorsList && Array.isArray(vendorsList) && vendorsList.length)) {
        dispatch(getVendors());
      }
    } else if (!setCustom) {
      dispatch(emptyVendorVehicles());
    }
    let quoteData = {
      customQuote: setCustom || false,
    }
    dispatch(updateTicketDetails("quoteDetails", quoteData));
    dispatch(setSelectedVehicle(tempList, currentVehicle));
  };

  const handleSelectHP = (indexHP, setCustom) => {
    if (disable) return;
    let tempList = [...vehicleListHP];
    let currentVehicle = null;
    for (let i = 0; i < tempList.length; i++) {
      if ((i !== indexHP || i === indexHP) && tempList[i].vehicleObject.vehicleSelected) {
        tempList[i].vehicleObject.vehicleSelected = false;
      } else if (i === indexHP && !tempList[i].vehicleObject.vehicleSelected) {
        tempList[i].vehicleObject.vehicleSelected = true
        currentVehicle = tempList[i]
      } else if (setCustom && tempList[i].vehicleObject.vehicleSelected) {
        tempList[i].vehicleObject.vehicleSelected = false;
      }
    }
    if (setCustom) {
      currentVehicle = {
        vehicleObject: {},
        basePrice: null,
        helpnowShare: null,
        patientAmount: null
      }
      if (!(vendorsListHP && Array.isArray(vendorsListHP) && vendorsListHP.length)) {
        dispatch(getVendors());
      }
    } else if (!setCustom) {
      dispatch(emptyVendorVehicles());
    }
    let quoteData = {
      customQuote: setCustom || false,
    }
    dispatch(updateTicketDetails("quoteDetails", quoteData));
    dispatch(setSelectedVehicle(tempList, currentVehicle));
  };

  const handleResponse = (error, response, latLng) => {
    let postBody = {
      location: [latLng.pickupLongitude, latLng.pickupLatitude],
      tripType: patientAmbulanceType,
      wheelChairCharges: false,
      extraHelper: ticket?.extraHelper,
      acChargesFixed: patientDetails && patientDetails.patientAmbulanceAddOns && Array.isArray(patientDetails.patientAmbulanceAddOns) && patientDetails.patientAmbulanceAddOns.includes('AC'),
      o2Charges: patientDetails && patientDetails.patientAmbulanceAddOns && Array.isArray(patientDetails.patientAmbulanceAddOns) && patientDetails.patientAmbulanceAddOns.includes('O2'),
      numberofExtraHelper: patientDetails && patientDetails.numberofExtraHelper,
      extraChargesMapperMap: ticket?.extraChargesMapperList
    };
    if (response) {
      let duration = response.duration.value;
      let distance = response.distance.value;
      postBody = {
        ...postBody,
        vendorTripKM: distance,
        estimatedTripTime: duration
      }
      let updateticket = {
        'quoteDetails': { ...quoteDetails, estimatedTripTime: duration, distanceFromSourceToDestination: distance },
        'leadDetails': { ...leadDetails, ...latLng }
      }
      dispatch(updateTicketDetails('ticketDetails', updateticket))
    } else if (error) {
      console.log('error fetching distance and time', error)
    }
    // console.log(postBody)
    dispatch(vehicleBodyRequest(postBody))
    dispatch(getIdleVehicleNearSourceAction(postBody));
    dispatch(getIdleVehicleNearSourceActionHP(postBody));
  }

  const setLatLng = (latLng) => {
    let loc = { pickupLocation, dropLocation, patientAmbulanceType };
    estimatedTripTime(
      pickupLocation, dropLocation, (err, res) => handleResponse(err, res, latLng));
    setLocation(loc);
    dispatch(setSelectedVehicle(vehicleList, {}));
  }

  const getUpdatedVehicles = async () => {
    if ((pickupLocation && dropLocation) && patientAmbulanceType && activeTab === 'quote' &&
      (ticket.ticketStage !== 'CONFIRMED' && ticket.ticketStage !== 'CANCELLED')
    ) {
      let fetch = false;
      let latLng = { pickupLongitude, pickupLatitude, dropLongitude, dropLatitude };
      if (pickupLocation !== location.pickupLocation || (patientAmbulanceType !== location.patientAmbulanceType)) {
        let res = await getLatLngByAddress(pickupLocation);
        fetch = true;
        latLng = {
          ...latLng,
          'pickupLongitude': res?.longitude || pickupLongitude || null,
          'pickupLatitude': res?.latitude || pickupLatitude || null,
        };
      }
      if (dropLocation !== location.dropLocation || (patientAmbulanceType !== location.patientAmbulanceType)) {
        let res = await getLatLngByAddress(dropLocation);
        fetch = true;
        latLng = {
          ...latLng,
          'dropLongitude': res?.longitude || dropLongitude || null,
          'dropLatitude': res?.latitude || dropLatitude || null
        };
      }
      if (fetch) {
        fetch = false;
        dispatch(setVehiclePending());
        setLatLng(latLng)
      } else {
        dispatch(setVehiclePending());
        setLatLng(latLng)
      }
    } else if ((!pickupLocation && !dropLocation) || (pickupLocation === '' && dropLocation === '')) {
      dispatch(setVehicleEmpty());
    }

  }

  const getCustomQuoteWithRate = async (selectedAmbDetails) => {
    let reqBody = {
      ...vehicleReqBody,
      vehicleNumber: selectedAmbDetails.vehicleNumber
    }
    let data = await getCustomQuote(reqBody);
    let vehicleObj = data?.data || false;
    return vehicleObj;
  }

  useEffect(() => {
    if (!toDisable) {
      getUpdatedVehicles();
    }
  }, [activeTab, patientAmbulanceType, ticketId, ticket?.extraHelper, ticket?.emergencyCase, ticket?.patientDetails?.numberofExtraHelper]);


  return (
    <QuoteBookMainWrapper>
      {
        openDispositionModal && <DispositionModal
          updating={updating}
          dispositionType={openDispositionModal}
          handleModal={(val, type) => openDispositionPopUp(val, type)}
          selectedAmbulance={selectedAmbulance}
          isValid={isValid || ((isValueEqual(ticket.ticketStage, "CANCELLED") || isValueEqual(ticket.ticketStage, "CONFIRMED")) ? true : false)}
        />
      }

      <TabContainer>
        <QuoteHeading>Nearby Ambulances</QuoteHeading>
        <Tab.Container activeKey={activeTab1} onSelect={(e) => setActiveTab1(e)} transition={false}>
          <NavContainer>
            <Nav.Link
              className={`${activeTab1 === 'helpnow' ? ' ambulancenavactive' : 'ambulancenav'}`}
              // style={{ backgroundColor: activeTab === 'helpnow' ? "#16999e" : "#9F9F9F" }} 
              eventKey="helpnow">
              HelpNow Ambulances
            </Nav.Link>
            <Nav.Link
              className={`${activeTab1 === 'others' ? ' ambulancenavactive' : 'ambulancenav'}`}
              // style={{ backgroundColor: activeTab === 'others' ? "#16999e" : "#9F9F9F" }} 
              eventKey="others">
              Non-Helpnow Ambulances
            </Nav.Link>
          </NavContainer>
          <Tab.Content>
            <Tab.Pane
              // className='t-tp'
              eventKey="helpnow">
              <NearbyAmbulancesHP
                // disabled={disabled} 
                // setActiveTab={setActiveTab}
                disable={disable || toDisable}
                handleSelect={handleSelectHP}
              />
              {/* <SelectedAmbulanceHP
                getCustomQuoteWithRate={getCustomQuoteWithRateHP}
                disable={disable || (!pickupLocation || !dropLocation || !patientAmbulanceType) || toDisable}
                handleSelect={handleSelectHP}
              /> */}
            </Tab.Pane>
            <Tab.Pane
              // className='t-tp'
              eventKey="others">
              <NearbyAmbulances
                // disabled={disabled} 
                // setActiveTab={setActiveTab}
                disable={disable || toDisable}
                handleSelect={handleSelect}
              />
              {/* <SelectedAmbulance
                // disabled={disabled} 
                // setActiveTab={setActiveTab}
                getCustomQuoteWithRate={getCustomQuoteWithRate}
                disable={disable || (!pickupLocation || !dropLocation || !patientAmbulanceType) || toDisable}
                handleSelect={handleSelect}
              /> */}

            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </TabContainer>
      <SelectedAmbulance
        // disabled={disabled} 
        // setActiveTab={setActiveTab}
        getCustomQuoteWithRate={getCustomQuoteWithRate}
        disable={disable || (!pickupLocation || !dropLocation || !patientAmbulanceType) || toDisable}
        handleSelect={handleSelect}
      />

      <ButtonSections
        // disabled={disabled} 
        // setActiveTab={setActiveTab} 
        updating={updating}
        openDispositionPopUp={(val, type) => openDispositionPopUp(val, type)}
        toDisable={toDisable}
      />
    </QuoteBookMainWrapper>
  )
}

export default QuotePage;
