import React from "react";
import { useSelector } from "react-redux";
import { InputFactory } from "../../utils/helper";
import { ContentWrapper } from "./styles";

const patientInfoDetails = [
  {
    id: "callerName",
    label: "Caller Name",
    type: "text",
    className: "in-t",
    errorMessage: "Enter Caller Name",
    disabled: false,
  },
  {
    id: "callerCity",
    label: "Caller City",
    type: "select",
    placeholder: "Select Caller City",
    className: "in-t",
    errorMessage: "Select Caller City",
    disabled: false,
  },
  {
    id: "patientName",
    label: "Patient Name",
    type: "text",
    className: "in-t",
    errorMessage: "Enter Patient Name",
    disabled: false,
  },
  {
    id: "pickupLocation",
    label: "Pickup Address",
    type: "location-text",
    className: "in-t",
    errorMessage: "Enter Valid Locations",
    disabled: false,
  },
  {
    id: "patientAmbulanceType",
    label: "Ambulance Type",
    type: "select",
    placeholder: "Select Ambulance Type",
    className: "in-t",
    errorMessage: "Select Ambulance Type",
    disabled: false,
  },
  {
    id: "callReferredBy",
    label: "Call Referred By",
    type: "select",
    placeholder: "Select Call Referred By",
    className: "in-t",
    errorMessage: "Select Call Referred By",
    disabled: false,
  },
  {
    id: "driverName",
    label: "Driver Name",
    type: "select",
    placeholder: "Select Driver Name",
    className: "in-t",
    errorMessage: "Select Driver Name",
    disabled: false,
  },
  {
    id: "tripTimeZone",
    label: "Trip Time Zone",
    type: "select",
    placeholder: "Select Trip Time Zone",
    className: "in-t",
    errorMessage: "Select Trip Time Zone",
    disabled: false,
  },
  {
    id: "transferType",
    label: "Transfer Type",
    type: "select",
    placeholder: "Select Transfer Type",
    className: "in-t",
    errorMessage: "Select Transfer Type",
    disabled: false,
  },
  {
    id: "patientExpectations",
    label: "Patient Special Needs",
    type: "textarea",
    className: "in-t",
    disabled: false,
  },
];

function LeftContent({
  getPropertyOptions,
  getPropertyValues,
  setPropertyValues,
  toDisable,
}) {
  //const isValid = useSelector(state => state.validation.isValid || false);
  const invalidDetailsKeys = useSelector(
    (state) => state.validation.invalidDetailsKeys || []
  );

  const handleChange = (e, id) => {
    setPropertyValues(e.target.value, id);
  };

  return (
    <ContentWrapper>
      {patientInfoDetails.map((p, i) => {
        p.disabled = toDisable;
        return (
          <InputFactory
            invalid={invalidDetailsKeys.includes(p.id)}
            key={i}
            onChange={(e) => handleChange(e, p.id)}
            getPropertyOptions={(id) => getPropertyOptions(id)}
            getPropertyValues={(id) => getPropertyValues(id)}
            {...p}
          />
        );
      })}
    </ContentWrapper>
  );
}

export default LeftContent;
