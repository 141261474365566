import styled from "styled-components";

const getScreenSize = props => {
  if (props.dashboard) {
    return "3 / 7";
  }
  if (props.login) {
    return "2 / 7";
  }
  if (props.extralarge) {
    return "2 / 3";
  } else {
    return "2 / 3";
  }
};

const CenterDiv = styled.div`
  grid-row: ${getScreenSize};
  grid-column: 2 / 3;
  display: grid;
  grid-template-rows: 0.1fr 2fr;
`;

export const NewTicketGrid = styled.div`
  margin: 2rem auto;
  height: 80vh;
  width: 75%;
`;

export default CenterDiv;
