import styled from 'styled-components'

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
  margin: 0.6em auto;
`
export const Bottom = styled.div`
  display: flex;
  padding: 0.3em 1.2em 1em 1.2em;
  align-items: center;
  justify-content: space-between;
`

// export const White = styled.div`
//   max-height: 75vh;
//   width: 101%;
//   background-color: #fff;
//   position: absolute;
//   top: 57px;
//   border-radius: 15px;
//   left: -2px;
//   z-index: 3;
// `

export const White = styled.div`
  background-color: #fff;
  border-radius: 1.2em;
  box-shadow: 0px 0px 18px #00000029;
`

export const Ticket = styled.span`
  display: block;
  margin-top: 1.2em;
  cursor: pointer;
  border-radius: 10px;
  width: 95%;
  :hover {
    box-shadow: 0px 0px 18px #D5A24C
  }
`;

export const ClearSpan = styled.span`
  color: #346CCC;
  font-size: 1vw;
  cursor: pointer;
`

export const PageSpan = styled.span`
  color: #346CCC;
  font-size: 1vw;
  padding-right: 0.7em;
  cursor: pointer;
`