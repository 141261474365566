import { VEHICLE } from '../constants'

const INITIAL_STATE_HP = {
  isGoogle: false,
  page: 0,
  vehicleCount: 5,
  displayedAmbulanceList: [],
  calculatingEtaDist: false,
}

const nearbyAmbulanceReducerHP = (state = INITIAL_STATE_HP, { type, payload }) => {
  // console.log('ambu', payload, type);
  switch (type) {
    case VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE_HP:
    case VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE_HP:
      return {
        ...state,
        displayedAmbulanceList: [...state.displayedAmbulanceList, ...payload.ambulanceList],
        page: payload.page || 0,
        isGoogle: !state.isGoogle,
      }
    case VEHICLE.CALCULATING_ETA_DIST_HP:
      return {
        ...state,
        calculatingEtaDist: payload
      }
    case VEHICLE.GET_VEHICLE_PENDING_HP:
      return INITIAL_STATE_HP;
    case VEHICLE.EMPTY_VEHICLES_HP:
      return INITIAL_STATE_HP;
    case VEHICLE.ADD_VEHICLES_FROM_TICKET_DETAILS_HP:
      return {
        ...state,
        displayedAmbulanceList: [...payload.ambulanceList],
      }
    case VEHICLE.UPDATE_MODIFIED_RATE_DISPLAY_LIST_HP:
      return {
        ...state,
        displayedAmbulanceList: [...payload.ambulanceList],
      }
    default:
      return state
  }
}

export default nearbyAmbulanceReducerHP;
