import styled, { css } from "styled-components";

const vendorImageStyles = css`
  margin-right: 0;
  height: 3vh;
  width: 1vw;
`;

const getImageStyles = props => {
  if(props.vendorImg) return vendorImageStyles;
  return css``;
};

const ButtonSvg = styled.img`
  margin-right: 10px;
  ${getImageStyles}
`;

export default ButtonSvg;
