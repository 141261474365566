import styled from 'styled-components';

export const Topbar = styled.div`
    display: flex;
    height: 12vh;
    align-items: center;
    padding: 1em;
    justify-content: space-between;
`;

export const LogTime = styled.div`
    flex: 1;
    margin-left: 8%;
`;

export const TakeABreak = styled.div`
    margin-right: 1.2em;
`;

export const Profile = styled.div``;

export const LogText = styled.p`
    margin: 0;
    font-size: 1.2vw;
`;


