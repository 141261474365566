import React, { useContext } from 'react'
import { Row, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { CallGreenIcon, CallHangupRedIcon, CallHoldIcon, CallResumeIcon } from '../../utils/icons';
import { CallEventButtons, CallBtnDiv, CallStateSpan, AmbulanceNameSpan } from './styles';
import { CheckboxWrapper } from './../QuoteAndBookPage/styles';
import { ellipsisForLongText } from '../../utils/helper';
import { CALL_STATES } from '../../constants';
// import { WebSocketContext } from '../../utils/websocket';

const HandleCallActions = (props) => {
  // const ws = useContext(WebSocketContext);

  const handleCallHold = (callData) => {
    let hangupCallId = callData.hangupUpCallId;
    // ws.hold(hangupCallId);
  }

  const handleHangup = (callData) => {
    let hangupCallId = callData.hangupUpCallId;
    // ws.hangUp(hangupCallId);
  }

  const handlePlaceCall = (callData) => {
    // console.log(callData)
    let { number, from, id } = callData;
    // ws.dial(number,from,id);
  }

  const handleCallResume = (callData) => {
    let hangupCallId = callData.hangupUpCallId;
    // ws.unhold(hangupCallId);
  }

  switch(props.callState){
    case CALL_STATES.ANSWERED_STATE: 
      return <CallBtnDiv>
        <CallEventButtons onClick={() => handleCallHold(props)}><CallHoldIcon /></CallEventButtons>
        <CallEventButtons onClick={() => handleHangup(props)}><CallHangupRedIcon /></CallEventButtons>
      </CallBtnDiv>
    case CALL_STATES.DISCONNECTED_STATE: 
      return <CallBtnDiv>
      <CallEventButtons onClick={() => handlePlaceCall(props)}><CallGreenIcon /></CallEventButtons>
    </CallBtnDiv>
    case CALL_STATES.HOLD_STATE: 
      return <CallBtnDiv>
      <CallEventButtons onClick={() => handleCallResume(props)}><CallResumeIcon /></CallEventButtons>
      <CallEventButtons onClick={() => handleHangup(props)}><CallHangupRedIcon /></CallEventButtons>
    </CallBtnDiv>
    case CALL_STATES.DIAL_STATE:
      return <CallBtnDiv>
      <CallEventButtons onClick={() => handleHangup(props)}><CallHangupRedIcon /></CallEventButtons>
    </CallBtnDiv>
    default:
      return <CallBtnDiv>
      <CallEventButtons onClick={() => handleCallHold(props)}><CallHoldIcon /></CallEventButtons>
      <CallEventButtons onClick={() => handleHangup(props)}><CallHangupRedIcon /></CallEventButtons>
    </CallBtnDiv>
  }
}

function SingleCallRow(props) {
  let callEvents = useSelector(state => state.callEvents || {});
  const ticketData = useSelector(state => state.patientInfo?.ticketData || {});
  const selectedNum = props.selectedNum || [];
  
  let quoteDetails = ticketData?.quoteDetails || {};
  let hangupCallIds = callEvents.hangupCallIds || [];
  let allVehicleList = quoteDetails?.nearbyAmbulanceDetails || []; 

  // console.log('hangupCallIds',hangupCallIds);

  const getAmbulanceName = (id) => {
    if(id){
      let vehicleIndex = allVehicleList.findIndex(obj => obj.vehicleObject.id === id);
      if(vehicleIndex !== -1){
        let name = allVehicleList[vehicleIndex].vehicleObject.vehicleOwnerName || ''; 
        let vehicleNum = allVehicleList[vehicleIndex].vehicleObject.vehicleNumber || '';
        return `${vehicleNum} (${name})`
      }
    }
  }

  let label = props && props.from && props.from === 'ambulance' && getAmbulanceName(props.id) ? getAmbulanceName(props.id) : props.number;

  return (
    <Row className={'cc-n s-c'}>
      <CheckboxWrapper style={{width: '1.2vw', height: '2.5vh'}} className='in-t'>
        <Form.Check 
         checked={selectedNum.includes(props.number)} 
         value={props.number} 
         onChange={(e) => props.handleSelect(e,props.number)} 
        />
      </CheckboxWrapper>
      <AmbulanceNameSpan>{ellipsisForLongText(label, 20)}</AmbulanceNameSpan>
      <CallStateSpan callState={props.callState}>{props.callState || ''}</CallStateSpan>
      <HandleCallActions {...props}/>
    </Row>
  )
}

export default SingleCallRow;
