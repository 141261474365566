import styled from "styled-components";

// Global Component for Patient Info page

export const PatienOnboardBodyGridWrapper = styled.div`
 
`;

export const ButtonsSection = styled.div`
  grid-row: 3 / 4;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-bottom: 0;
`;

export const RightSection = styled.div`
  grid-row: 2 / 3;
  display: grid;
  grid-template-rows: 0.1fr auto;
`;

//CheckBox Style

export const CheckBoxFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 30px;
`;

export const CheckBoxArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(4, auto);
  row-gap: 10px;
`;

export const CustomCheckBoxLabel = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: 500;
`;

export const RightSideHeading = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

// Accordion Grid Position

export const OuterWrapper = styled.div`
  width: ${props => (props.width || "45%")};
  margin: auto;
  padding: 1rem 0px;
  min-height: 67vh;
`;
