import React, { useContext, useState } from "react";

//Components
import GridWrapperSimple from "../../components/GridWrapperSimple/index";
import LogoHeading from "../../components/LogoHeading/index";
import PopupHeading from "../../components/PopupHeading/PopupHeading";
import CustomWhiteCenterDiv from "../../components/Call/index";
import RippleEffect from "../../components/RippleEffect/index";
import CustomButton from "../../components/CustomButton/index";
import ButtonSvg from "../../components/ButtonSvg/index";

//SVG
import phone from "../../images/phone.svg";

//Redux
import { connect, useSelector } from "react-redux";
import { createTicketAction } from "../../actions/createTicketAction";

import RenderRedirect from "../../utils/RenderRedirect";
import { NewTicketGrid } from './../../components/CenterDiv/index';
// import { WebSocketContext } from '../../utils/websocket';
import { Mixpanel } from '../../utils/mixpanelUtil';
import dayjs from "dayjs";

const NewTicketPage = ({ ...props }) => {
  // const ws = useContext(WebSocketContext);
  const callEvents = useSelector(state => state.callEvents || {});
  const incomingCall = callEvents.incomingCall || '';
  let hangupCallIds = callEvents.hangupCallIds || [];
  const [isloading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);

  const setLoading = (state) => {
    if (state === 'loading') {
      setIsLoading(true)
    } else if (state === false) {
      setError(true)
    }
  }

  const answerAndCreateTicket = () => {
    if (!isloading) {
      if(hangupCallIds && Array.isArray(hangupCallIds)){
        let activeIndex = hangupCallIds.findIndex(obj => obj.number === incomingCall);
        if(activeIndex !== -1 && hangupCallIds[activeIndex] && hangupCallIds[activeIndex].hangupUpCallId){
          let callId = hangupCallIds[activeIndex].hangupUpCallId;
          Mixpanel.track('Answer Incoming Call', { number: incomingCall }, true)
          // ws.answer(callId);
        }
      }
      Mixpanel.track('Clicked On Pick & Close', { number: incomingCall }, true)
      props.createTicketAction(props.requestTicket, setLoading);
    }
  }
  
  return (
    <>
      {props.ticketId ? <RenderRedirect
        responseStatus={props.responseStatus}
        pageAddress={`/call/${props.ticketId}`}
      /> : (!incomingCall || isError) ? <RenderRedirect
        responseStatus={{statusCode: 200}}
        pageAddress='/'
      /> : null}
      <GridWrapperSimple>
        <LogoHeading />
        <NewTicketGrid>
          <PopupHeading>New Ticket</PopupHeading>
          <CustomWhiteCenterDiv>
            <span
              style={{
                fontSize: "1.2vw",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              Call logged at {dayjs().format("hh:mm a")}
            </span>
            <RippleEffect />
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#40D980",
                fontSize: "24px"
              }}
            >
              RINGING
              <CustomButton
                green
                infoRound
                width='70%'
                style={{margin: '1rem 0 0 0'}}
                onClick={() => {
                  answerAndCreateTicket()
                }}
              >
                <ButtonSvg src={phone} /> Pick & Close
              </CustomButton>
            </span>
          </CustomWhiteCenterDiv>
        </NewTicketGrid>
      </GridWrapperSimple>
    </>
  );
};

const mapStateToProps = state => ({
  requestTicket: state.callDetails.requestTicketData,
  responseStatus: state.createTicket.responseStatus,
  ticketId: state.createTicket.ticketId,
});

const mapDispatchToProps = dispatch => ({
  createTicketAction: (data, callback) => dispatch(createTicketAction(data, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTicketPage);
