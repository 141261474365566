import { PATIENT_INFO, REDIRECT, REQUEST_TICKET, TICKETS, UPDATE_QUOTE, UPDATE_TICKET, VEHICLE } from "../constants";

const PATIENTINFO_TICKET_STATE = {
  isPending: false,
  ticketData: {},
  fetchedTicketData: {}
};

export const patientInfoReducer = (state = PATIENTINFO_TICKET_STATE, action) => {
  switch (action.type) {
    case TICKETS.CREATE_TICKET_PENDING:
      return { ...state, isPending: true };

    case TICKETS.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        ticketData: action.payload.data,
        fetchedTicketData: action.payload.data,
        isPending: false,
      };

    case TICKETS.CREATE_TICKET_FAILED:
      return {
        ...state,
        isPending: false,
      };

    case REQUEST_TICKET.REQUEST_TICKET_SUCCESS:{
      return {
        ...state,
        ticketData: action.payload.data,
        fetchedTicketData: action.payload.data,
        isPending: false,
      };
    }

    case UPDATE_QUOTE.UPDATE_QUOTE_SUCCESS: {
      return {
        ...state,
        fetchedTicketData: action.payload.data,
        ticketData: {
          ...state.ticketData,
          ...action.payload.data
        }
      }
    }

    case PATIENT_INFO.UPDATE_PATIENT_INFO: {
      return {
        ...state,
        ticketData: {
          ...state.ticketData,
          patientDetails: {
            ...state.ticketData.patientDetails,
            [action.payload.id]: action.payload.details,
          },
        },
      };
    }

    case UPDATE_TICKET.UPDATE_TICKET_LEAD_DETAILS:{
      return {
        ...state,
        ticketData: {
          ...state.ticketData,
          leadDetails: {
            ...state.ticketData.leadDetails,
            [action.payload.leadtype] : action.payload.data,
          },
        },
      };
    }

    case UPDATE_TICKET.UPDATE_ALL_TICKET_DETAILS:{
      return {
        ...state,
        ticketData: {
          ...state.ticketData,
          ...action.payload,
        },
      };
    }

    case UPDATE_TICKET.UPDATE_TICKET_LEAD_DETAILS_DATA:{
      return {
        ...state,
        ticketData: {
          ...state.ticketData,
          isScheduled: action.payload.isScheduled,
          leadDetails: {
            ...state.ticketData.leadDetails,
            ...action.payload.leadData,
          },
        },
      };
    }
    // case VEHICLE.GET_VEHICLE_SUCCESS: 
    //   let data = [...action.payload.data];
    //   return {
    //     ...state,
    //     ticketData : {
    //       ...state.ticketData,
    //       quoteDetails : {
    //         ...state.ticketData.quoteDetails,
    //         nearbyAmbulanceDetails: [...data]
    //       }
    //     }
    //   }
    case VEHICLE.LOAD_MODIFIED_RATE_VEHICLES: 
      let data = [...action.payload.data];
      return {
        ...state,
        ticketData : {
          ...state.ticketData,
          quoteDetails : {
            ...state.ticketData.quoteDetails,
            nearbyAmbulanceDetails: [...data]
          }
        }
      }
    
    case VEHICLE.UPDATE_VEHICLE_LIST:
      let freetrip = state.ticketData.tripCategory === 'FREE'
      let roundTrip = state.ticketData.tripCategory === 'ROUND'
      let { selectedAmbulance = {} } = action?.payload || {};
      let basePrice = selectedAmbulance?.modified ? selectedAmbulance?.modifiedRateCard?.baseFare : (selectedAmbulance?.baseFare || '0');
      let hnShare = selectedAmbulance?.modified ? selectedAmbulance?.modifiedRateCard?.helpnowShare : (selectedAmbulance?.helpnowShare || '0');
      let patientAmount = selectedAmbulance?.modified ? selectedAmbulance?.modifiedRateCard?.totalAmountCollected : (selectedAmbulance?.totalAmountCollected || '0');
      return {
        ...state,
        ticketData : {
          ...state.ticketData,
          quoteDetails : {
            ...state.ticketData.quoteDetails,
            basePrice: freetrip ? '0' : basePrice,
            helpnowShare: freetrip ? '0' : hnShare,
            patientAmount: freetrip ? '0' : patientAmount,
            basePrice: roundTrip ? '0' : basePrice,
            helpnowShare: roundTrip ? '0' : hnShare,
            patientAmount: roundTrip ? '0' : patientAmount,
            nearbyAmbulanceDetails: action.payload.updatedList,
            selectedAmbulanceDetails: action.payload.selectedAmbulance,
          }
        }
      }
    
    case REDIRECT.REDIRECT_TO_DASHBOARD: {
      return PATIENTINFO_TICKET_STATE;
    }
    default:
      if(state?.ticketData?.leadDetails?.leadCity?.toUpperCase() === 'BANGALORE' || state?.ticketData?.patientDetails?.callerCity?.toUpperCase() === 'BANGALORE'){
        state.ticketData.leadDetails.leadCity = 'BENGALURU';
        state.ticketData.patientDetails.callerCity = 'BENGALURU';
      }else if(state?.ticketData?.leadDetails?.leadCity?.toUpperCase() !== 'BANGALORE' && state?.ticketData?.leadDetails?.leadCity?.toUpperCase() !== 'BENGALURU' && state?.ticketData?.leadDetails?.leadCity?.toUpperCase() !== 'MUMBAI' && state?.ticketData?.leadDetails?.leadCity?.toUpperCase() !== 'DELHI' && state?.ticketData?.leadDetails?.leadCity ){
        state.ticketData.leadDetails.leadCity = 'OTHERCITIES';
        state.ticketData.patientDetails.callerCity = 'OTHERCITIES';
      }
      return state;
  }
};

const UPDATE_STATUS = {
  isPending: false,
  responseStatus: null,
};

export const updatePatientInfoReducer = (state = UPDATE_STATUS, action) => {
  switch (action.type) {
    case UPDATE_TICKET.UPDATE_TICKET_PENDING:
      return Object.assign({}, state, { isPending: true });
    case UPDATE_TICKET.UPDATE_TICKET_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        responseStatus: action.payload.meta,
      });
    case UPDATE_TICKET.UPDATE_TICKET_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        responseStatus: action.payload.meta,
      });
    default:
      return state;
  }
};
