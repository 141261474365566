import React from "react";
import styled from "styled-components";

export const OptionsAvailableSection = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 5px;
`;

export const OptionsHeading = styled.span`
  font-weight: 500;
  font-size: 22px;
  color: #16999e;
`;

// Table Style

const getTableColumnsCount = props => {
  if (props.columns) {
    return `repeat(${props.columns}, auto)`;
  } else {
    return "repeat(7, auto)";
  }
};

const getTableColor = props => {
  if (props.blue) {
    return "#D4EBED";
  }
  if (props.green) {
    return "#E9F4ED";
  } else {
    return "#F0F0F0";
  }
};

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: ${getTableColumnsCount};
  grid-template-rows: repeat(5, auto);
  row-gap: 10px;
  column-gap: 10px;
`;

export const OptionsTable = ({ children, ...props }) => {
  return <TableWrapper {...props}>{children}</TableWrapper>;
};

export const TableHeading = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: #343434;
`;

export const TableDataCheckbox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTableData = styled.span`
  height: 48px;

  background-color: ${getTableColor};
  border-radius: 4px;
  padding: 15px 15px;
  font-size: 1rem;
  font-weight: lighter;
  color: #343434;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TableData = styled.button`
  height: auto;
  cursor: pointer;
  border: none;
  background-color: ${getTableColor};
  border-radius: 4px;
  padding: 5px;
  font-size: 1rem;
  font-weight: lighter;
  color: #343434;
  align-items: center;
  text-align: center;
  &.skeleton-loader:empty {
    min-height: 50px;
    display: block;
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    animation-name: skeletonAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    &.skeleton-head{
      min-height: 20px
    }
  }
  
  @keyframes skeletonAnimation {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.5;
    }
  }
`;

export const OptionsSelectedSection = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
`;

//Button Section

export const ButtonsFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 0;
`;

export const ButtonsGridWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
`;

export const SearchInputDiv = styled.div`
  height: auto;
  cursor: pointer;
  border: none;
  background-color: ${getTableColor};
  border-radius: 4px;
  padding: 5px;
  font-size: 1rem;
  font-weight: lighter;
  color: #343434;
  align-items: center;
  text-align: center;
  display: inline-block
`