import React from 'react'
import PointsSection from './PointSection';
import { DashboardMainWrapper } from './style';
import MiddleSection from './MiddleSection';

function DashboardMiddle({notesdata}) {
  return (
    <DashboardMainWrapper>
      <PointsSection />
      <MiddleSection notesdata={notesdata}/>
    </DashboardMainWrapper>
  )
}

export default DashboardMiddle
