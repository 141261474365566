const UPDATE_TICKET_PENDING = "UPDATE_TICKET_PENDING";
const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
const UPDATE_TICKET_FAILED  = "UPDATE_TICKET_FAILED";
const UPDATE_TICKET_LEAD_DETAILS = "UPDATE_TICKET_LEAD_DETAILS";
const UPDATE_TICKET_LEAD_DETAILS_DATA = "UPDATE_TICKET_LEAD_DETAILS_DATA";
const UPDATE_TICKET_QUOTE_DETAILS_DATA = "UPDATE_TICKET_QUOTE_DETAILS_DATA";
const UPDATE_TICKET_DETAILS = "UPDATE_TICKET_DETAILS";
const UPDATE_ALL_TICKET_DETAILS = "UPDATE_ALL_TICKET_DETAILS";
const UPDATE_HN_TRIP = "UPDATE_HN_TRIP";

export default {
    UPDATE_TICKET_PENDING,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_FAILED,
    UPDATE_TICKET_LEAD_DETAILS,
    UPDATE_TICKET_LEAD_DETAILS_DATA,
    UPDATE_TICKET_QUOTE_DETAILS_DATA,
    UPDATE_TICKET_DETAILS,
    UPDATE_ALL_TICKET_DETAILS,
    UPDATE_HN_TRIP,
}