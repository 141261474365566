import React from "react";
import styled, { css } from "styled-components";

// // Global Container Wrapper

const CommonFlexRowProperties = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SpaceBetweenWrapper = styled.span`
  ${CommonFlexRowProperties}
  justify-content: space-between;
`;

export const CenterWrapper = styled.span`
  ${CommonFlexRowProperties}
  justify-content: center;
`;

// // Styles for Outer main body of card

// export const StyledCardContainer = styled.div`
//     background-color: #FFFFFF;
//     box-shadow: 0px 4px 16px #00000029;
//     border-radius: 18px;
//     display: grid;
//     grid-template-rows: repeat(5, auto);
//     padding: 17px 27px 7px 30px;
//     height: 100%;
// `;

export const StyledCardContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 16px #00000029;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 24px 10px 24px;
`;

// Styles for heading

export const HeadingWrapper = styled(SpaceBetweenWrapper)`
  border-bottom: 1px solid #e0d0fc;
  padding: 0px 0px 3px 0px;
`;

export const StyledHeading = styled.span`
  color: #945bf8;
  font-size: 1vw;
  font-weight: 600;
`;

// Styles for Description

export const StyledDescription = styled(CenterWrapper)`
  font-size: 1vw;
  color: #945bf8;
`;

// Styles for Table Data

export const StyledData = styled.span`
  color: #343434;
  font-size: 1vw;
  font-weight: 600;
`;

// Styles for toggle Button

export const LocationButtonWrapper = styled.span`
  width: 70%;
  align-self: center;
  height: 28px;
  border-radius: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #e0d0fc;
`;

export const LocationButtonActive = styled.button`
  border: none;
  color: #945bf8;
  border-radius: 14px 0px 0px 14px;
  background-color: #e0d0fc;
  font-weight: 600;
  font-size: 0.9vw;
`;

export const LocationButton = styled.button`
  border: none;
  color: #9f9f9f;
  border-radius: 14px 0px 0px 14px;
  background-color: transparent;
  font-weight: 600;
  font-size: 0.9vw;
`;

// Styles for Card footer

export const StyledFooterText = styled.span`
  font-size: 1vw;
  color: #ff9300;
`;
export const SeeMoreText = styled.span`
  font-size: .8vw;
  color: #ff9300;
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  cursor: pointer;
`;

export const FlexWrapper = styled.div`
  overflow-y: scroll;
`;

export const FooterTextTrips = ({ rank }) => {
  return (
    <div>
      <CenterWrapper
        style={{ fontSize: "1.1vw", fontWeight: "600", color: "#945BF8" }}
      >
        You’re at {rank}
      </CenterWrapper>
      <CenterWrapper style={{ fontSize: '0.9vw', color: "#945BF8" }}>
        Close more Trips to move up
      </CenterWrapper>
    </div>
  );
};

export const FooterTextDScore = () => {
  return (
    <div>
      <CenterWrapper style={{ color: "#945BF8", fontSize: "0.9vw" }}>
        {/* Congratulations for &nbsp; <b>3rd place!</b> */}
      </CenterWrapper>
      <CenterWrapper style={{ fontSize: "0.9vw", color: "#945BF8" }}>
        {/* Delight the callers to be &nbsp; <b>#1</b> */}
      </CenterWrapper>
    </div>
  );
};
