import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { TicketsWrapper } from './style';
import EmptyContainer from './../../../../EmptyContainer';
import SingleTicketDetails from './../../../../TicketDetailsDisplay/singleTicketDetails';
import { SingleTicketWrapper } from '../../../../TicketDetailsDisplay/style';
import { getLeadTicketByIdAction, getTicketByIdAction } from '../../../../../actions/patientInfoAction';
import { loadMoreLeadTickets, updateTicketDetails } from '../../../../../actions/ticketAction';
import { Mixpanel } from './../../../../../utils/mixpanelUtil';

function LeadTickets() {
  const scrollRef = useRef(null);
  const tickets = useSelector(state => state.leadTickets || {});
  let { leadTicketsData = [], leadTicketsLoading = false, leadSkip = 0, leadLimit = 15, leadTicketsErr = false } = tickets;
  const leadTickets = leadTicketsData && Array.isArray(leadTicketsData) ? leadTicketsData : [];
  const loading = leadTicketsLoading || false;

  const dispatch = useDispatch();

  const handleLeadTicket = (ticketData, error) => {
    if (ticketData && ticketData.data) {
      let leadTicketData = ticketData.data;
      let patientDetails = leadTicketData.patientDetails || {};
      let leadDetails = leadTicketData.leadDetails || {};
      let quoteDetails = leadTicketData.quoteDetails || {};
      let schedule = leadTicketData.schedule || {};
      let updatedData = {
        ...leadTicketData,
        patientDetails,
        leadDetails,
        quoteDetails,
        schedule,
      }
      dispatch(updateTicketDetails('ticketDetails', updatedData, () => {
        setTimeout(() => {
          dispatch(getTicketByIdAction(updatedData.ticketId))
        }, 500)
      }))
    }
  }

  const getTicketById = (ticketId) => {
    Mixpanel.track('Lead Ticket Clicked', { ticketId }, true);
    dispatch(getLeadTicketByIdAction(ticketId, handleLeadTicket))
  }

  const handleScroll = (e) => {
    let skip = leadSkip + leadLimit;
    let el = scrollRef.current;
    if (!loading && el.scrollHeight - el.scrollTop < 500) {
      dispatch(loadMoreLeadTickets(skip, leadLimit))
    }
  }

  if (leadTicketsErr) return <EmptyContainer text={"Something Went Wrong!"} />
  if (!leadTickets.length) return <EmptyContainer text={"No Tickets Found"} />
  return (
    <TicketsWrapper ref={scrollRef} onScroll={(e) => handleScroll(e)}>
      {leadTickets.map((ticket) => {
        return <SingleTicketWrapper cursor='auto' key={ticket.id} //onClick={() => getTicketById(ticket.ticketId)}
        >
          <SingleTicketDetails ticket={ticket} getTicketById={getTicketById} />
        </SingleTicketWrapper>
      })}
    </TicketsWrapper>
  )
}

export default LeadTickets;
