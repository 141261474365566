import WhiteCenterDiv from "../WhiteCenterDiv/index";
import styled from "styled-components";

const CustomWhiteCenterDiv = styled(WhiteCenterDiv)`
  display: grid;
  grid-template-rows: 0.1fr 1fr 0.1fr;
  row-gap: 5vh;
  justify-content: center;
  align-items: center;
  padding: 2em 0px 2em 0px;
`;

export default CustomWhiteCenterDiv;
