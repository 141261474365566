import React, { useContext, useEffect, useState } from "react";
import "./login.scss";
import LogoHeading from "../../components/LogoHeading/index";
// import Input from "../../components/Input/Input";
// import InputLabel from "../../components/Input/InputLabel";
// import InputWrapper from "../../components/Input/InputWrapper";
// import CenterDiv from "../../components/CenterDiv/index";
import WhiteCenterDiv from "../../components/WhiteCenterDiv/index";
// import PopupHeading from "../../components/PopupHeading/PopupHeading";
import ButtonSvg from "../../components/ButtonSvg/index";
import loginIcon from "../../images/loginIcon.svg";
import Spinner from 'react-bootstrap/Spinner';

// import {
//   GridWrapperComplex,
//   PointAndTicketSection
// } from "../../components/GridWrapperComplex/index";

//Redux
import { connect } from "react-redux";
import {
  loginAction,
  setInputDataAction,
  loginFailed,
} from "../../actions/loginAction";
import { withRouter, Redirect } from "react-router-dom";
import { 
  HeadWrapper, 
  LogoBtn, 
  MainWrapper, 
  TabName, 
  TabsMainWrapper, 
  ErrorMessage 
} from '../../components/NavTabs';
import { InputFactory, removeScript } from '../../utils/helper';
import { QuoteBookMainWrapper } from '../QuoteAndBookPage/styles';
import CustomButton from './../../components/CustomButton/index';
// import { WebSocketContext } from '../../utils/websocket';
import { Mixpanel } from '../../utils/mixpanelUtil';

const loginElements = [
  {
    type: 'text',
    label: 'User Name',
    className: 'in-t',
    id: 'userName',
    changeConst: 'SET_USER_NAME',
  },
  {
    type: 'text',
    label: 'Password',
    className: 'in-t',
    id: 'password',
    changeConst: 'SET_USER_PASSWORD',
  },
];

function LoginPage (props ){
  const [err, setErr] = useState(false);
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();
  const [loading, setLoading] = useState(false);
  // const ws = useContext(WebSocketContext);
  let { authenticated, loginErr, setInputData, userCredential, login, history, loginFailed  } = props;
  // let socket = ws.socket || null;

  useEffect(() => {
    Mixpanel.track('Login Page Loaded', {}, false);
    let id = 'map-api';
    let elem = document.getElementById(id);
    if(elem){
      removeScript(elem);
    }
  }, [])
  
  const handleFormSubmit = () => {
    Mixpanel.track('User Clicked On Log In', userCredential);
    setLoading(true);
    if(userCredential.userName !== "" && userCredential.password !== ""){
      userCredential.userName = userCredential?.userName?.trim();
      userCredential.password = userCredential?.password?.trim();
      login(userCredential, (val) => {
        // let { callUsername = '', callPassword = '', callServer = '', callToken = '' } = val
        setLoading(false);
        if(val){
          // if(socket && socket.readyState && socket.OPEN && socket.readyState === socket.OPEN){
            Mixpanel.track('Login Success', userCredential);
            // ws.lintlLogin(callUsername, callPassword, callServer, callToken);
            history.push("/");
          // }
        }else{
          Mixpanel.track('Login Failed', userCredential);
          setErr("Check User ID and Password");
          loginFailed()
        }
      });
    }else{
      setLoading(false);
      setErr("User ID and Password Should Not Be Empty");
    }
    setValue1('');
    setValue2('');
  };
   
    return (
      authenticated && !loading ? <Redirect to="/" /> :
      <MainWrapper isLogin>
        <HeadWrapper>
          <LogoBtn><LogoHeading /></LogoBtn>
        </HeadWrapper>
        <TabsMainWrapper isLogin>
          <TabName style={{ backgroundColor: '#16999e', marginLeft: '1.5rem' }}>Login</TabName>
          <WhiteCenterDiv>
            <QuoteBookMainWrapper isLogin>
              {/*loginElements.map((p,i) => {
                let valueInput = userCredential[p.id]
                return <InputFactory 
                  key={i}
                  onChange={(e) => setInputData(p.changeConst, e.target.value)} 
                  value={valueInput || ''}
                  {...p}
                />
              })*/}
              <InputFactory
                key={loginElements[0].id}
                onChange={(e) => {
                  setInputData(loginElements[0].changeConst, e.target.value)
                  setValue1(e.target.value)
                }}
                value={value1}
                type='text'
                label='User Name'
                className='in-t'
              />
              <InputFactory
                key={loginElements[1].id}
                onChange={(e) => {
                  setInputData(loginElements[1].changeConst, e.target.value)
                  setValue2(e.target.value)
                }}
                value={value2}
                type='text'
                label='Password'
                className='in-t'
              />
                {loading ?
                  <CustomButton loginButton green textCenter>Logging...</CustomButton>
                  : <CustomButton
                    loginButton
                    width='50%'
                    green onClick={() => handleFormSubmit()}
                    disabled={loading}
                  >
                    <ButtonSvg src={loginIcon} /> Login
                  </CustomButton>
                }
              {
                err ? <ErrorMessage>{err}</ErrorMessage>
                : loginErr ? <ErrorMessage>Error While Login, Please Check Your UserId and Password</ErrorMessage>
                : null
              }
            </QuoteBookMainWrapper>
          </WhiteCenterDiv>
        </TabsMainWrapper>
      </MainWrapper>
    );
  
}

const mapStateToProps = state => ({
  userCredential: state.userCredential,
  currentUserId: state.login.userId,
  authenticated: state.login.authenticated,
  loginErr: state.login.loginErr
});

const mapDispatchToProps = dispatch => ({
  setInputData: (inputType, inputText) => dispatch(setInputDataAction(inputType, inputText)),
  login: (userCred,callback) => dispatch(loginAction(userCred,callback)),
  loginFailed: () => dispatch(loginFailed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
