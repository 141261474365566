/* eslint-disable require-yield */
import { takeEvery } from "redux-saga/effects"; 
import { 
  REQUEST_TICKET, 
  TICKETS, 
  UPDATE_QUOTE, 
  UPDATE_TICKET, 
  REQUEST_CONFIG,
  VEHICLE, 
} from '../constants';
import { createNotification } from '../utils/helper';

function* handleFailures({ payload }) {
  console.error(payload)
  let res = payload?.response || {}
  let msg = res.data && res.data.meta && res.data.meta.msg ? res.data.meta.msg : "Oops! Something went wrong"
  createNotification("error", msg)
}

export default function* failureWatcherSaga() {
  yield takeEvery([
    UPDATE_QUOTE.UPDATE_QUOTE_FAILED, 
    UPDATE_TICKET.UPDATE_TICKET_FAILED, 
    TICKETS.CREATE_TICKET_FAILED, 
    // REQUEST_TICKET.REQUEST_ALL_TICKETS_FAILED, 
    // REQUEST_TICKET.REQUEST_LIVE_TICKETS_FAILED,
    REQUEST_TICKET.REQUEST_TICKET_FAILED, 
    REQUEST_CONFIG.CONFIG_DATA_FAILED,
    VEHICLE.GET_VEHICLE_FAILED,
    REQUEST_TICKET.REQUEST_TICKET_HISTORY_FAILED
  ], handleFailures)
}