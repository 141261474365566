import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import ProtectedRoute from "./routes/protectedRoutes";
//Redux
import { connect } from "react-redux";
import { loginSuccess, loginFailed, logoutAction } from "./actions/loginAction";
import { getLeadTicketsData } from "./actions/ticketAction";
import LoginPage from "./containers/LoginPage/index";
import HeathCheck from "./containers/HeathCheck/index";
import PrivateRoutes from "./routes/privateRoutes";
import {
  pusherApiKey,
  /*loginUrl*/
  pusherClusterName,
} from "./api/config";
import {
  createNotification,
  localStorageGetItem,
  notficationsound,
} from "./utils/helper";
// import { WebSocketContext } from './utils/websocket';
import Pusher from "pusher-js";
import { generateToken, verifyJWTToken } from "./api";

const pusher = new Pusher(pusherApiKey, {
  cluster: pusherClusterName,
  encrypted: true,
});

class App extends Component {
  // static contextType = WebSocketContext;
  // constructor(props,contextType){
  // super(props,contextType);
  constructor(props) {
    super(props);
    this.state = {
      pusherEnabled: false,
    };
  }

  // beforeUnload(e){
  //   console.log('before unload', this.context)

  //   this.props.logoutAction();
  //   this.context.socketClose();
  //   // this.props.history.push("/")
  // }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isAuthenticated !== this.props.isAuthenticated ||
      prevState.pusherEnabled !== this.state.pusherEnabled
    ) {
      if (this.props.isAuthenticated && !this.state.pusherEnabled) {
        const channel = pusher.subscribe("LeadTicketChannel");
        channel.bind("LeadTicketEvent", (data) => {
          if (data && data.message) {
            this.props.getLeadTicketsData(0, 15);
            // notficationsound();
            createNotification(
              "info",
              data.message,
              "Lead Found!",
              30000,
              "Jd"
            );
          }
        });
        this.setState({ pusherEnabled: true }, () => {
          // console.log('subscribed',this.state.pusherEnabled)
        });
      } else if (!this.props.isAuthenticated && this.state.pusherEnabled) {
        pusher.unsubscribe("LeadTicketChannel");
        this.setState({ pusherEnabled: false }, () => {
          // console.log('pusher unsubscribed!',this.state.pusherEnabled)
        });
      }
    }
  }

  async verifyToken(userToken, userID, refreshToken) {
    // console.log('verifying')
    let isTokenVerified = false;
    if (userToken && !isTokenVerified) {
      try {
        isTokenVerified = await verifyJWTToken(userToken);
        return { token: userToken };
      } catch (e) {
        // console.error("Error verifying JWTToken")
        if (
          e &&
          e.response &&
          e.response.status &&
          e.response.status === 401 &&
          userID &&
          userID !== "" &&
          refreshToken &&
          refreshToken !== "" &&
          userID !== "null" &&
          refreshToken !== "null"
        ) {
          try {
            const { data = {} } = await generateToken({ userID, refreshToken });
            const { token } = data?.data || {};
            return { token: token };
          } catch (e) {
            // console.error("Error Generating Token")
            return false;
          }
        }
      }
    }
    return false;
  }

  async componentDidMount() {
    // console.log('loginUrl',loginUrl, 'process.env', process.env, 'checking env variables')
    // window.addEventListener('beforeunload', (e) => {
    //   this.beforeUnload(e)
    // })
    const obj = {
      authenticated: null,
      userID: null,
      username: null,
      userToken: null,
      refreshToken: null,
    };
    const localStorage = localStorageGetItem(obj);
    const { authenticated, userID, username, userToken, refreshToken } =
      localStorage;
    const isTokenVerified = await this.verifyToken(
      userToken,
      userID,
      refreshToken
    );
    // console.log(isTokenVerified)
    // let googleUrl = mapUrl(mapKey);
    // appendScript(googleUrl);
    // console.log('url called')
    if (authenticated && isTokenVerified) {
      // console.log('login', localStorage)
      this.props.loginSuccess({
        userId: userID,
        username,
        userToken: isTokenVerified.token,
        refreshToken,
      });
    } else {
      this.props.loginFailed();
      this.props.history.push("/login");
    }
  }

  // componentWillUnmount(){
  //   // console.log('app unmount')
  //   window.removeEventListener('beforeunload', (e) => this.beforeUnload(e))
  // }

  render() {
    return (
      <div className="app">
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          {/* <Route path="/login1" exact component={LoginPage1} /> */}
          <Route path="/healthCheck" exact component={HeathCheck} />
          <ProtectedRoute path="/" component={PrivateRoutes} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserId: state.login.userId,
  isAuthenticated: state.login.authenticated,
});

const mapDispatchToProps = {
  loginSuccess,
  loginFailed,
  logoutAction,
  getLeadTicketsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
