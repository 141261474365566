import React, { useState } from 'react'
import { ChatSection } from "./styles";
import { useDispatch } from 'react-redux';
import { getCallDetailsAction } from './../../actions/getCallDetailsAction';
import { handleRedirection } from '../../actions/redirectAction';
import { Link } from 'react-router-dom';
import { checkIfStringIsNumber, createNotification } from '../../utils/helper';
import { Mixpanel } from './../../utils/mixpanelUtil';

function ChatSect() {
  const [number, setNumber] = useState('');
  const dispatch = useDispatch();

  const triggerCall = () => {
    Mixpanel.track('Create Ticket', { mobile: number }, true);
    let giveCall = checkIfStringIsNumber(number, 10);
    if(giveCall){
      let callData = {
        mobile: `${number}`,
        ticketStage: "NEWTICKET",
        leadDetails: {},
        patientDetails: {},
        quoteDetails: {},
        hospitalSelectDetails: {},
        patientOnboardDetails: {},
        billReviewDetails: {},
        ownerId: localStorage.getItem('username')
      }
      dispatch(getCallDetailsAction(callData));
      dispatch(handleRedirection(false));
    }else {
      createNotification('error', 'Use proper mobile number to give a call')
    }
  }

  const handleNumber = (e) => {
    setNumber(e.target.value);
  };

  return (
    <ChatSection>
      <div style={{height: '100%', padding: '50% 0% 0% 0%'}}>
        <span>enter mobile number to create ticket</span>
        <input
          style={{ border: "1px solid black" }}
          maxLength="10"
          minLength="10"
          value={number}
          onChange={event => handleNumber(event) }
        /><br />
        {number && number.length === 10 && checkIfStringIsNumber(number, 10) && (
          <Link to="/newticket">
            <button onClick={() => triggerCall()}>give a call</button>
          </Link>
        )}
      </div>
    </ChatSection>
  )
}

export default ChatSect
