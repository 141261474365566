const SET_PATIENT_NAME = "SET_PATIENT_NAME";
const SET_PATIENT_NUMBER = "SET_PATIENT_NUMBER";
const SET_PATIENT_TYPE = "SET_PATIENT_TYPE";
const SET_AMBULANCE_TYPE = "SET_AMBULANCE_TYPE";
const SET_AMBULANCE_ADD_ONS = "SET_AMBULANCE_ADD_ONS";
const SET_CALLER_NAME = "SET_CALLER_NAME";
const SET_CALLER_NUMBER = "SET_CALLER_NUMBER";
const SET_CALLER_CITY = "SET_CALLER_CITY";
const SET_LEAD_SOURCE = "SET_LEAD_SOURCE";
const UPDATE_PATIENT_INFO = "UPDATE_PATIENT_INFO";

export default {
    SET_PATIENT_NAME,
    SET_PATIENT_NUMBER,
    SET_PATIENT_TYPE,
    SET_AMBULANCE_TYPE,
    SET_AMBULANCE_ADD_ONS,
    SET_CALLER_NAME,
    SET_CALLER_NUMBER,
    SET_CALLER_CITY,
    SET_LEAD_SOURCE,
    UPDATE_PATIENT_INFO,
}