import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Tab } from 'react-bootstrap';
import { getLiveTicketsData, getLeadTicketsData, getAllTicketsData, getScheduledTicketsData } from './../../../../actions/ticketAction';
import { NavContainer, TabContainer, TicketsCountText } from './style';
import AllTickets from './TicketTabs/allTickets';
import LiveTickets from './TicketTabs/liveTickets';
import ScheduleTickets from './TicketTabs/scheduleTickets';
import LeadTickets from './TicketTabs/leadTickets';
import './styles.scss';;

function TicketsTab() {
  const [activeTab, setActiveTab] = useState('liveTickets');
  const dispatch = useDispatch();
  const tickets = useSelector(state => state.tickets || {});
  const loading = tickets.allTicketDataLoading || tickets.liveTicketDataLoading || tickets.scheduleTicketDataLoading;
  const userCredential = useSelector(state => state.userCredential || {});
  // const liveTicketslength = tickets.liveTicketsData && Array.isArray(tickets.liveTicketsData) ? tickets.liveTicketsData.length : 0;
  // const allTicketslength = tickets.allTicketsData && Array.isArray(tickets.allTicketsData) ? tickets.allTicketsData.length : 0;
  const liveTicketscount = tickets?.liveTicketscount || 0
  const scheduledTicketscount = tickets?.scheduledTicketscount || 0
  const allTicketscount = tickets?.allTicketscount || 0
  const closedTicketCount = tickets?.closedTicketCount || 0
  const newTicketCount = tickets?.newTicketCount || 0
  // useEffect(() => {
  //   dispatch(getLeadTicketsData(0,15));
  //   let interval = setInterval(() => dispatch(getLeadTicketsData(0,15)), 60000);
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])


  useEffect(() => {
    let username = userCredential.userName || localStorage.getItem('username');
    if (!loading) {
      dispatch(getLiveTicketsData(username));
      dispatch(getAllTicketsData(username, 0, 50));
      dispatch(getScheduledTicketsData(username));
      dispatch(getLeadTicketsData(0, 15));
    }
  }, [activeTab]);

  return (
    <TabContainer>
      <TicketsCountText>You have <b>Total {allTicketscount} tickets</b> with <b>{newTicketCount} New ticktes</b> ,<b>{liveTicketscount} Live tickets</b> and <b>{closedTicketCount} Closed tickets</b> </TicketsCountText>
      <Tab.Container activeKey={activeTab} onSelect={(e) => setActiveTab(e)} transition={false}>
        <NavContainer>
          <Nav.Link className={`t-l ${activeTab === 'liveTickets' ? ' active' : ''}`} eventKey="liveTickets">Live Tickets <span class="badge" style={{ 'backgroundColor': 'white', 'color': 'black', 'fontSize': '95%' }} >{liveTicketscount}</span>  </Nav.Link>
          <Nav.Link className={`t-l ${activeTab === 'allTickets' ? ' active' : ''}`} eventKey="allTickets">All Tickets <span class="badge" style={{ 'backgroundColor': 'white', 'color': 'black', 'fontSize': '95%' }} >{allTicketscount}</span></Nav.Link>
          <Nav.Link className={`t-l ${activeTab === 'scheduleTickets' ? ' active' : ''}`} eventKey="scheduleTickets">Schedule Tickets <span class="badge" style={{ 'backgroundColor': 'white', 'color': 'black', 'fontSize': '95%' }} >{scheduledTicketscount}</span></Nav.Link>
          <Nav.Link className={`t-l ${activeTab === 'leadTickets' ? ' active' : ''}`} eventKey="leadTickets">Lead Tickets</Nav.Link>
        </NavContainer>
        <Tab.Content>
          <Tab.Pane className='t-tp' eventKey="allTickets">
            <AllTickets />
          </Tab.Pane>
          <Tab.Pane className='t-tp' eventKey="liveTickets">
            <LiveTickets />
          </Tab.Pane>
          <Tab.Pane className='t-tp' eventKey="scheduleTickets">
            <ScheduleTickets />
          </Tab.Pane>
          <Tab.Pane className='t-tp' eventKey="leadTickets">
            <LeadTickets />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </TabContainer>
  )
}

export default TicketsTab
