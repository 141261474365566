import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateTicketDetails } from '../../actions/ticketAction';
import BootstrapPopup from '../../components/BootstrapPopup';
import { createNotification, InputFactory, isValueEqual } from '../../utils/helper';
import { isValueNotEqual } from './../../utils/helper';
import { ConfirmContainer, HoldContainer } from './dispositionContainers';
import { DISPOSITION_ERR, NOT_SELECTED_AMB_ERR, REMARK_FIELD_ERR, SCHEDULED_DETAILS_ERR } from './../../constants/notificationConst';



const remarkProp = {
  id: 'remark',
  label: 'Case Remark',
  type: 'textarea',
  className: 'g-c in-t',
};

const disSubNew = [
  {
    id: 'subDisposition',
    className: 'in-t',
    label: 'Sub Disposition Type',
    options: [],
    type: 'select',
    placeholder: 'Select Sub Disposition'
  }];

function DispositionModal(cprops) {
  let disSub;
  let { dispositionType,
    handleModal,
    isValid,
    updating = false
    /*estimatedVals*/
  } = cprops;


  if (dispositionType?.toLowerCase() === "cancelled") {
    disSub = [{
      id: 'disposition',
      className: 'in-t',
      label: 'Disposition Type',
      // type: 'text',
      // disabled: true,
      options: [],
      type: 'select',
      placeholder: 'Select Disposition'
    }];
  } else {
    disSub = [{
      id: 'disposition',
      className: 'in-t',
      label: 'Disposition Type',
      type: 'text',
      disabled: true,
    }, {
      id: 'subDisposition',
      className: 'in-t',
      label: 'Sub Disposition Type',
      options: [],
      type: 'select',
      placeholder: 'Select Sub Disposition'
    }];
  }

  const [subDispositionOptions, setSubDispositionOptions] = useState([]);
  const [subsubDispositionOptions, setSubSubDispositionOptions] = useState([]);
  const newDispositions = useSelector(state => state.ticketConfig.newDispositions || {});
  const newcancelDisposition = useSelector(state => state.ticketConfig.subDispositionTypeNew || {});
  const vehiclesEtanonhp = useSelector(state => state.vehicleList.vehiclesEta || {});
  const customVehicleEta = useSelector(state => state.vehicleList.customVehicleEta || {});
  const vehiclesEtaHP = useSelector(state => state.vehicleListHP.vehiclesEta || {});
  // const customVehicleEtaHP = useSelector(state => state.vehicleListHP.customVehicleEta || {});
  let vehiclesEta
  // let customVehicleEta
  if (cprops?.selectedAmbulance?.vehicleObject?.vehicleType === 'nonhelpnow') {
    vehiclesEta = vehiclesEtanonhp
    // customVehicleEta = customVehicleEtanonhp
  } else if (cprops?.selectedAmbulance?.vehicleObject?.vehicleType === 'helpnow') {
    vehiclesEta = vehiclesEtaHP
    // customVehicleEta = customVehicleEtaHP
  }

  const ticketData = useSelector(state => state.patientInfo.ticketData || {});
  let freeTrip = ticketData?.tripCategory || false;
  let leadDetails = ticketData && ticketData.leadDetails ? ticketData.leadDetails : {};
  let quoteDetails = ticketData && ticketData.quoteDetails ? ticketData.quoteDetails : {};
  let { customQuote = false, selectedAmbulanceDetails = {} } = quoteDetails;
  let mandatoryFields = selectedAmbulanceDetails && Object.keys(selectedAmbulanceDetails).length && selectedAmbulanceDetails.vehicleObject && selectedAmbulanceDetails.vehicleObject.id;
  let index = selectedAmbulanceDetails && selectedAmbulanceDetails.vehicleObject && selectedAmbulanceDetails.vehicleObject.id ? vehiclesEta?.findIndex(v => v.id === selectedAmbulanceDetails.vehicleObject.id) : null;
  let etaObj = customQuote ? customVehicleEta : (index !== null ? vehiclesEta[index] : false);
  let schedule = ticketData && ticketData.schedule && ticketData.schedule.scheduledDateTime ? ticketData.schedule.scheduledDateTime : null;

  let modalHeading = isValueEqual(dispositionType, "confirm") ? "Confirmed Booking"
    : (isValueEqual(dispositionType, "hold") ? "Call Hold"
      : (isValueEqual(dispositionType, "cancelled") ? "Call Dispose"
        : "NA"));

  const dispatch = useDispatch();

  const handleHoldTrip = (submit) => {
    if (ticketData.isScheduled && ticketData.schedule) {
      /*let scheduleData = {
        scheduledDateTime: ticketData.schedule.scheduledDateTime,
        ownerUsername: localStorage.getItem('username'),
        scheduleMobile: ticketData.mobile,
        ticketId: ticketData.id,
      }*/
      // dispatch(createSchedule(scheduleData));
    }
    handleModal(false, submit, {});
  }

  const handleConfirmTrip = (submit) => {
    if (mandatoryFields) {
      handleModal(false, submit, {});
    } else if (!mandatoryFields) {
      handleModal(false, false, {});
      createNotification("error", NOT_SELECTED_AMB_ERR);
    }
  }

  const handleCancelTrip = (submit) => {
    handleModal(false, submit, {});
  }

  const handleDispositionPopUp = (submit) => {
    if (leadDetails?.disposition?.toLowerCase() === 'cancelled') {
      leadDetails.disposition = subDispositionOptions[0];
    }
    if (submit) {
      if (leadDetails && leadDetails.remark && leadDetails.remark !== "") {
        if (dispositionType === 'confirm') handleConfirmTrip(submit);
        if (dispositionType !== 'confirm') {
          if (leadDetails.subDisposition && leadDetails.subDisposition !== '') {
            if (dispositionType === 'hold') {
              if (ticketData.schedule !== null) {
                handleHoldTrip(submit);
              } else {
                createNotification("error", SCHEDULED_DETAILS_ERR);
              }
            }
            if (dispositionType === 'cancelled') handleCancelTrip(submit);
          } else {
            createNotification("error", DISPOSITION_ERR);
          }
        }
      } else {
        createNotification("error", REMARK_FIELD_ERR);
      }
    } else {
      handleModal(false, submit, {})
    }
  }

  const setQuoteData = (e, detailsType, id) => {
    if (id === 'disposition') {
      let allDisposition = Object.keys(newcancelDisposition);
      let index = allDisposition.map(e => e).indexOf(e.target.value);
      let value = allDisposition[index];
      setSubSubDispositionOptions([...newcancelDisposition[value]])
    }


    // if(e.target.value === 'InvalidCall'){
    //   setSubSubDispositionOptions(newcancelDisposition.InvalidCall)
    // }
    // if(e.target.value === 'NotInterested'){
    //   setSubSubDispositionOptions(newcancelDisposition.NotInterested)
    // }
    // if(e.target.value === 'LostBooking'){
    //   setSubSubDispositionOptions(newcancelDisposition.LostBooking)
    // }

    let updatedQuoteDetails = {
      ...ticketData,
      [detailsType]: {
        ...ticketData[detailsType],
        [id]: e.target.value
      }
    }
    if (updatedQuoteDetails.leadDetails &&
      isValueEqual(dispositionType, updatedQuoteDetails.leadDetails.disposition) &&
      isValueEqual(updatedQuoteDetails.leadDetails.subDisposition, "ScheduleTicket") &&
      updatedQuoteDetails["isScheduled"] === false
    ) {
      updatedQuoteDetails["isScheduled"] = true;
    } else if (
      updatedQuoteDetails.leadDetails &&
      isValueNotEqual(updatedQuoteDetails.leadDetails.subDisposition, "ScheduleTicket") &&
      updatedQuoteDetails["isScheduled"] === true
    ) {
      updatedQuoteDetails["isScheduled"] = false;
      updatedQuoteDetails["schedule"] = null;
    }
    dispatch(updateTicketDetails("ticketDetails", updatedQuoteDetails))
  }

  const handleSchedule = (dateTime) => {
    let updateDetails = {
      schedule: {
        id: ticketData.id,
        scheduledDateTime: dateTime,
        ownerId: ticketData.ownerId,
        ownerUsername: ticketData.ownerName,
        ownerEmail: '',
        ownerMobile: '',
        scheduleMobile: ticketData.mobile,
        scheduleEmail: ticketData.email,
        ticketId: ticketData.ticketId,
        status: "SCHEDULED"
      }
    }
    dispatch(updateTicketDetails("ticketDetails", updateDetails));
  }

  useEffect(() => {
    let allDisposition = Object.keys(newDispositions);
    let disposition = allDisposition.filter(d => { return isValueEqual(d, dispositionType) });
    let update = { disposition: disposition[0] || null, subDisposition: '' };
    if (update?.disposition?.toLowerCase() === 'cancelled') {
      const dynamicValue = newDispositions[update.disposition][0];
      // setSubDispositionOptions(newcancelDisposition.Cancelled)
      setSubSubDispositionOptions(newcancelDisposition[dynamicValue])
    }
    if (update.disposition) setSubDispositionOptions([...newDispositions[update.disposition]])
    dispatch(updateTicketDetails('leadDetails', update));
  }, [])

  return (
    <BootstrapPopup
      updating={updating}
      show={dispositionType ? true : false}
      setShow={(submit) => handleDispositionPopUp(submit)}
      heading={modalHeading}
      contentClassName="m-content"
      headerClassName="m-header"
      bodyClassName="m-body"
      footerClassName="m-footer"
      transition={false}
    >
      {isValueEqual(dispositionType, 'hold') && disSub.map((prop, i) => {
        prop.options = prop.id === 'subDisposition' ? subDispositionOptions : [];
        return <InputFactory
          key={i}
          onChange={(e) => setQuoteData(e, 'leadDetails', prop.id)}
          value={leadDetails[prop.id] || ''}
          {...prop}
        />
      })}
      {dispositionType?.toLowerCase() === 'cancelled' ? disSub.map((prop, i) => {
        prop.options = prop.id === 'disposition' ? subDispositionOptions : [];
        return <InputFactory
          key={i}
          onChange={(e) => setQuoteData(e, 'leadDetails', prop.id)}
          value={leadDetails[prop.id] || ''}
          {...prop}
        />
      }) : null}
      {dispositionType?.toLowerCase() === 'cancelled' ? disSubNew.map((prop, i) => {
        prop.options = prop.id === 'subDisposition' ? subsubDispositionOptions : [];
        return <InputFactory
          key={i}
          onChange={(e) => setQuoteData(e, 'leadDetails', prop.id)}
          value={leadDetails[prop.id] || ''}
          {...prop}
        />
      }) : null}
      {
        dispositionType ?
          isValueEqual(dispositionType, 'confirm') ? <ConfirmContainer
            freeTrip={freeTrip}
            quoteDetails={ticketData.quoteDetails}
            handleChange={(e, detailsType, id) => setQuoteData(e, detailsType, id)}
            etaObj={etaObj}
          />
            : (isValueEqual(dispositionType, 'hold') ? <HoldContainer
              schedule={schedule}
              callback={ticketData.isScheduled || false}
              handleSchedule={(dateTime) => handleSchedule(dateTime)}
            />
              : null)
          : null
      }
      {
        <InputFactory
          onChange={(e) => setQuoteData(e, 'leadDetails', remarkProp.id)}
          value={leadDetails[remarkProp.id] || ''}
          {...remarkProp}
        />
      }
    </BootstrapPopup>
  )
}

export default DispositionModal
