import { REQUEST_CONFIG, REQUEST_TICKET, UPDATE_TICKET } from "../constants";
import {
  createBreak,
  getAllTicketsReq,
  ticketConfig,
  updateBreak,
  getLiveTicketsReq,
  getAllTicketsBySearch,
  getLeadTicketsReq,
  getScheduleTicketsReq,
  getLeaderBoardData,
  getTicketHistory
} from "../api";
export const getAllTicketsData = (id, skip, limit) => async (dispatch) => {
  // console.log(id, "id", skip, "skip", limit, "limit");
  dispatch({ type: REQUEST_TICKET.REQUEST_ALL_TICKET_PENDING });
  try {
    let ownerId = localStorage.getItem('username');
    let allTickets = await getAllTicketsReq(ownerId, skip, limit);
    if (allTickets.status === 200) {
      dispatch({ type: REQUEST_TICKET.REQUEST_ALL_TICKETS_SUCCESS, payload: allTickets.data });
    } else {
      let err = allTickets.error || "Error Fetching all tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_ALL_TICKETS_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.REQUEST_ALL_TICKETS_FAILED, payload: err });
  }
};
export const getTicketsBySearch = (searchTerm, skip) => async (dispatch) => {
  dispatch({ type: REQUEST_TICKET.TICKETS_BY_SEARCH });
  try {
    let allTickets = await getAllTicketsBySearch(searchTerm, skip);
    if (allTickets.status === 200) {
      dispatch({ type: REQUEST_TICKET.TICKETS_BY_SEARCH_SUCCESS, payload: allTickets.data });
    } else {
      let err = allTickets.error || "Error Fetching all tickets";
      dispatch({ type: REQUEST_TICKET.TICKETS_BY_SEARCH_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.TICKETS_BY_SEARCH_FAILED, payload: err });
  }
};
export const getLiveTicketsData = (ownerId) => async (dispatch) => {
  dispatch({ type: REQUEST_TICKET.REQUEST_LIVE_TICKET_PENDING });
  try {
    let liveTickets = await getLiveTicketsReq(ownerId)
    if (liveTickets.status === 200 && liveTickets.data && liveTickets.data.data && Array.isArray(liveTickets.data.data.ticketList)) {
      dispatch({ type: REQUEST_TICKET.REQUEST_LIVE_TICKETS_SUCCESS, payload: liveTickets.data });
    } else {
      let err = liveTickets.error || "Fetching live tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_LIVE_TICKETS_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.REQUEST_LIVE_TICKETS_FAILED, payload: err });
  }
};
export const getLeadTicketsData = (skip, limit) => async (dispatch) => {
  dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKET_PENDING });
  try {
    let leadTickets = await getLeadTicketsReq(skip, limit);
    if (leadTickets.status === 200 && leadTickets.data && leadTickets.data.data) {
      dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKETS_SUCCESS, payload: leadTickets.data.data });
    } else {
      let err = leadTickets.error || "Fetching live tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKETS_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKETS_FAILED, payload: err });
  }
};
export const loadMoreLeadTickets = (skip, limit) => async (dispatch) => {
  // console.log(skip,"skip",limit,"limit");
  dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKET_PENDING });
  try {
    let leadTickets = await getLeadTicketsReq(skip, limit);
    if (leadTickets.status === 200 && leadTickets.data && leadTickets.data.data) {
      let payload = { data: leadTickets.data.data, skip: skip }
      dispatch({ type: REQUEST_TICKET.REQUEST_MORE_LEAD_TICKETS_FETCHED, payload });
    } else {
      let err = leadTickets.error || "Fetching live tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKETS_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.REQUEST_LEAD_TICKETS_FAILED, payload: err });
  }
};
export const getScheduledTicketsData = (ownerId) => async (dispatch) => {
  dispatch({ type: REQUEST_TICKET.REQUEST_SCHEDULED_TICKETS_PENDING });
  try {
    let scheduledTickets = await getScheduleTicketsReq(ownerId);
    if (scheduledTickets.status === 200 && scheduledTickets.data && scheduledTickets.data.data) {
      dispatch({ type: REQUEST_TICKET.REQUEST_SHEDULED_TICKETS_SUCCESS, payload: scheduledTickets.data });
    } else {
      let err = scheduledTickets.error || "Fetching live tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_SCHEDULED_TICKETS_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.REQUEST_SCHEDULED_TICKETS_FAILED, payload: err });
  }
};
export const getTicketConfig = () => async (dispatch) => {
  try {
    let ticketConfigs = await ticketConfig();
    if (ticketConfigs.data && ticketConfigs.data.data && Object.keys(ticketConfigs.data.data)) {
      let configData = ticketConfigs.data.data;
      dispatch({ type: REQUEST_CONFIG.CONFIG_DATA, payload: configData });
    }
  } catch (error) {
    dispatch({ type: REQUEST_CONFIG.CONFIG_DATA_FAILED, payload: error });
  }
}
export const getLeaderBoardDetails = () => async (dispatch) => {
  try {
    let leaderBoardData = await getLeaderBoardData();
    dispatch({ type: REQUEST_CONFIG.LEADER_BOARD_UPDATED, payload: leaderBoardData })
  } catch (error) {
    dispatch({ type: REQUEST_CONFIG.LEADER_BOARD_FAILED, payload: error });
  }
}
export const postBreakRequest = (breakType, username, callback) => async () => {
  let startedAt = new Date().getTime();
  let breakData = { breakType, username, startedAt }
  try {
    let currentBreak = await createBreak(breakData);
    if (currentBreak.status && currentBreak.status === 200) {
      let currentBreakData = currentBreak.data.data;
      callback(currentBreakData)
    }
  } catch (error) {
    callback(error)
  }
}
export const updateBreakRequest = (id, callback) => async () => {
  let stoppedAt = new Date().getTime();
  let breakData = { stoppedAt };
  try {
    let currentBreak = await updateBreak(id, breakData);
    if (currentBreak.status && currentBreak.status === 200) {
      callback(true)
    }
  } catch (error) {
    callback(false)
  }
}
export const updateTicketDetails = (detailsType, data, callback) => ({
  type: UPDATE_TICKET.UPDATE_TICKET_DETAILS,
  payload: { detailsType, data, callback }
});
export const updateHNTrip = (tripObj, callback) => ({
  type: UPDATE_TICKET.UPDATE_HN_TRIP,
  payload: { tripObj, callback }
});
export const emptySearch = () => ({
  type: REQUEST_TICKET.EMPTY_SEARCH,
  payload: []
});
export const updateAllTicketDetails = (data) => ({
  type: UPDATE_TICKET.UPDATE_ALL_TICKET_DETAILS,
  payload: data
});
export const setTripsCount = (data) => ({
  type: REQUEST_TICKET.LEADERBOARD_DATA,
  payload: data
});
export const fetchTicketActionHistory = (ticketId) => async (dispatch) => {
  dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_HISTORY_PENDING });
  try {
    let ticketHistory = await getTicketHistory(ticketId)
    if (ticketHistory.status === 200 && ticketHistory.data && ticketHistory.data.data) {
      dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_HISTORY_SUCCESS, payload: ticketHistory.data.data });
    } else {
      let err = ticketHistory.error || "Fetching live tickets";
      dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_HISTORY_FAILED, payload: err });
    }
  } catch (err) {
    dispatch({ type: REQUEST_TICKET.REQUEST_TICKET_HISTORY_FAILED, payload: err });
  }
};