import { VEHICLE } from '../constants'

const INITIAL_STATE = {
  isGoogle: false,
  page: 0,
  vehicleCount: 5,
  displayedAmbulanceList: [],
  calculatingEtaDist: false,
}

const nearbyAmbulanceReducer = (state = INITIAL_STATE, { type, payload }) => {
  // console.log('ambunhp', payload, type);
  switch (type) {
    case VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_OPEN_ROUTE:
    case VEHICLE.CALCULATE_PAGINATED_AMBULANCES_ETA_WITH_GOOGLE:
      return {
        ...state,
        displayedAmbulanceList: [...state.displayedAmbulanceList, ...payload.ambulanceList],
        page: payload.page || 0,
        isGoogle: !state.isGoogle,
      }
    case VEHICLE.CALCULATING_ETA_DIST:
      return {
        ...state,
        calculatingEtaDist: payload
      }
    case VEHICLE.GET_VEHICLE_PENDING:
      return INITIAL_STATE;
    case VEHICLE.EMPTY_VEHICLES:
      return INITIAL_STATE;
    case VEHICLE.ADD_VEHICLES_FROM_TICKET_DETAILS:
      return {
        ...state,
        displayedAmbulanceList: [...payload.ambulanceList],
      }
    case VEHICLE.UPDATE_MODIFIED_RATE_DISPLAY_LIST:
      return {
        ...state,
        displayedAmbulanceList: [...payload.ambulanceList],
      }
    default:
      return state
  }
}

export default nearbyAmbulanceReducer;
