import { combineReducers } from "redux";
import { createTicketReducer } from "./createTicketReducer";
import getCallDetailsReducer from "./getCallDetailsReducer";
import { ticketReducer } from "./ticketsReducer";
import { loginReducer, userCredentialReducer } from "./loginReducer";
import {
  patientInfoReducer,
  updatePatientInfoReducer,
} from "./patientInfoReducer";
// import {
//   quoteAndBookReducer,
//   updatequoteAndBookReducer,
// } from "./quoteAndBookReducer";
import { vehicleListReducer } from "./vehicleListReducer";
import { vehicleListReducerHP } from "./vehicleListReducerHP";
// import { updateHospitalSelectReducer } from "./hospitalSelectReducer";
import ticketConfigReducer from "./ticketConfigReducer";
import redirectReducer from "./redirectReducer";
import validationReducer from "./validationReducer";
// import websocketReducer from './webSocketReducer';
import nearbyAmbulanceReducer from './nearbyAmbulancesReducer';
import leadTicketReducer from './leadTicketReducer';
import callSocketReducer from './handleSocketCallsReducer';
import ticketHistoryReducer from './ticketHistoryReducer';
import nearbyAmbulanceReducerHP from "./nearbyAmbulanceReducerHp";
import popupreducer from "./popupReducer";

export default combineReducers({
  tickets: ticketReducer,
  createTicket: createTicketReducer,
  callDetails: getCallDetailsReducer,
  patientInfo: patientInfoReducer,
  patientInfoStatus: updatePatientInfoReducer,
  // quoteAndBook: quoteAndBookReducer,
  vehicleList: vehicleListReducer,
  vehicleListHP: vehicleListReducerHP,
  // quoteAndBookStatus: updatequoteAndBookReducer,
  // hospitalSelectStatus: updateHospitalSelectReducer,
  login: loginReducer,
  userCredential: userCredentialReducer,
  ticketConfig: ticketConfigReducer,
  redirect: redirectReducer,
  validation: validationReducer,
  // ws: websocketReducer,
  nearbyAmbulances: nearbyAmbulanceReducer,
  nearbyAmbulancesHP: nearbyAmbulanceReducerHP,
  leadTickets: leadTicketReducer,
  callEvents: callSocketReducer,
  ticketHistory: ticketHistoryReducer,
  popup: popupreducer,
});
