import styled from 'styled-components';

export const MiddleSectionWrapper = styled.div`
    padding: 0 0 0 1em;
`;

export const  NavContainer = styled.div`
    display: flex;
    align-items: center;
`

export const  TabContainer = styled.div`
    margin-top: 1em;
`

export const  TicketsCountText = styled.p`
    text-align: center;
    margin: 0 0 1em 0;
    font-size: 1em;
    color: #16999E
`

export const  CountsContainer = styled.div`
    margin-top: 1em;
    background: #fff;
    height: 15%;
    display: flex;
    align-items: center;
    border-radius: 15px;
    box-sizing: border-box;
    box-shadow: 0px 0px 1.2em #00000029;
`