import { VALID, REDIRECT } from '../constants'

const INITIAL_STATE = {
  invalidDetails: [],
  invalidDetailsKeys: [],
  isValid: false,
  incompleteDetails: {},
}

const validationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VALID.VALID_PATIENT_DETAILS:
      // console.log(action.payload)
      return { 
        ...state, 
        isValid: action.payload.isValid,
      }
    case VALID.INVALID_DETAILS_KEYS: 
      return {
        ...state,
        invalidDetailsKeys: action.payload || [],
      }
    case REDIRECT.REDIRECT_TO_DASHBOARD: {
      return INITIAL_STATE;
    }
    default:
      return state
  }
}

export default validationReducer;
