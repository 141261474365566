const CREATE_TICKET_PENDING = "CREATE_TICKET_PENDING";
const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
const CREATE_TICKET_FAILED = "CREATE_TICKET_FAILED";
const RESET_TICKET = "RESET_TICKET";

export default {
    CREATE_TICKET_PENDING,
    CREATE_TICKET_SUCCESS,
    CREATE_TICKET_FAILED,
    RESET_TICKET,
}