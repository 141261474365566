import React from 'react'
import { Modal } from "react-bootstrap";
import CustomButton from '../CustomButton';
import WhiteContainer from '../WhiteContainer';

function BootstrapPopup(props) {
  let { 
    show,
    closeButton = false, 
    setShow, heading, 
    contentClassName, 
    headerClassName, bodyClassName, 
    footerClassName, children,
    disabled = true,
    updating = false
  } = props;
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={'static'}
      className={contentClassName || " "}
    >
     <Modal.Header className={headerClassName || " "}>
        <Modal.Title>{heading}</Modal.Title>
        {closeButton ? <button>x</button> : null}
      </Modal.Header>
      <WhiteContainer style={{borderRadius: "18px", width: "auto", maxHeight: '90vh'}}>
        <Modal.Body className={bodyClassName || " "}>
          {children}
        </Modal.Body>

        <Modal.Footer className={footerClassName || " "}>
          <CustomButton width='20%' infoRound onClick={() => setShow(false)}>Cancel</CustomButton>
          <CustomButton green width='20%' infoRound onClick={() => setShow(true)} disabled={(!disabled || updating)}>{updating ? "Wait.." : "Submit"}</CustomButton>
        </Modal.Footer>
      </WhiteContainer>
    </Modal>
  )
}

export default BootstrapPopup;
