import { LOGIN } from '../constants';
import { takeEvery, put } from 'redux-saga/effects';
import { localStorageSetItem } from '../utils/helper';

function* handleLogin({ payload }) {
  let { userId = '', username = '', callUsername = '', callPassword = '', callServer = '', callToken ='', userToken = '', refreshToken = '' } = payload;
  let localStorageData = {
    'authenticated': true,
    'userID': userId,
    'username': username,
    'lintelusername': callUsername,
    'lintelpwd': callPassword,
    'lintelserver': callServer,
    'linteltoken': callToken,
    'userToken': userToken,
    'refreshToken': refreshToken
  }
  yield localStorageSetItem(localStorageData);
  yield put({ type: LOGIN.SET_LOCALSTORAGE_AUTH_TRUE, payload });
}

export default function* watchLoginSuccess() {
  yield takeEvery(LOGIN.LOGIN_SUCCESS, handleLogin)
}
