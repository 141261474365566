import React, { Fragment, useRef, useContext, useState, useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyContainer from '../../components/EmptyContainer';
import { 
  QuoteBookWrapper, 
  QuoteHeads, 
  VehicleData, 
  CheckboxWrapper, 
  EmptyContainerWrapper,
  DisplayCallState,
  PlaceCallText,
  VehicleInputs,
} from './styles';
import { Form, Overlay, Tooltip } from 'react-bootstrap';
import { TableData, OptionsTable } from '../../components/HospitalAndAmbulanceOptions';
import CustomButton from './../../components/CustomButton/index';
import phone from "./../../images/phone.svg";
import ButtonSvg from './../../components/ButtonSvg/index';
// import { WebSocketContext } from '../../utils/websocket';
import { loadMoreVehicles } from '../../actions/quoteAndBookAction';
import PopupModal from '../../components/PopupModal';
import { EditIcon, InfoIcon, TickIcon } from '../../utils/icons';
import { createNotification, formatDateSmallMonthYear, secToTime, convertTimeToMinutes } from '../../utils/helper';
import { updateTicketDetails } from '../../actions/ticketAction';
import { updateDisplayAmbulanceList } from '../../actions/patientInfoAction';

let displayProps = ['Amb No.', 'ETA(mins)', 'Dist(kms)', 'Base Fare', 'HN Share','Total Cost','', ''];

function NearbyAmbulances({ handleSelect, disable }) {
  const [openCallPopup, setCallPopup] = useState(false);
  const target = useRef(null);
  const [edit, setEdit] = useState(false);
  const [mRateCard, setMRateCard] = useState({});
  const scrollRef = useRef(null);
  const ticketData = useSelector(state => state.patientInfo?.ticketData || {});
  const loadingVehicles = useSelector(state => state.vehicleList?.isPending || false);
  const vehiclesEta = useSelector(state => state.vehicleList?.vehiclesEta || false);
  let vehicleList = useSelector(state => state.nearbyAmbulances?.displayedAmbulanceList || []);
  let calculatingEtaDist = useSelector(state => state.nearbyAmbulances?.calculatingEtaDist || false);
  let callEvents = useSelector(state => state.callEvents || {});
  let hangupCallIds = callEvents.hangupCallIds || [];
  // console.log('hangupCallIds',hangupCallIds);
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  let quoteDetails = ticketData?.quoteDetails || {};
  let allVehicleList = quoteDetails?.nearbyAmbulanceDetails || []; 
  let selectedAmbulance = quoteDetails?.selectedAmbulanceDetails || {}; 

  
  const handleScroll = (e) => {
    if(disable) return;
    let el = scrollRef.current;
    if(!calculatingEtaDist && !loadingVehicles && (el.scrollHeight - (el.scrollTop < 240)) && vehicleList.length !== allVehicleList.length){
      setTimeout(() => {
        dispatch(loadMoreVehicles(allVehicleList))
      }, 100)
    }
  }

  // const ws = useContext(WebSocketContext);

  const placeCall = (vehicleId, number) => {
    let callNum = number.split("/");
    if(callNum && Array.isArray(callNum)){
      if(callNum.length === 1){
        // ws.dial(number, 'ambulance', vehicleId);
        if(openCallPopup){
          setCallPopup(false)
        }
      }else if(callNum.length > 1){
        setCallPopup({vehicleId, callNum})
      }
    }
  }

  const closeCallPopup = () => {
    setCallPopup(false)
  }

  const editRate = (vehicleId) => {
    if(disable) return;
    if(edit && edit === vehicleId) {
      const updatedVehicleList = [...allVehicleList];
      const vIndex = updatedVehicleList.findIndex(v => v?.vehicleObject?.id === edit);
      if(vIndex !== undefined && updatedVehicleList[vIndex]) {
        updatedVehicleList[vIndex] = {
          ...updatedVehicleList[vIndex],      
          modified: true,
          modifiedRateCard: {
            ...(updatedVehicleList[vIndex].modifiedRateCard || {}),
            ...mRateCard
          },
        }
      }
      const newQuote = {
        ...quoteDetails, 
        nearbyAmbulanceDetails: [...updatedVehicleList],
      }
      const displayAmbList = [];
      updatedVehicleList.forEach(v => {
        vehicleList.forEach(vel => {
          if(vel.vehicleObject.id === v.vehicleObject.id){
            displayAmbList.push(v);
          }
        })
      })
      dispatch(updateTicketDetails('quoteDetails', newQuote, () => {
        createNotification('success','Updated Rates Saved!')
        dispatch(updateDisplayAmbulanceList(displayAmbList))
        setEdit(false);
      }))
    }else {
      const vIndex = vehicleList.findIndex(v => v?.vehicleObject?.id === vehicleId);
      const modifiedBaseFare = vehicleList[vIndex]?.modifiedRateCard?.baseFare || vehicleList[vIndex].baseFare || '0';
      const modifiedHNShare = vehicleList[vIndex]?.modifiedRateCard?.helpnowShare || vehicleList[vIndex].helpnowShare || '0'
      const modifiedTCost = vehicleList[vIndex]?.modifiedRateCard?.totalAmountCollected|| vehicleList[vIndex].totalAmountCollected || '0'
      const modifiedRateCard = {
        baseFare: modifiedBaseFare,
        helpnowShare: modifiedHNShare,
        totalAmountCollected: modifiedTCost,
      }
      setMRateCard(modifiedRateCard);
      setEdit(vehicleId);
    }
  }

  const handleModifiedRate = (type, value) => {
    let newMCard = {
      ...mRateCard,
      [type]: value
    };
    newMCard.totalAmountCollected = parseFloat(newMCard.baseFare)+parseFloat(newMCard.helpnowShare)
    setMRateCard(newMCard);
  }

  // console.log(openCallPopup, openCallPopup && openCallPopup.callNum && Array.isArray(openCallPopup.callNum))
  return (
    <>
      {openCallPopup && openCallPopup.callNum && Array.isArray(openCallPopup.callNum) ? <PopupModal showModal={openCallPopup} closeModalBtn={true} setShowModal={() => closeCallPopup()}>
        <p>Select Number To Place Call</p>
        {openCallPopup.callNum.map((num,i) => {
          return <PlaceCallText onClick={() => placeCall(openCallPopup.vehicleId, num)}>Number {i+1}</PlaceCallText>
        })}
      </PopupModal> : null}
      {vehicleList && Array.isArray(vehicleList) && vehicleList.length ? 
        <QuoteBookWrapper ref={scrollRef} onScroll={(e) => handleScroll(e)}>
          {displayProps.map((p,i) => <QuoteHeads key={i}>{p}</QuoteHeads>)}
          {vehicleList.map((ambulance,i) => {
            let index = i;
            let vehicleObj = ambulance?.vehicleObject || null;
            let currentVehicle = selectedAmbulance.vehicleObject && selectedAmbulance.vehicleObject.id && selectedAmbulance.vehicleObject.id === vehicleObj.id ? true : false;
            let vehicleNumber =  vehicleObj?.vehicleNumber || 'NA';
            let etaIndex = vehiclesEta && Array.isArray(vehiclesEta) ? vehiclesEta.findIndex((v) => v.id === vehicleObj.id) : [];
            let time = vehiclesEta[etaIndex] && vehiclesEta[etaIndex].duration && vehiclesEta[etaIndex].duration.text ? vehiclesEta[etaIndex].duration.text : 'NA';
            let compareTime = vehiclesEta[etaIndex] && vehiclesEta[etaIndex].duration && vehiclesEta[etaIndex].duration.text ? vehiclesEta[etaIndex].duration.text : 'NA';
            let distance = vehiclesEta[etaIndex] && vehiclesEta[etaIndex].distance && vehiclesEta[etaIndex].distance.text ? vehiclesEta[etaIndex].distance.text : 'NA';
            let isOnCall = hangupCallIds && Array.isArray(hangupCallIds) ? hangupCallIds.findIndex(obj => obj.number === vehicleObj.vehicleOwnerContact) : -1;
            let callState = isOnCall !== -1 ? hangupCallIds[isOnCall].callState : false;
            let baseFare = ambulance.modified && ambulance.modifiedRateCard && ambulance.modifiedRateCard.baseFare ? ambulance.modifiedRateCard.baseFare : ambulance.baseFare;
            let helpnowShare = ambulance.modified && ambulance.modifiedRateCard && ambulance.modifiedRateCard.helpnowShare ? ambulance.modifiedRateCard.helpnowShare : ambulance.helpnowShare;
            let totalAmountCollected = ambulance.modified && ambulance.modifiedRateCard && ambulance.modifiedRateCard.totalAmountCollected ? ambulance.modifiedRateCard.totalAmountCollected : ambulance.totalAmountCollected;
            return {
              ambulance,
              vehicleObj,
              currentVehicle,
              vehicleNumber,
              time,
              distance,
              isOnCall,
              callState,
              baseFare,
              helpnowShare,
              totalAmountCollected,
              index,
              compareTime,
            };
          })
            .sort((a, b) => {
              let hourMinPattern = /^\d+ hour \d+ min$/;
              let hourMinPattern1 = /^\d+ hour \d+ mins$/;
              let hourMinPattern2 = /^\d+ hours \d+ min$/;
              let hourMinPattern3 = /^\d+ hours \d+ mins$/;

              if (hourMinPattern.test(a.compareTime) || hourMinPattern1.test(a.compareTime)||hourMinPattern2.test(a.compareTime)||hourMinPattern3.test(a.compareTime)) {
                a.compareTime = convertTimeToMinutes(a.compareTime)
              }
              if (hourMinPattern.test(b.compareTime) || hourMinPattern1.test(b.compareTime)||hourMinPattern2.test(b.compareTime)||hourMinPattern3.test(b.compareTime)) {
                b.compareTime = convertTimeToMinutes(b.compareTime)
              }
              // Convert time to a numeric value for comparison
              const timeA = a.compareTime === 'NA' ? Infinity : parseFloat(a.compareTime);
              const timeB = b.compareTime === 'NA' ? Infinity : parseFloat(b.compareTime);

              return timeA - timeB;
            })
            .map(({ ambulance, vehicleObj, currentVehicle, vehicleNumber, time, distance, isOnCall, callState, baseFare, helpnowShare, totalAmountCollected, index }) => {
              return <Fragment key={vehicleObj.id}>
              <VehicleData>{vehicleNumber || 'NA'}({vehicleObj?.vehicleOwnerName || 'NA'}) <span style={{fontWeight: 'bold'}}>({vehicleObj?.driverStatus || '--'})</span></VehicleData>
              {/* <VehicleData onClick={() => handleSelect(i)}>{vehicleNumber}</VehicleData> */}
              <VehicleData>{time || 'NA'}</VehicleData>
              <VehicleData>{distance || 'NA'}</VehicleData>
              {edit && edit === vehicleObj.id ? 
                <>
                  <VehicleInputs value={mRateCard.baseFare || '0'} onChange={(e) => handleModifiedRate('baseFare', e.target.value)}/>
                  <VehicleInputs value={mRateCard.helpnowShare || '0'} onChange={(e) => handleModifiedRate('helpnowShare', e.target.value)}/>
                  <VehicleInputs value={mRateCard.totalAmountCollected || '0'} onChange={(e) => handleModifiedRate('totalAmountCollected', e.target.value)}/>
                </>
              : <>
                <VehicleData>{baseFare || '0'}</VehicleData>
                <VehicleData>{helpnowShare || '0'}</VehicleData>
                <VehicleData>{totalAmountCollected || '0'}</VehicleData>
              </>}
                {/* <CustomButton 
                  disabled={onCall && onCall !== vehicleObj.id}
                infoRound padding='.8rem' onClick={() => placeCall(vehicleObj.id, vehicleObj.vehicleOwnerContact)}
              >
                <ButtonSvg src={phone} /> {(isOnCall !== -1 && callState) ? callState : 'Call Vendor'}
              </CustomButton> */}
              
              {isOnCall !== -1 && callState ? 
                <DisplayCallState callState={callState}>{callState}</DisplayCallState> 
              : <CustomButton 
                  vendorRound
                  onClick={() => placeCall(vehicleObj.id, vehicleObj.vehicleOwnerContact)}>
                    <ButtonSvg vendorImg src={phone} /> &nbsp;&nbsp; Call Vendor 
                </CustomButton>}
              <CheckboxWrapper className='in-t'>
                <span className='ed-t' disabled={disable} onClick={() => editRate(vehicleObj.id)}>
                  {!edit ? 
                    <EditIcon /> 
                  : edit && edit === vehicleObj.id ? <TickIcon />
                  : null}
                </span>
                <Form.Check
                  disabled={disable} 
                  checked={currentVehicle} 
                  value={currentVehicle} 
                  onChange={() => handleSelect(index)} 
                />
                {ambulance.modified && <span 
                  className='ed-t' 
                  ref={target} 
                  onClick={() => setShow(show === vehicleObj.id ? false : vehicleObj.id)}
                >
                  <InfoIcon />
                </span>}
                <Overlay target={target.current} show={show === vehicleObj.id} placement="right">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      {/* modifiedAt: {secToTime(ambulance.modifiedRateCard.modifiedAt)} */}
                      B Fare: {ambulance?.baseFare || 0} <br />
                      HN Share: {ambulance?.helpnowShare || 0} <br />
                      T Cost: {ambulance?.totalAmountCollected || 0}
                    </Tooltip>
                  )}
                </Overlay>
              </CheckboxWrapper>
            </Fragment>;
          })} 
        </QuoteBookWrapper> 
      : null}
      {!vehicleList.length && !loadingVehicles && <EmptyContainerWrapper allAmbulance><EmptyContainer text='No Idle Vehicles Found' /></EmptyContainerWrapper>}
      {!vehicleList.length && loadingVehicles &&
        <OptionsTable columns={8}>
          {[1,2,3,4].map((i) => (
            <Fragment key={i}>
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
              <TableData className={`skeleton-loader ${i === 1 ? "skeleton-head" : ""}`}></TableData> 
            </Fragment>
          ))}
        </OptionsTable>
      }
    </>
  )
} 

export default NearbyAmbulances;
