import React from 'react'
import { useState } from 'react';
import { Button, Form, Modal, Alert } from 'react-bootstrap'
import WhiteContainer from '../../components/WhiteContainer';
import CustomButton from '../../components/CustomButton';
import { PropInternalWrapper } from '../PatientInfoPage/styles';


function EditExtraChargePopup(props) {
    let {
        show,
        closeButton = false,
        setShow,
        // heading, 
        contentClassName,
        headerClassName, bodyClassName,
        footerClassName, children,
        disabled = true,
        updating = false,
        extraChargesMapperMap,
        setAddonsData,
        addonsData,
        extracharges,
        handleInputChange,
        handleupdateextracharges,
        errors,
    } = props;


    let totalextracharges = Object?.values(addonsData).reduce(
        (total, addon) => parseFloat(total) + parseFloat(addon.amountForAddons),
        0
    );
    console.log('addonsData', addonsData, "errors", errors);
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={'static'}
            className={contentClassName || " "}
        >
            <Modal.Header className={headerClassName || " "}>
                <Modal.Title>Extra Charges</Modal.Title>
                {closeButton ? <button>x</button> : null}
            </Modal.Header>
            <WhiteContainer style={{ borderRadius: "18px", width: "auto", maxHeight: '90vh' }}>
                <Modal.Body className={bodyClassName || " "}>
                    <Form className='in-t'>
                        {
                            extracharges?.map(({ id, label, unit }) => {
                                return (
                                    <PropInternalWrapper>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                            <Form.Label>{label}{" "}
                                                {unit && <span>in {unit}</span>}
                                            </Form.Label>
                                            <Form.Control type="text"
                                                value={addonsData[id]?.numberOfAddons}
                                                onChange={e => handleInputChange(id, 'numberOfAddons', e.target.value)}
                                            />
                                            {errors[`${id}_numberOfAddons`] && (
                                                <p style={{ color: 'red', fontSize: '12px', fontStyle: 'initial' }}>{errors[`${id}_numberOfAddons`]}</p>
                                            )}
                                        </Form.Group>
                                        <Form.Group style={{ width: '150px' }} className="mb-1" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Amount</Form.Label>
                                            <div className='s-c'>
                                                <span className='rs-t'>₹</span>
                                                <Form.Control type="text"
                                                    value={addonsData[id]?.amountForAddons}
                                                    onChange={e => handleInputChange(id, 'amountForAddons', e.target.value)}
                                                    disabled={addonsData[id]?.numberOfAddons <= 0 || !addonsData[id]?.numberOfAddons}
                                                />
                                            </div>
                                            {errors[`${id}_amountForAddons`] && (
                                                <p style={{ color: 'red', fontSize: '12px', fontStyle: 'initial' }} > {errors[`${id}_amountForAddons`]}</p>
                                            )}
                                        </Form.Group>
                                    </PropInternalWrapper>
                                )
                            })
                        }
                        {/* <PropInternalWrapper>
                            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                <Form.Label>Extra Helper</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group style={{ width: '150px' }} className="mb-1" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Amount</Form.Label>
                                <div className='s-c'>
                                    <span className='rs-t'>₹</span>
                                    <Form.Control type="text" />
                                </div>
                            </Form.Group>
                        </PropInternalWrapper>
                        <PropInternalWrapper>
                            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                <Form.Label>Waiting Time in Minutes</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group style={{ width: '150px' }} className="mb-1" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Amount</Form.Label>
                                <div className='s-c'>
                                    <span className='rs-t'>₹</span>
                                    <Form.Control type="text" />
                                </div>
                            </Form.Group>
                        </PropInternalWrapper>
                        <PropInternalWrapper>
                            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                <Form.Label>O<sup>2</sup></Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group style={{ width: '150px' }} className="mb-1" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Amount</Form.Label>
                                <div className='s-c'>
                                    <span className='rs-t'>₹</span>
                                    <Form.Control type="text" />
                                </div>
                            </Form.Group>
                        </PropInternalWrapper> */}
                        <hr className='mt-1 mb-1' />
                        <div style={{ textAlign: 'end', fontWeight: '700', color: '#16999E' }}>Total Extra Charges Cost: {totalextracharges}</div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={footerClassName || " "}>
                    <CustomButton width='20%' infoRound onClick={() => setShow(false)}>Cancel</CustomButton>
                    <CustomButton green width='20%' infoRound onClick={() => { handleupdateextracharges(totalextracharges) }} disabled={Object.values(errors).some(error => error !== null)} >Save</CustomButton>
                </Modal.Footer>
            </WhiteContainer>
        </Modal >
    )
}

export default EditExtraChargePopup