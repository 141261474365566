import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../components/CustomButton";
import ambulance from "../../images/ambulance.svg";
import {
  RightContentWrapper,
  ButtonSvg,
  RadioButtonWrapper,
  TextBoxWrapper,
} from "./styles";
import {
  patientDetailsValid,
  setInvalidPatientDetails,
} from "../../actions/validTicketAction";
import { updateTicketDetails } from "../../actions/ticketAction";
import {
  createNotification,
  checkIfStringIsNumber,
  checkIfEmailValid,
  TextArea,
  InputFactory,
  checkIfNumberIsNumber,
} from "../../utils/helper";
import { InfoErrorMessage } from "../../components/NavTabs";
import { redirectToDashboard } from "./../../actions/redirectAction";
import { getLatestTicketByMobile } from "../../actions/patientInfoAction";
import DispositionModal from "../DispositionModal";

const getTicketDataPropertyValues = (ticketData, id) => {
  const patientDetails = ticketData?.patientDetails || {};
  const leadDetails = ticketData?.leadDetails || {};
  if (id && patientDetails && patientDetails.hasOwnProperty(id)) {
    return {
      value: patientDetails[id] || "",
      prop: id,
      parentProp: "patientDetails",
    };
  } else if (id && leadDetails && leadDetails.hasOwnProperty(id)) {
    return {
      value: leadDetails[id] || "",
      prop: id,
      parentProp: "leadDetails",
    };
  } else if (id && ticketData.hasOwnProperty(id)) {
    return { value: ticketData[id] || "", prop: id, parentProp: "ticketData" };
  } else {
    return "";
  }
};

function RightContent({
  getPropertyValues,
  setPropertyValues,
  setActiveTab,
  toDisable = false,
  displayTieupHospitalList,
  tieupHospitalList = [],
  showHelperNumber,
  setPayByHospital,
  payByHospital,
}) {
  console.log(
    "sattt",
    useSelector((state) => state)
  );
  const ticketData = useSelector((state) => state.patientInfo.ticketData);
  const invalidPatientDetails = useSelector(
    (state) => state.validation.invalidDetailsKeys || []
  );
  let quoteDetails = ticketData?.quoteDetails || {};
  let selectedAmbulance = quoteDetails?.selectedAmbulanceDetails || null;
  const [invalidCall, setInvalidCall] = useState(false);
  const [leadRemark, setLeadRemark] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [openDispositionModal, setOpenDispositionModal] = useState(false);

  const dispatch = useDispatch();
  console.log("tdata", ticketData);
  let p = ticketData?.patientDetails || {};
  let l = ticketData?.leadDetails || {};
  let tieupHospitalName = l?.tieupHospitalName || null;
  let sendInvoice = ticketData ? ticketData?.sendInvoice : true;
  let freeTrip = ticketData?.tripCategory === "FREE";
  let roundTrip = ticketData ? ticketData?.roundTrip : true;
  // console.log("roundTrip " + roundTrip);

  const handleChange = (e) => {
    let value = "FREE";
    if (freeTrip) value = "REGULAR";
    let freeTripData = {
      basePrice: "0",
      helpnowShare: "0",
      patientAmount: "0",
    };
    let regularTripData = {
      basePrice: selectedAmbulance?.modified
        ? selectedAmbulance?.modifiedRateCard?.baseFare
        : selectedAmbulance?.baseFare || "0",
      helpnowShare: selectedAmbulance?.modified
        ? selectedAmbulance?.modifiedRateCard?.helpnowShare
        : selectedAmbulance?.helpnowShare || "0",
      patientAmount: selectedAmbulance?.modified
        ? selectedAmbulance?.modifiedRateCard?.totalAmountCollected
        : selectedAmbulance?.totalAmountCollected || "0",
    };
    let data = freeTrip ? regularTripData : freeTripData;
    dispatch(updateTicketDetails("quoteDetails", data));
    setPropertyValues(value, "tripCategory");
  };

  const handleSendInvoiceChange = (e) => {
    let value = e.target.checked;
    let data = {
      sendInvoice: value,
    };
    dispatch(updateTicketDetails("ticketDetails", data));
    setPropertyValues(value, "sendInvoice");
  };

  const patientValidationInfo = (isCancel) => {
    console.log("ppppp", p);
    let isValid = false;
    let newArr = [...invalidPatientDetails];
    let details = {
      callerName: p?.callerName || false,
      callerCity: p?.callerCity || false,
      patientName: isCancel ? isCancel : p?.patientName || false,
      patientEmail: p
        ? !p.patientEmail ||
          (p.patientEmail && checkIfEmailValid(p.patientEmail))
        : false,
      patientMobile: isCancel
        ? isCancel
        : p?.patientMobile && checkIfStringIsNumber(p.patientMobile, 10)
        ? p.patientMobile
        : false,
      patientAmbulanceType: isCancel
        ? isCancel
        : p?.patientAmbulanceType || false,
      leadSource: l?.leadSource || false,
      pickupLocation: isCancel
        ? isCancel
        : l
        ? !l.pickupLocation ||
          !l.dropLocation ||
          l.pickupLocation !== l.dropLocation
        : false,
      dropLocation: isCancel
        ? isCancel
        : l
        ? !l.pickupLocation ||
          !l.dropLocation ||
          l.pickupLocation !== l.dropLocation
        : false,
      remark: isCancel ? leadRemark : "not required",
      transferType: ticketData?.transferType || false,
      tripTimeZone: ticketData?.tripTimeZone || false,
      // callReferredBy:
      // numberofExtraHelper: ticketData?.extraHelper || showHelperNumber === true ? (p?.numberofExtraHelper && checkIfNumberIsNumber(p.numberofExtraHelper) ? p.numberofExtraHelper : false) : true
    };
    Object.keys(details).forEach((key) => {
      let index = newArr?.findIndex((i) => i === key);
      if (index === -1 && details[key] === false) {
        newArr.push(key);
      } else if (index !== -1 && details[key]) {
        newArr.splice(index, 1);
      }
    });
    dispatch(setInvalidPatientDetails(newArr));
    isValid = newArr.length ? false : true;
    return isValid;
  };

  const dispositionValidationInfo = (isCancel) => {
    let isValid = false;
    let newinvalidpatientdetails = invalidPatientDetails.filter(
      (item) => item !== "patientMobile" && item !== "patientName"
    );
    let newArr = [...newinvalidpatientdetails];
    let details = {
      callerName: p?.callerName || false,
      callerCity: p?.callerCity || false,
      // patientName: isCancel ? isCancel : (p?.patientName || false),
      // patientEmail: p ? (!p.patientEmail || (p.patientEmail && checkIfEmailValid(p.patientEmail))) : false,
      // patientMobile: isCancel ? isCancel : (p?.patientMobile && checkIfStringIsNumber(p.patientMobile, 10) ? p.patientMobile : false),
      patientAmbulanceType: isCancel
        ? isCancel
        : p?.patientAmbulanceType || false,
      leadSource: l?.leadSource || false,
      // pickupLocation: isCancel ? isCancel : (l ? ((!l.pickupLocation || !l.dropLocation) || (l.pickupLocation !== l.dropLocation)) : false),
      // dropLocation: isCancel ? isCancel : (l ? ((!l.pickupLocation || !l.dropLocation) || (l.pickupLocation !== l.dropLocation)) : false),
      // remark: isCancel ? leadRemark : 'not required',
      // numberofExtraHelper: ticketData?.extraHelper || showHelperNumber === true ? (p?.numberofExtraHelper && checkIfNumberIsNumber(p.numberofExtraHelper) ? p.numberofExtraHelper : false) : true
    };
    Object.keys(details).forEach((key) => {
      let index = newArr?.findIndex((i) => i === key);
      if (index === -1 && details[key] === false) {
        newArr.push(key);
      } else if (index !== -1 && details[key]) {
        newArr.splice(index, 1);
      }
    });
    dispatch(setInvalidPatientDetails(newArr));
    isValid = newArr.length ? false : true;
    return isValid;
  };

  const mapToOldTicket = (oldTicketData) => {
    let data = { ...oldTicketData.data };
    if (data) {
      let newData = {};
      const properties = [
        "callerCity", // from patienDetails
        "callerName",
        "patientAmbulanceAddOns",
        "patientAmbulanceType",
        "patientEmail",
        "patientExpectations",
        "patientMobile",
        "patientName",
        "dropLocation", // from leadDetails
        "leadSource",
        "pickupLocation",
        "email", // from data
        "tripCategory",
        // 'numberofExtraHelper',
        // 'extraHelper'
      ];

      properties.forEach((prop) => {
        let {
          value,
          prop: key,
          parentProp,
        } = getTicketDataPropertyValues(data, prop);
        if (parentProp === "ticketData") {
          if (value === "") {
            value = null;
          }
          newData = { ...newData, [key]: value };
        } else {
          if (value === "") {
            value = null;
          }
          newData[parentProp] = {
            ...(newData[parentProp] || {}),
            [key]: value,
          };
        }
      });
      // console.log(newData)
      dispatch(updateTicketDetails("ticketDetails", newData));
      // let matchData = getRequiredOldData(data)
    }
    // console.log('mapToOldTicket', tempTicketData)
  };
  const enterPatientInfo = (update, validate = false) => {
    console.log("update", update, validate);
    if (validate) {
      let isValid = patientValidationInfo(false);
      if (!isValid) {
        dispatch(patientDetailsValid(false, "patientInfo"));
        createNotification(
          "error",
          "Please fill all mandatory fields with appropriate details"
        );
      } else {
        if (ticketData?.extraHelper || showHelperNumber) {
          p.numberofExtraHelper = parseInt(p.numberofExtraHelper);
        } else {
          p.numberofExtraHelper = null;
        }
        dispatch(patientDetailsValid(true, "patientInfo"));
        if (update) {
          // setChecked(!checked);
          setUpdating(true);
          dispatch(
            updateTicketDetails(
              "ticketDetails",
              {
                ticketStage: "PATIENTINFO",
                roundTrip: checked,
                payByHospital: payByHospital,
              },
              (res) => {
                setUpdating(false);
                if (res) {
                  createNotification("success", "Your ticket has been updated");
                  setActiveTab("quote");
                }
              }
            )
          );
        }
      }
      setInvalidCall(false);
    } else {
      let basicValid = patientValidationInfo(true);
      if (invalidCall && basicValid) {
        let updatedDetails = {
          ticketStage: "CANCELLED",
          quoteDetails: {
            ...quoteDetails,
            cancelledTrip: true,
          },
          leadDetails: {
            ...l,
            subDisposition: invalidCall,
            remark: leadRemark,
          },
        };
        if (update) {
          dispatch(
            updateTicketDetails("ticketDetails", updatedDetails, (res) => {
              if (res) {
                createNotification("success", "Your ticket has been cancelled");
                dispatch(redirectToDashboard());
              }
            })
          );
        }
      } else {
        createNotification(
          "error",
          "Please fill all mandatory fields with appropriate details"
        );
      }
      // console.log(basicValid);
    }
  };
  const [checked, setChecked] = useState(false);

  const handleChangeRoundtrip = () => {
    setChecked(!checked);
    console.log(checked);
  };

  const handleChangeHospital = () => {
    setPayByHospital(!payByHospital);
  };

  const setData = () => {
    let updatedTicketData = { ...ticketData };
    let updateLeadDetails = {};
    let quoteType = "cancelled";
    let ticketStage = ticketData.ticketStage;
    setUpdating("cancel");
    ticketStage = "CANCELLED";
    dispatch(redirectToDashboard());
    let quoteData = {
      ...quoteDetails,
      [quoteType]: true,
    };
    updatedTicketData = {
      ...updatedTicketData,
      ticketStage,
      quoteDetails: {
        ...quoteData,
        ...updateLeadDetails,
      },
    };
    dispatch(
      updateTicketDetails("ticketDetails", updatedTicketData, (res, msg) => {
        setUpdating(false);
        if (res) {
          createNotification("success", "Ticket Details Updated !");
        }
      })
    );
  };

  const openDispositionPopUp = (modal, submit) => {
    setOpenDispositionModal(modal);
    if (submit) {
      setData();
    }
  };

  const dispositioncheck = (validate = false) => {
    if (validate) {
      let isValid = dispositionValidationInfo(false);
      if (!isValid) {
        dispatch(patientDetailsValid(false, "dispositioninfo"));
        createNotification(
          "error",
          "Please fill all mandatory fields with appropriate details"
        );
      } else {
        openDispositionPopUp("cancelled");
      }
    }
  };

  return (
    <RightContentWrapper>
      {openDispositionModal && (
        <DispositionModal
          updating={updating}
          dispositionType={openDispositionModal}
          handleModal={(val, type) => openDispositionPopUp(val, type)}
          selectedAmbulance={selectedAmbulance}
        />
      )}
      <div>
        {displayTieupHospitalList ||
        ticketData?.leadDetails?.leadSource === "HOSPITALSTAFF" ? (
          <InputFactory
            type="select"
            id={"tieupHospitalName"}
            options={tieupHospitalList}
            value={tieupHospitalName || ""}
            label={"Hospital Name"}
            className={"in-t"}
            placeholder={"Select Hospital Name"}
            onChange={(e) =>
              setPropertyValues(e.target.value, "tieupHospitalName")
            }
            disabled={toDisable}
          />
        ) : null}
        {displayTieupHospitalList ||
        ticketData?.leadDetails?.leadSource === "HOSPITALSTAFF" ? (
          <Form className={"a-it f-trip vr"}>
            <Form.Group>
              <InputGroup.Checkbox
                checked={payByHospital}
                value={payByHospital}
                onChange={handleChangeHospital}
              />
              <Form.Label>Pay by Hospital</Form.Label>
            </Form.Group>
          </Form>
        ) : null}
        <Form className={"a-it f-trip"}>
          {/* <Form.Group>
            <InputGroup.Checkbox checked={sendInvoice} value={sendInvoice} onChange={(e) => handleSendInvoiceChange(e)} disabled={toDisable}/>
            <Form.Label>Send Invoice</Form.Label>
          </Form.Group> */}
          <Form.Group>
            <InputGroup.Checkbox
              checked={freeTrip}
              value={"FREE"}
              onChange={(e) => handleChange(e)}
              disabled={toDisable}
            />
            <Form.Label>Free Trip</Form.Label>
          </Form.Group>
          <Form.Group>
            <InputGroup.Checkbox
              value={checked}
              onChange={handleChangeRoundtrip}
              disabled={toDisable}
            />
            <Form.Label>Round Trip</Form.Label>
          </Form.Group>
        </Form>
        <CustomButton
          infoRound
          width="100%"
          green
          onClick={() => enterPatientInfo(true, true)}
          disabled={toDisable || updating}
        >
          <ButtonSvg src={ambulance} />
          {updating ? "Wait.." : "Find Ambulance"}
        </CustomButton>
      </div>
      <div>
        {/* <TextBoxWrapper>
          <RadioButtonWrapper>
            <Form.Check 
              disabled={toDisable} 
              className='f-radio in-f-radio' 
              type='radio' 
              name='group1' 
              id='inline-radio-2' 
              checked={invalidCall && invalidCall === 'EnquiryCall'} 
              label={'Enquiry'} 
              value={'EnquiryCall'} 
              onChange={(e) => setInvalidCall(e.target.value)}
            />
            <Form.Check 
              disabled={toDisable} 
              className='f-radio in-f-radio' 
              type='radio' 
              name='group1' 
              id='inline-radio-1' 
              checked={invalidCall && invalidCall === 'SalesCall'} 
              label={'Sales'} 
              value={'SalesCall'} 
              onChange={(e) => setInvalidCall(e.target.value)}
            />
            <Form.Check 
              disabled={toDisable} 
              className='f-radio in-f-radio' 
              type='radio' 
              name='group1' 
              id='inline-radio-1' 
              checked={invalidCall && invalidCall === 'B2BCall'} 
              label={'B2B'} 
              value={'B2BCall'} 
              onChange={(e) => setInvalidCall(e.target.value)}
            />
            <Form.Check 
              disabled={toDisable} 
              className='f-radio in-f-radio' 
              type='radio' 
              name='group1' 
              id='inline-radio-1' 
              checked={invalidCall && invalidCall === 'InvalidCall'} 
              label={'Invalid'} 
              value={'InvalidCall'} 
              onChange={(e) => setInvalidCall(e.target.value)}
            />
          </RadioButtonWrapper>
        {invalidCall && 
          <>
            <div className="inv-c-wrapper">
              <TextArea 
                className='in-t' 
                onChange={(e) => setLeadRemark(e.target.value)}
                value={leadRemark || ''}
              />
            </div>
            {invalidPatientDetails && Array.isArray(invalidPatientDetails) && invalidPatientDetails.length && invalidPatientDetails.includes('remark') ? 
              <InfoErrorMessage marginBottom='0.3rem'>Add Remark</InfoErrorMessage> 
            : null}
            <CustomButton 
              infoRound 
              width='80%' 
              height='5.5vh' 
              green 
              onClick={() => enterPatientInfo(true, false)} 
              disabled={toDisable || updating}
              marginRight='0'
            >
              {updating ? "Wait.." : "Submit"}
            </CustomButton>
          </>}
        </TextBoxWrapper> */}
        <CustomButton
          infoRound
          width="80%"
          height="5.5vh"
          red
          marginRight="auto"
          marginLeft="auto"
          marginTop="1rem"
          onClick={
            () => dispositioncheck(true)
            // openDispositionPopUp("cancelled")
          }
          disabled={toDisable}
        >
          {"Dispose Ticket"}
        </CustomButton>
        <CustomButton
          infoRound
          width="80%"
          height="5.5vh"
          green
          onClick={() =>
            dispatch(
              getLatestTicketByMobile(
                ticketData.mobile,
                ticketData.ticketId,
                mapToOldTicket
              )
            )
          }
          disabled={toDisable || updating}
          marginRight="auto"
          marginLeft="auto"
          marginTop="1rem"
        >
          Map to Old Ticket
        </CustomButton>
      </div>
    </RightContentWrapper>
  );
}

export default RightContent;
