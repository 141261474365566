import React from 'react'
import { useSelector } from 'react-redux';
// import NewDispositionModal from '../DispositionModal/index1';
// import { updateTicketDetails } from '../../actions/ticketAction';
import { ButtonsWrapper } from './styles';
import CustomButton from '../../components/CustomButton';
import confirm from "../../images/confirm.svg";
import ButtonSvg from '../../components/ButtonSvg';
import { isValueEqual } from '../../utils/helper';

function ButtonSections({ openDispositionPopUp, toDisable = false, updating = false }) {
  const ticket = useSelector(state => state.patientInfo?.ticketData || {});
  const ticketStage = ticket?.ticketStage || false;

  return (
    <ButtonsWrapper>
      <CustomButton orange infoRound width='15%'
        disabled={(isValueEqual(ticketStage,"CANCELLED") || isValueEqual(ticketStage,"CONFIRMED") || toDisable) ? true : false} 
        onClick={() => openDispositionPopUp("hold")}
      >
        {updating && updating === "hold" ? "Wait..." : "On hold"} 
      </CustomButton>
      <CustomButton 
        infoRound 
        width='17%' 
        onClick={() => openDispositionPopUp("cancelled")}
        disabled={toDisable}
      > 
        {updating && updating === "cancel" ? "Wait..." : "Cancel Trip"}  
      </CustomButton>
      <CustomButton 
        disabled={(isValueEqual(ticketStage,"CANCELLED") || toDisable) ? true : false} 
        green infoRound width='22%' onClick={() => openDispositionPopUp("confirm")}
      >
        <ButtonSvg src={confirm} />
        {updating && updating === "confirm" ? "Wait..." : "Confirm Trip"}  
      </CustomButton>
    </ButtonsWrapper> 
  )
}

export default ButtonSections;
