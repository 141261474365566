import { CALL, CALL_STATES, REQUEST_TICKET, TICKETS } from '../constants';

const INITIAL_STATE = {
  onCallNumbers: [],
  hangupCallIds: [],
  answerCallIds: [],
  latestCallNumber: {},
  incomingCall: false,
  isMute: false,
  onConference: false,
}

const callSocketReducer = (state = INITIAL_STATE, { type, payload }) => {
  let hangupCallIds = [...state.hangupCallIds]; 
  let latestCallNumber = state.latestCallNumber;
  switch(type){
    case CALL.DIAL_CALL_EVENT: 
      if(payload && payload.data && payload.data.number){
        latestCallNumber = payload.data.number;
        return {
          ...state,
          latestCallNumber : {
            number: latestCallNumber,
            from: payload.from,
            id: payload.id
          },
        }
      }
      return state;
      
    case CALL.RINGING_CALL_EVENT: {
      let onCallNumbers = [...state.onCallNumbers];
      let isAlreadyOn = hangupCallIds.findIndex(obj => obj.number === latestCallNumber.number);
      if(isAlreadyOn === -1){
        let obj = {
          ...latestCallNumber,
          hangupUpCallId: payload['call-id'], 
          callState: CALL_STATES.DIAL_STATE,
        };
        hangupCallIds.push(obj);
        onCallNumbers.push(latestCallNumber.number);
      }else if(hangupCallIds[isAlreadyOn].callState === CALL_STATES.DISCONNECTED_STATE){
        hangupCallIds[isAlreadyOn].callState = CALL_STATES.DIAL_STATE;
        hangupCallIds[isAlreadyOn].hangupUpCallId = payload['call-id'];
      }
      return {
        ...state,
        hangupCallIds,
        onCallNumbers,
      }
    }

    case CALL.ANSWERED_CALL_EVENT: {
      let isAlreadyOn = hangupCallIds.findIndex(obj => obj.hangupUpCallId === payload['call-id']);
      if(isAlreadyOn !== -1){
        hangupCallIds[isAlreadyOn].callState = CALL_STATES.ANSWERED_STATE;
      }
      return {
        ...state,
        hangupCallIds,
        incomingCall: false,
        incomingCallStatusCode: false
      }
    }
    
    case CALL.HANGUP_CALL_EVENT: {
      let isAlreadyOn = hangupCallIds.findIndex(obj => payload['call-id'] === obj.hangupUpCallId);
      if(isAlreadyOn !== -1){
        hangupCallIds[isAlreadyOn].callState = CALL_STATES.DISCONNECTED_STATE;
      }
      return {
        ...state,
        hangupCallIds,
        incomingCall: false,
        incomingCallStatusCode: false
      }
    }

    case CALL.HOLD_CALL_EVENT: {
      let isAlreadyOn = hangupCallIds.findIndex(obj => payload['call-id'] === obj.hangupUpCallId);
      if(isAlreadyOn !== -1){
        hangupCallIds[isAlreadyOn].callState = CALL_STATES.HOLD_STATE;
      }
      return {
        ...state,
        hangupCallIds
      }
    }
    
    case CALL.INCOMING_CALL_EVENT: {
      console.log(payload, 'incoming call');
      if(payload && payload.caller && hangupCallIds && Array.isArray(hangupCallIds)){
        let latestCallNumber = { number: payload.caller, from: 'patients', id: null };
        let onCallNumbers = [...state.onCallNumbers];
        let isAlreadyOn = hangupCallIds.findIndex(obj => obj.number === latestCallNumber.number);
        if(isAlreadyOn === -1){
          let obj = {
            ...latestCallNumber,
            hangupUpCallId: payload['call-id'], 
            callState: CALL_STATES.INCOMING_CALL_STATE,
          };
          hangupCallIds.push(obj);
          onCallNumbers.push(latestCallNumber.number);
        }else if(hangupCallIds[isAlreadyOn].callState === CALL_STATES.DISCONNECTED_STATE){
          hangupCallIds[isAlreadyOn].callState = CALL_STATES.INCOMING_CALL_STATE;
          hangupCallIds[isAlreadyOn].hangupUpCallId = payload['call-id'];
        }
        return {
          ...state,
          latestCallNumber,
          onCallNumbers,
          hangupCallIds
        }
      }
      return state;
    }

    case CALL.MUTE_CALL_EVENT: {
      return {
        ...state,
        isMute: true
      }
    }

    case CALL.UNMUTE_CALL_EVENT: {
      return {
        ...state,
        isMute: false
      }
    }

    case CALL.CONFERENCE_CALL_EVENT: {
      return {
        ...state,
        onConference: true
      }
    }

    case REQUEST_TICKET.SET_REQUEST_TICKET:{
      return {
        ...state,
        incomingCall: payload.mobile,
        incomingCallStatusCode: { statusCode: 200 }
      }
    }

    case TICKETS.CREATE_TICKET_SUCCESS: {
      return {
        ...state,
        incomingCall: false,
        incomingCallStatusCode: false
      }
    } 

    case CALL.REMOVE_CALLS_FROM_CALL_LIST: {
      let newCallList = [...hangupCallIds];
      if(payload && Array.isArray(payload) && payload.length){
        newCallList.forEach((obj, i) => {
          if (payload.indexOf(obj.number) !== -1 && obj.callState && obj.callState === CALL_STATES.DISCONNECTED_STATE) {
            newCallList.splice(i, 1);
          }
        })
      }
      return {
        ...state,
        hangupCallIds: newCallList
      }
    }

    case CALL.LOGIN_CALL_EVENT : {
      return {
        ...state
      }
    }

    default: 
      return state;
  }
}


export default callSocketReducer;
