import { CALL, REQUEST_TICKET } from "../constants";

export const getCallDetailsAction = data => ({
  type: REQUEST_TICKET.SET_REQUEST_TICKET,
  payload: data
});


export const updateCallActions = (data) => ({
  type: CALL.UPDATE_CALL_EVENTS,
  payload: data
});

export const handleDialCallEvent = (data, from, id) => ({
  type: CALL.DIAL_CALL_EVENT,
  payload: { data, from, id }
});

export const handleHangupCallEvent = (data) => ({
  type: CALL.HANGUP_CALL_EVENT,
  payload: data
});

export const handleHoldCallEvent = (data) => ({
  type: CALL.HOLD_CALL_EVENT,
  payload: data
});

export const handleRingingCallEvent = (data) => ({
  type: CALL.RINGING_CALL_EVENT,
  payload: data
});

export const handleIncomingCallEvent = (data) => ({
  type: CALL.INCOMING_CALL_EVENT,
  payload: data
});

export const handleLoginCallEvent = (data) => ({
  type: CALL.LOGIN_CALL_EVENT,
  payload: data
});

export const handleAnsweredCallEvent = (data) => ({
  type: CALL.ANSWERED_CALL_EVENT,
  payload: data
});

export const handleLogoutCallEvent = (data) => ({
  type: CALL.LOGOUT_CALL_EVENT,
  payload: data
});

export const handleConferenceCallEvent = (data) => ({
  type: CALL.CONFERENCE_CALL_EVENT,
  payload: data
});

export const handleMuteCallEvent = (data) => ({
  type: CALL.MUTE_CALL_EVENT,
  payload: data
});

export const handleUnmuteCallEvent = (data) => ({
  type: CALL.UNMUTE_CALL_EVENT,
  payload: data
});

export const removeFromCallList = (data) => ({
  type: CALL.REMOVE_CALLS_FROM_CALL_LIST,
  payload: data
});
