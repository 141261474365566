const DIAL_STATE = 'DIALING';
const ANSWERED_STATE = 'ON CALL';
const DISCONNECTED_STATE = 'DISC';
const HOLD_STATE = 'ON HOLD';
const INCOMING_CALL_STATE = 'INCOMING CALL';
const MUTE_STATE = 'MUTE';
const CONFERENCE_STATE = 'CONFERENCE';

export default {
  DIAL_STATE,
  ANSWERED_STATE,
  DISCONNECTED_STATE,
  HOLD_STATE,
  INCOMING_CALL_STATE,
  MUTE_STATE,
  CONFERENCE_STATE,
}