import styled from "styled-components";

export const JourneyDetailsWrapper = styled.div`
  font-size: 0.8em;
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
`;

export const JourneyDetailsPara = styled.p`
 font-size: ${props => props.big ? '12px' : '10px'};
 margin: 0;
`;

export const JourneyText = styled.p`
  font-size: 10px;
  background-color: rgba(253,239,218,1);
  padding: 7px;
  color: rgba(92,128,129,1);
  border-radius: 25px;
  margin: 0;
  margin-left: 5px;
  border: 1px solid rgba(254,167,41,1);
`;

export const ButtonSvg = styled.img`
  width: 15px
`;

export const AutoRefreshWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  margin-left: 5px;
  background: #fff;
  border-radius: 25px;
  padding-right: 10px;
  border: 0.5px solid rgba(22, 153, 158, 1);
`
export const TimerPara = styled.span``

export const AutoRefreshPara = styled.span`
  background-color: rgba(22, 153, 158, 1);
  border-radius: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  margin: 0;
  cursor: pointer;
  svg {
    width: 15px
  }
`;



export const ConfirmBtn = styled.button`
  background-color: ${props => props.teal ? 'rgba(22, 153, 158, 1)' : 'rgba(159, 159, 159, 1)'};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 4px 17px;
  margin: ${props => props.margin || "0px"};
`

export const ButtonSvgWrapper = styled.div`
  background: rgba(22,153,158,1);
  border-radius: 25px;
  width: 30px;
  padding: 5px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 30px;
`;

export const JourneyDetails = styled.table`
  width: 50%;
  height: 100%;
  font-size: 0.8em;
`;

export const ProgressBarTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || 'flex-start'};
  font-size: 0.8vw;
`;

export const ProgressBarWrapper = styled.div`
  width: ${props => props.width || 'auto'};
  background-color: rgba(233, 244, 237, 1);
  padding: 25px 25px 20px 20px;
  border-radius: 20px;
  margin: 21px auto auto auto;
`
export const JourneyDetailsRow = styled.tr``;
export const JourneyDetailsHead = styled.th``;
export const JourneyDetailsData = styled.td``;


export const ButtonEditSvg = styled.img`
  width: 20px
`;
export const EditButton = styled.button`
border:none;
padding: 0px;
background: none;
`;

export const ExtraChargeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || 'flex-start'};
  font-size: 0.8vw;
  margin-bottom: 20px;
`;
export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || 'flex-start'};
  font-size: 0.8vw;
  justify-content: space-between;
`;
export const ExtraChargeHeading = styled.h4`
  font-size: 1vw;
  color:#16999E;
  margin-bottom: 0;
  padding-right: 20px;
`;