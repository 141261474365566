const SET_REQUEST_TICKET = "SET_REQUEST_TICKET";
const REQUEST_TICKET_PENDING = "REQUEST_TICKET_PENDING";
const REQUEST_TICKET_SUCCESS = "REQUEST_TICKET_SUCCESS";
const REQUEST_TICKET_FAILED = "REQUEST_TICKET_FAILED";
const REQUEST_ALL_TICKET_PENDING = "REQUEST_ALL_TICKET_PENDING";
const REQUEST_ALL_TICKETS_SUCCESS = "REQUEST_ALL_TICKETS_SUCCESS";
const REQUEST_ALL_TICKETS_FAILED = "REQUEST_ALL_TICKETS_FAILED";
const REQUEST_LIVE_TICKET_PENDING = "REQUEST_LIVE_TICKET_PENDING";
const REQUEST_LIVE_TICKETS_SUCCESS = "REQUEST_LIVE_TICKETS_SUCCESS";
const REQUEST_LIVE_TICKETS_FAILED = "REQUEST_LIVE_TICKETS_FAILED";
const TICKET_DISPOSITION_TYPES = "TICKET_DISPOSITION_TYPES";
const TICKETS_BY_SEARCH = "TICKETS_BY_SEARCH";
const TICKETS_BY_SEARCH_SUCCESS = "TICKETS_BY_SEARCH_SUCCESS";
const TICKETS_BY_SEARCH_FAILED = "TICKETS_BY_SEARCH_FAILED";
const SEARCH_BY_PAGE = "SEARCH_BY_PAGE";
const EMPTY_SEARCH = "EMPTY_SEARCH";
const REQUEST_LEAD_TICKET_PENDING = "REQUEST_LEAD_TICKET_PENDING";
const REQUEST_LEAD_TICKETS_SUCCESS = "REQUEST_LEAD_TICKETS_SUCCESS";
const REQUEST_LEAD_TICKETS_FAILED = "REQUEST_LEAD_TICKETS_FAILED";
const LEAD_TICKET_PENDING = "LEAD_TICKET_PENDING";
const LEAD_TICKET_SUCCESS = "LEAD_TICKET_SUCCESS";
const LEAD_TICKET_FAILED = "LEAD_TICKET_FAILED";
const LEADERBOARD_DATA = "LEADERBOARD_DATA";
const REQUEST_SCHEDULED_TICKETS_PENDING = "REQUEST_SCHEDULED_TICKETS_PENDING";
const REQUEST_SHEDULED_TICKETS_SUCCESS = "REQUEST_SHEDULED_TICKETS_SUCCESS";
const REQUEST_SCHEDULED_TICKETS_FAILED = "REQUEST_SCHEDULED_TICKETS_FAILED";
const REQUEST_MORE_LEAD_TICKETS_FETCHED = "REQUEST_MORE_LEAD_TICKETS_FETCHED";
const REQUEST_TICKET_HISTORY_PENDING = "REQUEST_TICKET_HISTORY_PENDING";
const REQUEST_TICKET_HISTORY_SUCCESS = "REQUEST_TICKET_HISTORY_SUCCESS";
const REQUEST_TICKET_HISTORY_FAILED = "REQUEST_TICKET_HISTORY_FAILED";

export default {
    REQUEST_ALL_TICKET_PENDING,
    REQUEST_TICKET_PENDING,
    REQUEST_TICKET_SUCCESS,
    REQUEST_TICKET_FAILED,
    SET_REQUEST_TICKET,
    REQUEST_ALL_TICKETS_SUCCESS,
    REQUEST_ALL_TICKETS_FAILED,
    REQUEST_LIVE_TICKET_PENDING,
    REQUEST_LIVE_TICKETS_SUCCESS,
    REQUEST_LIVE_TICKETS_FAILED,
    TICKET_DISPOSITION_TYPES,
    TICKETS_BY_SEARCH,
    TICKETS_BY_SEARCH_SUCCESS,
    TICKETS_BY_SEARCH_FAILED,
    SEARCH_BY_PAGE,
    EMPTY_SEARCH,
    REQUEST_LEAD_TICKET_PENDING,
    REQUEST_LEAD_TICKETS_SUCCESS,
    REQUEST_LEAD_TICKETS_FAILED,
    LEAD_TICKET_PENDING,
    LEAD_TICKET_SUCCESS,
    LEAD_TICKET_FAILED,
    REQUEST_SCHEDULED_TICKETS_PENDING,
    REQUEST_SHEDULED_TICKETS_SUCCESS,
    REQUEST_SCHEDULED_TICKETS_FAILED,
    REQUEST_MORE_LEAD_TICKETS_FETCHED,
    REQUEST_TICKET_HISTORY_PENDING,
    REQUEST_TICKET_HISTORY_SUCCESS,
    REQUEST_TICKET_HISTORY_FAILED,
    LEADERBOARD_DATA,
}  