import styled from "styled-components";

const getWhiteDivBorderRadius = props => {
  if (props.dashboard) {
    return "0px 0px 18px 18px";
  }
  if (props.chat) {
    return "0px 18px 18px 0px";
  } else {
    return "15px";
  }
};

const WhiteCenterDiv = styled.div`
  background-color: ${props => props.backgroundColor || "#ffffff"};
  box-shadow: 0px 4px 16px #00000029;
  border-radius: ${getWhiteDivBorderRadius};
  min-height: ${props => props.minHeight || '50vh'};
  max-height: auto;
  padding: 1.2vw 2vw 1vw 2vw;
`;

export default WhiteCenterDiv;
